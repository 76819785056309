import React, { FC } from 'react';
import {TextField as MTextField} from '@mui/material';
import { makeStyles, createStyles, Theme } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& label': {
        backgroundColor: 'white',
        paddingLeft: '5px',
        paddingRight: '5px',
        transform: 'translate(14px, 17px) scale(1)',
      },
      '& label.MuiFormLabel-filled': {
        transform: 'translate(14px, -9px) scale(0.75)'
      },

      '& label.Mui-focused': {
        transform: 'translate(14px, -9px) scale(0.75)'
      },
      /*
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
      },
      */
      "& .MuiOutlinedInput-input": {
        
      },
      "& .MuiOutlinedInput-input": {
        paddingTop: '16.5px', // to make it 56 pixels height to match other widgets
        paddingBottom: '16.5px',
        //height: '50px'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          //borderColor: 'red',
          border: 'none'
        },
        /*
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        },*/
      },
    },
  })
)

export const TextField = (props) => {
  const classes = useStyles(props)
  /*
  if (props.error) {
    console.log('qwe')
  }*/
  /*
  const inputProps = {
    style: {
      backgroundColor: 'red',
      paddingTop: 0,
      paddingBottom: 0
    }
  }*/
  return (
    <MTextField classes={classes} /*InputProps={inputProps}*/ {...props}/>
  )
}