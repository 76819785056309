import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(4),
      paddingBottom: '76px;', // When in small in small screens, the button will not overlap the elements.
    },
    tileContainer: {
      marginTop: theme.spacing(4),
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
      justifyContent: 'space-between',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    clearButton: {
      backgroundColor: 'transparent',
      border: 'none',
    },
  }),
);
