import React, { FC, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Badge
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ProfileIcon from '@mui/icons-material/PermIdentity';
import MenuIcon from '@mui/icons-material/Menu';
import { AirplacerIcon } from 'assets';
import LoginModal from 'components/modules/LoginModal';
import MobileAppBarMenu from 'components/modules/MobileAppBarMenu';
import { AppBarItem, Avatar, Typo } from 'components/primitives';
import { useStyles } from './AppBar.styles';
import {PORTALS} from 'routes'
import { EnvelopeIcon } from 'assets';

export const NewMessage = () => (
  <div
    style={{
      width: '12px',
      height: '12px',
      backgroundColor: 'rgb(0, 178, 169)',
      borderRadius: '50%',
      marginLeft: '0.5em',
    }}
  >
  </div>
);

const AppBarView = (props) => {
  const {
    goToHomeRoute,
    user,
    goToTerms,
    goToPrivacy,
    goToContactUs,
    goToMyBookings,
    goToSavedListings,
    goToMessages,
    goToInviteFriends,
    goToProfile,
    goToManageListings,
    goToBookingRequests,
    goToScheduleBookings,
    openSignUpModal,
    openLoginModal,
    showModal,
    closeModal,
    logOut,
    image,
    unreadMessages,
    appBarItems,
    setShowMobileMenu,
    showMobileMenu,
    handleCloseMenu,
    anchorEl,
    handleOpenMenu,
    portalName
  } = props;
  const classes = useStyles();



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoggedIn = user;
  
  if (isMobile) {
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={goToHomeRoute}
            >
              <AirplacerIcon />
            </IconButton>
            <div className={classes.spacer} />

            <IconButton
              aria-label="show more"
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={() => setShowMobileMenu(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <LoginModal open={showModal} onClose={closeModal} />
        <MobileAppBarMenu
          open={showMobileMenu}
          onClose={() => setShowMobileMenu(false)}
        >
          {appBarItems.map((appBarItem, i) => {
            return (
              <MenuItem key={i} onClick={appBarItem.onClick}>
                {appBarItem.icon}
                {appBarItem.text}
                {appBarItem.children}
              </MenuItem>
            )
          })}

          {!isLoggedIn ? (null) : (
            <Box>
              <MenuItem onClick={goToProfile}>
                <ListItemIcon>
                  <ProfileIcon />
                </ListItemIcon>
                <ListItemText primary={'Profile'} />
              </MenuItem>
              {portalName == PORTALS.HOST && <MenuItem onClick={props.goToProviders}>Providers</MenuItem>}
              {portalName == PORTALS.CUSTOMER && <MenuItem onClick={goToSavedListings}>My Saved Listings</MenuItem>}
              {portalName == PORTALS.CUSTOMER && <MenuItem onClick={goToInviteFriends}>Invite Friends</MenuItem>}
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  logOut();
                  handleCloseMenu();
                }}
              >
                Log Out
              </MenuItem>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Button style={{ color: 'red' }} onClick={goToTerms}>
                  T&Cs
                </Button>
                <Typo style={{ color: 'grey' }} variant="body2">
                  and
                </Typo>
                <Button style={{ color: 'red' }} onClick={goToPrivacy}>
                  Privacy Policy
                </Button>
              </Box>
            </Box>
          )}
        </MobileAppBarMenu>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={goToHomeRoute}
          >
            <AirplacerIcon />
          </IconButton>
          <div className={classes.spacer} />
          {appBarItems.map((appBarItem, i) => {
            return (
              <AppBarItem
                text={appBarItem.text}
                icon={appBarItem.icon}
                onClick={appBarItem.onClick}
                key={i}
              >
                {appBarItem.children}
              </AppBarItem>
            )
          })}
          {isLoggedIn ? (
            <IconButton
              aria-label="show more"
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={handleOpenMenu}
              color="inherit"
            >
              <Avatar margin={'0'} src={image} />
            </IconButton>
          ) : null}
          <Menu
            elevation={1}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            id={'customized-menu'}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            //getContentAnchorEl={null}
          >
            <Box style={{ width: 400 }}>
              <MenuItem onClick={goToProfile}>
                <ListItemIcon>
                  <ProfileIcon />
                </ListItemIcon>
                <ListItemText primary={'Profile'} />
              </MenuItem>
              {portalName == PORTALS.HOST && <MenuItem onClick={props.goToProviders}>Providers</MenuItem>}
              {portalName == PORTALS.CUSTOMER && <MenuItem onClick={goToMyBookings}>My Bookings</MenuItem>}
              {portalName == PORTALS.CUSTOMER && <MenuItem onClick={goToSavedListings}>My Saved Listings</MenuItem>}
              {portalName != PORTALS.ADMIN && 
                <MenuItem onClick={goToMessages}>
                  <EnvelopeIcon width={20} height={20}/>
                  {unreadMessages ? <NewMessage /> : null}
                </MenuItem>
              }
              {portalName == PORTALS.CUSTOMER && <MenuItem onClick={goToInviteFriends}>Invite Friends</MenuItem>}
              {portalName != PORTALS.ADMIN && <MenuItem onClick={goToContactUs}>Contact Us</MenuItem>}
              <MenuItem
                style={{ color: 'grey' }}
                onClick={() => {
                  logOut();
                  handleCloseMenu();
                }}
              >
                Log Out
              </MenuItem>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Button style={{ color: 'red' }} onClick={goToTerms}>
                  T&Cs
                </Button>
                <Typo style={{ color: 'grey' }} variant="body2">
                  and
                </Typo>
                <Button style={{ color: 'red' }} onClick={goToPrivacy}>
                  Privacy Policy
                </Button>
              </Box>
            </Box>
          </Menu>
        </Toolbar>
      </AppBar>
      <LoginModal open={showModal} onClose={closeModal} />
    </div>
  );
};

export default React.memo(AppBarView);
