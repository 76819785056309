import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import storeConfig from 'store';
import { Router } from 'components/core';
import { CssBaseline } from '@mui/material';
import {theme, ThemeProvider} from './theme';
import {GlobalStyles} from './theme/globalStyles'
import * as serviceWorker from './serviceWorker';
import { ModalProvider } from './contexts/ModalContext';
import ModalRoot from './contexts/ModalRoot';
import { StyledEngineProvider } from '@mui/material/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {QueryClientProvider} from 'react-query'
import {queryClient} from 'services/react-query'

const root = document.getElementById('root');

if (root) {
  ReactDOM.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Provider
            // @ts-ignore
            store={storeConfig.store}
          >
            <PersistGate persistor={storeConfig.persistor}>
              <ModalProvider>
                <ModalRoot />
                <Router/>
                <ToastContainer
                  position="bottom-center"
                  hideProgressBar
                />
              </ModalProvider>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
        <CssBaseline />
        <GlobalStyles/>
      </ThemeProvider>
    </StyledEngineProvider>,
    root,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
