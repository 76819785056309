import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { ForgotPasswordState } from 'types/store/ForgotPasswordState';
import View from './CheckYourEmailDialog.view';
import authThunks from '../../../thunks/auth';
import { CheckYourEmailDialogPublicProps } from './CheckYourEmailDialog.props';
import {verifyUserWithEmail, forgotPasswordSubmitWithEmail} from 'services/cognito'
import { LoginModalForms } from '../LoginModal/LoginModal.const';
import {forgotPasswordWithEmail} from 'services/cognito'
import {toast} from 'react-toastify'

const FieldContainer: FC<CheckYourEmailDialogPublicProps> = (
  props: CheckYourEmailDialogPublicProps,
) => {
  const dispatch = useDispatch();
  const forgotPassword = useSelectorSafe<ForgotPasswordState>(
    // @ts-ignore
    store => store.forgotPassword,
    asyncData(ASYNC_STATUS.INITIAL),
  );
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [busy, setBusy] = useState(false)
  const [message, setMessage] = useState({});
  const [error, setError] = useState('')
  const [clicked, setClicked] = useState(false);
  const resendForgotPasswordEmail = () => {
    setClicked(true);
    forgotPasswordWithEmail({email: props.params.email})
      .then(cognitoResponse => {
        setMessage({
          type: 'success',
          message: `Another email has been sent.`,
        });
      })
      .catch(err => {
        setMessage({
          type: 'error',
          message: err.message,
        });
      })
/*
    
    dispatch(
      authThunks.forgotPassword({
        email: props.params.email,
      }),
    );*/
  };
  const resendVerifyEmail = () => {
    setClicked(true);
    dispatch(
      authThunks.sendVerificationEmail(
        {
          email: props.params.email,
        },
        {
          finishUpdating: () => {
            setMessage({
              type: 'success',
              message: `Another email has been sent.`,
            });
          },
          updateErrorMessage: (err: any) => {
            setMessage({
              type: 'error',
              message: `Something went wrong try again later.`,
            });
          },
        },
      ),
    );
  };
  useEffect(() => {
    if (forgotPassword.status === 'COMPLETE' && clicked) {
      setMessage({ type: 'success', message: 'Another email has been sent.' });
    }
    if (forgotPassword.status === 'ERROR' && clicked) {
      setMessage({
        type: 'error',
        message: 'Something went wrong try again later.',
      });
    }
  }, [forgotPassword.status]);

  const [verificationCodeError, setVerificationCodeError] = useState()
  const setTouchedVerificationCode = () => {
    setVerificationCodeError('')
    if (!verificationCode) {
      setError('Verification code is required.')
      setVerificationCodeError('Verification code is required')
    }
  }

  const [passwordError, setPasswordError] = useState()
  const setTouchedPassword = () => {
    setPasswordError('')
    if (!password) {
      setError('Password is required')
      setPasswordError('Password is required')
    }
  }

  const [confirmPasswordError, setConfirmPasswordError] = useState()
  const setTouchedConfirmPassword = () => {
    setConfirmPasswordError('')
    if (!confirmPassword) {
      setError('Confirm Password is required')
      setConfirmPasswordError('Confirm Password is required')
    }
  }

  const verifyEmailAddress = () => {
    console.log('todo')
    setBusy(true)
    setError()

    verifyUserWithEmail({email: props.params.email, verificationCode})
      .then(success => {

        dispatch(
          authThunks.login({
            email: props.params.email,
            password: props.params.password,
          },
          (success2) => {
            toast.success('Email address verified')
            if (props.onClose) {
              props.onClose()
            }
          },
          (error2) => {
            if (error2.message) {
              error2.message = error2.message.replaceAll('Username', 'Email Address')
            }
            setError(error2)
            setBusy(false)
          }),
        );

      })
      .catch(error => {
        let errorMessage = error?.message || error
        errorMessage = errorMessage.replace('Confirmation code', 'Verification code').replace('confirmation code', 'verification code')
        setError(errorMessage)
        setBusy(false)
      })
    
  }

  const confirmNewPassword = () => {
    if (confirmPassword != password) {
      setError('Confirm Password and Password must match.')
      return
    }
    setError('')
    setBusy(true)
    forgotPasswordSubmitWithEmail({email: props.params.email, password, verificationCode})
      .then(success => {
        if (props.openModal) {
          props.openModal(LoginModalForms.LOGIN, {
            email: props.params.email
          })();
        }
      })
      .catch(error => {
        setError(error.message)
        setBusy(false)
      })
  }

  return (
    <View
      {...props}
      message={message}
      error={error}
      resendForgotPasswordEmail={resendForgotPasswordEmail}
      resendVerifyEmail={resendVerifyEmail}
      verifyEmailAddress={verifyEmailAddress}
      confirmNewPassword={confirmNewPassword}
      busy={busy}

      verificationCode={verificationCode}
      setVerificationCode={setVerificationCode}
      setTouchedVerificationCode={setTouchedVerificationCode}
      verificationCodeError={verificationCodeError}

      password={password}
      setPassword={setPassword}
      setTouchedPassword={setTouchedPassword}
      passwordError={passwordError}

      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      setTouchedConfirmPassword={setTouchedConfirmPassword}
      confirmPasswordError={confirmPasswordError}
    />
  );
};

export default React.memo(FieldContainer);
