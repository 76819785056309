import React, { FC, ReactNode } from 'react';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {routeAndPortalFromLocation, NOT_FOUND, PORTALS} from 'routes'
import { makeAuthGuard } from 'components/core/AuthGuard';

const Middleware = ({
  route,
  portalName,
  children,
}) => {
  let MiddlewareWrapper = route.middleware && route.middleware();
  if (portalName == PORTALS.HOST || portalName == PORTALS.ADMIN) {
    MiddlewareWrapper = makeAuthGuard(portalName)
  }

  return MiddlewareWrapper ? (
    <MiddlewareWrapper route={route}>{children}</MiddlewareWrapper>
  ) : (
    <>{children}</>
  );
};

const RouterContainer = (props) => {
  const location = useSelectorSafe(
    state => state.location.type,
    NOT_FOUND,
  );

  const lo = useSelectorSafe(
    state => state.location,
    NOT_FOUND,
  );

  //const route = getRouteFromLocation(location)

  const [routeName, portalName, portal] = routeAndPortalFromLocation(location)
  const route = portal[routeName]

/*
  const matchedRoute: RouteDef =
    location === NOT_FOUND
      ? props.components.NOT_FOUND
      : getRoute(portal, routeName); //props.components[routeName];
*/
  const Comp = route.component

  return (
    <Middleware route={route} portalName={portalName}>
      {/*LazyComponent(`routes/${matchedRoute.component}`, {
        route: matchedRoute,
      })*/}
      <Comp/>
    </Middleware>
  );
};

export default RouterContainer;
