import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {ROUTES} from 'routes';
import View from './CreateListing.view';
import { bookingDetailsActions } from 'store/actions';
import routerThunks from 'thunks/router'
import HeaderSection from 'components/modules/HeaderSection';
import CreateNewListingVenue from 'components/modules/CreateNewListingVenue' 
import CreateNewListingGallery from 'components/modules/CreateNewListingGallery'
import CreateNewListingBooking from 'components/modules/CreateNewListingBooking'
import CreateEditListingContainer from './CreateEditListing.container';
import {ArrowBack} from '@mui/icons-material'

const steps = [
  {
    label: 'Venue',
    component: (props) => {
      return (
        <div>
          <HeaderSection breadcrumbs={props.breadcrumbs} heading='Create New Listing' subHeading="Add information about your space"/>
          <CreateNewListingVenue {...props}/>
        </div>
      )
    }
  },
  {
    label: 'Gallery',
    component: (props) => {
      return (
        <div>
          <HeaderSection breadcrumbs={props.breadcrumbs} heading='What does your venue look like?' subHeading="Upload and select 1 to 5 photo/s and an optional video of your space."/>
          <CreateNewListingGallery {...props}/>
        </div>
      )
    }
  },
  {
    label: 'Booking',
    component: (props) => {
      return (
        <div>
          <HeaderSection breadcrumbs={props.breadcrumbs} heading='Booking Information' subHeading="Enter availability of your space"/>
          <CreateNewListingBooking {...props}/>
        </div>
      )
    }
  }
]

const CreateListingContainer = props => {
  const dispatch = useDispatch()
  const breadcrumbs = [{
    icon: <ArrowBack fontSize='16px'/>,
    label: 'Manage',
    onClick: () => {
      return dispatch(routerThunks.link(ROUTES.ROOT))
    }
  }]
  
  return (
    <CreateEditListingContainer
      {...props}
      view={View}
      steps={steps}
      breadcrumbs={breadcrumbs}
      mode='create'
    />
  );
};

export default React.memo(CreateListingContainer);
