import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useCalendar } from 'components/primitives';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import View from './MyBookingHistory.view';
import { filterActions } from '../../../store/actions';
import routerThunks from 'thunks/router'
import {ROUTES} from '../..';
import bookingThunks from '../../../thunks/booking';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import {bookingTimeData} from 'services/api'
import {useInfiniteScroll} from 'components/modules'

const DEBUG_SHOW_ALL = false // set this to true to show future bookings to allow reviews on them

const userFallback = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const Container = (props) => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: user } = useSelectorSafe(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }
  
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [calendarStart, setCalendarStart] = useState(null);
  const [calendarEnd, setCalendarEnd] = useState(null);
  const [startMoment, setStartMoment] = useState(null);
  const [endMoment, setEndMoment] = useState(null);

  const fetchMoreFn = (lastKey, resolve, reject) => {
    dispatch(
      bookingThunks.getMyBookings({startKey: lastKey},
        (response) => {
          const bookings = response.data.bookings; //Object.keys(response).map(key => response[key]);
          // @ts-ignore
          let filteredBookings = bookings.filter(booking => {
            if (DEBUG_SHOW_ALL) {
              return true
            }

            const endMomentIso = booking.endOn
              ? moment(booking.endOn)
              : null;
            const currentMomentIso = moment();
            return endMomentIso && currentMomentIso.isAfter(endMomentIso);
          })

          // filter based on selected date range
          filteredBookings = filteredBookings.filter(booking => {
            if (startMoment && endMoment) {
              return (
                // @ts-ignore
                moment(booking.startOn).isSameOrAfter(startMoment, 'day') &&
                // @ts-ignore
                moment(booking.endOn).isSameOrBefore(endMoment, 'day')
              );
            }
        
            if (startMoment) {
              return (
                // @ts-ignore
                moment(booking.startOn).isSame(startMoment, 'day') &&
                // @ts-ignore
                moment(booking.endOn).isSame(startMoment, 'day')
              );
            }
            return true;
          });

          // sort by start date/time
          filteredBookings = filteredBookings.sort((a, b) => {
            return moment(a.startOn) - moment(b.startOn)
          })

          const lk = lastKey
          console.assert(lk != response.lastKey)
          resolve(filteredBookings, response.lastKey)
        },
        (error) => {
          reject(error)
        }
      )
    )
  }
  const {data: bookings, setData: setBookings, fetchMore, hasMore, loading} = useInfiniteScroll(fetchMoreFn, [startMoment, endMoment])



/*
  const resetDateFilter = () => {
    filterActions.setFilter({
      dateRange: undefined,
    });
    setStartMoment(null);
    setEndMoment(null);
  };
  */
  const calendarHook = useCalendar(
    ({ from, to }) => {
      if (from) {
        setCalendarStart(from);
        setCalendarEnd(to || null);
      }
      // if (!(to || from)) {
      //   resetDateFilter();
      // }
    },
    () => true,
  );

  const handleViewDetailsClick = (id) => {
    dispatch(routerThunks.link(ROUTES.VIEW_BOOKING_DETAILS, { id }));
  };
  const handleWriteReviewClick = (id) => {
    dispatch(routerThunks.link(ROUTES.REVIEW_BOOKING, { id }));
  };
  const handleMyBookingsClick = () => {
    dispatch(routerThunks.link(ROUTES.MY_BOOKINGS));
  };
  const goToHostDetails = (host) => {
    dispatch(routerThunks.link(ROUTES.HOST_DETAILS, { id: host.ID }));
  };
  const breadcrumbs = [
    {
      title: 'My Bookings /',
      onClick: handleMyBookingsClick,
    },
    {
      title: 'My Bookings History',
      onClick: () => {},
    },
  ];
  const onSubmitDate = () => {
    // @ts-ignore
    setDateRange({ from: calendarHook.from, to: calendarHook.to });
    setDateFilterOpen(false);
    if (calendarStart && calendarHook.from) {
      // @ts-ignore
      setStartMoment(moment(calendarHook.from, 'ISO-8601'));
    } else {
      setStartMoment(null);
    }
    if (calendarEnd && calendarHook.to) {
      // @ts-ignore
      setEndMoment(moment(calendarHook.to, 'ISO-8601'));
    } else {
      setEndMoment(null);
    }
  };
  

  return (
    <View
      {...props}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      onViewDetailsClick={handleViewDetailsClick}
      onWriteReviewClick={handleWriteReviewClick}
      goToHostDetails={goToHostDetails}
      myBookings={bookings}
      breadcrumbs={breadcrumbs}
      calendarHook={calendarHook}
      dateFilterOpen={dateFilterOpen}
      setDateFilterOpen={(open) => {
        setDateFilterOpen(open);
      }}
      dateRange={dateRange}
      onSubmitDate={onSubmitDate}
    />
  );
};

export default React.memo(Container);
