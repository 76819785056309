import { Button } from 'components/primitives'
import {useStyles} from './SettingEditorStyles'
import fileDownload from 'js-file-download'
import { JsonEditor as Editor } from 'jsoneditor-react'
import 'jsoneditor-react/es/editor.min.css'
import {useRef, useState} from 'react'

export const UploadButton = ({ onChange }) => {
    const ref = useRef()
    const classes = useStyles()

    return (
        <>
            <input
                ref={ref}
                type="file"
                accept="application/JSON"
                style={{ display: 'none' }}
                id="contained-button-file"
                onChange={onChange}
            />
            <Button
                text="Upload"
                className={classes.button}
                onClick={() => {
                    ref.current.click()
                }}
            />
        </>
    )
}

export function SettingEditor(props) {
    const { name, value, onChange, onSave, saving } = props
    const classes = useStyles(props)
    const [key, setKey] = useState(0)

    const onUploadChange = (event) => {
        event.preventDefault()
        const reader = new FileReader()
        reader.onload = async (e) => { 
            const text = (e.target.result)
            onChange(JSON.parse(text))
            setKey(key + 1)
        }
        reader.readAsText(event.target.files[0])
    }

    return (
        <>
            <Editor
                value={value}
                onChange={onChange}
                key={key}
            />
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', gap: '10px' }}>
                <Button
                    text="Save Changes"
                    className={classes.button}
                    style={{width: '150px'}}
                    onClick={onSave}
                    loading={saving}
                    disabled={saving}
                />
                <div style={{ flexDirection: 'row', display: 'flex', gap: '10px' }}>
                    <Button
                        text="Download"
                        className={classes.button}
                        onClick={() => {
                            fileDownload(JSON.stringify(value, null, 2), `${name}.json`)
                        }}
                        loading={saving}
                        disabled={saving}
                    />
                    <UploadButton onChange={onUploadChange}/>
                </div>
            </div>
        </>
    )
}