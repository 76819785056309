import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SearchProps } from './Search.props';
import { AppBar } from '../../modules';
import { useStyles } from './Search.styles';

const SearchView: FC<SearchProps> = props => {
  const classes = useStyles(props);

  return (
    <Box className={classes.container}>
      <AppBar />
      <div className={classes.searchBar}>{props.searchBar}</div>
      {props.children}
    </Box>
  );
};

export default React.memo(SearchView);
