import React from 'react';
import { Button, LocationDetailsCard, InputField, Loading } from 'components/primitives';
import { BookingDetails as BookingDetailsLayout } from 'components/layouts';
import Grid from '@mui/material/Grid';
import MaterialButton from '@mui/material/Button';
import { CardElement, injectStripe } from 'react-stripe-elements';
import Typo from '../../../components/primitives/Typo';
import { useStyles } from './ConfirmBookingDetails.styles';

const DEBUG_ALLOW_MOCK_SUCCESS = false

// @ts-ignore
const SplitField = ({
  title,
  value,
  style,
}: {
  title: string;
  value: string;
  style?: any;
}) => (
  <Grid
    container
    style={{ display: 'flex', flexDirection: 'row', ...(style || {}) }}
  >
    <Grid item xs={6}>
      <Typo variant="body1" color="lightGrey">
        {title}
      </Typo>
    </Grid>
    <Grid
      item
      xs={6}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Typo variant="body1" color="darkGrey">
        {value}
      </Typo>
    </Grid>
  </Grid>
);

const ConfirmBookingDetailsView = ({
  // @ts-ignore
  discount,
  // @ts-ignore
  setDiscount,
  // @ts-ignore
  discountField,
  // @ts-ignore
  setDiscountField,
  // @ts-ignore
  applyDiscountCode,
  // @ts-ignore
  nameOnCard,
  // @ts-ignore
  setNameOnCard,
  // @ts-ignore
  total,
  // @ts-ignore
  bookingHourlyRate,
  // @ts-ignore
  weekdayBookingHourlyRate,
  // @ts-ignore
  weekendBookingHourlyRate,
  // @ts-ignore
  fields,
  // @ts-ignore
  entityImages,
  // @ts-ignore
  entityTitle,
  // @ts-ignore
  entityAddress,
  submitBusy,
  // @ts-ignore
  loading,
  // @ts-ignore
  clientSecret,
  // @ts-ignore
  productId,
  // @ts-ignore
  onSubmit,
  // @ts-ignore
  onSubmitMockSuccess,
  // @ts-ignore
  errors,
  // @ts-ignore
  provider,
  // @ts-ignore
  goToHostDetails,
  onManualBookingPress,
  ...props
}) => {
  const classes = useStyles();
  return (
    <BookingDetailsLayout
      heading="Confirm Booking"
      subheading="Enter details of your payment. You won't be charged until the host confirms."
      leftChildren={
        <LocationDetailsCard
          images={entityImages}
          title={entityTitle}
          address={entityAddress}
          price={bookingHourlyRate}
          weekdayPrice={weekdayBookingHourlyRate}
          weekendPrice={weekendBookingHourlyRate}
          fields={fields}
          provider={provider}
          goToHostDetails={goToHostDetails}
        />
      }
      rightChildren={
        <Grid container>
          <Grid item xs={12}>
            <Typo variant="h4" color="darkGrey">
              Card Details
            </Typo>
          </Grid>
          <Loading isLoading={loading}>
            <Grid item xs={12} style={{ marginTop: '1em' }}>
              <InputField
                id="nameOnCard"
                placeholder="Name on Card"
                onChange={event => setNameOnCard(event.target.value)}
                value={nameOnCard}
                type="text"
                onBlur={() => {}}
                errorMessage={errors.nameOnCard || ''}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '1em' }}>
              <div
                style={{
                  // @ts-ignore
                  backgroundColor: 'white',
                  padding: '0.85rem',
                  borderRadius: '4px',
                  border: '1px solid rgb(244, 244, 245)',
                }}
              >
                <CardElement
                  options={{ 
                    hidePostalCode: true 
                  }}
                  style={{
                    base: {
                      color: '#32325d',
                      lineHeight: '24px',
                      fontFamily: 'Helvetica Neue',
                      fontSmoothing: 'antialiased',
                      fontSize: '16px',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#fa755a',
                      iconColor: '#fa755a',
                    },
                  }}
                />
              </div>
            </Grid>
            {/*
            <Grid
              item
              xs={12}
              style={{ display: 'flex', flexDirection: 'row', marginTop: '1em' }}
            >
              {!discount ? (
                <>
                  <InputField
                    id="discountCode"
                    placeholder="Discount code"
                    onChange={event => setDiscountField(event.target.value)}
                    value={discountField}
                    type="text"
                    onBlur={() => {}}
                  />
                  <MaterialButton
                    color="primary"
                    style={{ width: '50px' }}
                    // @ts-ignore
                    onClick={event => applyDiscountCode(event.target.value)}
                  >
                    Apply
                  </MaterialButton>
                </>
              ) : (
                <MaterialButton
                  color="primary"
                  // @ts-ignore
                  onClick={event => setDiscount('')}
                >
                  Remove discount
                </MaterialButton>
              )}
            </Grid>
              */}
            <Grid item xs={12} className={classes.detailsContainer}>
              <SplitField
                title="Weekday Hourly Rate"
                value={`$${weekdayBookingHourlyRate}/per hour`}
              />
            </Grid>
            <Grid item xs={12} className={classes.detailsContainer}>
              <SplitField
                title="Weekend Hourly Rate"
                value={`$${weekendBookingHourlyRate}/per hour`}
              />
            </Grid>
            {discount ? (
              <Grid item xs={12} className={classes.detailsContainer}>
                <SplitField title="Discount" value={discount.formatted} />
              </Grid>
            ) : null}
            <Grid item xs={12} className={classes.totalContainer}>
              <SplitField title="Total" value={`$${total} AUD`} />
            </Grid>
            <Button
              loading={submitBusy}
              loadingText={'Processing Payment'}
              disabled={submitBusy}
              text="Secure Booking"
              onClick={() => {
                if (!loading) {
                  onSubmit(props.stripe, props.elements);
                }
              }}
            />
            {errors.booking ? (
              <Grid item xs={12} className={classes.errorMessage}>
                An error has occurred. Please contact the host to place your
                booking.
              </Grid>
            ) : null}
            {errors.message ? (
              <Grid item xs={12} className={classes.errorMessage}>
                {errors.message}
            </Grid>
            ) : null}
            {DEBUG_ALLOW_MOCK_SUCCESS &&
            <Button
              loading={submitBusy}
              loadingText={'Processing Payment'}
              text="Secure Booking [TEST: MOCK SUCCESS]"
              disabled={submitBusy}
              onClick={() => {
                if (!loading) {
                  onSubmitMockSuccess(props.stripe, props.elements);
                }
              }}
            />
            }
            {onManualBookingPress &&
              <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                <Typo
                  variant="body1"
                  component="a"
                  color="blue"
                  style={{ marginTop: '1em', textAlign: 'right' }}
                  onClick={onManualBookingPress}
                  className={classes.link}
                  disabled={submitBusy}
                >
                  Book Manually
                </Typo>
              </div>
            }
          </Loading>
        </Grid>
      }
    />
  );
};

export default injectStripe(ConfirmBookingDetailsView);
