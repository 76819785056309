import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
    },
  }),
);
