import React, {useState, useEffect, useRef} from 'react'

// https://dev.to/scastiel/react-hooks-get-the-current-state-back-to-the-future-3op2
export const useRefState = initialValue => {
    const [state, setState] = useState(initialValue)
    const stateRef = useRef(state)
    useEffect(
      () => { stateRef.current = state },
      [state]
    )
    return [state, stateRef, setState]
}
  