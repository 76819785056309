import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'date-fns/parse';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isWeekend from 'date-fns/isWeekend';
import Dinero from 'dinero.js';
import { differenceInDays, differenceInHours, isToday } from 'date-fns';
import availabilitiesThunks from 'thunks/availabilities';
import pathOr from 'ramda/es/pathOr';
import { useCalendar } from '../../../primitives/Calendar';
import BookingSelectView from './BookingSelect.view';
import { Store } from '../../../../types/store/Store';
import { FilterState } from '../../../../types/store/FilterState';
import moment from 'moment'

const times = {
  '00:00': {
    rawValue: 0,
    value: '00:00',
    label: '12am',
  },
  '01:00': {
    rawValue: 1,
    value: '01:00',
    label: '1am',
  },
  '02:00': {
    rawValue: 2,
    value: '02:00',
    label: '2am',
  },
  '03:00': {
    rawValue: 3,
    value: '03:00',
    label: '3am',
  },
  '04:00': {
    rawValue: 4,
    value: '04:00',
    label: '4am',
  },
  '05:00': {
    rawValue: 5,
    value: '05:00',
    label: '5am',
  },
  '06:00': {
    rawValue: 6,
    value: '06:00',
    label: '6am',
  },
  '07:00': {
    rawValue: 7,
    value: '07:00',
    label: '7am',
  },
  '08:00': {
    rawValue: 8,
    value: '08:00',
    label: '8am',
  },
  '09:00': {
    rawValue: 9,
    value: '09:00',
    label: '9am',
  },
  '10:00': {
    rawValue: 10,
    value: '10:00',
    label: '10am',
  },
  '11:00': {
    rawValue: 11,
    value: '11:00',
    label: '11am',
  },
  '12:00': {
    rawValue: 12,
    value: '12:00',
    label: '12pm',
  },
  '13:00': {
    rawValue: 13,
    value: '13:00',
    label: '1pm',
  },
  '14:00': {
    rawValue: 14,
    value: '14:00',
    label: '2pm',
  },
  '15:00': {
    rawValue: 15,
    value: '15:00',
    label: '3pm',
  },
  '16:00': {
    rawValue: 16,
    value: '16:00',
    label: '4pm',
  },
  '17:00': {
    rawValue: 17,
    value: '17:00',
    label: '5pm',
  },
  '18:00': {
    rawValue: 18,
    value: '18:00',
    label: '6pm',
  },
  '19:00': {
    rawValue: 19,
    value: '19:00',
    label: '7pm',
  },
  '20:00': {
    rawValue: 20,
    value: '20:00',
    label: '8pm',
  },
  '21:00': {
    rawValue: 21,
    value: '21:00',
    label: '9pm',
  },
  '22:00': {
    rawValue: 22,
    value: '22:00',
    label: '10pm',
  },
  '23:00': {
    rawValue: 23,
    value: '23:00',
    label: '11pm',
  },
  '24:00': {
    rawValue: 24,
    value: '24:00',
    label: '12am',
  },
};

const getTimePeriod = (
  startDate: string,
  endDate: string,
  startTime: any,
  endTime: any,
) => {
  if (startDate && endDate && startTime && endTime) {
    return (
      (differenceInDays(
        // @ts-ignore
        parse(endDate.date, 'yyyy-MM-dd', new Date()),
        // @ts-ignore
        parse(startDate.date, 'yyyy-MM-dd', new Date()),
      ) +
        1) *
      (endTime.rawValue - startTime.rawValue)
    );
  }
  return 0;
};

// This is currently an OR.
const getAvailableTimes = (
  availableDates: any,
  startDate: any,
  endDate: any,
) => {
  const startIndex = availableDates.findIndex(
    (availableDate: any) => availableDate.date === startDate,
  );
  const endIndex = availableDates.findIndex(
    (availableDate: any) => availableDate.date === endDate,
  );
  const availableDateTimes =
    startIndex !== -1 && endIndex !== -1
      ? availableDates.slice(startIndex, endIndex + 1)
      : [];
  if (startIndex === endIndex)
    availableDateTimes.push(availableDates[startIndex]);
  const availableTimesObj = {};

  availableDateTimes.forEach((availableDateTime: any) => {
    availableDateTime.times.forEach((time: any) => {
      if (
        isToday(
          // @ts-ignore
          parse(availableDateTime.date, 'yyyy-MM-dd', new Date()),
        )
      ) {
        const loopedDateTime = parse(
          `${availableDateTime.date} ${time}`,
          'yyyy-MM-dd H:m',
          new Date(),
        );
        const current = new Date();
        current.setHours(current.getHours());

        if (differenceInHours(loopedDateTime, current) >= 1) {
          // @ts-ignore
          if (!availableTimesObj[time]) {
            // @ts-ignore
            availableTimesObj[time] = { count: 0 };
          }
          // @ts-ignore
          // eslint-disable-next-line no-plusplus
          availableTimesObj[time].count++;
        }
      } else {
        // @ts-ignore
        if (!availableTimesObj[time]) {
          // @ts-ignore
          availableTimesObj[time] = { count: 0 };
        }
        // @ts-ignore
        // eslint-disable-next-line no-plusplus
        availableTimesObj[time].count++;
      }
    });
  });

  // @ts-ignore
  return Object.entries(availableTimesObj)
    .filter(
      // @ts-ignore
      ([key, value]) => value.count === availableDateTimes.length,
    )
    .map(([key, value]) => key);
};

const getIsoString = (date: any) => {
  const toLocaleString = (num: number) =>
    num.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  return `${date.getFullYear()}-${toLocaleString(
    date.getMonth() + 1,
  )}-${toLocaleString(date.getDate())}`;
};

const today = new Date(Date.now());
today.setHours(0);

const isDateAvailable = (availableDates: any, date: any) => {
  const isoDate = getIsoString(date);
  let found = availableDates
    .map((availableDate: any) => availableDate.date)
    .includes(isoDate);
  if (!found) {
    return false
  }

  // if today, make sure there are some time slots free for us to select it
  if (moment(date).isSame(moment(today), 'day')) {
    const times = getAvailableTimes(availableDates, isoDate, isoDate)
    if (!times || times.length <= 0) {
      return false
    }
  }

  return found
};

const getAvailableDate = (availableDates: any, date: any) => {
  const found = isDateAvailable(availableDates, date)
  if (!found) {
    return null
  }

  const isoDate = getIsoString(date);
  return availableDates.find(
    (availableDate: any) => availableDate.date === isoDate,
  );
};

const isTimeAvailable = (availableTimes: any, time: any) => {
  return availableTimes.includes(time);
};


const nextYear = new Date(today);
nextYear.setFullYear(nextYear.getFullYear() + 1);

const getTimeDiff = (startTime: any, endTime: any) => {
  return startTime && endTime ? endTime.rawValue - startTime.rawValue : 0;
};

const getWeekdayAndWeekendCount = (
  startDate: any,
  endDate: any,
  startTime: any,
  endTime: any,
) => {
  if (!(startDate || endDate || startTime || endTime))
    return { weekdays: 0, weekends: 0 };
  const days = eachDayOfInterval({
    start: parse(
      `${startDate.date} 00:00:00 Z`,
      'yyyy-MM-dd HH:mm:ss X',
      new Date(),
    ),
    end: parse(
      `${endDate.date} 00:00:00 Z`,
      'yyyy-MM-dd HH:mm:ss X',
      new Date(),
    ),
  });
  const weekendDays = days.filter(isWeekend);
  const weekendCount = weekendDays.length;
  const weekDayCount = Math.abs(days.length - weekendCount);
  const timeDiff = getTimeDiff(startTime, endTime);
  return {
    weekdays: weekDayCount * timeDiff,
    weekends: weekendCount * timeDiff,
  };
};

const BookingSelectContainer = ({
  entityState,
  user,
  setShowModal,
  goToContactHost,
  goToHostDetails,
  goToBookingDetails,
  capacity,
  title,
  description,
  images,
  location,
  provider,
}: {
  entityState: any;
  user: any;
  setShowModal: any;
  goToContactHost: any;
  goToHostDetails: any;
  goToBookingDetails: any;
  capacity: any;
  title: any;
  description: any;
  images: any;
  location: any;
  provider: any;
}) => {
  const dispatch = useDispatch();
  const host = provider;
  const hostData = provider ? provider.ID : null;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [openCalendar, setOpenCalendar] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const filterState = useSelector<Store, FilterState>(state => state.filter);
  const [dateRange, setDateRange] = useState(
    filterState && filterState.dateRange ? filterState.dateRange : null,
  );

  useEffect(() => {
    if (
      dateRange &&
      availableDates &&
      availableDates.length &&
      dateRange.from
    ) {
      setStartDate(getAvailableDate(availableDates, dateRange.from));
      setEndDate(
        getAvailableDate(availableDates, dateRange.to || dateRange.from),
      );
      setInitialDate({
        // @ts-ignore
        from: dateRange.from,
        to: dateRange.to ? dateRange.to : dateRange.from,
      });
    }
  }, [dateRange, availableDates]);

  useEffect(() => {
    if (!entityState) return;
    dispatch(
      availabilitiesThunks.getAvailabilitiesByEntityId(
        {
          productId: entityState.ID,
          startDate: getIsoString(today),
          endDate: getIsoString(nextYear),
        },
        (response: any) => {
          setAvailableDates(response.availabilities);
        },
        (error: any) => {
          console.log('_ERROR_', error);
        },
      ),
    );
  }, [entityState]);
  useEffect(() => {
    if (availableDates && availableDates.length) {
      // @ts-ignore
      setAvailableTimes(
        // @ts-ignore
        startDate && endDate
          ? getAvailableTimes(
              availableDates,
              // @ts-ignore
              startDate.date,
              // @ts-ignore
              endDate.date,
            )
          : [],
      );
    }
  }, [availableDates, startDate, startTime]);

  const disabledModifier = (date: any) => {
    const dateAvailable = isDateAvailable(availableDates, date);
    return !dateAvailable;
  };

  const currentDateAvailable = (date: any) => {
    const isoDate = getIsoString(date);
    return availableDates
      .map((availableDate: any) => availableDate.date)
      .includes(isoDate);
  };

  const calendarHook = useCalendar(
    // @ts-ignore
    ({ from, to }) => {
      if (from) {
        setStartDate(getAvailableDate(availableDates, from));
        setEndDate(getAvailableDate(availableDates, to || from));
        setOpenCalendar(false);
      }
      // if (to) {
      //   setOpenCalendar(false);
      // }
    },
    currentDateAvailable,
  );

  const toDate = moment().add(1, 'year').subtract(1, 'day').toDate()
  const notAvailableDates = [{ after: toDate }]

  useEffect(() => {
    if (!openCalendar) {
      calendarHook.onClose();
    }
  }, [openCalendar, calendarHook]);
  const startTimeOptions = [
    { label: '12am', value: '00:00' },
    { label: '1am', value: '01:00' },
    { label: '2am', value: '02:00' },
    { label: '3am', value: '03:00' },
    { label: '4am', value: '04:00' },
    { label: '5am', value: '05:00' },
    { label: '6am', value: '06:00' },
    { label: '7am', value: '07:00' },
    { label: '8am', value: '08:00' },
    { label: '9am', value: '09:00' },
    { label: '10am', value: '10:00' },
    { label: '11am', value: '11:00' },
    { label: '12pm', value: '12:00' },
    { label: '1pm', value: '13:00' },
    { label: '2pm', value: '14:00' },
    { label: '3pm', value: '15:00' },
    { label: '4pm', value: '16:00' },
    { label: '5pm', value: '17:00' },
    { label: '6pm', value: '18:00' },
    { label: '7pm', value: '19:00' },
    { label: '8pm', value: '20:00' },
    { label: '9pm', value: '21:00' },
    { label: '10pm', value: '22:00' },
    { label: '11pm', value: '23:00' },
  ];
  const endTimeOptions = [
    // { label: '12am', value: '00:00' },
    { label: '1am', value: '01:00' },
    { label: '2am', value: '02:00' },
    { label: '3am', value: '03:00' },
    { label: '4am', value: '04:00' },
    { label: '5am', value: '05:00' },
    { label: '6am', value: '06:00' },
    { label: '7am', value: '07:00' },
    { label: '8am', value: '08:00' },
    { label: '9am', value: '09:00' },
    { label: '10am', value: '10:00' },
    { label: '11am', value: '11:00' },
    { label: '12pm', value: '12:00' },
    { label: '1pm', value: '13:00' },
    { label: '2pm', value: '14:00' },
    { label: '3pm', value: '15:00' },
    { label: '4pm', value: '16:00' },
    { label: '5pm', value: '17:00' },
    { label: '6pm', value: '18:00' },
    { label: '7pm', value: '19:00' },
    { label: '8pm', value: '20:00' },
    { label: '9pm', value: '21:00' },
    { label: '10pm', value: '22:00' },
    { label: '11pm', value: '23:00' },
    { label: '12am', value: '24:00' },
  ];

  const startTimeOptionsFiltered = startTimeOptions.map(e => {
    const isBeforeEnd =
      startDate &&
      endDate &&
      // @ts-ignore
      endTime !== '' &&
      // @ts-ignore
      times[e.value].rawValue < endTime.rawValue;

    return {
      ...e,
      disabled: !isTimeAvailable(availableTimes, e.value),
      isBeforeEnd,
    };
  });
  const endTimeOptionsFiltered = endTimeOptions.map(e => {
    const keys = Object.keys(times);
    // @ts-ignore
    const previousValue = times[keys[times[e.value].rawValue - 1]].value;
    const isBeforeStart =
      startDate &&
      endDate &&
      // @ts-ignore
      startTime !== '' &&
      // @ts-ignore
      times[e.value].rawValue <= startTime.rawValue;
    return {
      ...e,
      disabled: !isTimeAvailable(availableTimes, previousValue),
      isBeforeStart,
    };
  });
  let startTimeDisabled = false;
  // eslint-disable-next-line no-plusplus
  for (let i = startTimeOptionsFiltered.length - 1; i >= 0; i--) {
    const startTimeOption = startTimeOptionsFiltered[i];
    if (startTimeOption.disabled && endTime && startTimeOption.isBeforeEnd) {
      startTimeDisabled = true;
    }
    if (!startDate) {
      startTimeOption.disabled = true;
      // eslint-disable-next-line no-continue
      continue;
    }
    if (startTimeDisabled) {
      startTimeOption.disabled = true;
      // eslint-disable-next-line no-continue
      continue;
    }

    if (endTime && !startTimeOption.isBeforeEnd) {
      startTimeOption.disabled = true;
    }
  }
  let endTimeDisabled = false;
  endTimeOptionsFiltered.forEach(endTimeOption => {
    if (!endDate) {
      // eslint-disable-next-line no-param-reassign
      endTimeOption.disabled = true;
      endTimeDisabled = true;
    }
    if (endTimeDisabled) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      endTimeOption.disabled = true;
    }
    // @ts-ignore
    if (startDate && endDate) {
      // @ts-ignore
      if (endTimeOption.isBeforeStart) {
        // eslint-disable-next-line no-param-reassign
        endTimeOption.disabled = true;
      }
    }
    if (endTimeOption.disabled) {
      // @ts-ignore
      if (startTime && !endTimeOption.isBeforeStart) {
        endTimeDisabled = true;
      }
    }
  });

  // @ts-ignore
  const timePeriod = getTimePeriod(startDate, endDate, startTime, endTime);
  const hourBreakdown = getWeekdayAndWeekendCount(
    startDate,
    endDate,
    startTime,
    endTime,
  );
  const weekdayHours = hourBreakdown.weekdays;
  const weekendHours = hourBreakdown.weekends;
  const weekdayPrice = pathOr(
    0,
    ['pricing', 'weekdays'],
    entityState,
  );
  const weekendPrice = pathOr(
    0,
    ['pricing', 'weekends'],
    entityState,
  );
  const priceData = Dinero({ amount: 0, currency: 'AUD' });
  const weekdayPriceData = weekdayPrice
    ? Dinero({
        amount: Math.round(weekdayPrice * 100),
        currency: 'AUD',
      })
    : Dinero({ amount: 0, currency: 'AUD' });
  const weekendPriceData = weekendPrice
    ? Dinero({
        amount: Math.round(weekendPrice * 100),
        currency: 'AUD',
      })
    : Dinero({ amount: 0, currency: 'AUD' });
  const weekdayDurationData = weekdayPriceData.multiply(weekdayHours);
  const weekendDurationData = weekendPriceData.multiply(weekendHours);
  const durationData = priceData
    .add(weekdayDurationData)
    .add(weekendDurationData);
  const serviceFeeData = Dinero({ amount: 0, currency: 'AUD' });
  const totalPriceData = durationData.add(serviceFeeData);
  const [canBook, setCanBook] = useState(false);
  useEffect(() => {
    setCanBook(
      startDate !== null &&
        endDate !== null &&
        startTime !== '' &&
        endTime !== '',
    );
  }, [startDate, endDate, startTime, endTime]);
  useEffect(() => {
    setStartTime('');
    setEndTime('');
  }, [startDate, endDate]);
  const onBookNow = () => {
    if (user && user.ID) {
      let price = totalPriceData.toUnit()
      goToBookingDetails(
        entityState,
        // @ts-ignore
        startDate.date,
        // @ts-ignore
        endDate.date,
        price,
        // @ts-ignore
        startTime.rawValue,
        // @ts-ignore
        endTime.rawValue,
        weekdayHours,
        weekendHours,
      );
    } else {
      setShowModal('LOGIN');
    }
  };
  const onHostDetails = () => {
    goToHostDetails();
  };
  const onContactHost = () => {
    if (user && user.ID) {
      goToContactHost();
    } else {
      setShowModal('LOGIN');
    }
  };
  const onStartTimeSelect = (v: any) => {
    if (v === 'placeholder') {
      setStartTime('');
    } else {
      // @ts-ignore
      setStartTime(times[v]);
    }
  };
  const onEndTimeSelect = (v: any) => {
    if (v === 'placeholder') {
      setEndTime('');
    } else {
      // @ts-ignore
      setEndTime(times[v]);
    }
  };

  return (
    // @ts-ignore
    <BookingSelectView
      initialDate={initialDate}
      startDate={startDate}
      endDate={endDate}
      openCalendar={openCalendar}
      setOpenCalendar={setOpenCalendar}
      calendarHook={calendarHook}
      toMonth={toDate}
      notAvailableDates={notAvailableDates}
      serviceFeeData={serviceFeeData}
      totalPriceData={totalPriceData}
      startTime={startTime}
      setStartTime={setStartTime}
      endTime={endTime}
      setEndTime={setEndTime}
      onBookNow={onBookNow}
      onHostDetails={onHostDetails}
      onContactHost={onContactHost}
      host={host}
      startTimeOptions={startTimeOptionsFiltered}
      endTimeOptions={endTimeOptionsFiltered}
      durationData={durationData}
      weekdayDurationData={weekdayDurationData}
      weekendDurationData={weekendDurationData}
      priceData={priceData}
      weekdayPriceData={weekdayPriceData}
      weekendPriceData={weekendPriceData}
      timePeriod={timePeriod}
      weekdayHours={weekdayHours}
      weekendHours={weekendHours}
      canBook={canBook}
      onStartTimeSelect={onStartTimeSelect}
      onEndTimeSelect={onEndTimeSelect}
      disabledModifier={disabledModifier}
    />
  );
};

export default BookingSelectContainer;
