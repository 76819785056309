import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
//import momentTz from 'moment-timezone';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { differenceInDays } from 'date-fns';
import parse from 'date-fns/parse';
import View from './ViewBookingDetails.view';
import { TextBlock, HorizontalLine } from '../../../components/primitives';
import bookingThunks from '../../../thunks/booking';
import routerThunks from 'thunks/router'
import {ROUTES, PORTALS, routeAndPortalFromLocation} from '../..';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import {bookingTimeData} from 'services/api'
import {useChangeBookingStatus} from 'components/modules'
import {toast} from 'react-toastify'
/*
// @ts-ignore
const toComponent = (question, lastQuestion) => (
  <div style={{ paddingBottom: '1em' }}>
    <TextBlock question={question.question} answer={question.answer} />
    {lastQuestion ? null : (
      <div style={{ height: '1em', paddingTop: '1em' }}>
        <HorizontalLine />
      </div>
    )}
  </div>
);

const getTimePeriod = (
  startDate,
  endDate,
  startTime,
  endTime,
) => {
  if (
    startDate &&
    endDate &&
    typeof startTime !== 'undefined' &&
    typeof endTime !== 'undefined'
  ) {
    return (
      (differenceInDays(
        // @ts-ignore
        parse(endDate, 'yyyy-MM-dd', new Date()),
        // @ts-ignore
        parse(startDate, 'yyyy-MM-dd', new Date()),
      ) +
        1) *
      (endTime - startTime)
    );
  }
  return 0;
};
*/
const getBookingById = (dispatch, id) =>
  new Promise((resolve, reject) => {
    dispatch(
      bookingThunks.getById(
        { bookingId: id },
        response => {
          const {startOn, endOn, singleDay} = bookingTimeData(response.data)
          const hours = endOn.diff(startOn, 'hours', true)
          const date = startOn.format('D MMMM YYYY')
          const time = `${startOn.format('h:mma')} - ${endOn.format('h:mma')}`
          let fields = []
          if (singleDay) {
            fields = [
              { title: 'Date', value: date },
              { title: 'Time', value: time },
              { title: 'Duration', value: `${hours} hours` },
              { title: 'Price', value: `$${response.data.price}` },
            ]
          } else {
            fields = [
              { title: 'Date', value: `${date} - ${endOn.format('D MMMM YYYY')}` },
              { title: 'Time', value: time },
              { title: 'Duration', value: `${hours} hours` },
              { title: 'Price', value: `$${response.data.price}` },
            ]
          }
          resolve({
            ...response.data,
            fields
          });
        },
        error => {
          reject(error);
        },
      ),
    );
  });

const userFallback = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

// @ts-ignore
const BookingDetailsContainer = props => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const [loading, setLoading] = useState(true)
  const { data: user } = useSelectorSafe(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }

  const locationState = useSelectorSafe((state) => {
    return state.location;
  });
  const [prevRouteName, prevPortalName, prevPortal] = routeAndPortalFromLocation(locationState.prev?.type)
  const [routeName, portalName, portal] = routeAndPortalFromLocation(locationState.type)
  const prevRoute = prevPortal && prevPortal[prevRouteName]
  const onBack = () => {
    return dispatch({type: locationState.prev?.type, payload: locationState.prev?.payload});
  }

  const payload = useSelectorSafe(state => state.location.payload);
  const [booking, setBooking] = useState(null);

  const [bookingPassed, setBookingPassed] = useState(true);
  useEffect(() => {
    // @ts-ignore
    if (booking || !(payload && payload.id)) return;
    // @ts-ignore
    getBookingById(dispatch, payload.id)
      .then(response => {
        // @ts-ignore
        setBooking(response);
        // @ts-ignore
        //setQuestions(response.data.questions);
        // @ts-ignore
        // Determine if the booking has passed yet or not.
        const endMomentIso =
          // @ts-ignore
          response && response.data && response.data.endDate
            ? //
              // @ts-ignore
              moment(response.data.endDate)
            : null;
        const currentMomentIso = moment();
        setBookingPassed(
          // @ts-ignore
          endMomentIso && currentMomentIso.isAfter(endMomentIso),
        );
        setLoading(false)
      })
      .catch(error => {
        console.log('_ERROR_', error);
        toast.error(error.message || error.error)
        // We should make this specific to an error which means they do not have permission to see the user.
        // For now we will just switch page regardless of error, need to have specification from Jon.
        goToListings();
      });
  }, [payload]);

  let breadcrumbs = []
  if (portalName == PORTALS.HOST) {
      breadcrumbs = [
      {
        label: prevRoute && prevRoute.label,
        onClick: () => {
          onBack()
        },
      }
    ];
  } else {
    breadcrumbs = [
      {
        label: 'My Bookings',
        onClick: () => {
          dispatch(routerThunks.link(ROUTES.MY_BOOKINGS));
        },
      }
    ];
  }
  const goToListing = () => {
    dispatch(
      // @ts-ignore
      routerThunks.link(ROUTES.LISTING_DETAILS, { id: booking.product.ID }),
    );
  };
  const goToHostDetails = () => {
    // @ts-ignore
    if (
      // @ts-ignore
      booking &&
      // @ts-ignore
      booking.provider &&
      // @ts-ignore
      booking.provider.ID
    ) {
      // @ts-ignore
      const hostId = booking.provider.ID;
      // @ts-ignore
      dispatch(
        // @ts-ignore
        routerThunks.link(ROUTES.HOST_DETAILS, { id: hostId }),
      );
    }
  }

  const changeBookingStatus = useChangeBookingStatus((newBooking) => {
    setBooking(newBooking)
  })

  const onContactUser = () => {
    dispatch(routerThunks.link(ROUTES.MESSAGES, { user: booking.user }));
  }

  return (
    <View
      {...props}
      loading={loading}
      //questionComponents={questionComponents}
      booking={booking}
      onRequestChangeClick={() =>
        // @ts-ignore
        dispatch(routerThunks.link(ROUTES.MESSAGES, { provider: booking.provider, booking }))
      }
      onCancelBookingClick={() =>
        // @ts-ignore
        dispatch(routerThunks.link(ROUTES.MESSAGES, { provider: booking.provider, booking }))
      }
      breadcrumbs={breadcrumbs}
      goToListing={goToListing}
      goToHostDetails={goToHostDetails}
      onContactUser={onContactUser}
      bookingPassed={bookingPassed}
      changeBookingStatus={changeBookingStatus}
      portalName={portalName}
    />
  );
};

export default React.memo(BookingDetailsContainer);
