import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {ROUTES} from 'routes';
import { routerActions, bookingDetailsActions, createListingActions, productActions } from 'store/actions';
import routerThunks from 'thunks/router'
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import useForm from 'utils/Hooks/useForm'
import { useCalendar } from 'components/primitives/Calendar';
import productThunks from 'thunks/product';
import {initialState as availabilityInitialState} from 'components/modules/Availability'
import {moment, weekdays, weekdayAbbrev} from 'utils/moment'
import * as imageConversion from 'image-conversion'
import {useGetSettingsList, getSettingValue} from 'services/react-query'

const DEBUG_DISABLE_VALIDATION = false

const initialFormState = {
  id: { value: '', error: '' },
  venueName: { value: '', error: '' },
  description: { value: '', error: '' },
  address: { value: '', error: '' },
  capacityMin: { value: '', error: '' },
  capacityMax: { value: '', error: '' },

  type: { value: '', error: '' },
  subtype: { value: '', error: '' },

  attributes: { value: [], error: '' },

  images: { value: [], error: '' },
  video: { value: null, error: '' },

  schedule: { value: availabilityInitialState, error: '' },

  question1: { value: '', error: '' },
  question1bool: { value: false, error: '' },

  question2: { value: '', error: '' },
  question2bool: { value: false, error: '' },

  question3: { value: '', error: '' },
  question3bool: { value: false, error: '' },

  weekdaysHourlyRate: { value: '', error: '' },
  weekendsHourlyRate: { value: '', error: '' }
}

const formToProduct = (form/*, existingProduct = {}*/) => {
  let listing = Object.keys(form.state).reduce((acc, k) => {
    return {
        ...acc,
        [k]: form.state[k].value,
    }
  }, {})
  const images = listing.images.map(img => img.data_url)
  const videos = listing.video ? [listing.video] : []
  /*[
    listing.headerImage.length && listing.headerImage[0].data_url, 
    listing.image1.length && listing.image1[0].data_url, 
    listing.image2.length && listing.image2[0].data_url,
    listing.image3.length && listing.image3[0].data_url,
    listing.image4.length && listing.image4[0].data_url
  ].filter(i => !!i)
*/
  // location comes in 2 formats:
  // 1. the native google place format when first creating a new placeement
  // 2. what ever was saved in the database when editing an existing location 
  let location = null
  if (listing.address.hasOwnProperty('address')) {
    location = {
      address: listing.address.description,
      latitude: listing.address.latitude,
      longitude: listing.address.longitude,
    }
  } else {
    const latitude = listing.address?.details?.geometry?.location?.lat() ?? 0
    const longitude = listing.address?.details?.geometry?.location?.lng() ?? 0
    location = {
      address: listing.address.description,
      latitude,
      longitude
    }
  }

  // compile questions into object
  const questions = [
    {
      question: listing.question1,
      type: listing.question1bool ? 'yesNo' : 'longText'
    },
    {
      question: listing.question2,
      type: listing.question2bool ? 'yesNo' : 'longText'
    },
    {
      question: listing.question3,
      type: listing.question3bool ? 'yesNo' : 'longText'
    }
  ].filter(q => !!q.question)

  const product = {
    //...existingProduct,
    title: listing.venueName,
    description: listing.description,
    images,
    videos,
    capacity: {
      min: Number(listing.capacityMin),
      max: Number(listing.capacityMax)
    },
    type: listing.type,
    subtype: listing.subtype,
    location,
    attributes: listing.attributes,
    pricing: {
      weekdays: Number(listing.weekdaysHourlyRate),
      weekends: Number(listing.weekendsHourlyRate)
    },
    questions,
    schedule: listing.schedule
  }
  if (listing.id != 'local' && listing.id != '') {
    product.ID = listing.id
  }
  return product
}

const applyProductToForm = (p, form) => {
  if (!p) {
    return
  }
  // push the product fields into the form fields

  // ID is a special case for editing an existing product
  if (p.ID) {
    form.handleOnChange('id')(p.ID)
  }

  p.questions.forEach((q, index) => {
    form.handleOnChange(`question${index+1}`)(q.question)
    form.handleOnChange(`question${index+1}bool`)(q.type === 'yesNo')
  })

  form.handleOnChange('venueName')(p.title)
  form.handleOnChange('description')(p.description)
  form.handleOnChange('capacityMin')(p.capacity?.min)
  form.handleOnChange('capacityMax')(p.capacity?.max)
  form.handleOnChange('type')(p.type)
  form.handleOnChange('subtype')(p.subtype)
  form.handleOnChange('attributes')(p.attributes)
  form.handleOnChange('address')({
    description: p.location.address,
    ...p.location,
  })
  form.handleOnChange('weekdaysHourlyRate')(p.pricing && p.pricing.weekdays)
  form.handleOnChange('weekendsHourlyRate')(p.pricing && p.pricing.weekends)

  const images = p.images.map(img => ({data_url: img}))
  form.handleOnChange('images')(images)

  const video = p.videos.length ? p.videos[0] : null
  form.handleOnChange('video')(video)

  form.handleOnChange('schedule')(p.schedule)
}

const CreateEditListingContainer = props => {
  const {steps, view: View} = props
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(true);

  const settingsListQuery = useGetSettingsList()
  const attributes = getSettingValue(settingsListQuery, 'product-attributes', [])
  const typeOptions = getSettingValue(settingsListQuery, 'product-types', [])

  const form = useForm(
    initialFormState,
    {
    },
    (s) => {
      console.log('submit!')
    },
  );

  const setCapacityMinOnBlur = () => {
    form.handleOnBlur('capacityMin')()
  }

  const setCapacityMaxOnBlur = () => {
    form.handleOnBlur('capacityMax')()
  }

  const createListingState = useSelectorSafe(
    state => state.createListing
  );

  const locationPayload = useSelectorSafe(state => state.location.payload);
  useEffect(() => {
    if (locationPayload.id == 'local') {
      applyProductToForm(createListingState.data, form)
      setLoading(false)
      return
    }

    setLoading(true);
    dispatch(
      productThunks.getById(
        {
          // @ts-ignore
          productId: locationPayload.id,
        },
        (response) => {
          setLoading(false);
          const p = response.data
          applyProductToForm(p, form)
        },
        (error) => {
          setLoading(false);
        },
      ),
    );
  }, [locationPayload])

  const sliderRef = useRef(null);

  const [activeStep, setActiveStep] = React.useState(0);
  const stepCount = steps.length;

  let fieldsEmpty = [
    form.state.venueName.value == '' || form.state.description.value == '' || !form.state.address.value || form.state.capacityMin.value == '' || form.state.capacityMax.value == '' || form.state.type.value == '' || form.state.subtype.value == '' || form.state.attributes.value.length <= 0,
    form.state.images.value.length <= 0,
    form.state.weekdaysHourlyRate.value == '' || form.state.weekendsHourlyRate.value == ''
  ]
  if (DEBUG_DISABLE_VALIDATION) {
    fieldsEmpty = fieldsEmpty.map(v => false)
  }
 
  const [errorMessage, setErrorMessage] = useState()
  const [errorField, setErrorField] = useState()

  const setFieldError = (field, message) => {
    setErrorMessage(message)
    setErrorField(field)
  }

  const validateStep = async (step) => {
    const state = form.state
    const nonDigitExp = new RegExp(/\D+/g)

    if (step == 0) {
      if (!state.venueName.value) {
        setFieldError('venueName', 'Venue Name is required')
        return false
      }

      if (!state.address.value) {
        setFieldError('address', 'Address is required')
        return false
      }

      if (!state.capacityMin.value) {
        setFieldError('capacityMin', 'Minimum Capacity is required')
        return false
      }

      const capacityMin = Number(Number(state.capacityMin.value).toFixed(0))
      const capacityMinValue = '' + state.capacityMin.value
      if (capacityMin <= 0 || isNaN(capacityMin) || capacityMinValue.match(nonDigitExp)) {
        setFieldError('capacityMin', 'Minimum Capacity must be a positive number')
        return false
      }

      if (!state.capacityMax.value) {
        setFieldError('capacityMax', 'Maximum Capacity is required')
        return false
      }

      const capacityMax = Number(Number(state.capacityMax.value).toFixed(0))
      const capacityMaxValue = '' + state.capacityMax.value
      if (capacityMax <= 0 || isNaN(capacityMax) || capacityMaxValue.match(nonDigitExp)) {
        setFieldError('capacityMax', 'Maximum Capacity must be a positive number')
        return false
      }

      if (capacityMin > capacityMax) {
        setFieldError('capacityMax', 'Maximum Capacity must be greater than or equal to Minimum Capacity')
        return false
      }

      if (!state.type.value) {
        setFieldError('type', 'Type is required')
        return false
      }

      if (!state.subtype.value) {
        setFieldError('subtype', 'Subtype is required')
        return false
      }

      if (state.attributes.value.length <= 0) {
        setFieldError('facilities', 'At least 1 facility is required')
        return false
      }

      /* now optional
      if (!state.description.value) {
        setFieldError('description', 'Description is required')
        return false
      }*/

    } else if (step == 1) {

      if (state.images.value.length <= 0) {
        setFieldError('images', 'At least 1 image is required')
        return false
      }

      for (let i = 0; i < state.images.value.length; ++i) {
        const image = state.images.value[i]
        const im = await imageConversion.dataURLtoImage(image.data_url)
        let {width, height} = im
        if (width < 1024) {
          setFieldError('images', `Image ${i+1} must be at least 1024px in width`)
          return false
        }
        if (height < 683) {
          setFieldError('images', `Image ${i+1} must be at least 683px in height`)
          return false
        }
      }

    } else if (step == 2) {

      if (!state.weekdaysHourlyRate.value) {
        setFieldError('weekdaysHourlyRate', 'Hourly Weekday Rate is required')
        return false
      }

      const weekdaysHourlyRate = Number(Number(state.weekdaysHourlyRate.value).toFixed(2))
      const weekdaysHourlyRateValue = '' + state.weekdaysHourlyRate.value
      if (weekdaysHourlyRate <= 0 || isNaN(weekdaysHourlyRate) || weekdaysHourlyRateValue != weekdaysHourlyRate) {
        setFieldError('weekdaysHourlyRate', 'Hourly Weekday Rate must be a positive number with up to 2 decimals')
        return false
      }

      if (!state.weekendsHourlyRate.value) {
        setFieldError('weekendsHourlyRate', 'Hourly Weekend Rate is required')
        return false
      }

      const weekendsHourlyRate = Number(Number(state.weekendsHourlyRate.value).toFixed(2))
      const weekendsHourlyRateValue = '' + state.weekendsHourlyRate.value
      if (weekendsHourlyRate <= 0 || isNaN(weekendsHourlyRate) || weekendsHourlyRateValue != weekendsHourlyRate) {
        setFieldError('weekendsHourlyRate', 'Hourly Weekend Rate must be a positive number with up to 2 decimals')
        return false
      }

      // validate schedule
      const {rrules} = state.schedule.value
      for (let rule of rrules) {
        const startTime = moment(rule.startTime, 'hh:mm')
        const endTime = moment(rule.endTime, 'hh:mm')

        if (endTime.isBefore(startTime) || endTime.isSame(startTime)) {
          const dayName = weekdays.find(dayName => weekdayAbbrev(dayName) == rule.day)
          setFieldError('schedule', `${dayName}'s End Time must be after the Start Time`)
          return false
        }
      }

    }

    return true
  }

  const onSaveListing = async () => {
    setFieldError(null, null)

    // ensure all steps are valid
    if (!(await validateStep(0))) {
      return
    }
    if (!(await validateStep(1))) {
      return
    }
    if (!(await validateStep(2))) {
      return
    }

    const product = formToProduct(form)
    dispatch(
      createListingActions.updated({data: product}),
    );

    dispatch(routerThunks.link(ROUTES.LISTING_DETAILS, { id: 'local' }));
  }

  const onNextStep = async () => {
    setFieldError(null, null)

    // validate current step
    if (!(await validateStep(activeStep))) {
      return
    }

    let nextActiveStep = activeStep + 1
    const finished = nextActiveStep >= stepCount
    nextActiveStep = Math.min(stepCount - 1, nextActiveStep)

    if (finished) {
      onSaveListing()
      return
    }

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(nextActiveStep)
    }
    setActiveStep(nextActiveStep);
  };

  const onBackStep = () => {
    let nextActiveStep = Math.max(0, activeStep - 1)

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(nextActiveStep)
    }

    setActiveStep(nextActiveStep);
  };

  const onSwipe = (index) => {
    setActiveStep(index);
  }

  const onViewListing = () => {
    return dispatch(routerThunks.link(ROUTES.ROOT, { id: 0 }));
  }


  const selectedFacilities = form.state.attributes;

  const onToggleFacility = (facility) => {
    const setFacilities = form.handleOnChange('attributes')
    let newSelectedFacilities = []
    if (selectedFacilities.value.includes(facility)) {
      newSelectedFacilities = selectedFacilities.value.filter(f => f !== facility)
    } else {
      newSelectedFacilities = [...selectedFacilities.value, facility]
    }
    setFacilities(newSelectedFacilities)
  };

  const onChangeSchedule = (scheduleJSON) => {
    form.handleOnChange('schedule')(scheduleJSON)
  }

  const setType = (type) => {
    form.handleOnChange('type')(type)
    form.handleOnChange('subtype')(null) // force user to pick a new subtype
  }

  const type = form.state.type
  const subtypeOptions = typeOptions?.find(to => to.value == type?.value)?.subtypeOptions || []

  return (
    <View
      {...props}
      onViewListing={onViewListing}
      steps={steps}
      activeStep={activeStep}
      onNextStep={onNextStep}
      onBackStep={onBackStep}
      onSwipe={onSwipe}
      sliderRef={sliderRef}
      errorMessage={errorMessage}
      errorField={errorField}
      //nextDisabled = {fieldsEmpty[activeStep]}
      onSaveListing={onSaveListing}
      onPublishListing={onSaveListing}

      venueName={form.state.venueName}
      setVenueName={form.handleOnChange('venueName')}
      setTouchedVenueName={form.handleOnBlur('venueName')}

      description={form.state.description}
      setDescription={form.handleOnChange('description')}
      setTouchedDescription={form.handleOnBlur('description')}

      address={form.state.address}
      setAddress={form.handleOnChange('address')}
      setTouchedAddress={form.handleOnBlur('address')}

      capacityMin={form.state.capacityMin}
      setCapacityMin={form.handleOnChange('capacityMin')}
      setTouchedCapacityMin={setCapacityMinOnBlur}

      capacityMax={form.state.capacityMax}
      setCapacityMax={form.handleOnChange('capacityMax')}
      setTouchedCapacityMax={setCapacityMaxOnBlur}

      typeOptions={typeOptions}
      type={form.state.type}
      setType={setType}
      setTouchedType={form.handleOnBlur('type')}

      subtypeOptions={subtypeOptions}
      subtype={form.state.subtype}
      setSubtype={form.handleOnChange('subtype')}
      setTouchedSubtype={form.handleOnBlur('subtype')}

      images={form.state.images}
      setImages={form.handleOnChange('images')}

      video={form.state.video}
      setVideo={form.handleOnChange('video')}
      setTouchedVideo={form.handleOnBlur('video')}

      schedule={form.state.schedule}
      setSchedule={onChangeSchedule}

      question1={form.state.question1}
      question1bool={form.state.question1bool}
      setQuestion1={form.handleOnChange('question1')}
      setTouchedQuestion1={form.handleOnBlur('question1')}
      setQuestion1bool={form.handleOnChange('question1bool')}

      question2={form.state.question2}
      question2bool={form.state.question2bool}
      setQuestion2={form.handleOnChange('question2')}
      setTouchedQuestion2={form.handleOnBlur('question2')}
      setQuestion2bool={form.handleOnChange('question2bool')}

      question3={form.state.question3}
      question3bool={form.state.question3bool}
      setQuestion3={form.handleOnChange('question3')}
      setTouchedQuestion3={form.handleOnBlur('question3')}
      setQuestion3bool={form.handleOnChange('question3bool')}

      weekdaysHourlyRate={form.state.weekdaysHourlyRate}
      setWeekdaysHourlyRate={form.handleOnChange('weekdaysHourlyRate')}
      setTouchedWeekdaysHourlyRate={form.handleOnBlur('weekdaysHourlyRate')}

      weekendsHourlyRate={form.state.weekendsHourlyRate}
      setWeekendsHourlyRate={form.handleOnChange('weekendsHourlyRate')}
      setTouchedWeekendsHourlyRate={form.handleOnBlur('weekendsHourlyRate')}

      attributes={attributes}
      selectedFacilities={selectedFacilities}
      onToggleFacility={onToggleFacility}
/*
      startTimeOptions={startTimeOptions}
      endTimeOptions={endTimeOptions}
      openCalendar={openCalendar}
      setOpenCalendar={setOpenCalendar}
      calendarHook={calendarHook}
*/
      loading={loading}
    />
  );
};

export default React.memo(CreateEditListingContainer);
