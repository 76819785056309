import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import { useStyles } from './CreateNewListingBooking.styles';
import {Typo, SplitView, InputField, Select, Calendar, Checkbox, HorizontalLine, Link, onKeyPressPositiveDecimal, onPasteDisable} from 'components/primitives';
import {Availability} from 'components/modules'
import { Box, FormControlLabel } from '@mui/material';
import moment from 'moment'
import { DriveEta, Info } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const YesOrNoLabel = (props) => {
  return (
    <Typo variant="body0">
      Yes or No Question?

      <Tooltip title="Check this box if this question has a YES or NO as the only possible answers to the above question">
        <IconButton>
          <Info width={15} height={15}/>
        </IconButton>
      </Tooltip>
    </Typo>
  )
}

const CreateNewListingBookingView = (
  props,
) => {
  // refer to BookingSelect.view in customer web app
  const {
    initialDate,
    openCalendar,
    setOpenCalendar,
    startDate,
    endDate,
    calendarHook,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    startTimeOptions,
    endTimeOptions,
    onStartTimeSelect,
    onEndTimeSelect,
    disabledModifier,
    disabled,
    errorField
  } = props
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      {props.headerChildren ? props.headerChildren : null}
      <div className={classes.splitViewContainer}>
        <SplitView
          leftChild={
            <div>
              <Typo variant="h4" color="darkGrey" className={classes.heading}>
                Hourly Weekday Rate
              </Typo>
              <InputField
                id="weekdaysHourlyRate"
                placeholder="Enter Amount"
                onChange={event => {
                  props.setWeekdaysHourlyRate(event.target.value);
                }}
                onBlur={props.setTouchedWeekdaysHourlyRate}
                value={props.weekdaysHourlyRate?.value}
                //error={Boolean(props.weekdaysHourlyRate?.error) && props.weekdaysHourlyRate?.touched}
                error={errorField == 'weekdaysHourlyRate'}
                className={classes.field}
                disabled={disabled}
                onKeyPress={onKeyPressPositiveDecimal}
                //onPaste={onPasteDisable}
                required
              />
              <Typo variant="h4" color="darkGrey" className={classes.heading}>
                Hourly Weekend Rate
              </Typo>
              <InputField
                id="weekendsHourlyRate"
                placeholder="Enter Amount"
                onChange={event => {
                  props.setWeekendsHourlyRate(event.target.value);
                }}
                onBlur={props.setTouchedWeekendsHourlyRate}
                value={props.weekendsHourlyRate?.value}
                //error={Boolean(props.weekendsHourlyRate?.error) && props.weekendsHourlyRate?.touched}
                error={errorField == 'weekendsHourlyRate'}
                className={classes.field}
                disabled={disabled}
                onKeyPress={onKeyPressPositiveDecimal}
                //onPaste={onPasteDisable}
                required
              />
              <Availability value={props.schedule?.value} onChange={props.setSchedule}/>
            </div>
          }
          rightChild={
            <div className={classes.rightChildContainer}>
              <Typo variant="h4" color="darkGrey" className={classes.heading}>
                Questions for your Guests?
              </Typo>
              <Typo variant="body1" color="lightGrey" className={classes.heading}>
                Set the optional questions you'd like guests to answer prior to requesting a booking.
              </Typo>
              <InputField
                id="question1"
                placeholder="Question 1"
                onChange={event => {
                  props.setQuestion1(event.target.value);
                }}
                onBlur={props.setTouchedQuestion1}
                value={props.question1?.value}
                //error={Boolean(props.question1?.error) && props.question1?.touched}
                error={errorField == 'question1'}
                className={classes.field}
                disabled={disabled}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.question1bool.value}
                    onClick={(e) => {
                      props.setQuestion1bool(!props.question1bool.value)
                    }}
                    disabled={disabled}
                  />
                }
                label={<YesOrNoLabel/>}
                className={classes.field}
              />
              <HorizontalLine className={classes.spacer}/>
              <InputField
                id="question2"
                placeholder="Question 2"
                onChange={event => {
                  props.setQuestion2(event.target.value);
                }}
                onBlur={props.setTouchedQuestion2}
                value={props.question2?.value}
                //error={Boolean(props.question2?.error) && props.question2?.touched}
                error={errorField == 'question2'}
                className={classes.field}
                disabled={disabled}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.question2bool.value}
                    onClick={(e) => {
                      props.setQuestion2bool(!props.question2bool.value)
                    }}
                    disabled={disabled}
                  />
                }
                label={<YesOrNoLabel/>}
                className={classes.field}
              />
              <HorizontalLine className={classes.spacer}/>
              <InputField
                id="question3"
                placeholder="Question 3"
                onChange={event => {
                  props.setQuestion3(event.target.value);
                }}
                onBlur={props.setTouchedQuestion3}
                value={props.setQuestion3?.value}
                //error={Boolean(props.question3?.error) && props.question3?.touched}
                error={errorField == 'question3'}
                className={classes.field}
                disabled={disabled}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.question3bool?.value}
                    onClick={(e) => {
                      props.setQuestion3bool(!props.question3bool.value)
                    }}
                    disabled={disabled}
                  />
                }
                label={<YesOrNoLabel/>}
                className={classes.field}
              />
              {/*
              <Typo
                variant="body1"
                //component="a"
                color="blue"
                //style={{ marginTop: '1em' }}
                //onClick={onBack}
                className={classes.button}
              >
                Remove Details
              </Typo>
              */}
            </div>
          }
          leftFraction={props.leftFraction || 0.333}
        />
      </div>
    </div>
  );
};

export default CreateNewListingBookingView;
