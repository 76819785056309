import { handleActions } from 'redux-actions';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions, createListingActions } from '../actions';

const DEFAULT_STATE = asyncData(ASYNC_STATUS.INITIAL);

const reducer = handleActions(
  {
    ...createAsyncReducers('CREATE_LISTING/SUBMIT'),
    [String(createListingActions.updated)]: presetReducers.makeSetter(),
    [String(createListingActions.clear)]: () => DEFAULT_STATE,
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
