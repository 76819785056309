import { Box } from '@mui/material';
import React, { FC } from 'react';
import { Calendar, Typo } from '../../primitives';
import MobileButton from '../MobileButton';
import { CalendarFilterProps } from './CalendarFilter.props';
import { useStyles } from './CalendarFilter.styles';

const CalendarFilterView: FC<CalendarFilterProps> = ({
  state,
  onSubmitDate,
  disablePast = true,
  disableModifier,
  notAvailableDates,
  toMonth
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.container}>
        <Typo variant="h2">Date</Typo>
        <Box className={classes.dateContainer}>
          <div className={classes.headerContainer}>
            <Typo style={{ marginLeft: 24 }} variant="h4">
              Select Date
            </Typo>
            <button className={classes.clearButton} onClick={state.handleReset}>
              <Typo variant="body2" color="lightGrey">
                Clear
              </Typo>
            </button>
          </div>
          <Calendar 
            disablePast={disablePast} 
            hookState={state}
            notAvailableDates={notAvailableDates}
            disableModifier={disableModifier}
            toMonth={toMonth}
          />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          position: 'fixed',
          bottom: 0,
          width: 400,
          maxWidth: 'calc(100% - 60px)',
        }}
      >
        <MobileButton text="Select Dates" onClick={onSubmitDate} />
      </Box>
    </Box>
  );
};

export default React.memo(CalendarFilterView);
