import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      height: 'calc(100% - 144px)',
      top: 144,
    },
    sidebar: {
      width: 400,
    },
  }),
);
