import {routeAndPortalFromLocation} from 'routes'

const link = (nextRoute, payload, portalNameOverride = null) => (
  dispatch,
  getState,
) => {
  const location = getState().location.type
  const [routeName, portalName, portal] = routeAndPortalFromLocation(location)
  const m = {
    type: `ROUTER/${portalNameOverride || portalName}/${nextRoute}`,
    payload: payload || {},
  }
  dispatch(m)
}

export default {
  link
}
