import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    imagesContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: 470,
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 20
    },
    imageCarouselPlaceHolder: {
      height: '100%',
      flex: 3,
      //width: 'calc(100% - 40px)',
      //margin: 'auto',
      backgroundColor: colors.lightGrey,
    },
    sideImagesContainer: {
      flex: 1,
      marginLeft: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: '2px'
    },
    sideImagePlaceHolder: {
      flex: 1,
      //marginBottom: 30,
      height: (470 / 2) - 2, // - 30,
      
      //width: 'calc(100% - 40px)',
    },
    container: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    leftContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    price: {
      alignSelf: 'flex-end',
      marginBottom: 25,
    },
    rightContent: {
      width: 370,
      height: '100%',
      marginLeft: 28,
      boxShadow: '0 5px 30px 0 rgba(0,0,0,0.05)',
      borderRadius: 5,
      padding: 20,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 20,
        marginLeft: 0,
      },
    },
    headerInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
    },
    listingTitle: {
      marginBottom: 10,
    },
    listingAddress: {
      marginBottom: 15,
    },
    ratingContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    listingDescription: {
      marginTop: 20,
      marginBottom: 20,
    },
    listingParagraph: {
      marginBottom: 20,
    },
    attributes: {
      background: 'rgba(162, 170, 173, 0.1)',
      borderRadius: 5,
      padding: 20,
      marginBottom: 20,
      '& ul': {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        paddingLeft: 0,
        '& li': {
          marginLeft: 20,
        },
      },
    },
    subHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    location: {},
    mapContainer: {
      width: '100%',
      height: 300,
      background: colors.lightGrey,
      marginBottom: 20,
    },
    reviews: {
      marginBottom: 20,
    },
    pendingReviews: {
      marginTop: 20,
      marginBottom: 20,
    },
    reviewContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: 30,
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridRowGap: 10,
      },
    },
    review: {},
    reviewText: {},
    reviewFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    reviewAuthor: {
      display: 'flex',
      alignItems: 'center',
    },
    reviewAuthorName: {
      fontWeight: 500,
    },
    relatedPlaces: {
      marginTop: 40,
    },
    related: {
      marginTop: 20,
      marginBottom: '80px',
      position: 'relative',
      '&:after': {
        content: '""',
        background: colors.lightGrey,
        opacity: 0.1,
        position: 'absolute',
        top: '45%',
        zIndex: -1,
        left: 0,
        right: 0,
        bottom: '-30px',
      },
    },
    inputLabel: {
      marginBottom: 10,
    },
    note: {
      marginBottom: 10,
    },
    selectDate: {
      marginBottom: 20,
    },
    selectTime: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridGap: 4,
      marginBottom: 20,
    },
    breakDown: {
      marginBottom: 20,
    },
    breakDownItem: {
      display: 'flex',
      marginBottom: 10,
    },
    breakDownItemLabel: {
      flex: 1,
    },
    breakDownItemValue: {},
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: '20px',
      paddingTop: '100px',
      paddingBottom: '50px'
    },
    button: {
      width: '345px',

      [theme.breakpoints.down('sm')]: {
        flex: 1,
      }
    },
    questionsContainer: {
      marginBottom: '24px'
    },
    question: {
      fontSize: '16px',
      fontWeight: 'bold'
    }
  }),
);
