import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import View from './MyBookings.view';
import bookingThunks from '../../../thunks/booking';
import routerThunks from 'thunks/router'
import {ROUTES} from '../..';
import { UserState } from '../../../types/store/UserState';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import {useInfiniteScroll} from 'components/modules'

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const Container = (props: any) => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: user } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }

  const [currentFilterStatus, setCurrentFilterStatus] = useState('all');

  const filterMethods = {
    all: () => (element: any) => element !== undefined,
    pending: () => (element: any) => element.status === 'pending',
    confirmed: () => (element: any) => element.status === 'confirmed',
    declined: () => (element: any) =>
      element.status === 'declined' || element.status === 'cancelled' || element.status === 'deleted',
  };
  const [currentFilterMethod, setCurrentFilterMethod] = useState(
    filterMethods.all,
  );

  const fetchMoreFn = (lastKey, resolve, reject) => {
    dispatch(
      bookingThunks.getMyBookings({startKey: lastKey},
        (response) => {
          const bookings = response.data.bookings; //Object.keys(response).map(key => response[key]);
          // @ts-ignore
          let filteredBookings = bookings.filter(booking => {
            const endMomentIso = booking.endOn
              ? moment(booking.endOn)
              : null;
            const currentMomentIso = moment();
            return endMomentIso && currentMomentIso.isBefore(endMomentIso);
          });

          // filter based on query
          filteredBookings = filteredBookings.filter(currentFilterMethod)

          // sort by start date/time
          filteredBookings = filteredBookings.sort((a, b) => {
              return moment(a.startOn) - moment(b.startOn)
          })

          const lk = lastKey
          if (lk) {
            console.assert(lk != response.lastKey)
          }
          resolve(filteredBookings, response.lastKey)
        },
        (error) => {
          reject(error)
        }
      )
    )
  }
  const {data: bookings, setData: setBookings, fetchMore, hasMore, loading} = useInfiniteScroll(fetchMoreFn, [currentFilterMethod])

  const setFilterMethodByStatus = (status: string) => {
    // @ts-ignore
    setCurrentFilterMethod(filterMethods[status]);
    setCurrentFilterStatus(status);
  };

  const handleBookingClick = (id: string) => {
    dispatch(routerThunks.link(ROUTES.VIEW_BOOKING_DETAILS, { id }));
  };
  const handleContactHostClick = (provider: any, booking) => {
    dispatch(routerThunks.link(ROUTES.MESSAGES, { provider, booking }));
  };
  const handleBookingHistoryClick = () => {
    dispatch(routerThunks.link(ROUTES.MY_BOOKING_HISTORY));
  };

  const [isMapVisible, setMapVisible] = useState(false);
  const toggleMap = () => {
    setMapVisible(!isMapVisible);
  };

  return (
    <View
      {...props}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      myFilteredBookings={bookings}
      currentFilterStatus={currentFilterStatus}
      onBookingClick={handleBookingClick}
      onContactHostClick={handleContactHostClick}
      onBookingHistoryClick={handleBookingHistoryClick}
      setCurrentFilterMethod={setFilterMethodByStatus}
      isMapVisible={isMapVisible}
      toggleMap={toggleMap}
    />
  );
};

export default React.memo(Container);
