import React, { FC, ReactElement } from 'react';

import { ListingDetails } from 'components/modules';
import { ListingDetailsProps } from './ListingDetails.props';

const ListingDetailsView: FC<ListingDetailsProps> = (
  props: ListingDetailsProps,
): ReactElement<'div'> => {
  return <ListingDetails />;
};

export default ListingDetailsView;
