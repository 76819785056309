import { Box } from '@mui/material';
import React, { FC } from 'react';
import pathOr from 'ramda/es/pathOr';
import format from 'date-fns/format';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './Calendar.css';
import { CalendarProps } from './Calendar.props';

const CalendarView: FC<CalendarProps> = ({
  hookState,
  disablePast,
  bookedDates,
  notAvailableDates = [],
  disabledModifier = () => false,
  initialSelectedDate = null,
  toMonth = null
}) => {
  let fromMonth = null
  if (disablePast) {
    fromMonth = new Date()
  }
  return (
    <Box>
      <DayPicker
        className="Selectable"
        numberOfMonths={1}
        // @ts-ignore
        selectedDays={
          initialSelectedDate !== null && !hookState.from
            ? [initialSelectedDate.from, initialSelectedDate]
            : [hookState.from, { from: hookState.from, to: hookState.to }]
        }
        disabledDays={
          disablePast
            ? [
                ...notAvailableDates,
                { before: new Date() },
                ...(bookedDates || []),
                disabledModifier,
              ]
            : [disabledModifier]
        }
        modifiers={{
          ...hookState.modifiers,
          weekend: { daysOfWeek: [0, 6] },
        }}
        onDayClick={(day: any) => {
          // @ts-ignore
          hookState.handleDayClick(day, disablePast);
        }}
        fromMonth={fromMonth}
        toMonth={toMonth}
      />
    </Box>
  );
};

export default React.memo(CalendarView);
