import { bookingActions } from 'store/actions';
import { Dispatch } from 'redux';
import { addBookingEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

const addBooking = (
  data,
  onSuccess,
  onError,
) => (dispatch, getState) => {
  const addBookingFetch = bookingActions.addBooking(
    apiFetch(addBookingEndpoint, data)(dispatch, getState)
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        if (onError) onError(error);
      }),
  );
  dispatch(addBookingFetch);
};

export default addBooking;
