import { makeStyles, createStyles } from '@mui/styles';
import { colors, fontFamilies } from 'theme/themeConstants';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    checkbox: {},
    icon: {
      width: 16,
      height: 16,
      marginLeft: '3px',
      backgroundColor: colors.white,
      border: `1px solid ${colors.lightGrey}`,
      borderRadius: '2px',
      /*
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },*/
    },
    checkedIcon: {
      backgroundColor: colors.blue,
      border: `1px solid ${colors.blue}`,
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: `url("data:image/svg+xml,%0A%3Csvg width='12px' height='12px' viewBox='0 0 9 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Checkbox/Ticked' transform='translate(-4.000000, -5.000000)' stroke='%23FFFFFF' stroke-width='1.5'%3E%3Cpolyline id='Path-5' points='5 9.22428385 7.19135569 11 12 6'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
  }),
);
