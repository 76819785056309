import React, { FC } from 'react';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from './Button.styles';
import { ButtonProps } from './Button.props';
import Typo from '../Typo';

const ButtonView: FC<ButtonProps> = (props: ButtonProps) => {
  const classes = useStyles(props);
  const {
    text,
    style,
    onClick,
    icon: Icon,
    className,
    type,
    loading = false,
    loadingText = '',
    disabled,
    variant,
    color
  } = props;

  let variantClassName = classes.button
  switch (variant) {
    case 'text':
      variantClassName = classes.textButton
      break
  }

  return (
    // @ts-ignore
    <Button
      className={`${variantClassName} ${className}`}
      style={style}
      onClick={onClick}
      // @ts-ignore
      type={type}
      disabled={disabled}
      variant={variant}
    >
      {Icon && <Icon className={classes.icon} />}
      <Typo className={classes.typography} variant="h5" color={color}>
        {text}
      </Typo>
      {loading &&
        <CircularProgress
          style={{ color: '#fff', marginLeft: 16}}
          size={20}
        />
      }
    </Button>
  );
};

export default React.memo(ButtonView);
