import { colors, fontFamilies } from './themeConstants';

export default {
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
        styleOverrides: {
            root: {
              '&:hover': {
                  backgroundColor: 'transparent',
              },
            },
        }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: fontFamilies.gilroy,
          fontSize: '16px',
          lineHeight: '19px'
        }
      }
    },

    MuiIconButton: {
        styleOverrides: {
            root: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        }
    },

    MuiInputBase: {
        styleOverrides: {
            root: {},
            input: {
              paddingTop: 0,
              paddingBottom: 0,
              fontFamily: fontFamilies.gilroy,
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '1em',

              
              '&:-internal-autofill-selected': {
                '-webkit-transition-delay': '9999999s'
              },
              
              '&:-webkit-autofill': {
                '-webkit-transition-delay': '9999999s'
              }
            },
        }
    },

    MuiBackdrop: {
        styleOverrides: {
            root: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          },
        }
    },

    MuiPaper: {
        styleOverrides: {
            elevation8: {
              boxShadow: '0 5px 30px 0 rgba(0,0,0,0.05)',
            },
          }
    },

    MuiSelect: {
        styleOverrides: {
            root: {},
            select: {
              '&:focus': {
                backgroundColor: 'transparent',
              },
            },
          }
    },

    MuiSlider: {
        styleOverrides: {
            root: {
              height: '30px',
              marginTop: '22px',
              width: 'calc(100% - 28px)',
              marginLeft: '14px',
              marginRight: '14px',
            },
            valueLabel: {
              fontFamily: fontFamilies.gilroy,
              fontSize: '15px',
              left: 'calc(-50% + 11px)',
              top: -22,
              '& *': {
                background: 'transparent',
                color: colors.lightGrey,
              },
            },
            thumb: {
              height: '30px',
              width: '30px',
              marginTop: '-14px',
              marginLeft: '-14px',
              marginRight: '14px',
              color: colors.white,
              border: '1px solid rgb(160,170,173, 0.2)',
              borderRadius: '5px',
              '&:focus,&:hover,&$active': {
                boxShadow: '0px 2px 3px 1px rgba(0,0,0, 0.02)',
              },
            },
            track: {
              height: '3px',
              color: colors.blue,
            },
            rail: {
              color: colors.lightGrey,
            },
          }
    }
}
