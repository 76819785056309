import React, { FC } from 'react';
// @ts-ignore
import StarRatings from 'react-star-ratings';
import { colors } from 'theme/themeConstants';
import { HeartIcon } from 'assets';
import { useStyles } from './LocationCard.styles';
import { LocationCardProps } from './LocationCard.props';
import Typo from '../Typo';
import Slider from 'react-slick';

export const entityTypeColors = {
  corporate: 'rgb(88, 193, 219)',
  creative: 'rgb(204, 87, 97)',
  functions: 'rgb(73, 107, 183)',
  sports: 'rgb(238, 177, 79)',
  outdoor: 'rgb(102, 170, 166)',
  other: 'rgb(231, 162, 144)',
}

const reallyOnClick = (onClick: any) => (event: any) => {
  onClick(event)
  event.stopPropagation()
}

function SliderArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={style}
      onClick={reallyOnClick(onClick)}
    />
  );
}

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SliderArrow />,
  prevArrow: <SliderArrow />
};

const LocationCardView: FC<LocationCardProps> = props => {
  const classes = useStyles(props);
  const { isLoggedIn } = props;
  return (
    <div
      className={classes.container}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver ? props.onMouseOver : () => {}}
      onMouseOut={props.onMouseOut ? props.onMouseOut : () => {}}
    >
      <div className={classes.imageContainer}>
        <div className={classes.imageInnerContainer}>
          <Slider {...sliderSettings} className={classes.imageSlider}>
            {props.images && props.images.map((image, idx) => {
              return (
                <img src={image} className={classes.sliderImage} key={idx}/>
              )
            })}
          </Slider>
          {/*<div className={classes.image} />*/}
          {isLoggedIn && (
            <HeartIcon
              className={classes.heart}
              onClick={(event: any) => {
                event.stopPropagation();
                props.onFavouriteClick();
              }}
            />
          )}
        </div>
      </div>
      <div className={classes.infoContainer}>
        <Typo variant="h6" color="blue" style={{color: entityTypeColors[props.type] || '#dc4d5e'}}>
          {props.type}
        </Typo>
        <Typo variant="h6" color="blue" style={{fontSize: '14px'}}>
          Capacity: {props.capacity.min}-{props.capacity.max}
        </Typo>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          //height: '130px',
          flex: 1,
        }}
      >
        <div
          style={{
            //flex: 1.75,
            display: 'flex',
            //overflow: 'hidden',
            //textOverflow: 'ellipsis',
            //minHeight: '52px',
            //maxHeight: '52px',
            marginBottom: '10px',
            //alignItems: 'center',
            maxWidth: '273px' // FMNOTE: not ideal!
          }}
        >
          <Typo variant="h3" color="darkGrey" className={classes.title}>
            {props.title}
          </Typo>
        </div>
        <div
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typo variant="body1" color="lightGrey" className={classes.address}>
              {props.address}
            </Typo>
          </div>
          <div
            className={classes.bottomContainer}
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <StarRatings
              rating={props.rating}
              starRatedColor={colors.red}
              starHoverColor={colors.red}
              starEmptyColor={colors.lightGrey}
              numberOfStars={5}
              svgIconPath="M7.5 11.25 3.09161061 13.5676275 3.93353806 8.65881373 0.367076128 5.18237254 5.2958053 4.46618627 7.5 0 9.7041947 4.46618627 14.6329239 5.18237254 11.0664619 8.65881373 11.9083894 13.5676275z"
              svgIconViewBox="0 0 15 14"
              starDimension="15px"
              starSpacing="0px"
              name="rating"
            />
            <Typo variant="h5" color="darkGrey" className={classes.price}>
              ${props.weekdayPrice} - ${props.weekendPrice} /hour
            </Typo>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LocationCardView);
