import React from 'react';
import { useDispatch } from 'react-redux';
import {ROUTES} from 'routes';
import moment from 'moment';
import parse from 'date-fns/parse';
import { differenceInDays } from 'date-fns';
import View from './BookingRequested.view';
import routerThunks from 'thunks/router'
import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import { UserState } from '../../../types/store/UserState';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';

const getTimePeriod = (
  startDate: string,
  endDate: string,
  startTime: any,
  endTime: any,
) => {
  if (startDate && endDate && startTime && endTime) {
    return (
      (differenceInDays(
        // @ts-ignore
        parse(endDate, 'yyyy-MM-dd', new Date()),
        // @ts-ignore
        parse(startDate, 'yyyy-MM-dd', new Date()),
      ) +
        1) *
      (endTime - startTime)
    );
  }
  return 0;
};

const toBookingDetailsDto = (bookingDetails: any) => {
  const startDate =
    bookingDetails && bookingDetails.startDate
      ? moment(bookingDetails.startDate, 'DD MMM, YYYY').format(
          'YYYY-MM-DD hh:mm:ss',
        )
      : '';
  const endDate =
    bookingDetails && bookingDetails.endDate
      ? moment(bookingDetails.endDate, 'DD MMM, YYYY').format(
          'YYYY-MM-DD hh:mm:ss',
        )
      : '';
  const startTime =
    bookingDetails && bookingDetails.startTime
      ? moment(bookingDetails.startTime, 'hh').format('LT')
      : '';
  const endTime =
    bookingDetails && bookingDetails.endTime
      ? moment(bookingDetails.endTime, 'hh').format('LT')
      : '';
  const startDateTime = moment(startDate, 'DD MMM, YYYY').add(
    bookingDetails.startTime,
    'hours',
  );
  const endDateTime = moment(endDate, 'DD MMM, YYYY').add(
    bookingDetails.endTime,
    'hours',
  );
  const hours =
    bookingDetails &&
    bookingDetails.startDate &&
    bookingDetails.endDate &&
    bookingDetails.startTime &&
    bookingDetails.endTime
      ? getTimePeriod(
          bookingDetails.startDate,
          bookingDetails.endDate,
          bookingDetails.startTime,
          bookingDetails.endTime,
        )
      : 0;
  const convertToDisplayDate = (date: string) => {
    const newDate = moment(date, 'YYYY-MM-DD').format('DD MMM, YYYY');
    return newDate;
  };
  const startDateDisplay =
    bookingDetails && bookingDetails.startDate
      ? convertToDisplayDate(bookingDetails.startDate)
      : '';
  const endDateDisplay =
    bookingDetails && bookingDetails.endDate
      ? convertToDisplayDate(bookingDetails.endDate)
      : '';
  const data = {
    fields: [
      {
        title: 'Date',
        value: `${startDateDisplay} - ${endDateDisplay}`,
      },
      { title: 'Time', value: `${startTime} - ${endTime}` },
      { title: 'Duration', value: `${hours}` },
    ],
    startDate,
    endDate,
    startDateTime,
    endDateTime,
    duration: hours,
    ...bookingDetails
  };
  return data;
};

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

// @ts-ignore
const BookingRequestedContainer = props => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: user } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }
  const goHome = () => dispatch(routerThunks.link(ROUTES.ROOT));
  const goToMyBookings = () => dispatch(routerThunks.link(ROUTES.MY_BOOKINGS));
  const bookingState = useSelectorSafe<any>(
    (state: any) => state.bookingDetails,
  );
  const bookingDetails =
    bookingState && bookingState.booking ? bookingState.booking : null;
  const bookingDetailsDto = bookingDetails
    ? toBookingDetailsDto(bookingDetails)
    : null;
  const goToHostDetails = () => {
    // @ts-ignore
    if (
      // @ts-ignore
      bookingDetails &&
      // @ts-ignore
      bookingDetails.host &&
      // @ts-ignore
      bookingDetails.host.id
    ) {
      // @ts-ignore
      const hostId = bookingDetails.host.id;
      // @ts-ignore
      dispatch(
        // @ts-ignore
        routerThunks.link(ROUTES.HOST_DETAILS, { id: hostId }),
      );
    }
  };
  return (
    <View
      {...props}
      goHome={goHome}
      bookingDetails={bookingDetailsDto}
      goToMyBookings={goToMyBookings}
      goToHostDetails={goToHostDetails}
    />
  );
};

export default BookingRequestedContainer;
