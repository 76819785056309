import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { fallback } from 'utils/Data';
import { routerActions } from 'store/actions';
import Login from 'routes/Login'
import {CreateOrJoinProvider} from 'routes/host/CreateOrJoinProvider'
import { PORTALS } from 'routes'
import { authActions } from 'store/actions'

const AuthGuardContainer = (props) => {
  const {portalName, route, children} = props
  const { data: user, status, errors } = useSelectorSafe(
    state => state.user,
    asyncData(ASYNC_STATUS.INITIAL),
  )
  const dispatch = useDispatch()
  const authRequired = route.hasOwnProperty('authRequired') ? route.authRequired : true
  
  if (authRequired) {
    if (!user || !user.data || !user.data.user) {
      return (<Login/>)
    }

    if (portalName == PORTALS.HOST) {
      if (!user.data.provider) {
        return (<CreateOrJoinProvider/>)
      }
    }

    if (portalName == PORTALS.ADMIN) {
      //  check the user is a airplacer admin...
      if (!user.data.user.roles.includes('administrator')) {
        dispatch(authActions.logout())
        return null
      }
    }
  }

  return (
    <div>{children}</div>
  )
}

export default AuthGuardContainer
