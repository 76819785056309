import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors, fontFamilies } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
    },
    textArea: (props) => ({
      resize: 'none',
      display: 'flex',
      flex: 1,
      border: props.error
        ? `1px solid ${colors.red}`
        : '1px solid rgb(245, 246, 246)',
      borderRadius: '5px',
      padding: theme.spacing(2),
      fontFamily: fontFamilies.gilroy,
      fontSize: '16px'
    }),
    maximumLabel: {
      width: '100%',
      textAlign: 'right',
      marginTop: '5px',
      marginRight: '5px'
    }
  }),
);
