import React, { FC } from 'react';
// @ts-ignore
import workIconUrl from 'assets/work-icon.svg';
// @ts-ignore
import educationIconUrl from 'assets/education-icon.svg';
// @ts-ignore
import functionsIconUrl from 'assets/functions-icon.svg';
// @ts-ignore
import sportsIconUrl from 'assets/sports-icon.svg';
// @ts-ignore
import outdoorsIconUrl from 'assets/outdoors-icon.svg';
import { TileGroupPublicProps } from './TileGroup.props';
import View from './TileGroup.view';

const data = {
  elements: [
    {
      text: 'Work',
      iconUrl: workIconUrl,
      backgroundColor: 'rgb(5, 195, 222)',
      iconAlignedLeft: false,
      isGhostTile: false,
    },
    {
      text: 'Education',
      iconUrl: educationIconUrl,
      backgroundColor: 'rgb(237, 31, 84)',
      iconAlignedLeft: true,
      isGhostTile: false,
    },
    {
      text: 'Functions',
      iconUrl: functionsIconUrl,
      backgroundColor: 'rgb(77, 73, 190)',
      iconAlignedLeft: true,
      isGhostTile: false,
    },
    {
      text: 'Sports',
      iconUrl: sportsIconUrl,
      backgroundColor: 'rgb(255, 184, 28)',
      iconAlignedLeft: true,
      isGhostTile: false,
    },
    {
      text: 'Outdoor',
      iconUrl: outdoorsIconUrl,
      backgroundColor: 'rgb(0, 178, 169)',
      iconAlignedLeft: false,
      isGhostTile: false,
    },
    {
      text: 'Other',
      iconUrl: '',
      backgroundColor: 'rgb(255, 134, 118)',
      iconAlignedLeft: false,
      isGhostTile: true,
    },
  ],
};

const TileGroupContainer: FC<TileGroupPublicProps> = (
  props: TileGroupPublicProps,
) => <View {...props} {...data} />;

export default React.memo(TileGroupContainer);
