import { productActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getEntityFilters } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

const getFilterOptions = () => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    productActions.getFilters(
      apiFetch<any, any>(getEntityFilters, {})(dispatch, getState),
    ),
  );
};

export default getFilterOptions;
