import { makeStyles, createStyles, Theme } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(2),
      
    },
    select: {
      borderRadius: '5px',
      border: '1px solid rgb(162, 170, 173, 0.1)',
      width: '100%'
    },
    input: {
      margin: '0px',
      minHeight: '50px',

      '& .MuiSelect-select': {
        color: 'rgba(0, 0, 0, 0.87)', //'rgb(187, 192, 194)',
        paddingTop: '16.5px',
        paddingRight: '14px',
        paddingBottom: '16.5px',
        paddingLeft: '14px',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        //backgroundColor: 'pink'
      },

      '& .MuiSelect-icon': {
        top: 'calc(50% - 15px)'
      }
    },
  }),
);
