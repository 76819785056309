import user from './user';
import token from './token';
import exampleListings from './exampleListings';
import profile from './profile';
import createAccount from './createAccount';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import contact from './contact';
import filter from './filter';
import product from './product';
import updatePassword from './updatePassword';
import booking from './booking';
import filterOptions from './filterOptions';
import productDetails from './productDetails';
import bookingDetails from './bookingDetails';
import favourites from './favourites';
import createListing from './createListing';
import provider from './provider';

export default {
  user,
  token,
  exampleListings,
  profile,
  createAccount,
  forgotPassword,
  resetPassword,
  contact,
  filter,
  product,
  updatePassword,
  booking,
  filterOptions,
  productDetails,
  bookingDetails,
  favourites,
  createListing,
  provider
};
