export const Flex = {
  Row: 'row',
  Col: 'col',
  Top: 'top',
  Bottom: 'bottom',
  Right: 'right',
  Left: 'left',
  Center: 'center',
  SpaceAround: 'space-around',
  SpaceBetween: 'space-between',
  SpaceEvenly: 'space-evenly',
};

// method to get correct justification and alignment to make using flex easier
// eg:
// flex({ fd: Flex.Row, v: Flex.Bottom, h: Flex.Left, ...otherStyleParams })
export const flex = (props) => {
  if (!props) {
    return null;
  }

  // no flex props
  if (!props.fd && !props.v && !props.h && !props.flex) {
    return props;
  }

  let {fd, v: verticalAlign, h: horizontalAlign, ...otherProps} = props;
  fd = fd || Flex.Col;
  verticalAlign = verticalAlign || Flex.Top;
  horizontalAlign = horizontalAlign || Flex.Left;

  if (horizontalAlign == 'space-around') {
    console.log('space around');
  }

  if (fd == 'col') {
    fd = 'column';
  }

  let justifyContent = verticalAlign;
  let alignItems = horizontalAlign;

  switch (verticalAlign) {
    case 'bottom':
      justifyContent = 'flex-end';
      break;

    case 'top':
      justifyContent = 'flex-start';
      break;
  }

  switch (horizontalAlign) {
    case 'right':
      alignItems = 'flex-end';
      break;

    case 'left':
      alignItems = 'flex-start';
      break;
  }

  if (fd == 'row') {
    let temp = alignItems;
    alignItems = justifyContent;
    justifyContent = temp;
  }

  return {justifyContent, alignItems, flexDirection: fd, ...otherProps};
};



export const flex_row = {
  display: 'flex',
  flexDirection: 'row'
}

export const flex_col = {
  display: 'flex',
  flexDirection: 'column'
}