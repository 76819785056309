import { handleActions } from 'redux-actions';
import { asyncData, createAsyncReducers, presetReducers } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';

const DEFAULT_STATE = asyncData(ASYNC_STATUS.INITIAL);

const reducer = handleActions(
  {
    ...createAsyncReducers('BOOKING/GET_BOOKING_BY_ID'),
    ...createAsyncReducers('BOOKING/ADD_BOOKING'),
    ...createAsyncReducers('BOOKING/GET_BOOKING_BY_PRODUCT_ID'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
    ...createAsyncReducers('BOOKING/GET_BOOKINGS'),
    ...createAsyncReducers('BOOKING/GET_PROVIDER_BOOKINGS')
  },
  DEFAULT_STATE,
);

export default reducer;
