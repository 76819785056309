import React, { FC } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import { useStyles } from './HeaderSection.styles';
import Typo from 'components/primitives/Typo';
import HorizontalLine from 'components/primitives/HorizontalLine';
import Link from 'components/primitives/Link';

const HeaderSectionView = (
  props,
) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>

      <div className={classes.leftContainer}>
        <Box style={{ width: 400 }}>
          {props.breadcrumbs && props.breadcrumbs.map(b => {
            return (
              <Typo variant="body1" color="blue" className={classes.breadcrumbType}>
                {b.icon}
                <Link onClick={() => {
                  b.onClick()
                }}>{b.label}</Link>
              </Typo>
            )
          })}
        </Box>
        
        <Typo variant="h1" className={classes.heading}>
          {props.heading ? props.heading : 'Profile'}
        </Typo>
        {// eslint-disable-next-line no-nested-ternary
        props.subHeading ? (
          props.onSubheadingClick ? (
            <Typo variant="body1" className={classes.field}>
              <Link
                onClick={
                  props.onSubheadingClick ? props.onSubheadingClick : () => {}
                }
              >
                {props.subHeading}
              </Link>
            </Typo>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typo variant="body1" color="lightGrey">
                {props.subHeading}
              </Typo>
              {props.subHeadingComponents ? props.subHeadingComponents : null}
            </div>
          )
        ) : null}

      </div>

      <div className={classes.rightContainer}>
            {props.rightChildren}
      </div>

      {/*
      <div style={{ paddingTop: '1em', paddingBottom: '1em', width: '100%' }}>
        <HorizontalLine />
      </div>
      */}
    </Box>
  );
};

export default React.memo(HeaderSectionView);
