import React, { FC, useState, useRef, useEffect } from 'react';
import { Search, SideBar } from 'components/layouts';
import { Container, Hidden } from '@mui/material';
import {
  TypesFilter,
  TypesFilterMobile,
  CalendarFilter,
  CalendarFilterMobile,
  AdditionalFilter,
  Map,
  InfiniteScroll,
  WebsiteFooter
} from 'components/modules';
import { LocationSearch, LocationCard, Typo, Loading, Button } from 'components/primitives';
import Slider from 'react-slick';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { FilterIconActive, ShowMap, HideMap } from 'assets';
// @ts-ignore
import format from 'date-fns/format';
import { useStyles } from './Search.styles';
import { SearchProps } from './Search.props';
import _ from 'lodash'

import {entityTypeColors} from 'components/primitives/LocationCard/LocationCard.view'


const SearchView: FC<SearchProps> = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({...props, isMobile});

  const [openDrawer, setOpenDrawer] = useState(false);
  const [visibleFilter, setVisibleFilter] = useState('');

  /*
  const mapRef = useRef(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(15);
  const [map, setMap] = useState(null);
  const [selectedId, setSelectedId] = useState('');
  const setCenterMap = (position: any, markerId: string) => {
    if (map) {
      // @ts-ignore
      map.panTo(new window.google.maps.LatLng(position.lat, position.lng));
      setSelectedId(markerId);
    }
  };
  const deselectMarker = () => {
    setSelectedId('');
  };
  */

  const [showMap, setShowMap] = useState(true);
  useEffect(() => {
    // @ts-ignore
    if (props.results && props.results.length) {
      setShowMap(true);
    }
  }, [props.results]);
  const showMobileTypeFilter: boolean = visibleFilter === 'type' && isMobile;
  const showMobileDateFilter: boolean = visibleFilter === 'date' && isMobile;

  const toggleDrawer = (open: boolean, type: string) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    if (open && type !== visibleFilter) {
      setOpenDrawer(false);
      setTimeout(() => {
        setOpenDrawer(true);
        setVisibleFilter(type);
      }, 500);
    } else {
      if (!open) {
        setVisibleFilter('');
      } else {
        setVisibleFilter(type);
      }
      setOpenDrawer(open);
    }
  };

  const sliderSettings = {
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    useTransform: true,
    autoplay: true,
    arrows: false,
    infinite: false,
    dots: false,
    pauseOnHover: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '30px',
        },
      },
    ],
  };

  const sliderSettingsVisibleMap = {
    ...sliderSettings,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const featuredItems = props.featured.map(feature => (
    <LocationCard
      key={feature.id}
      type={feature.type}
      capacity={feature.capacity}
      title={feature.title}
      address={feature.address}
      rating={feature.rating}
      price={feature.price}
      weekdayPrice={feature.weekdayPrice}
      weekendPrice={feature.weekendPrice}
      favourite={feature.favourite}
      //image={feature.image}
      images={feature.images}
      onClick={props.onViewListing(feature.id)}
      onFavouriteClick={() =>
        props.toggleFavourite(feature.id, feature.favourite)
      }
      onMouseOver={() => props.locationCardOnMouseOver(feature)
        /*() =>
        setCenterMap(
          { lat: feature.latitude, lng: feature.longitude },
          feature.id,
        )*/
      }
      onMouseOut={() => props.locationCardOnMouseOut(feature)/*() => {
        props.locationCardOnMouseOut
        //deselectMarker();
      }*/}
    />
  ));

  let dateButtonText = 'Select Date'
  if (props?.filterState?.dateRange?.from) {
    dateButtonText = format(props.filterState.dateRange.from, 'MMM d')
  }
  if (props?.filterState?.dateRange?.to) {
    dateButtonText += ` - ${format(props.filterState.dateRange.to, 'MMM d')}`
  }

  //let types = props.filterState.types.join(', ')
  
  return (
    <Search
      searchBar={
        <div className={classes.searchBar}>
          <div className={classes.locationSearchContainer}>
            <LocationSearch id="search" onChange={props.onChangeLocation} value={props.filterLocation}/>
          </div>
          <div className={classes.filterBarContainer}>
              <Button
                text={dateButtonText}
                onClick={toggleDrawer(visibleFilter !== 'date', 'date')}
                round
                small
                style={
                  visibleFilter === 'date' ? { backgroundColor: '#dc4d5e' } : {}
                }
                //loadingText="Cancel"
                //className={classes.button}
                //disabled={busy}
                //onClick={onCancel || onClose}
                //variant="text"
                //color="lightGrey"
              />

              {props.filterState.types && props.filterState.types.length ? (
                props.filterState.types.map((type, key) => {
                  return (
                    <Button
                      text={_.capitalize(type)}
                      onClick={toggleDrawer(visibleFilter !== 'type', 'type')}
                      round
                      small
                      style={{backgroundColor: entityTypeColors[type] || '#dc4d5e'}}
                      key={key}
                      //loadingText="Cancel"
                      //className={classes.button}
                      //disabled={busy}
                      //onClick={onCancel || onClose}
                      //variant="text"
                      //color="lightGrey"
                    />
                  )
                }))
                : (
                  <Button
                    text="Select Type"
                    onClick={toggleDrawer(visibleFilter !== 'type', 'type')}
                    round
                    small
                    style={
                      visibleFilter === 'type'
                        ? { backgroundColor: '#dc4d5e' }
                        : {}
                    }
                    //loadingText="Cancel"
                    //className={classes.button}
                    //disabled={busy}
                    //onClick={onCancel || onClose}
                    //variant="text"
                    //color="lightGrey"
                  />
                )}

              
              <Button
                text='Filters'
                onClick={toggleDrawer(visibleFilter !== 'filter', 'filter')}
                round
                small
                style={
                  visibleFilter === 'filter' ? { backgroundColor: '#dc4d5e' } : {}
                }
                //loadingText="Cancel"
                //className={classes.button}
                //disabled={busy}
                //onClick={onCancel || onClose}
                //variant="text"
                //color="lightGrey"
              />

              {/*
              <button
                style={
                  visibleFilter === 'date' ? { backgroundColor: '#dc4d5e' } : {}
                }
                className={classes.filterLabel}
                onClick={toggleDrawer(visibleFilter !== 'date', 'date')}
              >
                {!props.filterState.dateRange ||
                !props.filterState.dateRange.from
                  ? 'Select Date'
                  : format(props.filterState.dateRange.from, 'MMM d')}
                {!props.filterState.dateRange || !props.filterState.dateRange.to
                  ? ''
                  : ` - ${format(props.filterState.dateRange.to, 'MMM d')}`}
              </button>
              {props.filterState.types && props.filterState.types.length ? (
                props.filterState.types.map((type, key) => (
                  <button
                    key={key}
                    style={{
                      backgroundColor:
                        // @ts-ignore
                        entityTypeColors[type] || '#dc4d5e',
                      textTransform: 'capitalize',
                    }}
                    className={classes.filterLabel}
                    onClick={toggleDrawer(visibleFilter !== 'type', 'type')}
                  >
                    {type}
                  </button>
                ))
              ) : (
                <button
                  style={
                    visibleFilter === 'type'
                      ? { backgroundColor: '#dc4d5e' }
                      : {}
                  }
                  className={classes.filterLabel}
                  onClick={toggleDrawer(visibleFilter !== 'type', 'type')}
                >
                  Select Type
                </button>
              )}
              <button
                className={classes.filterToggle}
                onClick={toggleDrawer(visibleFilter !== 'filter', 'filter')}
              >
                Filters
              </button>
            */}
          </div>
        </div>
      }
    >
      <div className={classes.contentContainer}>
        <Loading isLoading={props.loading} center/>

        {!showMobileTypeFilter && !showMobileDateFilter && (
          <div className={classes.featureContainer} id='scrollableDiv'>
            <Container>
              <div className={classes.featureHeader}>
                <Typo variant="h1" color="darkGrey">
                  Featured Places
                </Typo>
              </div>
            </Container>

            <div className={classes.featureItemsContainer}>
              <Container>
                {props.isMapVisible ? (
                  <Slider key="mapVisible" {...sliderSettingsVisibleMap}>
                    {featuredItems}
                  </Slider>
                ) : (
                  <Slider key="mapNotVisible" {...sliderSettings}>
                    {featuredItems}
                  </Slider>
                )}
              </Container>
            </div>
            <Container>
              <InfiniteScroll
                className={
                  props.isMapVisible
                    ? classes.resultsItemContainerVisibleMap
                    : classes.resultsItemsContainer
                }
                hasMore={props.hasMore}
                fetchMore={props.fetchMore}
                scrollableTarget='scrollableDiv'
              >
                {props.results.map(result => (
                  <LocationCard
                    key={result.id}
                    type={result.type}
                    capacity={result.capacity}
                    title={result.title}
                    address={result.address}
                    rating={result.rating}
                    price={result.price}
                    weekdayPrice={result.weekdayPrice}
                    weekendPrice={result.weekendPrice}
                    favourite={result.favourite}
                    //image={result.image}
                    images={result.images}
                    onClick={props.onViewListing(result.id)}
                    onFavouriteClick={() =>
                      props.toggleFavourite(result.id, result.favourite)
                    }
                    onMouseOver={() => props.locationCardOnMouseOver(result)
                      /*() =>
                      setCenterMap(
                        { lat: feature.latitude, lng: feature.longitude },
                        feature.id,
                      )*/
                    }
                    onMouseOut={() => props.locationCardOnMouseOut(result)/*() => {
                      props.locationCardOnMouseOut
                      //deselectMarker();
                    }*/}

                    /*
                    onMouseOver={() =>
                      setCenterMap(
                        {
                          lat: result.latitude,
                          lng: result.longitude,
                        },
                        result.id,
                      )
                    }
                    onMouseOut={() => {
                      deselectMarker();
                    }}
                    */
                  />
                ))}
              </InfiniteScroll>
            </Container>
            <WebsiteFooter/>
          </div>
        )}

        {showMobileTypeFilter && (
          <div className={classes.filterContainer}>
            <TypesFilterMobile
              activeType={props.filterTypes}
              onToggleType={props.onToggleType}
              reset={props.resetTypeFilter}
              onSubmit={() => {
                props.onSubmitTypes();
                setOpenDrawer(false);
                setVisibleFilter('');
              }}
            />
          </div>
        )}

        {showMobileDateFilter && (
          <div className={classes.filterContainer}>
            <Container>
              <CalendarFilterMobile
                state={props.calendarHook}
                onSubmitDate={(...p) => {
                  props.onSubmitDate(...p);
                  setOpenDrawer(false);
                  setVisibleFilter('');
                }}
              />
            </Container>
          </div>
        )}

        <Hidden xsDown>
          <div className={classes.mapContainer}>
            <div className={classes.showMapButtonContainer}>
              <button
                className={classes.toggleMapButton}
                onClick={props.toggleMap}
              >
                <span className={classes.buttonIconContainer}>
                  {props.isMapVisible ? <HideMap /> : <ShowMap />}
                </span>
              </button>
            </div>
            <div className={isMobile ? classes.mapMobile : classes.map}>
              {showMap ? (
                <div className={classes.mapInner}>
                  <Map
                    center={props.center}
                    defaultZoom={props.zoom}
                    mapRef={props.mapRef}
                    markers={props.results}
                    autoCenter
                    setMap={props.setMap}
                    selectedId={props.selectedId}
                    onChange={props.onMapChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Hidden>
      </div>
      <SideBar
        open={openDrawer && (!isMobile || visibleFilter === 'filter')}
        onClose={toggleDrawer(false, '')}
      >
        {visibleFilter === 'date' && (
          <CalendarFilter
            state={props.calendarHook}
            toMonth={props.toMonth}
            notAvailableDates={props.notAvailableDates}
            disableModifier={props.dateDisabledModifier}
            onSubmitDate={(...p) => {
              props.onSubmitDate(...p);
              setOpenDrawer(false);
              setVisibleFilter('');
            }}
          />
        )}
        {visibleFilter === 'type' && (
          <TypesFilter
            activeType={props.filterTypes}
            onToggleType={props.onToggleType}
            reset={props.resetTypeFilter}
            onSubmit={() => {
              props.onSubmitTypes();
              setOpenDrawer(false);
              setVisibleFilter('');
            }}
          />
        )}
        {visibleFilter === 'filter' && (
          <AdditionalFilter
            filterOptions={props.filterOptions}
            setFilterPrice={props.setFilterPrice}
            setFilterCapacity={props.setFilterCapacity}
            onToggleFacility={props.onToggleFacility}
            onToggleSubType={props.onToggleSubType}
            reset={props.resetAdditionalFilter}
            filterPrice={props.filterPrice}
            filterCapacity={props.filterCapacity}
            filterSubTypes={props.filterSubTypes}
            filterFacilities={props.filterFacilities}
            onSubmit={() => {
              props.onSubmitAdditionalFilters();
              setOpenDrawer(false);
              setVisibleFilter('');
            }}
          />
        )}
      </SideBar>
    </Search>
  );
};

export default React.memo(SearchView);
