import MGlobalStyles from "@mui/material/GlobalStyles"


export const GlobalStyles = (props) => {
    return (
        <MGlobalStyles
            styles={{
                /*
                '::-webkit-scrollbar': {
                    width: 0,
                    background: 'transparent'
                }*/
            }}
        />
    )
}