import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    splitViewContainer: {
      marginTop: theme.spacing(2),
    },
    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelButton: {
      color: 'rgb(187, 192, 194)',
      textTransform: 'none',
      marginRight: theme.spacing(2),
    },
    changePhotoButton: {
      color: 'rgb(187, 192, 194)',
      textTransform: 'none',
      cursor: 'pointer',
    },
    fileInput: {
      '&::-webkit-file-upload-button': {
        cursor: 'pointer',
      },
    },
    imageUploadContainer: {
      width: '300px',
      height: '300px'
    }
  }),
);
