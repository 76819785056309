import React, { FC } from 'react';
import View from './LocationDetailsCard.view';

const fields2 = [
  { title: 'Date', value: '' },
  { title: 'Time', value: '' },
  { title: 'Duration', value: '' },
];

const SelectContainer = ({
  fields,
  ...props
}) => <View fields={fields || fields2} {...props} />;

export default React.memo(SelectContainer);
