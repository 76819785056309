import { Dispatch } from 'redux';
import { getThreadByIdEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

interface Data {
  threadId: string;
}

const getThreadMessages = (
  data: Data,
  onSuccess?: (response: any) => void,
  onError?: (error: string) => void,
) => (dispatch: Dispatch, getState: () => Store) => {
  const getThreadByIdFetch = apiFetch<Data, any>(getThreadByIdEndpoint, data)(
    dispatch,
    getState,
  )
    .then(response => {
      if (response && onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    })
    .catch(error => {
      if (error && onError) {
        onError(error);
      }
      return error;
    });
  return getThreadByIdFetch;
};

export default getThreadMessages;
