import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import apiFetch from './fetch/apiFetch'
import { methods, auth, endpointGroups } from './fetch/apiEndpoints'
import { API } from 'consts'
import storeConfig from '../store'

const { URL, VERSION } = API

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

export const useGetSettingsList = () => useQuery('getSettingsList', async () => {
    console.log("getting settings list.... auth not required")
    const r = await apiFetch({
        url: `${URL}/${VERSION}/${endpointGroups.SETTINGS}`,
        method: methods.GET,
        auth: auth.NOT_REQUIRED,
    })(storeConfig.store.dispatch, storeConfig.store.getState)
    console.log("fetched setting list:", r.data)
    return r.data
})

export const getSettingValue = (query, id, nullValue) => {
    return query.data?.find(s => s.ID == id)?.value || nullValue
}

export const useMutateSetting = () => useMutation(async (setting) => {
    console.log("mutate settings list....")
    const r = await apiFetch({
        url: `${URL}/${VERSION}/${endpointGroups.SETTINGS}`,
        method: methods.POST,
        auth: auth.REQUIRED
    }, setting)(storeConfig.store.dispatch, storeConfig.store.getState)
    return r
}, {
    onSuccess: () => {
        queryClient.invalidateQueries('getSettingsList')
    }
})