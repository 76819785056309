import React, { useEffect, useState } from 'react'
import {Typo, Button, ButtonContainer} from 'components/primitives'
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {makeStyles, createStyles} from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },
    heading: {
        textTransform: 'none'
    },
    link: {
        cursor: 'pointer',
    },
  }),
)

const ConfirmModal = (props) => {
    const {title, message, open, onClose, onCancel, onConfirm} = props
    const classes = useStyles(props)
    const [busy, setBusy] = useState(false)
  
    const confirm = async () => {
        setBusy(true)
        await onConfirm()
        onClose()
        setBusy(false)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                <Typo variant="h2" className={classes.heading}>
                    {title || 'Confirmation'}
                </Typo>
            </DialogTitle>
            <DialogContent className={classes.contentContainer}>
                <Typo variant="body1">
                    {message}
                </Typo>
            </DialogContent>
            <DialogActions>
                <ButtonContainer>
                    <Button
                        text="Cancel"
                        loadingText="Cancel"
                        className={classes.button}
                        disabled={busy}
                        onClick={onCancel || onClose}
                        variant="text"
                        color="lightGrey"
                    />
                    <Button
                        text="Confirm"
                        loadingText="Confirm"
                        className={classes.button}
                        loading={busy}
                        disabled={busy}
                        onClick={confirm}
                        type="submit"
                    />
                </ButtonContainer>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal