import { authActions, userActions } from 'store/actions';
import { Dispatch } from 'redux';
import {
  getProfileEndpoint,
  loginEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { User } from '../../types/User';
import { Store } from '../../types/store/Store';
import { routeAndPortalFromLocation } from 'routes'

interface GoogleCredentials {
  type: string;
  // eslint-disable-next-line camelcase
  access_token: string;
  email: string;
  name: string;
  
  picture: any;
  userID: string;
}

interface Data {}

interface Signalling {
  finishUpdating?: (data: any) => void;
  updateErrorMessage?: (message: any) => void;
}

const googleAccess = (
  credentials: GoogleCredentials,
  signalling: Signalling,
) => (dispatch: Dispatch, getState: () => Store) => {
  const locationState = getState().location
  const [routeName, portalName, portal] = routeAndPortalFromLocation(locationState.type)

  dispatch(
    authActions.login(
      apiFetch<GoogleCredentials, User>(loginEndpoint, {
          ...credentials,
          portal: portalName.toLowerCase()
        })(
        dispatch,
        getState,
      )
        .then(user => {
          dispatch(
            userActions.getProfile(
              apiFetch<Data, any>(getProfileEndpoint, {})(
                dispatch,
                getState,
              ).then(p => p.data),
            ),
          );

          if (signalling.finishUpdating) {
            signalling.finishUpdating(user);
          }
          return user;
        })
        .catch(err => {
          if (signalling.updateErrorMessage) {
            signalling.updateErrorMessage(err);
          }

          throw err;
        }),
    ),
  );
};

export default googleAccess;
