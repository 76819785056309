import { userActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getFavouritesEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

interface Data {}

const getFavourites = () => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    userActions.getFavourites(
      apiFetch<Data, any>(getFavouritesEndpoint, {})(dispatch, getState),
    ),
  );
};

export default getFavourites;
