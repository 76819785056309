import React, {useEffect, useState} from 'react'
import {Autocomplete, Grid} from '@mui/material'
import {TextField} from '../TextField'
import { useStyles } from '../InputField/InputField.styles'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import _ from 'lodash'

const AddressInputField = React.memo((props) => {
  const {className, style, onBlur} = props

  const [valueChangeCount, setValueChangeCount] = useState(0)
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const classes = useStyles(props);
  
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_APP_SECRET || '',
    options: {
      types: ['address'],
      componentRestrictions: { country: 'au' },
    }
  })

  useEffect(() => {
    if (inputValue) {
      getPlacePredictions({ input: inputValue })
    }
  }, [value, inputValue])

  useEffect(() => {
    // ignore the first time
    if (valueChangeCount <= 0) {
      return
    }

    if (value) {
      // get lat/lon
      if (placesService) {
        placesService.getDetails({placeId: value.place_id}, (placeDetails) => {
          value.details = placeDetails
          props.onChange && props.onChange(value)
        })
      }
    } else {
      props.onChange && props.onChange(null)
    }
  }, [valueChangeCount])

  // if props.value changes, set value to props.value without triggering a onChange
  useEffect(() => {
    if (!value && !props.value) {
      return
    }

    if (!_.isEqual(props.value, value)) {
      setValue(props.value)
    }
  }, [props.value])

  return (
    <Grid
      container
      alignItems="flex-end"
      className={`${classes.container} ${className}`}
      style={style}
    >
      <Autocomplete
        id={props.id}
        sx={{ width: '100%' }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={placePredictions}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={props.value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
          setValueChangeCount(prev => prev + 1)
        }}
        onInputChange={(event, newInputValue) => {
           setInputValue(newInputValue)
        }}
        onBlur={onBlur}
        renderInput={(params) => {
          return (
            <TextField
              {...params} 
              label={props.placeholder}
              className={classes.inputField}
              inputProps={{
                ...params.inputProps,
                autocomplete: 'stop-doing-it-chrome!'
              }}
              InputProps={{
                ...params.InputProps,
                //disableUnderline: true,
                className: classes.input
              }}
              fullWidth 
              required={props.required}
            />
          )
        }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              {option.description}
            </li>
          )
        }}
      />
    </Grid>
  )
})

export default AddressInputField