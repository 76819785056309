import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'PROVIDER';
const createUserActions = makeCreateActions(ns);

const providerActions = createUserActions({
  getProvider: presetActions.makeAsyncAction(`${ns}/GET_PROVIDER`),
  updateProvider: presetActions.makeAsyncAction(`${ns}/UPDATE_PROVIDER`),
});

export default providerActions;
