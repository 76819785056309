import React, { FC, ReactElement } from 'react';
import { Container } from '@mui/material';
import { ListingDetails } from 'components/modules';
import { Typo, Button, InputField, Link } from 'components/primitives';
import { useStyles } from './ListingDetails.styles';

const ListingDetailsView = (
  props,
) => {
  const classes = useStyles(props);

  return <ListingDetails/>;
};

export default ListingDetailsView;
