import React, { FC } from 'react';
import { Paper, Button } from '@mui/material';
import { useStyles } from './MobileButton.styles';
import { MobileButtonProps } from './MobileButton.props';
import { Button as CustomButton } from '../../primitives';

const MobileButtonView: FC<MobileButtonProps> = (props: MobileButtonProps) => {
  const classes = useStyles();
  const { text, cancelText, onClick, onCancel } = props;
  const showNegative = Boolean(cancelText && onCancel);
  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={2}>
        <CustomButton
          onClick={onClick}
          style={{ width: showNegative ? '50%' : '100%' }}
          text={text}
          disabled={props.disabled}
        />
        {showNegative && (
          <Button
            onClick={onCancel}
            disabled={props.disabled}
            className={classes.negativeButton}
          >
            {cancelText}
          </Button>
        )}
      </Paper>
    </div>
  );
};

export default React.memo(MobileButtonView);
