import React from 'react'
import ReactPlayer from 'react-player'

export const VideoPlayer = (props) => {
    return (
        <ReactPlayer
            width="100%"
            height="100%"
            controls={false}
            playsinline={true}
            config={{
                youtube: {
                    playerVars: {
                        autoplay: false,
                        showinfo: 0,
                        controls: 0,
                        loop: 1,
                        modestbranding: 1,
                        iv_load_policy: 3,
                    },
                },
            }}
            {...props}
        />
    )
}