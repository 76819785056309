import { handleActions } from 'redux-actions';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';
import { ResetPassword } from 'types/ResetPassword';
import { ResetPasswordState } from 'types/store/ResetPasswordState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';

const DEFAULT_STATE: ResetPasswordState = asyncData(ASYNC_STATUS.INITIAL);


const reducer = handleActions<ResetPasswordState, any>(
  {
    ...createAsyncReducers<ResetPassword>('AUTH/RESET_PASSWORD'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
