import React, { FC } from 'react';
import { Divider } from '@mui/material';
import { useStyles } from './HorizontalLine.styles';

const HorizontalLineView = (
  props,
) => {
  const classes = useStyles();
  const additionalProps = props.style ? { style: props.style } : {};
  return (
    <Divider
      className={`${classes.default} ${props.className}`}
      {...additionalProps}
    />
  );
};

export default React.memo(HorizontalLineView);
