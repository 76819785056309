import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useStyles } from './AppBarItem.styles';

const AppBarItemView = (props) => {
  const {
    icon,
    text,
    onClick,
    children,
  } = props;
  const classes = useStyles(props);
  return (
    <Button color="inherit" onClick={onClick} className={classes.button}>
      {icon}
      {text}
      {children}
    </Button>
  );
};

export default React.memo(AppBarItemView);
