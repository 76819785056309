import React, {Component} from 'react';
import { useStyles } from './RequestedBookings.styles';
import { Loading, BookingCard, ListPlaceholder } from 'components/primitives';
import {HeaderSection, InfiniteScroll, ChangeBookingStatus} from 'components/modules'
import {Main} from 'components/layouts'

const RequestedBookings = (props) => {
    const classes = useStyles(props)
    const {changeBookingStatus} = props
    return (
        <Main>
            <div style={{ marginTop: '1em' }}>
                <HeaderSection
                    heading="Request"
                    subHeading="View and manage requests"
                />
                <Loading isLoading={props.loading}>
                    <InfiniteScroll
                        className={classes.resultsContainer}
                        hasMore={props.hasMore}
                        fetchMore={props.fetchMore}
                        emptyMessage={
                            <ListPlaceholder
                                description='There are no requested bookings at this time'
                            />
                        }
                    >
                        {props.results.map(result => (
                            <BookingCard
                                booking={result}
                                key={result.ID}
                                onClick={props.onViewListing(result.ID)}
                                onFavouriteClick={() =>
                                    props.toggleFavourite(result.ID, result.favourite)
                                }
                                //onContactGuest={props.onContactGuest(result)}
                                onViewRequest={props.onViewRequest(result.ID)}
                                onApproveBooking={props.onApproveBooking(result)}
                                onCancelBooking={props.onCancelBooking(result)}
                            />
                        ))}
                    </InfiniteScroll>
                </Loading>
                <ChangeBookingStatus {...changeBookingStatus}/>
            </div>
        </Main>
    )
}

export default React.memo(RequestedBookings);