import { NOT_FOUND as RFR_NOT_FOUND } from 'redux-first-router'
import _ from 'lodash'
import { NOT_FOUND } from 'redux-first-router'
import Search from './customer/Search'
import Profile from './shared/Profile'
import CustomerListingDetails from './customer/ListingDetails'
import ContactUs from './shared/ContactUs'
import CustomerBookingDetails from './customer/BookingDetails'
import ConfirmBookingDetails from './customer/ConfirmBookingDetails'
import BookingRequested from './customer/BookingRequested'
import NotFound from './shared/NotFound'
import MyBookings from './customer/MyBookings'
import ViewBookingDetails from './shared/ViewBookingDetails'
import MyBookingHistory from './customer/MyBookingHistory'
import ReviewBooking from './customer/ReviewBooking'
import InviteAFriend from './customer/InviteAFriend'
import MySavedListings from './customer/MySavedListings'
import Messages from './shared/Messages'
import HostDetails from './customer/HostDetails'
import ManageListings from './host/ManageListings'
import CreateListing from './host/CreateListing'
import EditListing from './host/EditListing'
import RequestedBookings from './host/RequestedBookings'
import ScheduledBookings from './host/ScheduledBookings'
import BookingHistory from './host/BookingHistory'
import AdminListingDetails from './host/ListingDetails'
import DevRoot from './other/DevRoot'
import { Providers } from './host/Providers'
import { ProviderAdmin } from './admin/ProviderAdmin'
import { SettingsAdmin } from './admin/SettingsAdmin'

export { NOT_FOUND }

console.log('Running in portal:', process.env.REACT_APP_PORTAL)

const patchRoutes = (routes, pathPrefix) => {
  const r = _.cloneDeep(routes)
  Object.keys(r).forEach((k) => {
    // disable modifying the path when a PORTAL env var is supplied
    if (!process.env.REACT_APP_PORTAL) {
      r[k].path = '/' + pathPrefix + (r[k].path == '/' ? '' : r[k].path)
    }

    if (pathPrefix == '') {
      r[k].routeKey = `ROUTER/${k}`
    } else {
      r[k].routeKey = `ROUTER/${pathPrefix.toUpperCase()}/${k}`
    }
  })
  return r
}

// routes common to custom and admin portal
const sharedRoutes = {
  PROFILE: {
    component: Profile,
    path: '/profile',
  },
  MESSAGES: {
    component: Messages,
    path: '/messages',
  },
  CONTACT_US: {
    component: ContactUs,
    path: '/contact-us',
  },
  VIEW_BOOKING_DETAILS: {
    component: ViewBookingDetails,
    path: '/view-booking-details/:id',
    label: 'Booking Details'
  },
  NOT_FOUND: {
    component: NotFound,
    id: RFR_NOT_FOUND,
    path: '/404',
    authRequired: false
  },
}

export const customerRoutes = patchRoutes({
  ROOT: {
    component: Search,
    path: '/',
  },
  SEARCH: {
    component: Search,
    path: '/search',
  },
  LISTING_DETAILS: {
    component: CustomerListingDetails,
    path: '/listing/:id',
  },
  BOOKING_DETAILS: {
    component: CustomerBookingDetails,
    path: '/booking-details',
  },
  CONFIRM_BOOKING_DETAILS: {
    component: ConfirmBookingDetails,
    path: '/confirm-booking-details',
  },
  BOOKING_REQUESTED: {
    component: BookingRequested,
    path: '/booking-requested',
  },
  MY_BOOKINGS: {
    component: MyBookings,
    path: '/my-bookings',
  },
  MY_BOOKING_HISTORY: {
    component: MyBookingHistory,
    path: '/my-booking-history',
  },
  REVIEW_BOOKING: {
    component: ReviewBooking,
    path: '/review-booking/:id',
  },
  INVITE_A_FRIEND: {
    component: InviteAFriend,
    path: '/invite-a-friend',
  },
  MY_SAVED_LISTINGS: {
    component: MySavedListings,
    path: '/my-saved-listings',
  },
  HOST_DETAILS: {
    component: HostDetails,
    path: '/host-details/:id',
  },
  ...sharedRoutes
}, 'customer')

export const hostRoutes = patchRoutes({
  ROOT: {
    component: ManageListings,
    path: '/'
  },
  CREATE_LISTING: {
    component: CreateListing,
    path: '/create-listing',
    label: 'Create Listing'
  },
  EDIT_LISTING: {
    component: EditListing,
    path: '/edit-listing/:id',
    label: 'Edit Listing'
  },
  LISTING_DETAILS: {
    component: AdminListingDetails,
    path: '/listing/:id',
    label: 'Listing Details'
  },
  REQUESTED_BOOKINGS: {
      component: RequestedBookings,
      path: '/requested-bookings/:id?',
      label: 'Request'
  },
  SCHEDULED_BOOKINGS: {
    component: ScheduledBookings,
    path: '/scheduled-bookings',
    label: 'Schedule'
  },
  BOOKING_HISTORY: {
    component: BookingHistory,
    path: '/booking-history',
    label: 'Booking History'
  },
  PROVIDERS: {
    component: Providers,
    path: '/providers',
    label: 'Providers'
  },
  ...sharedRoutes
}, 'host')

export const adminRoutes = patchRoutes({
  ROOT: {
    component: ProviderAdmin,
    path: '/'
  },
  PROVIDERS_ADMIN: {
    component: ProviderAdmin,
    path: '/providers-admin'
  },
  SETTINGS_ADMIN: {
    component: SettingsAdmin,
    path: '/settings-admin'
  },
  NOT_FOUND: {
    component: NotFound,
    id: RFR_NOT_FOUND,
    path: '/404',
    authRequired: false
  },
  PROFILE: {
    component: Profile,
    path: '/profile',
  },
}, 'admin')


export const otherRoutes = patchRoutes({
  ROOT: {
    component: DevRoot,
    path: '/',
  },
  NOT_FOUND: {
    component: NotFound,
    id: RFR_NOT_FOUND,
    path: '/404',
  },
}, '')

export const PORTALS = {
  CUSTOMER: 'CUSTOMER',
  HOST: 'HOST',
  ADMIN: 'ADMIN',
  OTHER: 'OTHER'
}

const compileRoutes = () => {
  let r = []
  if (!process.env.REACT_APP_PORTAL || process.env.REACT_APP_PORTAL == 'admin') {
    Object.keys(adminRoutes).forEach((k) => {
      r[k] = k
    })
  }
  if (!process.env.REACT_APP_PORTAL || process.env.REACT_APP_PORTAL == 'host') {
    Object.keys(hostRoutes).forEach((k) => {
      r[k] = k
    })
  }
  if (!process.env.REACT_APP_PORTAL || process.env.REACT_APP_PORTAL == 'customer') {
    Object.keys(customerRoutes).forEach((k) => {
      r[k] = k
    })
  }
  return r
}

const getRoutes = (portal) => {
  switch (portal) {
    default:
    case PORTALS.CUSTOMER:
      return customerRoutes

      case PORTALS.HOST:
        return hostRoutes

      case PORTALS.ADMIN:
        return adminRoutes

      case PORTALS.OTHER:
        return otherRoutes
  }
}

export const routeAndPortalFromLocation = (location) => {
  if (!location) {
    return [null, null, null]
  }

  let routeName = null
  let portalName = null
  const locationsSplits = location.split('/')
  if (locationsSplits.length == 2) {
    routeName = locationsSplits[1]
    portalName = PORTALS.OTHER
  } else {
    portalName = locationsSplits[1]
    routeName = locationsSplits[2].toUpperCase()
  }
  return [routeName, portalName, getRoutes(portalName)]
}

export const ROUTES = compileRoutes()

