import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '290px',
      backgroundColor: '#A0AAAD',
      textAlign: 'center',
    },
    headline: {
      color: 'white',
    },
  }),
);
