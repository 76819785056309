import React, {Fragment} from 'react'
import {
  Button,
  LocationDetailsCard,
  SplitView,
  Link,
  Typo,
  HorizontalLine,
  Loading,
  StatusLabel
} from 'components/primitives';
import { HeaderSection, HostedBy, ChangeBookingStatus } from 'components/modules';
import Box from '@mui/material/Box';
import Main from 'components/layouts/Main';
import { PORTALS } from 'routes';
import { useStyles } from './ViewBookingDetails.styles';
import {bookingIsChangeable} from 'services/api'

// @ts-ignore
const ViewBookingDetailsView = (props) => {
  const {
    questionComponents,
    booking,
    onRequestChangeClick,
    onCancelBookingClick,
    breadcrumbs,
    goToListing,
    goToHostDetails,
    onContactUser,
    bookingPassed,
    portalName,
    changeBookingStatus
  } = props
  const classes = useStyles(props);
  const product = (booking && booking.product) || {}
  const bookingChangeable = bookingIsChangeable(booking)

  // @ts-ignore
  return (
    <Main>
      <Box style={{ paddingTop: '1em' }}>
        <HeaderSection heading="Booking Details" breadcrumbs={breadcrumbs}/>
        <Loading isLoading={props.loading}/>
        {booking &&
          <Box>
            <SplitView
              leftChild={
                <>
                  {portalName == PORTALS.CUSTOMER &&
                    <div style={{ marginBottom: '1em' }}>
                      <HostedBy
                        host={booking && booking.provider}
                        onClick={goToHostDetails}
                      />
                    </div>
                  }
                  <LocationDetailsCard
                    images={product.images}
                    title={product.title}
                    address={
                      product && product.location && product.location.address
                        ? product.location.address
                        : ''
                    }
                    price={booking.price}
                    weekdayPrice={booking.weekdayPrice}
                    weekendPrice={booking.weekendPrice}
                    fields={booking.fields}
                    user={portalName == PORTALS.HOST && booking.user}
                    goToHostDetails={goToHostDetails}
                    onContactUser={onContactUser}
                  />
                  <div style={{ height: '1em' }}>
                    <HorizontalLine />
                  </div>
                  <Link onClick={goToListing}>View Listing</Link>
                </>
              }
              rightChild={
                <div className={classes.rightChildContainer}>
                  <Typo variant="h4" color="darkGrey" className={classes.heading}>
                    Additional information
                  </Typo>
                  {booking.questions && booking.questions.map(qr => {
                    return (
                      <div className={classes.questionsContainer}>
                        <Typo className={classes.question}>
                          {qr.question}
                        </Typo>
                        <Typo className={classes.answer}>
                          {qr.answer}
                        </Typo>
                      </div>
                    )
                  })}
                  {(!booking.questions || booking.questions.length <= 0) &&
                    <div className={classes.questionsContainer}>
                      None
                    </div>
                  }
                  <Fragment>
                    <Typo variant="h4" color="darkGrey" className={classes.heading}>
                      Booking Status
                    </Typo>
                    <div className={classes.questionsContainer}>
                      <p style={{display: 'flex', flexDirection: 'row'}}>
                        <Typo variant="body1" color="lightGrey" component="span">
                          Status
                        </Typo>
                        <StatusLabel
                          status={booking.status}
                          style={{ marginLeft: '15px' }}
                        />
                      </p>
                      {(booking.status == 'declined' || booking.status == 'cancelled') && (
                        <p>
                          <Typo variant="body1" color="lightGrey" component="span">
                            Reason
                          </Typo>
                          <Typo variant="body1" color="darkGrey" component="span" style={{ marginLeft: '10px' }}>
                            {booking.statusReason || 'None'}
                          </Typo>
                        </p>
                      )}
                    </div>
                  </Fragment>
                  {portalName == PORTALS.CUSTOMER && bookingChangeable ? (
                    <SplitView
                      // @ts-ignore
                      leftChild={null}
                      rightChild={
                        <div style={{ paddingTop: '6em' }}>
                          <Button
                            text="Contact host"
                            onClick={onRequestChangeClick}
                          />
                          {/*
                          <div
                            style={{
                              paddingTop: '1em',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Link onClick={onCancelBookingClick}>
                              Cancel Booking
                            </Link>
                          </div>
                        */}
                        </div>
                      }
                    />
                  ) : null}
                  {portalName == PORTALS.HOST && bookingChangeable &&
                    <SplitView
                      // @ts-ignore
                      leftChild={null}
                      // @ts-ignore
                      rightChild={
                        <div className={classes.buttonContainer}>
                          {booking.status == 'pending' &&
                            <Button
                              style={{ marginTop: '1em' }}
                              text="Approve"
                              onClick={() => changeBookingStatus.show(booking, 'confirmed')}
                              loading={changeBookingStatus.busy && changeBookingStatus.status == 'confirmed'}
                              disabled={changeBookingStatus.busy}
                              loadingText='Approving'
                            />
                          }
                          {booking.status == 'pending' &&
                            <Typo
                              variant="body1"
                              component="a"
                              color="#ED1F54"
                              style={{ marginTop: '1em', textAlign: 'right' }}
                              onClick={() => changeBookingStatus.show(booking, 'declined')}
                              className={classes.link}
                              loading={changeBookingStatus.busy && changeBookingStatus.status == 'declined'}
                              disabled={changeBookingStatus.busy}
                            >
                              Decline Booking
                            </Typo>
                          }
                          {booking.status == 'confirmed' &&
                            <Typo
                              variant="body1"
                              component="a"
                              color="#ED1F54"
                              style={{ marginTop: '1em', textAlign: 'right' }}
                              onClick={() => changeBookingStatus.show(booking, 'cancelled')}
                              className={classes.link}
                              loading={changeBookingStatus.busy && changeBookingStatus.status == 'cancelled'}
                              disabled={changeBookingStatus.busy}
                            >
                              Cancel Booking
                            </Typo>
                          }

                          <ChangeBookingStatus {...changeBookingStatus}/>
                        </div>
                      }
                      leftFraction={0.5}
                    />
                  }
                </div>
              }
              leftFraction={0.3}
            />
          </Box>
        }
      </Box>
    </Main>
  );
};

export default React.memo(ViewBookingDetailsView);
