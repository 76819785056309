import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    searchBar: {
      position: 'relative',
      zIndex: 20,
      padding: '10px 24px',
      boxShadow: '0 1px 1px rgba(0,0,0,0.02)',
    },
    container: {
      overflowY: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
  }),
);
