import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    default: {
      backgroundColor: 'rgb(162, 170, 173)',
      opacity: 0.1
    },
  }),
);
