import React, {Component, useState} from 'react'
import { useDispatch } from 'react-redux'
import Main from 'components/layouts/Main'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { makeStyles, createStyles, Theme } from '@mui/styles'
import { colors, fontFamilies } from 'theme/themeConstants'
import { Modal, Typo, Button, InputField, ImageUpload } from 'components/primitives'
import { EmailIcon } from 'assets'
import { postProvider, postProviderUpdateUser } from 'thunks/provider'
import useSelectorSafe from 'store/selectors/useSelectorSafe'
import { asyncData } from 'utils/Redux'
import { ASYNC_STATUS } from 'types/store/AsyncStatus'
import { authActions } from 'store/actions'

export const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(2),
    },
    subHeading: {
      marginBottom: theme.spacing(4),
    },
    button: {
      marginBottom: theme.spacing(1.5),
    },
    errorMessage: {
      marginBottom: theme.spacing(1.5),
    },
    tabPanel: {
      marginTop: theme.spacing(1.5),
    },
    field: {
      marginBottom: theme.spacing(1.5),
    },
    footer: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    link: {
      cursor: 'pointer',
    },
  }),
)

export const CreateOrJoinProvider = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles(props)
    const [modalOpen, setModalOpen] = useState(true)
    const [createBusy, setCreateBusy] = useState(false)
    const [errorField, setErrorField] = useState([])
    const [name, setName] = useState()
    const [images, setImages] = useState([])
    const [createMessage, setCreateMessage] = useState()
    const { data: user, status, errors } = useSelectorSafe(
      state => state.user,
      asyncData(ASYNC_STATUS.INITIAL),
    )

    const onLogOut = () => {
      dispatch(authActions.logout())
    }

    const onCreate = () => {
      setCreateMessage()
      setErrorField()

      if (!name) {
        setCreateMessage('Name is required')
        setErrorField('name')
        return
      }

      /*
      if (images.length <= 0) {
        setCreateMessage('An image is required')
        setErrorField('image')
        return
      }*/

      setCreateBusy(true)
      dispatch(postProvider({
          name,
          image: images.length ? images[0].data_url : null
        }))
        .then(response => {
            // push provider into the user data
            const modifiedUser = {
              data: {
                ...user.data,
                provider: response.data
              }
            }
            return dispatch(authActions.updateUser(modifiedUser))
        })
        .then(response => {
          // all good, this modal will now be closed and allow the user to use Airplacer
          console.log('all good')
        })
        .catch(error => {
          setCreateMessage(error.message || error)
        })
    }

    const [tab, setTab] = useState(0)
    const onTabChange = (e, newValue) => setTab(newValue)

    return (
        <Main>
            <Modal
                open={modalOpen}
                hideCloseButton={true}
            >
              <Typo variant="h2" className={classes.heading}>
                  Join or Create a Provider
              </Typo>

              <Typo variant="body2" color="lightGrey">
                  Before you begin you need to either join an existing provider or create a new provider. A provider is a group of one or more hosts which allows for the shared management of listings.
              </Typo>

              <Tabs value={tab} onChange={onTabChange} aria-label="basic tabs example">
                <Tab label="Join" /*{...a11yProps(0)}*/ />
                <Tab label="Create" /*{...a11yProps(1)}*/ />
              </Tabs>

              {tab == 0 && 
                <div className={classes.tabPanel}>
                      
                  <Typo variant="body2" color="lightGrey">
                      Contact a user in a provider you wish to join to get them to add you to their provider.
                  </Typo>

                </div>
              }

              {tab == 1 && 
                <div className={classes.tabPanel}>

                  <InputField
                    id="name"
                    placeholder="Name"
                    className={classes.field}
                    value={name}
                    onChange={event => setName(event.target.value)}
                    error={errorField == 'name'}
                  />

                  <div className={classes.field}>
                    <ImageUpload 
                      onChange={setImages} 
                      value={images} 
                      maxNumber={1}
                    />
                  </div>

                  {Boolean(createMessage) && (
                    <Typo variant="body1" color="red">
                      {createMessage}
                    </Typo>
                  )}

                  <Button
                    text="Create"
                    loadingText="Creating"
                    className={classes.button}
                    loading={createBusy}
                    disabled={createBusy}
                    onClick={onCreate}
                    type="submit"
                  />


                </div>
              }

              <div className={classes.footer}>
                <Typo variant="body1" color="lightGrey">
                  <Typo
                    variant="body1"
                    component="a"
                    color="blue"
                    onClick={onLogOut}
                    className={classes.link}
                  >
                    Log Out
                  </Typo>
                </Typo>
              </div>

            </Modal>
        </Main>
    )
}
