import {
    postProviderEndpoint, providerSignedUrlEndpoint, postProviderUpdateUserEndpoint, getProviderUsersEndpoint, getProvidersEndpoint, 
    getProvidersForUserEndpoint, setActiveProviderForUserEndpoint, getActiveProviderForUserEndpoint
} from 'services/fetch/apiEndpoints'
import apiFetch from 'services/fetch/apiFetch'
import {uploadImages} from 'utils' 
import { authActions } from 'store/actions'

export const postProvider = (data) => async (dispatch, getState) => {
    let provider = data
    try {
        // if this is a new provider, create it without any images as we want to get an ID for signed urls
        // then we can upload the images and modify the provider again to include images
        const isNew = !provider.hasOwnProperty('ID')
        if (isNew) {
            const image = provider.image
            provider.image = null
            const r = await apiFetch(postProviderEndpoint, provider)(dispatch, getState)
                .catch((error) => {
                    throw error
                })

            provider = {
                ...data,
                ID: r.data.ID,
                image
            }
        }

        const images = await uploadImages([provider.image], providerSignedUrlEndpoint, {ID: provider.ID}, dispatch, getState)
        provider.image = images[0]

        const r = await apiFetch(postProviderEndpoint, provider)(dispatch, getState)
                .catch((error) => {
                    throw error
                })

        // patch our user provider if the current user provider was modified
        const user = getState().user.data       
        const returnProvider = r.data
        if (returnProvider.ID == user.data.provider?.ID) {
            // push provider into the user data
            const modifiedUser = {
                data: {
                  ...user.data,
                  provider: returnProvider
                }
              }
            dispatch(authActions.updateUser(modifiedUser))
        }
                
        return r
    } catch (error) {
        throw error
    }
}

export const getActiveProvider = () => async (dispatch, getState) => {
    return apiFetch(getActiveProviderForUserEndpoint, {})(dispatch, getState)
}

export const postProviderUpdateUser = (data) => async (dispatch, getState) => {
    return apiFetch(postProviderUpdateUserEndpoint, data)(dispatch, getState)
        .then(async (response) => {
            // did we removed ourself from the active provider?
            // if so, we need to pull the info about our current active provider
            if (data.operation == 'remove') {
                // patch our user provider if the current user provider was modified
                const user = getState().user.data
                if (data.prov_ID == user.data.provider?.ID && data.user_ID == user.data.user?.ID) {
                    const r = await dispatch(getActiveProvider())

                    // push provider (or what we know about the provider) into the user data
                    const modifiedUser = {
                        data: {
                            ...user.data,
                            provider: r.data
                        }
                    }
                    dispatch(authActions.updateUser(modifiedUser))
                }
            }

            return response
        })
}

export const getUsersForProvider = (provider) => async (dispatch, getState) => {
    return apiFetch(getProviderUsersEndpoint, provider)(dispatch, getState)
}

export const getProvidersForUser = () => async (dispatch, getState) => {
    return apiFetch(getProvidersForUserEndpoint, {})(dispatch, getState)
}

export const getProviders = () => async (dispatch, getState) => {
    return apiFetch(getProvidersEndpoint, {})(dispatch, getState)
        .then(response => {
            response.data.providers.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            return response
        })
}

export const setActiveProvider = (prov_ID) => async (dispatch, getState) => {
    return apiFetch(setActiveProviderForUserEndpoint, {prov_ID})(dispatch, getState)
        .then(response => {
            // push provider into the user data
            const user = getState().user.data
            const modifiedUser = {
                data: {
                    ...user.data,
                    provider: response.data
                }
            }
            dispatch(authActions.updateUser(modifiedUser))
            return response
        })
}
