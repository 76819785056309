import React, { FC, useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useForm from 'utils/Hooks/useForm';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { ForgotPasswordState } from 'types/store/ForgotPasswordState';
import authThunks from '../../../thunks/auth';
import { LoginModalForms } from '../LoginModal/LoginModal.const';
import { ResetPasswordFormPublicProps } from './ResetPasswordForm.props';
import View from './ResetPasswordForm.view';
import {forgotPasswordWithEmail} from 'services/cognito'

const errMsg = `There is currently no account associated with the provided email address.`;

const FieldContainer: FC<ResetPasswordFormPublicProps> = (
  props: ResetPasswordFormPublicProps,
) => {

  /*
  const dispatch = useDispatch();
  const forgotPassword = useSelectorSafe<ForgotPasswordState>(
    // @ts-ignore
    store => store.forgotPassword,
    asyncData(ASYNC_STATUS.INITIAL),
  );

  const serverErrors = useMemo(
    () => ({
      emailAddress: {
        error: forgotPassword.status === 'ERROR' ? errMsg : null,
      },
    }),
    [forgotPassword.status],
  );
*/
    //const [error, setError] = useState()

  const {
    state,
    disable,
    handleOnChange,
    handleOnSubmit,
    handleOnBlur,
  } = useForm(
    {
      emailAddress: { value: '', error: '' },
    },
    {
      /*
      emailAddress: (email: string) => {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          return 'You must enter a valid email address.';
        }

        return null;
      },
      */
    },
    (s: any) => {
/*
      forgotPasswordWithEmail({email: s.emailAddress.value})
        .then(cognitoResponse => {
          if (props.openModal) {
            props.openModal('CHECK_EMAIL', {
              email: s.emailAddress.value,
              action: 'reset-password',
            })()
          }
        })
        .catch(error => {
          setError(error.message)
        })
        /*
      dispatch(
        authThunks.forgotPassword({
          email: s.emailAddress.value,
        }),
      );*/
    },
    //serverErrors,
  );

  const [errorMessage, setErrorMessage] = useState()
  const [errorField, setErrorField] = useState()
  const [busy, setBusy] = useState(false)

  const setFieldError = (field, message) => {
    setErrorMessage(message)
    setErrorField(field)
  }

  const onConfirm = () => {
    setFieldError(null, null)

    if (!state.emailAddress.value) {
      setFieldError('emailAddress', 'Email Address is required')
      return
    }

    forgotPasswordWithEmail({email: state.emailAddress.value})
        .then(cognitoResponse => {
          if (props.openModal) {
            props.openModal('CHECK_EMAIL', {
              email: state.emailAddress.value,
              action: 'reset-password',
            })()
          }
        })
        .catch(error => {
          setErrorMessage(error.message)
          setBusy(false)
        })
  }

  
  const onLoginClick = props.openModal
    ? props.openModal(LoginModalForms.LOGIN)
    : () => {};
    /*
  const openCheckEmail = props.openModal
    ? props.openModal(LoginModalForms.CHECK_EMAIL, {
        email: state.emailAddress.value,
        action: 'reset-password',
      })
    : () => {};
    */
  const onSignUpClick = props.openModal
    ? props.openModal(LoginModalForms.SIGNUP)
    : () => {};

    /*
  useEffect(() => {
    if (forgotPassword.status === 'COMPLETE' && props.openModal) {
      openCheckEmail();
    }
  }, [forgotPassword.status]);
*/
  return (
    <View
      {...props}
      busy={busy}
      errorMessage={errorMessage}
      errorField={errorField}
      onLoginClick={onLoginClick}
      onSignUpClick={onSignUpClick}
      onConfirm={onConfirm} //handleOnSubmit}
      emailAddress={state.emailAddress}
      //disabled={disable}
      setEmailAddress={handleOnChange('emailAddress')}
      setTouchedEmailAddress={handleOnBlur('emailAddress')}
      //error={error}
    />
  );
};

export default React.memo(FieldContainer);
