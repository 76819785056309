import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: (props) => ({
      backgroundColor: 'white',
      borderRadius: '5px',
      border:
        (props.errorMessage || '').length > 0 || props.error
          ? `1px solid ${colors.red}`
          : '1px solid rgb(162, 170, 173, 0.1)',
      color: 'rgb(5, 195, 217)',
      width: '100%',
    }),
    iconContainer: {
      height: '50px',
      width: '36px', //'50px', 50 - 14px
      paddingLeft: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      width: '20px',
      height: '20px',
    },
    inputField: {
      width: '100%',
      boxSizing: 'border-box',
      /*
      paddingLeft: (props) => {
        if (props.icon) {
          return '0px'
        }
        return theme.spacing(2)
      }*/
    },
    input: {
      color: 'rgba(0, 0, 0, 0.87)', //rgb(187, 192, 194)',
      minHeight: '50px',
      border: 'none'
    },
    error: {
      paddingLeft: 2,
      paddingRight: 2,
    },
  }),
);
