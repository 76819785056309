import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './Terms.styles';
import { Typo, HorizontalLine } from '../../primitives';

const TermsView: FC = props => {
  const classes = useStyles();
  // @ts-ignore
  const { title, content } = props;
  return (
    <Box>
      <Typo className={classes.title} variant="h2">
        {title}
      </Typo>
      <HorizontalLine className={classes.separator} />
      <Typo variant="body0" className={classes.description} color="lightGrey">
        {content.description}
      </Typo>
      <ol>
        {content.elements.map((data: any, i: any) => (
          <Typo key={i} variant="h4" className={classes.subTitle}>
            {data.title}
            <Typo
              variant="body0"
              className={classes.description}
              color="lightGrey"
            >
              {data.elements.map(
                // @ts-ignore
                (element, j) => (
                  <li key={j}>
                    <Typo
                      variant="body0"
                      className={classes.description}
                      color="lightGrey"
                    >
                      {element.content}
                      <ol style={{ listStyleType: 'lower-roman' }}>
                        {element.subElements.map(
                          // @ts-ignore
                          (subElement, k) => (
                            <li key={k}>{subElement}</li>
                          ),
                        )}
                      </ol>
                    </Typo>
                  </li>
                ),
              )}
            </Typo>
          </Typo>
        ))}
      </ol>
    </Box>
  );
};

export default React.memo(TermsView);
