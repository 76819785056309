import {makeStyles, createStyles} from '@mui/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },

    margins: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },

    childContainer: {
        marginRight: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },

    lastChildContainer: {

    }
  }),
)

const ButtonContainer = (props) => {
    const classes = useStyles(props)
    const children = props.children && (Array.isArray(props.children) ? props.children : [props.children])

    return (
        <div className={`${classes.buttonContainer} ${!props.hasOwnProperty('noMargins') && classes.margins} ${props.className}`}>
            {children && children.map((child, index) => {
                const isLast = children.length == (index + 1)
                return (
                    <div className={isLast ? classes.lastChildContainer : classes.childContainer}>
                        {child}
                    </div>
                )
            })}
        </div>
    )
}

export default ButtonContainer