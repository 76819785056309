import React, { FC } from 'react';
import { Box } from '@mui/material';
import { EmailIcon } from 'assets';
import { useStyles } from './ResetPasswordForm.styles';
import { ResetPasswordFormProps } from './ResetPasswordForm.props';
import { Typo, Button, InputField } from '../../primitives';

const ResetPasswordFormView: FC<ResetPasswordFormProps> = props => {
  const classes = useStyles();
  const {errorMessage, errorField, busy} = props

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        Reset Password
      </Typo>
      <Typo variant="body2" className={classes.subHeading} color="lightGrey">
        Please enter your email address below and we&lsquo;ll send a reset link
      </Typo>
      <InputField
        icon={EmailIcon}
        id="email"
        placeholder="Email Address"
        className={classes.field}
        error={errorField == 'emailAddress'}
        //error={Boolean(props.emailAddress.error) && props.emailAddress.touched}
        value={props.emailAddress.value}
        onChange={event => props.setEmailAddress(event.target.value)}
        onBlur={props.setTouchedEmailAddress}
      />
      {/*props.error ? (
        <div className={classes.errorMessage}>
          <Typo variant="body1" color="red">
            {props.error}
          </Typo>
        </div>
      ) : null*/}
      {Boolean(errorMessage) && (
        <Typo variant="body1" color="red">
          {errorMessage}
        </Typo>
      )}
      {/*Boolean(props.emailAddress.error) && props.emailAddress.touched && (
        <Box className={classes.footer}>
          <Typo variant="body1" color="red">
            {props.emailAddress.error}
          </Typo>
        </Box>
      )*/}
      <Button
        text="Confirm"
        className={classes.button}
        disabled={busy}
        loading={busy}
        onClick={props.onConfirm}
      />
      <Box className={classes.footer}>
        <Typo
          variant="body1"
          color="blue"
          component="a"
          onClick={props.onSignUpClick}
          className={classes.link}
        >
          Create account
        </Typo>
      </Box>
      <Box className={classes.footer}>
        <Typo
          variant="body1"
          color="blue"
          component="a"
          onClick={props.onLoginClick}
          className={classes.link}
        >
          Return home
        </Typo>
      </Box>
    </>
  );
};

export default React.memo(ResetPasswordFormView);
