import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    container: {
      padding: theme.spacing(1),
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    negativeButton: {
      width: '50%',
      color: 'rgb(187, 192, 194)',
      textTransform: 'none',
      marginRight: theme.spacing(2),
    },
  }),
);
