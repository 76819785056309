import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Main from 'components/layouts/Main'
import {HeaderSection, ConfirmModal} from 'components/modules'
import {Typo, SplitView, HeaderImage, ImageUpload, Field, HorizontalLine, Loading, Button, ButtonContainer, InputField, Select, Link} from 'components/primitives'
import {makeStyles, createStyles} from '@mui/styles'
import useSelectorSafe from 'store/selectors/useSelectorSafe'
import { asyncData } from 'utils/Redux'
import { ASYNC_STATUS } from 'types/store/AsyncStatus'
import {getUsersForProvider, postProviderUpdateUser, getProvidersForUser, setActiveProvider, postProvider} from 'thunks/provider'
import {getUsers} from 'thunks/user'
import { EmailIcon } from 'assets'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {validEmail} from 'utils'

export const useStyles = makeStyles((theme) =>
  createStyles({
    imageUploadContainer: {
        width: '300px',
        height: '300px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },
    splitViewContainer: {
        marginTop: 16,
        marginBottom: 16
    },
    membersContainer: {
        marginTop: 16
    },
    usersHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    heading: {
        textTransform: 'none'
    },
    link: {
        cursor: 'pointer',
    },
    buttonContainer: {
    },

    rightChild: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    providerUserEntryContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },
    providerUserCol: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },

    activeProviderContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center'
    },
    activeProviderHeading: {
        width: '150px',
        textTransform: 'none',
        marginBottom: '16px'
    }
  }),
)

export const ProviderUserEntry = (props) => {
    const classes = useStyles(props)
    const {providerUser, onRemove, canRemove, isSelf} = props
    const user = providerUser.user || {
        firstName: '_MISSING_USER_',
    }
    const [mouseOver, setMouseOver] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    return (
        <div className={classes.providerUserEntryContainer} onMouseOver={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
            <div className={classes.providerUserCol}>
                {user?.firstName} {user?.lastName}
            </div>

            <div className={classes.providerUserCol}>
                <a href={`mailto:${user?.email}`}>
                    {user?.email}
                </a>
            </div>

            {/*
            <div className={classes.providerUserCol}>
                {providerUser.role}
            </div>
            */}

            <div className={classes.providerUserCol} style={{justifyContent: 'flex-end'}}>
                {(mouseOver && canRemove) &&
                    <Typo variant="body1">
                        <Link onClick={() => setConfirmModalOpen(true)}>
                            Remove
                        </Link>
                    </Typo>
                }
            </div>

            <ConfirmModal 
                open={confirmModalOpen} 
                onConfirm={() => onRemove(providerUser)} 
                onClose={() => setConfirmModalOpen(false)} 
                message={isSelf ? `Are you sure you want to remove youself from this provider?` : `Are you sure you want to remove ${user?.firstName} ${user?.lastName} from this provider?`}
            />
        </div>
    )
}

export const AddUserModal = (props) => {
    const {provider, onSuccess, open, onClose} = props
    const dispatch = useDispatch()
    const classes = useStyles(props)
    const [email, setEmail] = useState()
    const [errorField, setErrorField] = useState([])
    const [busy, setBusy] = useState(false)
    const [error, setError] = useState()

    useEffect(() => {
        setError()
        setBusy(false)
        setEmail()
    }, [open])

    const onSubmit = () => {
        if (!email) {
            setError('Email Address is required.')
            return
        }
        if (!validEmail(email)) {
            setError('You must enter a valid email address')
            return
        }

        setError()
        setBusy(true)

        // first find the user by email address
        dispatch(getUsers({email}))
            .then(response => {
                // get the first valid user
                const addUser = response.data && response.data.users && response.data.users[0]
                if (!addUser) {
                    setError(`No user found with email ${email}`)
                    setBusy(false)
                    return
                }

                // join the user to the provider with the appropriate role
                const p = dispatch(postProviderUpdateUser({
                    operation: 'add',
                    role: 'admin',
                    prov_ID: provider.ID,
                    user_ID: addUser.ID
                  }))
                  .then(puuResponse => {
                    onSuccess && onSuccess(addUser)
                    setBusy(false)
                  })
                  .catch(err => {
                    setError(err.message || err.error || err)
                    setBusy(false)
                })
                
            })
            .catch(err => {
                setError(err.message || err)
                setBusy(false)
            })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                <Typo variant="h2" className={classes.heading}>
                Add User
                </Typo>
            </DialogTitle>
            <DialogContent className={classes.contentContainer}>
                <InputField
                    icon={EmailIcon}
                    id="email"
                    placeholder="Email"
                    className={classes.field}
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    error={errorField == 'email'}
                />

                {Boolean(error) &&
                    <Typo variant="body1" color="red">
                    {error}
                    </Typo>
                }
            </DialogContent>
            <DialogActions>
                <ButtonContainer noMargins>
                    <Button
                        text="Cancel"
                        className={classes.button}
                        disabled={busy}
                        onClick={onClose}
                        type="submit"
                        variant="text"
                        color="lightGrey"
                    />
                    <Button
                        text="Add"
                        className={classes.button}
                        loading={busy}
                        disabled={busy}
                        onClick={onSubmit}
                        type="submit"
                    />
                </ButtonContainer>
            </DialogActions>
        </Dialog>
    )
}

const ProviderAdmin = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles(props)

    const { data: user, status, errors } = useSelectorSafe(
        state => state.user,
        asyncData(ASYNC_STATUS.INITIAL),
    )
    const currentActiveProvider = user.data.provider

    const [editMode, setEditMode] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const [refreshProviders, setRefreshProviders] = useState(0)
    const [refreshUsers, setRefreshUsers] = useState(0)
    const [providerUsers, setProviderUsers] = useState([])
    const [error, setError] = useState()
    const [loadingProviders, setLoadingProviders] = useState(true)
    const [loadingUsers, setLoadingUsers] = useState(true)

    const [providers, setProviders] = useState([])
    const [activeProvID, setActiveProvID] = useState(currentActiveProvider?.ID)

    useEffect(() => {
        dispatch(getUsersForProvider({ID: activeProvID}))
            .then(response => {
                setProviderUsers(response.data.providerusers)
                setLoadingUsers(false)
            })
            .catch(err => {
                setError(err.message || err)
                setLoadingUsers(false)
            })
    }, [refreshUsers, activeProvID])

    useEffect(() => {
        dispatch(getProvidersForUser())
            .then(response => {
                setProviders(response.data.providers)
                setLoadingProviders(false)
            })
            .catch(err => {
                setError(err.message || err)
                setLoadingProviders(false)
            })
    }, [refreshProviders])

    const onSelectProvider = (prov_ID) => {
        setEditMode(false)
        setLoadingUsers(true)
        setLoadingProviders(true)
        setProviderUsers([])
        dispatch(setActiveProvider(prov_ID))
            .then(response => {
                setActiveProvID(response.data.ID)
                setLoadingProviders(false)
            })
            .catch(err => {
                setError(err.message || err)
            })
    }

    const onRemoveProviderUser = async (providerUser) => {
        const isSelf = (user.data.user.ID == providerUser.user_ID)
        return dispatch(postProviderUpdateUser({
            operation: 'remove',
            prov_ID: providerUser.prov_ID,
            user_ID: providerUser.user_ID
          }))
          .then(puuResponse => {
            if (isSelf) {
                setActiveProvID(puuResponse.data?.prov_ID)
                setRefreshProviders(refreshProviders + 1)
            } else {
                setRefreshUsers(refreshUsers + 1)
            }
          })
          .catch(err => {
            setError(err.message || err)
        })
    }

    const foundProvider = providers.find(p => p.ID == activeProvID)
    const {image, name} =  foundProvider || {}
    const providerOptions = providers.map(p => {
        return {label: p.name, value: p.ID}
    })

    const [editBusy, setEditBusy] = useState(false)
    const [editName, setEditName] = useState()
    const [editImages, setEditImages] = useState([])

    const onEditPress = () => {
        setError()
        setEditBusy(false)
        setEditName(name)
        setEditImages(image ? [{data_url: image}] : [])
        setEditMode(true)
    }

    const onSavePress = () => {
        setError()
        setEditBusy(true)
        dispatch(postProvider({
            ...foundProvider,
            name: editName,
            image: editImages[0].data_url
          }))
          .then(response => {
            setActiveProvID(response.data.ID)
            setRefreshProviders(refreshProviders + 1)
            setEditMode(false)
          })
          .catch(error => {
              setError(error.message || error)
          })
    }

    return (
        <Main>
            <HeaderSection
                heading="Providers"
                subHeading="Manage and administer your providers"
            />

            {Boolean(error) &&
                <Typo variant="body1" color="red">
                  {error}
                </Typo>
            }

            {providers.length >= 2 &&
                <>
                    <div className={classes.activeProviderContainer}>
                        <Typo variant="h5" color="darkGrey" className={classes.activeProviderHeading}>
                            Active Provider
                        </Typo>

                        <Select
                            id="provider"
                            value={activeProvID || 'placeholder'}
                            onChange={(option) => onSelectProvider(option)}
                            options={providerOptions}
                        />
                    </div>
                    <HorizontalLine/>
                </>
            }

            <Loading isLoading={loadingProviders}>
                <div className={classes.splitViewContainer}>
                    <SplitView
                        leftChild={
                            <>
                                {editMode ?
                                    <div className={classes.imageUploadContainer}>
                                        <ImageUpload 
                                            onChange={setEditImages} 
                                            value={editImages} 
                                            maxNumber={1}
                                        />
                                    </div>
                                :
                                    <HeaderImage image={image} />
                                }
                            </>
                        }
                        rightChild={
                            <div className={classes.rightChild}>
                                <div className={classes.usersHeaderContainer}>
                                    <Typo variant="h3" className={classes.field}>
                                        Details
                                    </Typo>

                                    {!editMode &&
                                        <Typo variant="body1" className={classes.field}>
                                            <Link onClick={onEditPress}>
                                                Edit
                                            </Link>
                                        </Typo>
                                    }
                                </div>

                                {editMode ?
                                    <InputField
                                        id="name"
                                        placeholder="Name"
                                        onChange={event => setEditName(event.target.value)}
                                        value={editName}
                                        //onBlur={props.setTouchedLastName}
                                        //error={Boolean(props.lastName.error) && props.lastName.touched}
                                    />
                                :
                                    <Field label="Name" value={name} className={classes.field} />
                                }

                                <div style={{flex: 1}}/>

                                {editMode &&
                                    <ButtonContainer className={classes.buttonContainer}>
                                        <Button className={classes.cancelButton} onClick={() => setEditMode(false)}
                                            text="Cancel"
                                            variant='text'
                                            color='lightGrey'
                                            disabled={editBusy}
                                        />
                                        <Button
                                            disabled={editBusy}
                                            loading={editBusy}
                                            text="Save changes"
                                            onClick={onSavePress}
                                        />
                                    </ButtonContainer>
                                }
                            </div>
                        }
                    />
                </div>
                <HorizontalLine />
                <div className={classes.membersContainer}>
                    <div className={classes.usersHeaderContainer}>
                        <Typo variant="h3" className={classes.field}>
                            Users
                        </Typo>

                        <Typo variant="body1" className={classes.field}>
                            <Link onClick={() => setModalOpen(true)}>
                                Add User
                            </Link>
                        </Typo>
                    </div>
                    <div>
                        <Loading isLoading={loadingUsers} />
                        {providerUsers.map(providerUser => 
                            <ProviderUserEntry 
                                providerUser={providerUser} 
                                onRemove={onRemoveProviderUser} 
                                canRemove={providerUsers.length > 1}
                                isSelf={user.data.user.ID == providerUser.user_ID}
                            />
                        )}
                    </div>
                </div>
            </Loading>

            <AddUserModal open={modalOpen} onClose={() => setModalOpen(false)} onSuccess={() => {setModalOpen(false); setRefreshUsers(refreshUsers + 1)}} provider={foundProvider}/>
        </Main>
    )
}

export default ProviderAdmin