import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { AsyncData } from 'types/store/AsyncData';
import { AppErr } from 'types/AppErr';
import { Action } from 'redux-actions';
import { asyncData } from '../asyncData/asyncData';

export const createAsyncReducers = <StateType>(actionNameSnake: string, options) => {
  const {keepDataOnPending = false} = options || {}
  return {
    [`${actionNameSnake}_PENDING`]: (
      state: AsyncData<StateType>,
      action: Action<StateType>,
    ) => {
      return asyncData<StateType>(ASYNC_STATUS.LOADING, keepDataOnPending ? state.data : null)
    },

    [`${actionNameSnake}_FULFILLED`]: (
      state: AsyncData<StateType>,
      action: Action<StateType>,
    ) => asyncData(ASYNC_STATUS.COMPLETE, action.payload),

    [`${actionNameSnake}_REJECTED`]: (
      state: AsyncData<StateType>,
      action: Action<AppErr[]>,
    ) => asyncData<StateType>(ASYNC_STATUS.ERROR, action.payload),
  }
}

export const makeCreateAsyncReducers = (ns: string) => <StateType>(
  actionNameSnake: string,
) => createAsyncReducers<StateType>(`${ns}/${actionNameSnake}`);


export const createNonAsyncReducers = <StateType>(actionNameSnake: string) => ({
  [`${actionNameSnake}`]: (
    state: AsyncData<StateType>,
    action: Action<StateType>,
  ) => asyncData(ASYNC_STATUS.COMPLETE, action.payload),
});