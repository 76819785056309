import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    listElement: {
      marginBottom: '0.1px',
      background: 'none',
      color: 'inherit',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
      width: '100%',
      '&:hover': {
        backgroundColor: 'rgb(245, 245, 245)',
      },
    },
    selectedListElement: {
      marginBottom: '0.1px',
      background: 'none',
      color: 'inherit',
      border: 'none',
      padding: 0,
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
      width: '100%',
      backgroundColor: 'rgb(245, 245, 245)',
    },
  }),
);
