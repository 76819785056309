import React, { FC } from 'react';
// @ts-ignore
import StarRatings from 'react-star-ratings';
import { colors } from 'theme/themeConstants';
import { HeartIcon } from 'assets';
import { useStyles } from './LocationCard.styles';
import Typo from '../Typo';
import Slider from 'react-slick';
import { Button, IconButton } from '@mui/material';
import { LockIcon, UnlockIcon } from 'assets';

const reallyOnClick = (onClick) => (event) => {
  onClick(event)
  event.stopPropagation()
}

function SliderArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={style}
      onClick={reallyOnClick(onClick)}
    />
  );
}

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SliderArrow />,
  prevArrow: <SliderArrow />
};

const LocationCardView = props => {
  const classes = useStyles(props);
  const { isLoggedIn } = props;
  return (
    <div
      className={classes.container}
    >
      <div className={classes.innerContainer}>
        <div className={classes.clickContainer}
          onClick={props.onClick}
          onMouseOver={props.onMouseOver ? props.onMouseOver : () => {}}
          onMouseOut={props.onMouseOut ? props.onMouseOut : () => {}}
        >

          <div className={classes.imageContainer}>
            <div className={classes.imageInnerContainer}>
              <Slider {...sliderSettings} className={classes.imageSlider}>
                {props.images && props.images.map((image, idx) => {
                  return (
                    <img src={image} className={classes.sliderImage} key={idx}/>
                  )
                })}
              </Slider>
            </div>
          </div>
          <div className={classes.iconButtonContainer}>
            <div className={classes.iconButtonBg}>
              <IconButton
                className={classes.iconButton}
                onClick={props.onLockPress}
              >
                {props.isLocked ? <LockIcon /> : <UnlockIcon />}
              </IconButton>
            </div>
          </div>
          <div className={classes.bodyContainer}>
            <div
              style={{
                //flex: 1.75,
                display: 'flex',
                //overflow: 'hidden',
                //textOverflow: 'ellipsis',
                //minHeight: '52px',
                //maxHeight: '52px',
                marginBottom: '10px',
                //alignItems: 'center',
                maxWidth: '233px' // FMNOTE: not ideal!
              }}
            >
              <Typo variant="h3" color="darkGrey" className={classes.title}>
                {props.title}
              </Typo>
            </div>
            <div
              style={{
                flex: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                overflow: 'hidden'
              }}
            >
              <Typo variant="body1" color="lightGrey" className={classes.date}>
                {props.address}
              </Typo>
              {/*
              <Typo variant="body1" color="lightGrey" className={classes.time}>
                9am - 9pm
              </Typo>
              */}
            </div>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <Button
            className={classes.footerButton}
            onClick={props.onEditListing}
          >
            <Typo variant="body2" color="blue">
              Edit Listing
            </Typo>
          </Button>  

          <Button
            className={classes.footerButton}
            onClick={() => {
              props.onViewRequest()
            }}
          >
            <Typo variant="body2" color="blue">
              View Requests
            </Typo>
          </Button>   
        </div>
      </div>
    </div>
  );
};

export default React.memo(LocationCardView);
