import React from 'react';
import View from './Stepper.view';

// @ts-ignore
const StepperContainer = (props) => {
    return (
        <View {...props}
        />
    )
};

export default React.memo(StepperContainer);
