import { productDetailsActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getEntityByIdEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

interface Data {
  productId: string;
}

const getEntityById = (data: Data, onSuccess?: any, onError?: any) => (
  dispatch: Dispatch,
  getState: () => Store,
) => {
  const getByIdFetch = productDetailsActions.getById(
    apiFetch<Data, any>(getEntityByIdEndpoint, data)(dispatch, getState)
      .then((response: any) => {
        if (onSuccess) onSuccess(response);
        return response;
      })
      .catch((error: any) => {
        if (onError) onError(error);
        return error;
      }),
  );

  dispatch(getByIdFetch);
  // dispatch(
  //   productDetailsActions.getById(
  //     apiFetch<Data, any>(getEntityByIdEndpoint, data)(dispatch, getState),
  //   ),
  // );
};

export default getEntityById;

// import { productDetailsActions } from 'store/actions';
// import { Dispatch } from 'redux';
// import { getEntityByIdEndpoint } from 'services/fetch/apiEndpoints';
// import apiFetch from 'services/fetch/apiFetch';
// import { Store } from '../../types/store/Store';
//
// interface Data {
//     productId: string;
// }
//
// const getEntityById = (data: Data, onSuccess?: any, onError?: any) => (
//     dispatch: Dispatch,
//     getState: () => Store,
// ) => {
//     const getById = productDetailsActions.getById(
//         apiFetch<Data, any>(getEntityByIdEndpoint, data)(dispatch, getState)
//             .then((response: any) => {
//                 if (onSuccess) onSuccess(response);
//             })
//             .catch((error: any) => {
//                 if (onError) onError(error);
//             }),
//     );
//     dispatch(getById);
// };
//
// export default getEntityById;
