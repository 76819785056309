import React, {useRef} from 'react';

import { Button as MButton, Container } from '@mui/material';
import { Button, LocationDetailsCard, SplitView, Stepper, Select, HorizontalLine, Loading } from 'components/primitives';
import { BookingDetails as BookingDetailsLayout } from 'components/layouts';
import {HeaderSection} from 'components/modules';
import Typo from 'components/primitives/Typo';
import { useStyles } from './EditListing.styles';
import Main from 'components/layouts/Main';
import Slider from 'react-slick';

const sliderSettings = {
  touchMove: false,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  useTransform: true,
  autoplay: false,
  arrows: false,
  infinite: false,
  dots: false,
  pauseOnHover: true,
  centerMode: false,
};

// @ts-ignore
const EditListingView = (props) => {
  const {
    // @ts-ignore
    questionComponents,
    // @ts-ignore
    bookingDetails,
    // @ts-ignore
    onSubmit,
    // @ts-ignore
    goToHostDetails,
    onViewListing,

    steps,
    activeStep,
    onNextStep,
    onBackStep,
    nextDisabled,
    errorMessage
  } = props;
  const classes = useStyles(props);
  const Component = steps[activeStep].component

  return (
    <Main>
      <HeaderSection breadcrumbs={props.breadcrumbs} heading='Edit Listing' subHeading="Change details of your listing"/>
      <Loading isLoading={props.loading}>
        {props.steps.map((step, index) => {
          const Component2 = step.component
          return (
            <div>
              {index !=0 && <HorizontalLine className={classes.separator}/>}
              <Component2 {...props}/>
            </div>
          )
        })}

        <Container className={classes.footerContainer}>
          {Boolean(errorMessage) && (
            <Typo variant="body1" color="red">
              {errorMessage}
            </Typo>
          )}
          <div className={classes.footerButtonContainer}>
            <Button
              text="Preview Changes"
              className={classes.button}
              onClick={props.onSaveListing}
              disabled={props.disabled}
            />
          </div>
        </Container>
      </Loading>
    </Main>
  );
};

export default React.memo(EditListingView);
