import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { AppBar, WebsiteFooter } from 'components/modules';
import { MainProps } from './Main.props';

const MainView: FC<MainProps> = (props: MainProps) => {
  return (
    <Box style={{ height: '100vh' }}>
      <AppBar />

      {props.fullWidth ? (
        <Box
          style={{
            height: 'calc(100% - 64px - 12px)',
          }}
        >
          {props.children}

          {!props.hideWebsiteFooter && <WebsiteFooter/>}

        </Box>
      ) : (
        <Box
          style={{
            height: 'calc(100% - 64px - 12px)',
          }}
        >
          <Container>
            {props.children}
          </Container>

          {!props.hideWebsiteFooter && <WebsiteFooter/>}

        </Box>
      )}

      <Box
        style={{
          display: 'flex',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        {props.footer}
      </Box>
    </Box>
  );
};

export default React.memo(MainView);
