import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      width: '113px',
      height: '124px',
      '&:hover': {
        backgroundColor: (props: { backgroundColor: string }) =>
          props.backgroundColor,
      },
      backgroundColor: (props: {
        iconUrl: string;
        backgroundColor: string;
        iconAlignedLeft: boolean;
        isGhostTile: boolean;
      }) => (props.isGhostTile ? 'transparent' : props.backgroundColor),
      border: (props: {
        iconUrl: string;
        backgroundColor: string;
        iconAlignedLeft: boolean;
        isGhostTile: boolean;
        isActive?: boolean;
      }) => {
        if (props.isActive) {
          return `4px solid ${colors.blue}`;
        }

        return props.isGhostTile
          ? `2px solid ${props.backgroundColor}`
          : 'none';
      },
      backgroundImage: (props: {
        iconUrl: string;
        backgroundColor: string;
        iconAlignedLeft: boolean;
        isGhostTile: boolean;
      }) => `url(${props.iconUrl})`,
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      backgroundPosition: (props: {
        iconUrl: string;
        backgroundColor: string;
        iconAlignedLeft: boolean;
        isGhostTile: boolean;
      }) => (props.iconAlignedLeft ? 'left bottom' : 'right bottom'),
    },
    typography: (props: {
      iconUrl: string;
      backgroundColor: string;
      iconAlignedLeft: boolean;
      isGhostTile: boolean;
    }) =>
      props.isGhostTile
        ? {
            color: props.backgroundColor,
            position: 'absolute',
            bottom: theme.spacing(2),
          }
        : {
            color: 'white',
            position: 'absolute',
            bottom: theme.spacing(2),
          },
  }),
);
