import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import { HeaderSection, Map, InfiniteScroll } from '../../../components/modules';
import {
  Link,
  Typo,
  Select,
  HorizontalLine,
  Loading,
  StatusBadge,
  ListPlaceholder
} from '../../../components/primitives';
import Main from '../../../components/layouts/Main';
import {bookingTimeData} from 'services/api'
import { useStyles } from './MyBookings.styles';
import { FilterIconActive, ShowMap, HideMap } from 'assets';

// @ts-ignore
const Booking = ({
  // @ts-ignore
  id,
  // @ts-ignore
  onBookingClick,
  // @ts-ignore
  onContactHostClick,
  // @ts-ignore
  status,
  // @ts-ignore
  myBooking,
  // @ts-ignore,
  onMouseOver,
  // @ts-ignore
  onMouseOut,
}) => {
  const product = myBooking.product || {}
  const {startOn, endOn} = bookingTimeData(myBooking)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 4px 2px rgba( 0, 0, 0, 0.1 )',
        overflow: 'hidden',
        borderRadius: '16px',
        maxWidth: '380px',
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div style={{ position: 'relative' }}>
        <img
          src={product.images && product.images.length && product.images[0]}
          style={{
            width: '100%',
            height: '227px',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={() => onBookingClick(id)}
        />
        <StatusBadge
          status={status}
          style={{
            position: 'absolute',
            bottom: '0',
            right: '1em',
            transform: 'translateY(15%)'
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div
          style={{
            paddingTop: '1em',
            paddingLeft: '1em',
            paddingRight: '1em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Link
            onClick={() => onBookingClick(id)}
            style={{ textDecoration: 'none' }}
          >
            <Typo variant="h3" style={{ marginBottom: '0.5em' }}>
              {myBooking.title}
            </Typo>
          </Link>
        </div>
        <div>
          <div
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              paddingBottom: '1em',
            }}
          >
            <Typo color="lightGrey">
              {startOn.format('DD MMM YYYY, hh:mm A')} - {endOn.format('DD MMM YYYY, hh:mm A')}
            </Typo>
          </div>
          <div
            style={{
              height: '0.5em',
              width: '100%',
              paddingTop: '0.5em',
            }}
          >
            <HorizontalLine />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: '1em',
            }}
          >
            <Typo color="blue">
              <Link onClick={() => onContactHostClick(myBooking.provider, myBooking)}>
                Contact host
              </Link>
            </Typo>
            <Typo color="blue">
              <Link onClick={() => onBookingClick(id)}>Booking details</Link>
            </Typo>
          </div>
        </div>
      </div>
    </div>
  );
};

const View = (props) => {
  const {
    hasMore,
    fetchMore,
    loading,
    myFilteredBookings,
    currentFilterStatus,
    onBookingClick,
    onContactHostClick,
    onBookingHistoryClick,
    setCurrentFilterMethod,
    isMapVisible,
    toggleMap
  } = props

  const classes = useStyles(props);

  const mapRef = useRef(null);
  const [center, setCenter] = useState({ lat: -27, lng: 133 });
  const [zoom, setZoom] = useState(4.1);
  const [showMap, setShowMap] = useState(true);
  const [markers, setMarkers] = useState([]);
  useEffect(() => {
    if (myFilteredBookings && myFilteredBookings.length) {
      // @ts-ignore
      setMarkers(
        // @ts-ignore
        myFilteredBookings.map((e: any) => {
          return {
            latitude: e.product.location.latitude,
            longitude: e.product.location.longitude,
            status: e.status,
            id: e.product.ID,
          };
        }),
      );
      setShowMap(true);
    }
  }, [myFilteredBookings]);
  const [map, setMap] = useState(null);
  const [selectedId, setSelectedId] = useState('');
  const setCenterMap = (position: any, markerId: string) => {
    if (map) {
      // @ts-ignore
      map.panTo(new window.google.maps.LatLng(position.lat, position.lng));
      setSelectedId(markerId);
    }
  };
  const deselectMarker = () => {
    setSelectedId('');
  };
  return (
    <Box style={{ height: '100vh' }}>
      <Main fullWidth>
        <div style={{ height: '100%', marginTop: 16, marginLeft: '2em' }}>
          <Hidden mdUp>
            <div
              style={{
                paddingLeft: '3em',
                paddingRight: '3em',
                width: '100%',
                margin: 0,
                padding: 0,
                height: '100%',
                overflow: 'auto',
              }}
              id='scrollableDiv'
            >
              <div style={{ paddingRight: '2em' }}>
                <HeaderSection
                  heading="My Bookings"
                  subHeading="View and manage your bookings"
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '1em',
                    flexDirection: 'row'
                  }}
                >
                  <div style={{width: '200px'}}>
                    <Select
                      id="messageType"
                      value={currentFilterStatus}
                      onChange={value => setCurrentFilterMethod(value)}
                      options={[
                        {
                          label: 'All',
                          value: 'all',
                        },
                        {
                          label: 'Confirmed',
                          value: 'confirmed',
                        },
                        {
                          label: 'Pending',
                          value: 'pending',
                        },
                        {
                          label: 'Declined',
                          value: 'declined',
                        },
                      ]}
                    />
                  </div>
                  <div style={{flex: 1}}/>
                  <Typo color="lightGrey">
                    <Link onClick={() => onBookingHistoryClick()}>
                      My Bookings History
                    </Link>
                  </Typo>
                </div>

                <Loading isLoading={loading}>
                  <InfiniteScroll
                    style={{
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, 345px)',
                      gridGap: '2em',
                      justifyContent: 'center',
                      paddingBottom: '2em',
                    }}
                    hasMore={hasMore}
                    fetchMore={fetchMore}
                    scrollableTarget='scrollableDiv'
                    emptyMessage={
                      <ListPlaceholder
                        description='There are no bookings at this time'
                      />
                    }
                  >
                    {myFilteredBookings.map((myBooking, i) => (
                      <Booking
                        key={i}
                        id={myBooking.ID}
                        onBookingClick={onBookingClick}
                        onContactHostClick={onContactHostClick}
                        status={myBooking.status}
                        myBooking={myBooking}
                        onMouseOver={() => {
                          setCenterMap(
                            {
                              lat: myBooking.product.location.latitude,
                              lng: myBooking.product.location.longitude,
                            },
                            myBooking.product.ID,
                          );
                        }}
                        onMouseOut={() => {
                          deselectMarker();
                        }}
                      />
                    ))}
                  </InfiniteScroll>
                </Loading>
              </div>
            </div>
          </Hidden>
          <Hidden smDown>

            <div className={classes.contentContainer}>
              <div className={classes.featureContainer} id='scrollableDiv'>
                <HeaderSection
                  heading="My Bookings"
                  subHeading="View and manage your bookings"
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '1em',
                  }}
                >
                  <div style={{width: '200px'}}>
                    <Select
                      id="messageType"
                      value={currentFilterStatus}
                      onChange={value => setCurrentFilterMethod(value)}
                      options={[
                        {
                          label: 'All',
                          value: 'all',
                        },
                        {
                          label: 'Confirmed',
                          value: 'confirmed',
                        },
                        {
                          label: 'Pending',
                          value: 'pending',
                        },
                        {
                          label: 'Declined',
                          value: 'declined',
                        },
                      ]}
                    />
                  </div>
                  <div style={{flex: 1}}
                  />
                  <Typo color="lightGrey">
                    <Link onClick={() => onBookingHistoryClick()}>
                      My Bookings History
                    </Link>
                  </Typo>
                </div>

                <Loading isLoading={loading}>
                  <InfiniteScroll
                    style={{
                      width: '100%',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, 345px)',
                      gridGap: '2em',
                      justifyContent: 'center',
                      paddingBottom: '2em',
                    }}
                    hasMore={hasMore}
                    fetchMore={fetchMore}
                    scrollableTarget='scrollableDiv'
                    emptyMessage={
                      <ListPlaceholder
                        description='There are no bookings at this time'
                      />
                    }
                  >
                    {myFilteredBookings.map((myBooking: any, i: number) => (
                      <Booking
                        key={i}
                        id={myBooking.ID}
                        onBookingClick={onBookingClick}
                        onContactHostClick={onContactHostClick}
                        status={myBooking.status}
                        myBooking={myBooking}
                        onMouseOver={() => {
                          setCenterMap(
                            {
                              lat: myBooking.product.location.latitude,
                              lng: myBooking.product.location.longitude,
                            },
                            myBooking.product.ID,
                          );
                        }}
                        onMouseOut={() => {
                          deselectMarker();
                        }}
                      />
                    ))}
                  </InfiniteScroll>
                </Loading>
              </div>

              <Hidden xsDown>
                <div className={classes.mapContainer}>
                  <div className={classes.showMapButtonContainer}>
                    <button
                      className={classes.toggleMapButton}
                      onClick={toggleMap}
                    >
                      {isMapVisible ? <HideMap /> : <ShowMap />}
                    </button>
                  </div>
                  <div className={classes.map}>
                    {showMap ? (
                      <div className={classes.mapInner}>
                        <Map
                          center={center}
                          defaultZoom={zoom}
                          mapRef={mapRef}
                          markers={markers}
                          autoCenter
                          setMap={setMap}
                          selectedId={selectedId}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </Hidden>
            </div>
                  
          </Hidden>
        </div>
      </Main>
    </Box>
  );
};

export default React.memo(View);
