import React, { FC, useEffect, useState } from 'react';
import View from './CreateNewListingBooking.view';

const CreateNewListingBookingContainer = (props) => { 
  return (
    <View {...props}/>
  );
};

export default CreateNewListingBookingContainer;
