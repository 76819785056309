import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    container: {
      padding: theme.spacing(4),
      flex: 1,
    },
    fieldName: {
      marginBottom: '20px',
    },
    checkboxContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
    },
    spacer: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '30px',
    },
    clearButton: {
      backgroundColor: 'transparent',
      border: 'none',
    },
  }),
);
