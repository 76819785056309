import React, {useState, useEffect, useRef} from 'react'
import {Loading, HorizontalLine} from 'components/primitives'
import RInfiniteScroll from 'react-infinite-scroll-component'
import {makeStyles, createStyles} from '@mui/styles'
import {useRefState} from 'utils'

export const useStyles = makeStyles((theme) =>
  createStyles({
    loadingContainer: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(20),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    endContainer: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(20),
    }
  }),
)

const InfiniteScroll = (props) => {
    const {children, hasMore, fetchMore, className, style, emptyMessage, ...otherProps} = props
    const classes = useStyles(props)
    const showEmptyMessage = (!children || children.length <= 0) && !hasMore
    // we use the inner div so the loading and end message don't get put into a grid or what ever
    return (
        <RInfiniteScroll
            dataLength={children.length}
            hasMore={hasMore}
            next={fetchMore}
            style={{overflow: 'visible'}}
            loader={<div className={classes.loadingContainer}><Loading isLoading={true}/></div>}
            endMessage={<div className={classes.endContainer}><HorizontalLine/></div>}
            scrollThreshold={0.5}
            {...otherProps}
        >
            <div className={className} style={style}>
                {children}
            </div>
            {showEmptyMessage && emptyMessage}
        </RInfiniteScroll>
    )
}

export default InfiniteScroll

export const useInfiniteScroll = (fetchMore, resetDeps) => {
    const [data, setData] = useState([])
    const [lastKey, setLastKey] = useState()
    const [loading, setLoading] = useState(true)
    const [resetCount, resetCountRef, setResetCount] = useRefState(0)

    // define early to fix javascript error
    let fetchMoreCb = null

    // resetCount, passed in as resetId to reject and resolve serve to 
    // help us work out if an async function's call is out of date and should be discarded.
    // This out of date will occur if a refresh from resetDeps occurs
    
    const reject = (resetId) => (error) => {
        // discard if the fetch we just completed is old
        if (resetCountRef.current != resetId) {
            return
        }

        console.error(error)
        setLoading(false)
    }

    const resolve = (resetId) => (d, lk) => {
        // discard if the fetch we just completed is old
        if (resetCountRef.current != resetId) {
            return
        }

        // the results were filtered out manually
        if (d.length <= 0 && lk) {
            fetchMore(lk, resolve(resetId), reject(resetId))
            return
        }

        // https://stackoverflow.com/questions/61096061/why-do-react-hooks-reference-old-data-in-event-handlers
        setData((prev) => {
            return prev.concat(d)
        })
        setLastKey(lk)
        setLoading(false)
    }

    fetchMoreCb = () => {
        fetchMore(lastKey, resolve(resetCount), reject(resetCount))
    }

    useEffect(() => {
        setResetCount(resetCount + 1)
        setLoading(true)
        setData([])
        setLastKey(null)
        fetchMore(null, resolve(resetCount + 1), reject(resetCount + 1))
    }, resetDeps || [])

    return {
        hasMore: !!lastKey,
        fetchMore: fetchMoreCb,
        setLoading,
        loading,
        data,
        setData
    }
}