import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    appBar: {
      height: '60px',
      backgroundColor: 'white',
      boxShadow: '0 1px 1px rgba(0,0,0,0.02)',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      borderRadius: '5px',
    },
    spacer: {
      flexGrow: 1,
    },
  }),
);
