import { Dispatch } from 'redux';
import { getClientSecretEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

const getClientSecret = (
  data,
  success,
  error,
) => (dispatch, getState) => {
  const getClientSecretFetch = apiFetch(getClientSecretEndpoint, data)(
    dispatch,
    getState,
  )
    .then(p => {
      if (success) success(p.data);
      return p.data;
    })
    .catch(err => {
      if (error) error(err);
    });
  return getClientSecretFetch;
};

export default getClientSecret;
