import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      paddingLeft: '7.5px',
      paddingRight: '7.5px',
      cursor: 'pointer !important',
      pointerEvents: 'auto',
    },
    innerContainer: {
      backgroundColor: 'white',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      overflow: 'hidden'
    },
    imageContainer: {
      height: 0,
      overflow: 'hidden',
      paddingTop: '75.24752475%',
      background: colors.lightGrey,
      position: 'relative',
      //borderRadius: '10px',
      marginBottom: '10px',
    },
    imageInnerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    imageSlider: {
      width: '100%',
      height: '100%',
      
      //background: "green",

      "& .slick-list": {
        //display: 'flex',
        height: '100% !important',
        //background: "red"
      },
      "& .slick-track": {
        //display: 'flex',
        height: '100% !important',
        //background: "blue"
      },
      "& .slick-slide > div": {
        height: '100% !important',
      },
      "& .slick-prev": {
        left: '15px',
        zIndex: 100
      },
      "& .slick-next": {
        right: '15px',
      }
    },
    sliderImage: {
      //position: 'absolute',
      //top: 0,
      //left: 0,
      objectFit: 'cover',
      width: '100%',
      height: '100%',
    },
    bookedByContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid rgba(162, 170, 173, 0.1)'
    },
    bodyContainer: {
      position: 'relative',
      marginTop: '12px',
      marginLeft: '15px',
      marginRight: '15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '89px',
      flex: 1,
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginBottom: '0px',
      cursor: 'pointer !important',
      pointerEvents: 'auto',
    },
    date: {
      marginBottom: '10px',
    },
    time: {
      marginBottom: '10px',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px'
    },
    bottomContainer: {
      display: 'flex',
    },
    price: {
      marginLeft: '10px',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid rgba(162, 170, 173, 0.1)'
    },
    footerButton: {
      flex: 1,
      height: '50px',
      textTransform: 'none'
    }
  }),
);
