import _moment from 'moment'

export const moment = _moment

moment.updateLocale('en', { week: {
    dow: 1, // First day of week is Monday
}})

export const weekdays = [...moment.weekdays().slice(1), moment.weekdays()[0]] // start on monday plz!
//const weekdaysShort = weekdays.map((dayName) => dayName.substring(0, 2))
export const weekdayAbbrev = (dayName) => dayName.substring(0, 2).toUpperCase()
