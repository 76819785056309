import React, { FC, Fragment } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './Agreement.styles';
import { Typo, HorizontalLine } from '../../primitives';

const AgreementView: FC = props => {
  const classes = useStyles();
  // @ts-ignore
  const { title, content } = props;
  return (
    <Box>
      <Typo className={classes.title} variant="h2">
        {title}
      </Typo>
      <HorizontalLine className={classes.separator} />
      <Typo variant="h4" className={classes.subTitle}>
        <ol style={{ whiteSpace: 'pre-wrap' }}>
          {content.map((data: any, i: any) => (
            <Fragment key={data.title}>
              <li style={{ marginTop: i === 0 ? '0' : '1.5rem' }}>
                {data.title}
                <Typo
                  variant="body0"
                  className={classes.description}
                  color="lightGrey"
                >
                  <ol className={classes.ol}>
                    {data.elements.map(
                      // @ts-ignore
                      (element, j) => (
                        <li key={j} className={classes.li}>
                          <Typo
                            variant="body0"
                            className={classes.description}
                            color="lightGrey"
                          >
                            {element}
                          </Typo>
                        </li>
                      ),
                    )}
                  </ol>
                </Typo>
              </li>
            </Fragment>
          ))}
        </ol>
      </Typo>
    </Box>
  );
};

export default React.memo(AgreementView);
