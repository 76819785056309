import React from 'react';
import { Button, LocationDetailsCard, SplitView } from 'components/primitives';
import { BookingDetails as BookingDetailsLayout } from 'components/layouts';
import Typo from '../../../components/primitives/Typo';

// @ts-ignore
const BookingDetailsView = ({
  // @ts-ignore
  questionComponents,
  // @ts-ignore
  bookingDetails,
  // @ts-ignore
  fields,
  // @ts-ignore
  onSubmit,
  // @ts-ignore
  goToHostDetails,
}) => {
  const product = bookingDetails.product || {}
  const pricing = product.pricing || {}

  return (
    <BookingDetailsLayout
      heading="Booking Details"
      leftChildren={
        <LocationDetailsCard
          images={product.images}
          title={product.title}
          address={
            product.location && product.location.address
              ? product.location.address
              : ''
          }
          price={bookingDetails.price}
          weekdayPrice={pricing.weekdays}
          weekendPrice={pricing.weekends}
          fields={fields}
          provider={product.provider}
          goToHostDetails={goToHostDetails}
        />
      }
      rightChildren={
        <div>
          <Typo variant="h4" color="darkGrey">
            Additional information
          </Typo>
          {questionComponents}
          {(!questionComponents || questionComponents.length <= 0) &&
            <div style={{
                marginTop: '1em',
                marginBottom: '1em'
              }}
            >
              None
            </div>
          }
          <SplitView
            // @ts-ignore
            leftChild={null}
            // @ts-ignore
            rightChild={
              <Button
                style={{ marginTop: '1em' }}
                text="Confirm Booking Details"
                onClick={onSubmit}
              />
            }
            leftFraction={0.5}
          />
        </div>
      }
    />
  );
};

export default React.memo(BookingDetailsView);
