import React, { FC, useState } from 'react';
import { asyncData } from 'utils/Redux';
import { ProfileState } from 'types/store/ProfileState';
import { UserState } from 'types/store/UserState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { useDispatch } from 'react-redux';
import ProfileView from './Profile.view';
import { ProfilePublicProps } from './Profile.props';
import userThunks from '../../../thunks/user';
import routerThunks from 'thunks/router'
import {ROUTES} from '../../index';

interface Profile {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  postcode: string;
}

const profileFallback: ProfileState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load profile' },
]);

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const ProfileContainer: FC<ProfilePublicProps> = (
  props: ProfilePublicProps,
) => {
  const dispatch = useDispatch();

  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: userState } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );
  if (!userState) {
    goToListings();
  }
  const profile = useSelectorSafe<ProfileState>(
    // @ts-ignore
    state => state.profile,
    profileFallback,
  );

  // const user = useSelectorSafe<UserState>(state => state.user, userFallback);
  const [inEditMode, setEditMode] = useState(false);
  const [message, setMessage] = useState({});
  // @ts-ignore
  const onSave = (data: Profile) => {
    dispatch(
      userThunks.updateProfile(data,
        (response: any) => {
          setEditMode(false)
          // setMessage({
          //   type: 'success',
          //   message: `Profile successfully updated.`,
          // });

          //response.image += `?nocache=true` // force change of image path to trigger a refetch from the browser
          //dispatch(userThunks.getProfile({}))
/*
          userActions.getProfile(
            apiFetch<Data, any>(getProfileEndpoint, {})(
              dispatch,
              getState,
            ).then(p => p.data),
          ),*/
          
          /*
          setTimeout(function() {
            window.location.reload()
          }, 1)
          */

          console.log('done!')
        },
        (err: any) => {
          setMessage({
            type: 'error',
            message: `Something went wrong. Try again.`,
          });
        },
      ),
    );
  };
  const setEditModeClearMessage = (b: boolean) => {
    setMessage({});
    setEditMode(b);
  };
  const user: any = profile.data || {}
  return (
    <ProfileView
      {...props}
      inEditMode={inEditMode}
      setEditMode={setEditModeClearMessage}
      message={message}
      onSave={onSave}
      user={user}
    />
  );
};

export default React.memo(ProfileContainer);
