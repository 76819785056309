import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
//import momentTz from 'moment-timezone';
import moment from 'moment';
import View from './ReviewBooking.view';
import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import bookingThunks from '../../../thunks/booking';
import reviewThunks from '../../../thunks/review';
import {ROUTES} from '../..';
import routerThunks from 'thunks/router'
import { UserState } from '../../../types/store/UserState';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import {bookingTimeData} from 'services/api'

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const getBookingById = (dispatch: any, id: string) =>
  new Promise((resolve, reject) => {
    dispatch(
      bookingThunks.getById(
        { bookingId: id },
        response => {
          const booking = response.data
          const product = booking.product
          const {startOn, endOn} = bookingTimeData(booking)

          const startMoment = startOn;
          const endMoment = startOn;
          const startDate = startMoment
            ? startMoment.format('DD MMM YYYY, hh:mm A')
            : '';
          const endDate = endMoment
            ? endMoment.format('DD MMM YYYY, hh:mm A')
            : '';
          const location = product.location;
          const locationText = location
            ? `${location.address}, ${location.suburb} ${location.state} ${
                location.postcode
              }`
            : '';
          const duration =
            startMoment && endMoment
              ? moment.duration(endMoment.diff(startMoment))
              : null;
          const hours = duration ? duration.asHours() : '';
          resolve({
            name: product.title,
            location: locationText,
            price: booking.price,
            hostedBy: booking.provider && booking.provider.name,
            fields: [
              { title: 'Start Date', value: startDate },
              { title: 'End date', value: endDate },
              { title: 'Duration', value: `${hours} hours` },
            ],
            listingId: product.ID, // ? is this right?
            images: product.images,
            showReview:
              !booking.review,
            data: {
              id: booking.ID,
              startDate,
              endDate,
              status: booking.status,
              totalPrice: booking.price,
              // @ts-ignore
              questions:
                (((response || {}).data || {}).metadata || {}).questions || [],
              title: product.title,
              capacity: product.capacity,
              timezone: product.timezone,
              active: product.isActive,
              price: booking.price,
              type: product.type,
              attributes: product.attributes,
              subtype: product.subtype,
              location: product.location,
              description: product.description,
              rules: product.rules,
              images: product.images,
              productId: product.ID,
              providerId: booking.prov_ID
            },
          });
        },
        error => {
          reject(error);
        },
      ),
    );
  });

const createReview = (dispatch: any, review: any) =>
  new Promise((resolve, reject) => {
    dispatch(
      reviewThunks.submitReview(
        review,
        success => {
          resolve(success);
        },
        error => {
          reject(error);
        },
      ),
    );
  });

const getReviews = (dispatch: any, productId: string) =>
  new Promise((resolve, reject) => {
    dispatch(
      reviewThunks.getReviews(
        { productId, status: 'confirmed' },
        (response: any) => {
          resolve(response.reviews);
        },
        (error: any) => {
          reject(error);
        },
      ),
    );
  });

const ReviewBookingContainer = () => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: user } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }
  const payload = useSelectorSafe<any>((state: any) => {
    return state.location.payload;
  });
  const [rating, setRating] = useState(0);
  const [yourExperience, setYourExperience] = useState('');
  const [booking, setBooking] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const submitReview = () => {
    createReview(dispatch, {
      rating,
      commentBooking: yourExperience,
      // @ts-ignore
      book_ID: booking.data.id,
      // @ts-ignore
      prod_ID: booking.data.productId,
      // @ts-ignore
      prov_ID: booking.data.providerId
    })
      .then(reviewResponse => {
        setRating(0);
        setYourExperience('');
        setShowFields(false);
      })
      .catch(error => {
        console.log('_ERROR_', error);
      });
  };
  const goToListing = () => {
    // @ts-ignore
    if (booking && booking.listingId) {
      dispatch(
        routerThunks.link(ROUTES.LISTING_DETAILS, {
          // @ts-ignore
          id: booking.listingId,
        }),
      );
    }
  };
  const goToBookingHistory = () => {
    dispatch(routerThunks.link(ROUTES.MY_BOOKING_HISTORY));
  };
  useEffect(() => {
    setSubmitDisabled(yourExperience === '' && rating === 0);
  }, [yourExperience && rating]);
  useEffect(() => {
    // @ts-ignore
    if (booking || !(payload && payload.id)) return;
    // @ts-ignore
    getBookingById(dispatch, payload.id)
      .then(response => {
        // @ts-ignore
        setBooking(response);
        // @ts-ignore
        setShowFields(response.showReview);
      })
      .catch(error => {
        console.log('_ERROR_', error);
        goToListings();
      });
  }, [payload]);
  useEffect(() => {
    // @ts-ignore
    if (booking && booking.listingId) {
      // @ts-ignore
      getReviews(dispatch, booking.listingId)
        .then((reviewsResponse: any) => {
          const isObject = (value: any) => {
            return (
              value && typeof value === 'object' && value.constructor === Object
            );
          };
          const trimmedReviews = reviewsResponse.slice(0, 3);
          setReviews(trimmedReviews);
        })
        .catch(error => {
          console.log('_ERROR_', error);
        });
    }
  }, [booking]);
  const breadcrumbs = [
    {
      title: 'My Bookings History /',
      onClick: () => {
        dispatch(routerThunks.link(ROUTES.MY_BOOKING_HISTORY));
      },
    },
    {
      title: 'Booking Details',
      onClick: () => {},
    },
  ];
  return (
    // @ts-ignore
    <View
      booking={booking}
      rating={rating}
      setRating={(value: any) => setRating(value)}
      yourExperience={yourExperience}
      setYourExperience={(event: any) => setYourExperience(event.target.value)}
      reviews={reviews}
      submitReview={submitReview}
      submitDisabled={submitDisabled}
      goToListing={goToListing}
      goToBookingHistory={goToBookingHistory}
      breadcrumbs={breadcrumbs}
      showFields={showFields}
    />
  );
};

export default ReviewBookingContainer;
