import React, { FC } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { Grid } from '@mui/material';
import { SplitViewProps } from './SplitView.props';

const SplitViewView: FC<SplitViewProps> = ({
  leftChild,
  rightChild,
  leftFraction,
}: SplitViewProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const totalWidth = Math.round(
    leftFraction === 0 || leftFraction === undefined ? 6 : 12 * leftFraction,
  );
  return (
    <Grid container spacing={3}>
      <Grid
        item
        // @ts-ignore
        xs={matches ? totalWidth : 12}
      >
        {leftChild}
      </Grid>
      <Grid
        item
        // @ts-ignore
        xs={matches ? 12 - totalWidth : 12}
      >
        {rightChild}
      </Grid>
    </Grid>
  );
};

export default React.memo(SplitViewView);
