import React, { FC, Fragment } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './CheckYourEmailDialog.styles';
import { CheckYourEmailDialogProps } from './CheckYourEmailDialog.props';
import { Typo, InputField, Button } from '../../primitives';

const getMessage = (action: string) => {
  if (action === 'verify-email') {
    return {
      title: 'Verify Email Address',
      message: `We sent you an email with a verification code to verify your email address. Please check your junk or spam folders.`,
      action: 'verify-email',
    };
  }

  return {
    title: 'Reset Password',
    message: `We sent you an email with a verification code to reset your password. Please check your junk or spam folders.`,
    action: 'reset-password',
  };
};

const ResetPasswordFormView: FC<CheckYourEmailDialogProps> = props => {
  const classes = useStyles();
  const messages = getMessage(props.params.action);

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        {messages.title}
      </Typo>
      <Typo variant="body2" className={classes.subHeading} color="lightGrey">
        {messages.message}
      </Typo>
      <div>
        <InputField
          id="verification-code"
          placeholder="Verification Code"
          className={classes.field}
          value={props.verificationCode}
          error={props.verificationCodeError}
          onChange={event => props.setVerificationCode(event.target.value)}
          onBlur={props.setTouchedVerificationCode}
        />
        {messages.action === 'reset-password' &&
          <Fragment>
            <InputField
              id="password"
              placeholder="Password"
              type="password"
              className={classes.field}
              value={props.password}
              error={props.passwordError}
              onChange={event => props.setPassword(event.target.value)}
              onBlur={props.setTouchedPassword}
            />
            <InputField
              id="confirm-password"
              placeholder="Confirm Password"
              type="password"
              className={classes.field}
              value={props.confirmPassword}
              error={props.confirmPasswordError}
              onChange={event => props.setConfirmPassword(event.target.value)}
              onBlur={props.setTouchedConfirmPassword}
            />
          </Fragment>
        }
      </div>
      {props.error ? (
        <Typo variant="body1" color="red" className={classes.errorMessage}>
          {props.error}
        </Typo>
      ) : null}
      <Button
        loading={props.busy}
        text={messages.action === 'reset-password' ? "Confirm New Password" : "Verify Email Address"}
        loadingText={messages.action === 'reset-password' ? "Confirming New Password" : "Verifying Email Address"}
        disabled={props.busy}
        className={classes.button}
        onClick={
          messages.action === 'reset-password'
            ? props.confirmNewPassword 
            : props.verifyEmailAddress
        }
      />
      <Box className={classes.footer}>
        <Typo variant="body1" color="lightGrey">
          Didn&lsquo;t receive it?&nbsp;
          <Typo
            variant="body1"
            color="blue"
            component="a"
            className={classes.sendAgain}
            onClick={
              messages.action === 'reset-password'
                ? props.resendForgotPasswordEmail
                : props.resendVerifyEmail
            }
            style={{
              cursor: 'pointer',
            }}
          >
            Send again
          </Typo>
        </Typo>
      </Box>
      <Box className={classes.footer}>
        {Boolean(props.message.message) && (
          <Typo
            variant="body1"
            color={props.message.type === 'success' ? 'green' : 'red'}
          >
            {props.message.message}
          </Typo>
        )}
      </Box>
    </>
  );
};

export default React.memo(ResetPasswordFormView);
