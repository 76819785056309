import { productActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getAllEntitiesEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';

const getAllEntities = (data, onSuccess, onError) => (
  dispatch,
  getState,
) => {
  dispatch(
    productActions.get(
      apiFetch(getAllEntitiesEndpoint, data)(dispatch, getState)
        .then((response) => {
          if (onSuccess) onSuccess(response);
          return response;
        })
        .catch((error) => {
          if (onError) onError(error);
          return error;
        }),
    ),
  );
};

export default getAllEntities;
