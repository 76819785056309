import React, { FC } from 'react';
import { Box, Slider, FormControlLabel } from '@mui/material';
import { pathOr } from 'ramda';
import { AdditionalFilterProps } from './AdditionalFilter.props';
import { Typo, HorizontalLine, Checkbox } from '../../primitives';

import MobileButton from '../MobileButton';
import { useStyles } from './AdditionalFilter.styles';

const AdditionalFilterView: FC<AdditionalFilterProps> = props => {
  const classes = useStyles();

  return (
    <Box className={classes.mainContainer}>
      <Box
        className={classes.container}
        style={{ flex: 1, overflowY: 'scroll' }}
      >
        <div className={classes.headerContainer}>
          <Typo variant="h2">Filters</Typo>
          <button className={classes.clearButton} onClick={props.reset}>
            <Typo variant="body2" color="lightGrey">
              Clear
            </Typo>
          </button>
        </div>
        <Box>
          <Typo variant="h5" className={classes.fieldName}>
            Price{' '}
            <Typo variant="body3" component="span">
              (per hour)
            </Typo>
          </Typo>
          <div className={classes.headerContainer} style={{ margin: 0 }}>
            <Typo variant="body2" color="lightGrey">
              {props.filterPrice && props.filterPrice.length
                ? `$${props.filterPrice[0]}`
                : `$${pathOr(0, ['filterOptions', 'pricing', 'min'], props)}`}
            </Typo>
            <Typo variant="body2" color="lightGrey">
              {props.filterPrice && props.filterPrice.length
                ? `$${props.filterPrice[1]}`
                : `$${pathOr(0, ['filterOptions', 'pricing', 'max'], props)}`}
            </Typo>
          </div>
          <Slider
            value={
              props.filterPrice && props.filterPrice.length
                ? props.filterPrice
                : [
                    pathOr(0, ['filterOptions', 'pricing', 'min'], props),
                    pathOr(0, ['filterOptions', 'pricing', 'max'], props),
                  ]
            }
            min={pathOr(0, ['filterOptions', 'pricing', 'min'], props)}
            max={pathOr(0, ['filterOptions', 'pricing', 'max'], props)}
            valueLabelFormat={value => `$${value}`}
            // eslint-disable-next-line
            onChange={(e, v) => props.setFilterPrice(v as number[])}
          />
          <HorizontalLine className={classes.spacer} />

          <Typo variant="h5" className={classes.fieldName}>
            Capacity
          </Typo>
          <div className={classes.headerContainer} style={{ margin: 0 }}>
            <Typo variant="body2" color="lightGrey">
              {props.filterCapacity && props.filterCapacity.length
                ? props.filterCapacity[0]
                : pathOr(0, ['filterOptions', 'capacity', 'min'], props)}
            </Typo>
            <Typo variant="body2" color="lightGrey">
              {props.filterCapacity && props.filterCapacity.length
                ? props.filterCapacity[1]
                : pathOr(0, ['filterOptions', 'capacity', 'max'], props)}
            </Typo>
          </div>
          <Slider
            value={
              props.filterCapacity && props.filterCapacity.length
                ? props.filterCapacity
                : [
                    pathOr(0, ['filterOptions', 'capacity', 'min'], props),
                    pathOr(0, ['filterOptions', 'capacity', 'max'], props),
                  ]
            }
            min={pathOr(0, ['filterOptions', 'capacity', 'min'], props)}
            max={pathOr(0, ['filterOptions', 'capacity', 'max'], props)}
            // eslint-disable-next-line
            onChange={(e, v) => props.setFilterCapacity(v as number[])}
          />
          <HorizontalLine className={classes.spacer} />

          {/*
          <Typo variant="h5" className={classes.fieldName}>
            Venue Type
          </Typo>
          <Box className={classes.checkboxContainer}>
            {pathOr([], ['filterOptions', 'subtypes'], props).map(
              (subtype: string) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.filterSubTypes &&
                        props.filterSubTypes.includes(subtype)
                      }
                      onClick={() => props.onToggleSubType(subtype)}
                    />
                  }
                  className={classes.capitalize}
                  label={subtype}
                  key={subtype}
                />
              ),
            )}
          </Box>

          <HorizontalLine className={classes.spacer} />
                */}

          <Typo variant="h5" className={classes.fieldName}>
            Facilities
          </Typo>
          <Box className={classes.checkboxContainer}>
            {pathOr([], ['filterOptions', 'attributes'], props).map(
              (facility: string) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        props.filterFacilities &&
                        props.filterFacilities.includes(facility)
                      }
                      onClick={() => props.onToggleFacility(facility)}
                    />
                  }
                  className={classes.capitalize}
                  label={facility}
                  key={facility}
                />
              ),
            )}
          </Box>
          <HorizontalLine className={classes.spacer} />
        </Box>
      </Box>
      <Box>
        <MobileButton text="Confirm" onClick={props.onSubmit} />
      </Box>
    </Box>
  );
};

export default React.memo(AdditionalFilterView);
