import { makeCreateActions, presetActions } from 'utils/Redux';
import { createAction } from 'redux-actions';

const ns = 'CREATE_LISTING';
const createContactActions = makeCreateActions(ns);

const createListingActions = {
  ...createContactActions({
    updated: presetActions.makeIdentity(),
    submit: presetActions.makeAsyncAction(`${ns}/SUBMIT`),
  }),
  clear: createAction(`${ns}/CLEAR`),
};

export default createListingActions;
