import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(2),
    },
    field: {
      marginBottom: theme.spacing(1.5),
    },
    videoField: {
      marginTop: theme.spacing(3.5),
    },
    rightChildContainer: {
      marginLeft: theme.spacing(6),
    },
    galleryContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      height: '285px'
    },
    description: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    }
  }),
);
