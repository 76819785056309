import { authActions, userActions, providerActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getProfileEndpoint, loginEndpoint, getProviderEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { User } from '../../types/User';
import { Store } from '../../types/store/Store';
import { tokenActions } from 'store/actions';
import { signInWithEmail } from 'services/cognito'
import { routeAndPortalFromLocation } from 'routes'

interface Credentials {
  email: string;
  password?: string;
}

interface Data {}

/*
    .then(response => {
      if (response && onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    })
    .catch(error => {
      if (error && onError) {
        onError(error);
      }
      return error;
    });
*/
const login = (credentials: Credentials, onSuccess, onError) => (
  dispatch: Dispatch,
  getState: () => Store,
) => {
/*
  const fakeLogin = true;
  if (fakeLogin) {
    // fire a fake login success message
    dispatch({
      type: 'AUTH/LOGIN_FULFILLED',
      payload: {
        data: {
          user: {
            id: 'fake-1'
          }
        },
        user: {
          test: 'yep1'
        },
        id: 'fake-1'
      }
    })
    dispatch(
      tokenActions.updated("fake-token"),
    );
    return
  }
*/
  const locationState = getState().location
  const [routeName, portalName, portal] = routeAndPortalFromLocation(locationState.type)

  signInWithEmail(credentials)
    .then(async (cognitoResponse) => {

      /*
      console.log(JSON.stringify({
        type: 'email',
        cognitoResponse
      }))
      */

      dispatch(
        authActions.login(
          apiFetch<Credentials, User>(loginEndpoint, {
            type: 'email',
            cognitoResponse,
            portal: portalName.toLowerCase()
          })(
            dispatch,
            getState,
          ).then(u => {
            dispatch(
              userActions.getProfile(
                apiFetch<Data, any>(getProfileEndpoint, {})(
                  dispatch,
                  getState,
                ).then(p => {
                  if (onSuccess) {
                    onSuccess(p.data)
                  }
                  return p.data
                }),
              ),
            );
            /*
            dispatch(
              providerActions.getProvider(
                apiFetch<Data, any>(getProviderEndpoint, {})(
                  dispatch,
                  getState,
                ).then(p => p.data),
              ),
            );*/
            return u;
          })
          .catch(error => {
            if (error && onError) {
              if (error.message && error.message.message) {
                onError(error.message)
              } else {
                onError(error)
              }
            }
            return error
          }),
        ),
      )

    })
    .catch(error => {
      if (error && onError) {
        onError(error)
      }
      return error
    })
}

export default login;
