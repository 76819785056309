import React, {useState, useEffect} from 'react'
import {makeStyles, createStyles} from '@mui/styles'
import {moment, weekdays, weekdayAbbrev} from 'utils/moment'
import {FormControlLabel, Switch} from '@mui/material'
import {Typo, Checkbox, Select} from 'components/primitives'
import _ from 'lodash'
import DayPicker, { DateUtils } from 'react-day-picker'

export const useStyles = makeStyles((theme) =>
  createStyles({
    availabilityContainer: {
      marginBottom: theme.spacing(2),
    },
    dayContainer: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column'
    },
    timeContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridGap: theme.spacing(1.5),
      marginLeft: theme.spacing(1.5)
    },
    heading: {
      marginBottom: theme.spacing(2),
    },
  }),
)

const timeOptions = [
  // { label: '12am', value: '00:00' },
  { label: '1am', value: '01:00' },
  { label: '2am', value: '02:00' },
  { label: '3am', value: '03:00' },
  { label: '4am', value: '04:00' },
  { label: '5am', value: '05:00' },
  { label: '6am', value: '06:00' },
  { label: '7am', value: '07:00' },
  { label: '8am', value: '08:00' },
  { label: '9am', value: '09:00' },
  { label: '10am', value: '10:00' },
  { label: '11am', value: '11:00' },
  { label: '12pm', value: '12:00' },
  { label: '1pm', value: '13:00' },
  { label: '2pm', value: '14:00' },
  { label: '3pm', value: '15:00' },
  { label: '4pm', value: '16:00' },
  { label: '5pm', value: '17:00' },
  { label: '6pm', value: '18:00' },
  { label: '7pm', value: '19:00' },
  { label: '8pm', value: '20:00' },
  { label: '9pm', value: '21:00' },
  { label: '10pm', value: '22:00' },
  { label: '11pm', value: '23:00' },
  { label: '12am', value: '24:00' },
]

const defaultState = weekdays.reduce((acc, cur) => {
  return {
    ...acc,
    [cur]: {
      dayEnabled: true,
      startTime: '09:00',
      endTime: '17:00'
    }
  }
}, {})

const Day = (props) => {
    const classes = useStyles(props)
    const {dayName, dayEnabled, onToggleDay, startTimeOptions, endTimeOptions, startTime, endTime, disabled, onStartTimeSelect, onEndTimeSelect} = props
    return (
        <div className={classes.dayContainer}>
            <FormControlLabel
              value="start"
              control={
                <Checkbox color="primary" 
                  checked={dayEnabled}
                  onClick={() => onToggleDay(dayName, !dayEnabled)}
                />
              }
              label={
                <Typo variant="body0">
                  {dayName}
                </Typo>
              }
            />
            <div className={classes.timeContainer}>
              <Select
                id="start-time"
                //label='Start Time'
                value={startTime}
                onChange={(option) => onStartTimeSelect(dayName, option)}
                options={startTimeOptions || []}
                disabled={disabled}
              />
              <Select
                id="end-time"
                //label='End Time'
                value={endTime}
                onChange={(option) => onEndTimeSelect(dayName, option)}
                options={endTimeOptions || []}
                disabled={disabled}
              />
            </div>
        </div>
    )
}

const generateSchedule = (state, blockedDays) => {
  const rrules = []
  weekdays.forEach((day, idx) => {
    const dayState = state[day]
    if (!dayState.dayEnabled) {
      return
    }

    rrules.push({
      day: weekdayAbbrev(day),
      startTime: dayState.startTime,
      endTime: dayState.endTime
    })
  })

  const schedule = {}
  if (rrules && rrules.length) {
    schedule.rrules = rrules
  }
  if (blockedDays && blockedDays.length) {
    schedule.exdates = blockedDays.map(date => moment(date).format('YYYY-MM-DD'))
  }
  return schedule
}

export const initialState = generateSchedule(defaultState)

const Availability = (props) => {
    const {onChange, value} = props
    const classes = useStyles(props)
    
    const [state, setState] = useState(defaultState)
    const [blockedDays, setBlockedDays] = useState([])
    const [valueCopy, setValueCopy] = useState(value)

    const onInternalChange = (state, blockedDays) => {
      const schedule = generateSchedule(state, blockedDays)
      setValueCopy(schedule)
      onChange && onChange(schedule)
    }

    const onToggleDay = (dayName, checked) => {
      const s = _.cloneDeep(state)
      s[dayName].dayEnabled = checked
      setState(s)
      onInternalChange(s, blockedDays)
    }

    const onStartTimeSelect = (dayName, time) => {
      const s = _.cloneDeep(state)
      s[dayName].startTime = time
      setState(s)
      onInternalChange(s, blockedDays)
    }

    const onEndTimeSelect = (dayName, time) => {
      const s = _.cloneDeep(state)
      s[dayName].endTime = time
      setState(s)
      onInternalChange(s, blockedDays)
    }

    const onDayClick = (day, { selected }) => {
      const sd = blockedDays.concat()
      if (selected) {
        const selectedIndex = sd.findIndex(selectedDay =>
          DateUtils.isSameDay(selectedDay, day)
        );
        sd.splice(selectedIndex, 1)
      } else {
        sd.push(day)
      }
      setBlockedDays(sd)
      onInternalChange(state, sd)
    }

    // on external change
    useEffect(() => {
      try {
        // no change.... parent just updating props to newest value
        if (_.isEqual(valueCopy, value)) {
          return
        }

        // determine the enabled days
        const s = _.cloneDeep(state)
        weekdays.forEach((dayName, idx) => {
          s[dayName].dayEnabled = false
        })

        const {rrules, exdates} = value

        rrules.forEach((rrule, idx) => {    
          const {startTime, endTime, day} = rrule
          const dayName = weekdays.find(dayName => weekdayAbbrev(dayName) == day)
          s[dayName].dayEnabled = true
          s[dayName].startTime = startTime
          s[dayName].endTime = endTime
        })
        setState(s)

        setBlockedDays(exdates.map(dt => moment(dt, 'YYYY-MM-DD').toDate()))
        console.log(value)
      } catch (err) {
        console.warn('AVAILABILITY RRULE ERROR: ', err)
      }
    }, [value])
     

    return (
        <div className={classes.availabilityContainer}>
          <Typo variant="h4" color="darkGrey" className={classes.heading}>
            Availability
          </Typo>
          {weekdays.map((dayName, idx) => {
              return (
                <Day
                  dayName={dayName} key={idx} 
                  onToggleDay={onToggleDay}
                  onStartTimeSelect={onStartTimeSelect}
                  onEndTimeSelect={onEndTimeSelect}
                  {...state[dayName]}
                  startTimeOptions={timeOptions}
                  endTimeOptions={timeOptions}
                />
              )
          })}

          <Typo variant="h4" color="darkGrey" className={classes.heading}>
              Block days
          </Typo>
          <DayPicker
            selectedDays={blockedDays}
            onDayClick={onDayClick}
            disabledDays={[
              { before: new Date() }
            ]}
          />
        </div>
    )
}

export default Availability