import { fontFamilies } from './themeConstants';

const { gilroy } = fontFamilies;

const typography = {
  h1: {
    fontFamily: gilroy,
    fontSize: '35px',
    fontWeight: 'bold',
  },
  h2: {
    fontFamily: gilroy,
    fontSize: '30px',
    fontWeight: 'bold',
  },
  h3: {
    fontFamily: gilroy,
    fontSize: '25px',
    fontWeight: 'bold',
  },
  h4: {
    fontFamily: gilroy,
    fontSize: '20px',
    fontWeight: 'bold',
  },
  h5: {
    fontFamily: gilroy,
    fontSize: '15px',
    fontWeight: 'bold',
  },
  h6: {
    fontFamily: gilroy,
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  body1: {
    fontFamily: gilroy,
    fontSize: '16px',
  },
  body2: {
    fontFamily: gilroy,
    fontSize: '15px',
  },
};

const appTypo = {
  body0: {
    fontFamily: gilroy,
    fontSize: '18px',
  },
  body3: {
    fontFamily: gilroy,
    fontSize: '12px',
  },
};

export const appTypoVariantMapping = {
  body0: 'body2',
  body3: 'body2',
};

export default {
  ...typography,
  ...appTypo,
};
