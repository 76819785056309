import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  PinBlue,
  PinRed,
  PinYellow,
  PinRedSelected,
  PinBlueSelected,
  PinYellowSelected,
} from '../../../assets';

const Pin = ({
  lat,
  lng,
  status,
  selected = false,
}: {
  lat: number;
  lng: number;
  status?: string;
  selected?: boolean;
}) => {
  if (status === 'pending') {
    return selected ? (
      <PinYellowSelected
        lat={lat}
        lng={lng}
        style={{ transform: 'translate(-50%, -100%)' }}
      />
    ) : (
      <PinYellow
        lat={lat}
        lng={lng}
        style={{ transform: 'translate(-50%, -100%)' }}
      />
    );
  }
  if (status === 'confirmed') {
    return selected ? (
      <PinBlueSelected
        lat={lat}
        lng={lng}
        style={{ transform: 'translate(-50%, -100%' }}
      />
    ) : (
      <PinBlue
        lat={lat}
        lng={lng}
        style={{ transform: 'translate(-50%, -100%' }}
      />
    );
  }
  return selected ? (
    <PinRedSelected
      lat={lat}
      lng={lng}
      style={{ transform: 'translate(-50%, -100%' }}
    />
  ) : (
    <PinRed
      lat={lat}
      lng={lng}
      style={{ transform: 'translate(-50%, -100%' }}
    />
  );
};

const Map = ({
  center,
  defaultZoom,
  mapRef,
  markers,
  autoCenter,
  setMap,
  selectedId,
  onChange,
}: any) => {
  const [map, setMapInternal] = useState(null)
  const [autoCenterOccured, setAutoCenterOccured] = useState(false)

  // only run autoCenter when we get some markers for the first time
  useEffect(() => {
    if (autoCenterOccured) {
      return
    }

    if (autoCenter && map && markers.length) {
      setAutoCenterOccured(true)
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker: any) => {
        bounds.extend(
          new window.google.maps.LatLng(marker.latitude, marker.longitude),
        );
        map.fitBounds(bounds);
      });
    }
  }, [markers, map])

  return (
    <GoogleMapReact
      options={{
        fullscreenControl: false
      }}
      yesIWantToUseGoogleMapApiInternals={true}
      bootstrapURLKeys={{
        key: process.env.REACT_APP_MAPS_SECRET || '',
      }}
      center={center}
      defaultZoom={defaultZoom}
      ref={mapRef}
      onGoogleApiLoaded={({ map, maps }: any) => {
        if (setMap) {
          setMapInternal(map)
          setMap(map)
        }
      }}
      onChange={(props) => {
        onChange && onChange(props)
      }}
    >
      {markers.map((e: any, i: any) => {
        return (
          <Pin
            lat={e.latitude}
            lng={e.longitude}
            key={i}
            status={e.status}
            selected={selectedId === e.id}
          />
        );
      })}
    </GoogleMapReact>
  );
};

export default Map;
