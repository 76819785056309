import { userActions } from 'store/actions';
import { Dispatch } from 'redux';
import {
  removeFavouriteEndpoint,
  getFavouritesEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { User } from '../../types/User';
import { Store } from '../../types/store/Store';

interface Data {
  productId: string;
}

const removeFavourite = (data: Data) => (
  dispatch: Dispatch,
  getState: () => Store,
) => {
  dispatch(
    userActions.removeFavourite(
      apiFetch<Data, User>(removeFavouriteEndpoint, data)(
        dispatch,
        getState,
      ).then(async () => {
        return apiFetch<{}, User>(getFavouritesEndpoint, {})(
          dispatch,
          getState,
        );
      }),
    ),
  );
};

export default removeFavourite;
