import React, { FC, useEffect, useState } from 'react';
import View from './CreateNewListingGallery.view';

const CreateNewListingGalleryContainer = (props) => { 
  return (
    <View {...props}/>
  );
};

export default CreateNewListingGalleryContainer;
