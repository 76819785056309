import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(2),
    },
    field: {
      marginBottom: theme.spacing(1.5),
    },
    rightChildContainer: {
      marginLeft: theme.spacing(6),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1.5)
    },
    checkboxContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      marginBottom: theme.spacing(0.5),
    },
    capitalize: {
      textTransform: 'capitalize',
    },
  }),
);
