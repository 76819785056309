import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import routerThunks from 'thunks/router'
import {ROUTES} from 'routes';
import RequestedBookingsView from './RequestedBookings.view';
import bookingThunks from 'thunks/booking';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {useInfiniteScroll, useChangeBookingStatus} from 'components/modules'
import { bookingIsPassed } from 'services/api'
import {toast} from 'react-toastify'
import { borderBottom } from '@mui/system';
import moment from 'moment';

const RequestedBookingsContainer = (props) => {
  const dispatch = useDispatch()
  const payload = useSelectorSafe(state => state.location.payload)

  const fetchMoreFn = (lastKey, resolve, reject) => {
    const params = {
      status: 'pending'
    }
    if (payload.id) {
      params.prod_ID = payload.id
    }
    if (lastKey) {
      params['start-key'] = lastKey
    }

    dispatch(
      bookingThunks.getProviderBookings(params,
        (response) => {
          const bookings = response.data.bookings
          let filteredBookings = bookings.sort((a, b) => {
            if (b.product.title == a.product.title) {
              return moment(a.startOn) - moment(b.startOn)
            }
            return a.product.title.localeCompare(b.product.title)
          })
          resolve(filteredBookings, response.lastKey)
        },
        (error) => {
          reject(error)
        }
      )
    )
  }
  const {data: bookings, setData: setBookings, fetchMore, hasMore, loading} = useInfiniteScroll(fetchMoreFn)

  const onViewListing = (id) => () => {
    return dispatch(routerThunks.link(ROUTES.VIEW_BOOKING_DETAILS, { id }));
  }

  const onContactGuest = (booking) => () => {
    dispatch(routerThunks.link(ROUTES.MESSAGES, { user: booking.user }));
  }

  const changeBookingStatus = useChangeBookingStatus((newBooking) => {
      setBookings((prev) => {
        const patchedBookings = prev.filter((b) => b.ID != newBooking.ID)
        return patchedBookings
      })
    },
    (error) => {
      toast.error(error.message)
    })

  const onApproveBooking = (booking) => () => {
    changeBookingStatus.show(booking, 'confirmed')
  }

  const onCancelBooking = (booking) => () => {
    changeBookingStatus.show(booking, 'cancelled')
  }

  return (
    <RequestedBookingsView
      {...props}
      results={bookings}
      onViewListing={onViewListing}
      onContactGuest={onContactGuest}
      onViewRequest={onViewListing}
      onApproveBooking={onApproveBooking}
      onCancelBooking={onCancelBooking}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      changeBookingStatus={changeBookingStatus}
    />
  );
};

export default React.memo(RequestedBookingsContainer);
