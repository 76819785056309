import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'ENTITY';
const createContactActions = makeCreateActions(ns);

const productActions = createContactActions({
  updated: presetActions.makeIdentity(),
  get: presetActions.makeAsyncAction(`${ns}/ENTITY`),
  getById: presetActions.makeAsyncAction(`${ns}/GET_ENTITY_BY_ID`),
  getFilters: presetActions.makeAsyncAction(`${ns}/GET_ENTITY_FILTERS`),
  addProduct: presetActions.makeAsyncAction(`${ns}/ADD_PRODUCT`),
});

export default productActions;
