import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';
import { LocationCardPublicProps } from './LocationCard.props';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      paddingLeft: '7.5px',
      paddingRight: '7.5px',
      cursor: 'pointer !important',
      pointerEvents: 'auto',
    },
    imageContainer: {
      height: 0,
      overflow: 'hidden',
      paddingTop: '75.24752475%',
      background: colors.lightGrey,
      position: 'relative',
      borderRadius: '10px',
      marginBottom: '10px',
    },
    imageInnerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    /*
    image: ({ image }: LocationCardPublicProps) => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url('${image}')`,
      backgroundSize: 'cover',
    }),*/
    imageSlider: {
      width: '100%',
      height: '100%',
      
      //background: "green",

      "& .slick-list": {
        //display: 'flex',
        height: '100% !important',
        //background: "red"
      },
      "& .slick-track": {
        //display: 'flex',
        height: '100% !important',
        //background: "blue"
      },
      "& .slick-slide > div": {
        height: '100% !important',
      },
      "& .slick-prev": {
        left: '15px',
        zIndex: 100
      },
      "& .slick-next": {
        right: '15px',
      }
    },
    sliderImage: {
      //position: 'absolute',
      //top: 0,
      //left: 0,
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      
    },
    title: {
      //display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      //marginBottom: '10px',
      cursor: 'pointer !important',
      pointerEvents: 'auto',
      lineHeight: '1', 
      fontSize: '25px'
    },
    address: {
      marginBottom: '10px',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    bottomContainer: {
      display: 'flex',
    },
    price: {
      marginLeft: '10px',
      marginTop: '1.5px'
    },
    heart: ({ favourite }: LocationCardPublicProps) =>
      favourite
        ? {
            position: 'absolute',
            right: '10px',
            top: '10px',
            transition: 'all 250ms ease-in-out',
            '&:hover': {
              transform: 'scale(1.2)',
              /*
              '& #Heart': {
                stroke: colors.white,
                fill: 'transparent',
              },*/
            },
            '& #Heart': {
              stroke: colors.white,
              fill: colors.blue,
            },
          }
        : {
            position: 'absolute',
            right: '10px',
            top: '10px',
            transition: 'all 250ms ease-in-out',
            '&:hover': {
              transform: 'scale(1.2)',
              /*
              '& #Heart': {
                stroke: colors.white,
                fill: colors.blue,
              },*/
            },
            '& #Heart': {
              stroke: colors.white,
              fill: 'transparent',
            },
          },
  }),
);
