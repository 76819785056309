import React, {useState} from 'react'
import Main from 'components/layouts/Main'
import {HeaderSection} from 'components/modules'
import {SettingEditor} from './SettingEditor'
import {useGetSettingsList, useMutateSetting} from 'services/react-query'
import {Typo, HorizontalLine, Loading, Select, ListPlaceholder} from 'components/primitives'
import {useStyles} from '../../host/Providers/Providers'

export function SettingsAdmin(props) {
    const classes = useStyles(props)
    const settingsListQuery = useGetSettingsList()
    const settingMutation = useMutateSetting()
    const [selectedSetting, setSelectedSetting] = useState()
    const settingsOptions = settingsListQuery.data?.map(s => {
        return {label: s.ID, value: s.ID}
    }) || []

    const [value, setValue] = useState({})
   
    const onChangeSetting = (option) => {
        setSelectedSetting(option)
        const setting = settingsListQuery.data.find(s => s.ID == option)
        setValue(setting.value)
    }

    const onValueChange = (value) => {
        setValue(value)
    }

    const onSave = () => {
        console.log('save')
        const setting = settingsListQuery.data.find(s => s.ID == selectedSetting)
        const newSetting = {
            ...setting,
            value
        }
        settingMutation.mutate(newSetting)
    }

    return (
        <Main>
            <HeaderSection
                heading="Settings Admin"
                subHeading="Manage and administer settings"
            />

            {settingsListQuery.isError &&
                <Typo variant="body1" color="red">
                  {settingsListQuery.error}
                </Typo>
            }

            {
                <>
                    <div className={classes.activeProviderContainer}>
                        <Typo variant="h5" color="darkGrey" className={classes.activeProviderHeading}>
                            Setting
                        </Typo>

                        <Select
                            id="provider"
                            value={selectedSetting || 'placeholder'}
                            onChange={onChangeSetting}
                            options={settingsOptions}
                        />
                    </div>
                    <HorizontalLine/>
                </>
            }

            <Loading isLoading={settingsListQuery.isLoading}>
                {!selectedSetting &&
                    <ListPlaceholder
                        description='Select a setting'
                    />
                }
                {selectedSetting &&
                    <SettingEditor 
                        name={selectedSetting}
                        value={value} 
                        onChange={onValueChange} 
                        onSave={onSave} 
                        saving={settingMutation.isLoading} 
                        key={selectedSetting}
                    />
                }
            </Loading>
        </Main>
    )
}