import { Dispatch } from 'redux';
import { Store } from 'types/store/Store';
import AppErr from 'utils/App/AppErr/AppErr';
import { tokenActions, authActions } from '../../store/actions';
import { Endpoint, methods, auth } from './apiEndpoints';

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  //'Access-Control-Allow-Credentials': 'true', // disabled for now, as I"m not sure how to setup the AWS exctly to work with this....
};

const useLocal = false
const samAWS = false // running sam aws lambda's locally?

const buildRequest = (
  method: string,
  body?: {},
  extraHeaders: {} = {},
): RequestInit => {
  const request = {
    method,
    headers: {
      ...DEFAULT_HEADERS,
      ...extraHeaders,
    },
  };

  // somehow the headers (Content-Type and Access-Control-Allow-Credentials) don't work when using the local sam aws
  // as I think the lambda doesnt return the right content type?
  if (samAWS) {
    request.headers = {};
  }
  
  return body ? { ...request, body: JSON.stringify(body) } : { ...request };
};

// The data can look like anything depending on the query.

const createQueryString = (data: any) =>
  Object.keys(data)
    .map(key => `${key}=${data[key]}`)
    .join('&');

const transformToLocal = (url: string) => {
  if (!useLocal) {
    return url
  }

  const apiUrl = samAWS ? 'http://127.0.0.1:3000/' : 'http://localhost:3006/api-mock/'
  // 'https://api.airplacer.com/v1/messaging/thread'
  let localUrl = url.replace('https://staging-api.airplacer.com/v1/', apiUrl);

  if (samAWS) {
    return localUrl;
  }

  // @ts-ignore
  localUrl = localUrl.replaceAll('&', '_and_')
  // @ts-ignore
  localUrl = localUrl.replaceAll('?', '_qn_')
  // @ts-ignore
  localUrl = localUrl.replaceAll('=', '_eq_')
  

  localUrl = `${localUrl}.json`;


  return localUrl;
}

const requestMethods = {
  GET: <PayloadType extends {}>(
    url: string,
    payload: PayloadType,
    extraHeaders: {},
    urlTransformer?: (data: any) => string,
  ): Promise<Response> => {
    // eslint-disable-next-line no-nested-ternary
    const turl = urlTransformer
    ? urlTransformer(payload)
    : payload && Object.keys(payload).length > 0
    ? `${url}?${createQueryString(payload)}`
    : url;

    const br = buildRequest(methods.GET, undefined, extraHeaders)

    console.log(turl, br);

    const lurl = transformToLocal(turl);

    return fetch(
      lurl,
      br,
    ).then(r => {
      // redirected to an error? local file doesnt exist!
      if (r.redirected || r.status == 404) {
        console.log(`GET URL IS NOT MOCKED: ${turl} (looking for local: ${lurl})`)
        return fetch(turl, br)
        .then(r2 => {
          console.log('all good!')
          return r2;
        })
        .catch(e => {
          console.error(e)
          throw e
        });
      }

      return r;
    })
  },
  PUT: <PayloadType extends {}>(
    url: string,
    payload: PayloadType,
    extraHeaders: {},
    urlTransformer?: (data: any) => string,
  ): Promise<Response> => {
    console.log(`PUT IS NOT MOCKED`)
    return fetch(
      urlTransformer ? urlTransformer(payload) : url,
      buildRequest(methods.PUT, payload, extraHeaders),
    )
  },
  POST: <PayloadType extends {}>(
    url: string,
    payload: PayloadType,
    extraHeaders: {},
    urlTransformer?: (data: any) => string,
  ): Promise<Response> => {
    const turl = urlTransformer ? urlTransformer(payload) : url;
    const br = buildRequest(methods.POST, payload, extraHeaders)

    console.log(turl, br);

    const lurl = transformToLocal(turl);

    return fetch(
      lurl,
      br,
    ).then(r => {
      // redirected to an error? local file doesnt exist!
      if (r.redirected || r.status == 404) {
        console.log(`POST URL IS NOT MOCKED: ${turl} (looking for local: ${lurl})`)
        return fetch(turl, br);
      }

      return r;
    })
  },
  DELETE: <PayloadType extends {}>(
    url: string,
    payload: PayloadType,
    extraHeaders: {},
    urlTransformer?: (data: any) => string,
  ): Promise<Response> => {
    console.log(`DELETE IS NOT MOCKED`)
    const turl = urlTransformer
    ? urlTransformer(payload)
    : payload && Object.keys(payload).length > 0
    ? `${url}?${createQueryString(payload)}`
    : url;
    const params = buildRequest(methods.DELETE, undefined, extraHeaders)
    console.log(turl)
    console.log(params)
    return fetch(turl, params)
  },
};

const apiRequest = <PayloadType extends {}, ResponseType extends {}>(
  endpoint: Endpoint,
  payload: PayloadType,
  extraHeaders: {} = {},
): Promise<ResponseType> => {
  // @ts-ignore
  return requestMethods[endpoint.method](
    endpoint.url,
    payload,
    extraHeaders,
    endpoint.urlTransformer,
  ).then(async (resp: Response) => {
    const response = (await resp.json()) as ResponseType;
    if (!resp.ok) {
      throw response;
    }

    console.log(resp.url);
    console.log(JSON.stringify(response));

    return response;
  });
};

interface RespWToken {
  token?: string;
}

export default <PayloadType extends {}, SuccessPayload>(
  endpoint: Endpoint,
  payload: PayloadType,
) => (dispatch: Dispatch, getState: () => Store): Promise<SuccessPayload> => {
  let extraHeaders: HeadersInit = {};
  if (endpoint.auth === auth.REQUIRED) {
    const { token } = getState();
    if (!token) {
      return Promise.reject(new AppErr('Token not set for authedFetch'));
    }
    extraHeaders = {
      Authorization: `Bearer ${token || ''}`,
    };
  } else if (endpoint.auth === auth.OPTIONAL) {
    const { token } = getState();
    if (token) {
      extraHeaders = {
        Authorization: `Bearer ${token || ''}`,
      };
    }
  }
  // @ts-ignore
  return apiRequest(endpoint, payload, extraHeaders)
    .then(async (response: unknown) => {
      if ((response as RespWToken).token !== undefined) {
        console.log('token:', response.token)
        dispatch(
          tokenActions.updated((response as RespWToken).token as string),
        );
      }
      return response as SuccessPayload;
    })
    .catch(error => {
      if (error.error == 'Bad Request') {
        if (error.message == 'Authorization expired' || error.message == 'Authorization invalid') {
          dispatch(authActions.logout())
        }
      }
      throw error;
    });
};
