import React, {useCallback, useRef, useEffect} from 'react'
import {VideoPlayer} from './VideoPlayer'

import Lightbox from 'lightbox-react'
import 'lightbox-react/style.css' // This only needs to be imported once in your app

import { makeStyles, createStyles, Theme } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    video: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '100px'
    },
  })
)

const Video = (props) => {
    const classes = useStyles(props)
    return (
        <div className={classes.video}>
            <VideoPlayer {...props} />
        </div>
    )
}

export const Gallery = (props) => {
    const {data, slideIndex, setSlideIndex, open, onClose} = props

    if (!open) {
        return (null)
    }

    const modifiedData = data.map((d, i) => {
        if (d.type == 'video') {
            return <Video url={d.url} playing={slideIndex == i}/>
        }

        return d.url
    })

    return (
        <Lightbox
          mainSrc={modifiedData[slideIndex]}
          nextSrc={modifiedData[(slideIndex + 1) % modifiedData.length]}
          prevSrc={modifiedData[(slideIndex + modifiedData.length - 1) % modifiedData.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={() => {
            setSlideIndex((slideIndex + modifiedData.length - 1) % modifiedData.length)
          }}
          onMoveNextRequest={() => {
            setSlideIndex((slideIndex + 1) % modifiedData.length)
          }}
        />
    )
}