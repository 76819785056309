import React, { FC, useState, useEffect } from 'react';
import View from './Login.view';

const LoginContainer = (props) => {
  return (
    <View
      {...props}
      showModal={'LOGIN'}
    />
  );
};

export default React.memo(LoginContainer);
