import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors, fontFamilies } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      overflowY: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },

    questionsContainer: {
      marginTop: '1em',
      marginBottom: '1em'
    },
    question: {

    },
    answer: {
      color: '#A0AAAD'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    declineButton: {
      border: 'none',
      backgroundColor: 'transparent',
      color: '#ED1F54'
    },
    link: {
      cursor: 'pointer',
    },
    contentContainer: {
      display: 'flex',
      overflow: 'hidden',
      flex: 1,
    },
    featureContainer: {
      //paddingBottom: '50px',
      overflowY: 'auto',
      flex: 1,
    },
    bottomPad: {
      height: '50px'
    },
 
    footerContainer: {
      position: 'relative',
      zIndex: 20,
      padding: '10px 24px',
      borderTop: '1px solid rgba(0,0,0,0.02)',
    },

    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: '10px'
    },

    errorContainer: {
      marginBottom: '10px'
    },
    
    mobileStepper: {
      width: '100%'
    },
    stepLabelContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    stepDone: {
      color: colors.blue,
      flex: 1,
      textAlign: 'center'
    },
    stepTodo: {
      color: 'lightGrey',
      flex: 1,
      textAlign: 'center'
    },

    backButton: {
      cursor: 'pointer',
      marginRight: '16px'
    },
    nextButton: {
      width: '345px'
    }
  }),
);
