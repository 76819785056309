import React from 'react'
import { useStyles } from './ListPlaceholder.styles'
import Typo from '../Typo'

export const ListPlaceholder = (props) => {
    const {icon, title, description} = props
    const classes = useStyles()
    return (
        <div className={classes.container}>
            {icon}
            <Typo variant="h3" color="darkGrey" className={classes.title}>
                {title}
            </Typo>
            <Typo variant="body1" color="lightGrey" className={classes.description}>
                {description}
            </Typo>
        </div>
    )
}