import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { useStyles } from './GhostButton.styles';
import { GhostButtonProps } from './GhostButton.props';

const GhostButtonView: FC<GhostButtonProps> = ({
  text,
  style,
  onClick,
  selected = false,
}: GhostButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      className={selected ? classes.selectedButton : classes.button}
      style={style}
      onClick={onClick}
    >
      <Typography className={classes.typography}>{text}</Typography>
    </Button>
  );
};

export default React.memo(GhostButtonView);
