import { Dispatch } from 'redux';
import { validateDiscountCodeEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

const validateDiscountCode = (
  data,
  success,
  error,
) => (dispatch, getState) => {
  const validateDiscountCodeFetch = apiFetch(
    validateDiscountCodeEndpoint,
    data,
  )(dispatch, getState)
    .then(p => {
      if (success) success(p.data);
      return p.data;
    })
    .catch(err => {
      if (error) error(err);
    });
  return validateDiscountCodeFetch;
};

export default validateDiscountCode;
