import React, {Component} from 'react';
import Main from 'components/layouts/Main';
import LoginModal from 'components/modules/LoginModal';

const Login = (props) => {
    return (
        <Main>
            <LoginModal open={'LOGIN'} hideCloseButton={true} />
        </Main>
    )
}

export default React.memo(Login);
