import React from 'react';
import {
  Avatar,
  Button,
  StarRating,
  TextArea,
  Typo,
  Link,
  HorizontalLine,
  Breadcrumbs,
  SplitView,
} from 'components/primitives';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStyles } from './ReviewBooking.styles';
import { HeaderSection } from '../../../components/modules';
import Main from '../../../components/layouts/Main';

// @ts-ignore
const ReviewList = ({ reviews }) => {
  const theme = useTheme();
  // @ts-ignore
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  return (
    <div style={{ marginLeft: '1em', marginBottom: '1em' }}>
      <Typo variant="h4">Recent Reviews</Typo>
      <div
        style={{
          display: 'flex',
          marginTop: '1em',
          flexDirection: fullScreen ? 'column' : 'row',
        }}
      >
        {reviews && reviews.length ? (
          // @ts-ignore
          reviews.map((review, i) => (
            <div
              style={{
                marginRight:
                  i === reviews.length - 1 || fullScreen ? '0' : '1em',
                flex: 1,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              key={i}
            >
              <Typo variant="body1" component="p" color="lightGrey">
                {`'${review.commentBooking}'`}
              </Typo>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar square={true} size={30} />
                  <Typo variant="body1" className={classes.reviewAuthorName}>
                    {review.user && review.user.firstName} {review.user && review.user.lastName}
                  </Typo>
                </div>
                <StarRating rating={review.rating} />
              </div>
            </div>
          ))
        ) : (
          <div>
            <Typo variant="body1" component="p">
              There are currently no reviews for this venue.
            </Typo>
          </div>
        )}
      </div>
    </div>
  );
};

const ReviewBookingView = ({
  // @ts-ignore
  booking,
  // @ts-ignore
  yourExperience,
  // @ts-ignore
  feedbackForHost,
  // @ts-ignore
  setYourExperience,
  // @ts-ignore
  setFeedbackForHost,
  // @ts-ignore
  submitReview,
  // @ts-ignore
  reviews,
  // @ts-ignore
  submitDisabled,
  // @ts-ignore
  goToListing,
  // @ts-ignore
  goToBookingHistory,
  // @ts-ignore
  breadcrumbs,
  // @ts-ignore
  rating,
  // @ts-ignore
  setRating,
  // @ts-ignore
  showFields,
}) => (
  <Main>
    <Box style={{ paddingTop: '1em' }}>
      <Breadcrumbs elements={breadcrumbs} hideLast />
      <HeaderSection heading="Review your experience" />
      <Box>
        <SplitView
          leftChild={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                src={booking && booking.images && booking.images[0]}
                height="150px"
                style={{
                  objectFit: 'cover',
                  borderRadius: '8px',
                  marginBottom: '1em',
                }}
              />
              <div style={{ marginBottom: '1em' }}>
                <Typo>How was your booking at</Typo>
              </div>
              <div style={{ marginBottom: '1em' }}>
                <Link onClick={goToListing}>
                  <>{booking ? booking.name : ''}?</>
                </Link>
              </div>
              {showFields ? (
                <StarRating
                  size="28px"
                  rating={rating}
                  onChangeRating={setRating}
                />
              ) : null}
            </div>
          }
          rightChild={
            <div>
              {showFields ? (
                <>
                  <TextArea
                    placeholder="Type something"
                    value={yourExperience}
                    label="Tell us about your experience"
                    onChange={setYourExperience}
                  />
                  <div
                    style={{
                      marginTop: '2em',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div style={{ width: '50%' }}>
                      <Button
                        text="Submit Review"
                        disabled={submitDisabled}
                        onClick={submitReview}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: '1em' }}>
                    <Typo>Review submitted</Typo>
                  </div>
                  <div style={{ marginBottom: '1em' }}>
                    <Link onClick={goToBookingHistory}>
                      Return to my bookings history
                    </Link>
                  </div>
                </>
              )}
            </div>
          }
          leftFraction={0.3}
        />
        <>
          <div style={{ height: '10em', paddingTop: '4em' }}>
            <HorizontalLine />
          </div>
          <ReviewList reviews={reviews} />
        </>
      </Box>
    </Box>
  </Main>
);

export default ReviewBookingView;
