import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'BOOKING';
const createBookingActions = makeCreateActions(ns);

const bookingActions = createBookingActions({
  getById: presetActions.makeAsyncAction(`${ns}/GET_BOOKING_BY_ID`),
  addBooking: presetActions.makeAsyncAction(`${ns}/ADD_BOOKING`),
  getByProductId: presetActions.makeAsyncAction(`${ns}/GET_BOOKING_BY_PRODUCT_ID`),
  get: presetActions.makeAsyncAction(`${ns}/GET_BOOKINGS`),
  getProviderBookings: presetActions.makeAsyncAction(`${ns}/GET_PROVIDER_BOOKINGS`),
});

export default bookingActions;
