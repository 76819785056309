import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    messageBoxOther: {
      borderTopRightRadius: '16px',
      borderBottomRightRadius: '16px',
      '&:first-child': {
        borderTopLeftRadius: '16px',
      },
      width: '100%',
      maxWidth: '550px',
      margin: '0.25em',
      // padding: '1em',
      backgroundColor: 'rgb(245, 245, 245)',
      color: 'rgb(150, 160, 163)',
      overflow: 'hidden',
    },
    messageBoxYours: {
      borderTopLeftRadius: '16px',
      borderBottomLeftRadius: '16px',
      '&:first-child': {
        borderTopRightRadius: '16px',
      },
      width: '100%',
      maxWidth: '550px',
      margin: '0.25em',
      // padding: '1em',
      alignSelf: 'flex-end',
      backgroundColor: 'rgb(5, 195, 222)',
      color: 'white',
      overflow: 'hidden',
    }
  }),
);
