import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(10),
    },
    separator: {
      marginTop: theme.spacing(3),
    },
    subTitle: {
      marginTop: theme.spacing(3),
    },
    senderDetails: {
      marginTop: theme.spacing(3),
    },
    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 16,
    },
    selectContainer: {
      marginTop: theme.spacing(3),
    },
    textAreaContainer: {
      marginTop: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      marginTop: theme.spacing(6),
    },
    errorMessage: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
  }),
);
