import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import { useStyles } from './CreateNewListingVenue.styles';
import {Typo, SplitView, InputField, Select, TextArea, Checkbox, AddressInputField, onKeyPressPositiveInteger, onPasteDisable} from 'components/primitives'
import { Box, FormControlLabel } from '@mui/material';

const CreateNewListingVenueView = (
  props,
) => {
  const classes = useStyles(props);
  const {disabled, errorField} = props

  return (
    <div className={classes.container}>
      {props.headerChildren ? props.headerChildren : null}
      <div className={classes.splitViewContainer}>
        <SplitView
          leftChild={
            <div>
              <Typo variant="h4" color="darkGrey" className={classes.heading}>
                Venue Details
              </Typo>
              <InputField
                id="venueName"
                placeholder="Venue Name"
                onChange={event => {
                  props.setVenueName(event.target.value);
                }}
                onBlur={props.setTouchedVenueName}
                value={props.venueName?.value}
                //error={Boolean(props.venueName?.error) && props.venueName?.touched}
                error={errorField == 'venueName'}
                className={classes.field}
                disabled={disabled}
                required
              />
              <AddressInputField
                id="address"
                placeholder="Address"
                onChange={event => {
                  props.setAddress(event); //event.target.value);
                }}
                onBlur={props.setTouchedAddress}
                value={props.address?.value}
                //error={Boolean(props.address?.error) && props.address?.touched}
                error={errorField == 'address'}
                className={classes.field}
                disabled={disabled}
                required
              />
              <InputField
                id="capacityMin"
                placeholder="Minimum Capacity"
                onChange={event => {
                  props.setCapacityMin(event.target.value);
                }}
                onBlur={props.setTouchedCapacityMin}
                value={props.capacityMin?.value}
                //error={Boolean(props.capacityMin?.error) && props.capacityMin?.touched}
                error={errorField == 'capacityMin'}
                className={classes.field}
                disabled={disabled}
                onKeyPress={onKeyPressPositiveInteger}
                //onPaste={onPasteDisable}
                required
              />
              <InputField
                id="capacityMax"
                placeholder="Maximum Capacity"
                onChange={event => {
                  props.setCapacityMax(event.target.value);
                }}
                onBlur={props.setTouchedCapacityMax}
                value={props.capacityMax?.value}
                //error={Boolean(props.capacityMax?.error) && props.capacityMax?.touched}
                error={errorField == 'capacityMax'}
                className={classes.field}
                disabled={disabled}
                onKeyPress={onKeyPressPositiveInteger}
                //onPaste={onPasteDisable}
                required
              />
{/*
              <Select
                label="Capacity"
                id="capacity"
                value={props.capacity}
                onChange={value => props.setCapacity(value)}
                options={[
                  {
                    label: '0-10',
                    value: '0-10',
                  },
                  {
                    label: '10-50',
                    value: '10-50',
                  },
                  {
                    label: '50-100',
                    value: '50-100',
                  },
                ]}
                className={classes.field}
              />
              */}
              <Typo variant="h4" color="darkGrey" className={classes.heading}>
                Select Type
              </Typo>
              <div className={classes.row}>
                <Select
                  id="type"
                  label='Type'
                  value={props.type.value}
                  onChange={value => props.setType(value)}
                  options={props.typeOptions || []}
                  disabled={disabled}
                  error={errorField == 'type'}
                  required
                />
                <Select
                  id="subtype"
                  label='Subtype'
                  value={props.subtype.value}
                  onChange={value => props.setSubtype(value)}
                  options={props.subtypeOptions || []}
                  disabled={disabled}
                  error={errorField == 'subtype'}
                  required
                />
              </div>
              <Typo variant="h4" color="darkGrey" className={classes.heading}>
                Facilities
              </Typo>
              <Box className={classes.checkboxContainer}>
                {props.attributes.map(
                  (facility) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            props.selectedFacilities.value &&
                            props.selectedFacilities.value.includes(facility)
                          }
                          onClick={() => props.onToggleFacility(facility)}
                          disabled={disabled}
                        />
                      }
                      className={classes.capitalize}
                      label={facility}
                      key={facility}
                    />
                  ),
                )}
              </Box>
            </div>
          }
          rightChild={
            <div className={classes.rightChildContainer}>
              <Typo variant="h4" color="darkGrey" className={classes.heading}>
                Description
              </Typo>
              <TextArea
                placeholder="Tell us more about your venue..."
                rows={18}
                value={props.description.value}
                error={Boolean(props.description?.error) && props.description?.touched}
                onChange={event => {
                  props.setDescription(event.target.value);
                }}
                onBlur={props.setTouchedDescription}
                disabled={disabled}
                maximum={500}
              />
            </div>
          }
          leftFraction={props.leftFraction || 0.333}
        />
      </div>
    </div>
  );
};

export default CreateNewListingVenueView;
