import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: 'rgb(243, 245, 245)',
      borderRadius: '5px',
      height: '300px',
      width: '300px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%'
    },
    icon: {
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center'
    }
  }),
);
