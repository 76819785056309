import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(2),
    },
    field: {
      marginBottom: theme.spacing(1.5),
    },
    rightChildContainer: {
      marginLeft: theme.spacing(6),
    },
    selectDate: {
      marginBottom: 20,
    },
    selectTime: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridGap: theme.spacing(1.5)
    },
    spacer: {
      marginTop: theme.spacing(2.5 - 1.5),
      marginBottom: theme.spacing(2.5),
      backgroundColor: 'rgba(162, 170, 173)',
      opacity: 0.1
    },
    button: {
      marginTop: theme.spacing(2.5 - 1.5),
      cursor: 'pointer'
    },
  }),
);
