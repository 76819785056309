import React, { FC, useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { Drawer } from '@mui/material';
import { Modal as ModalComponent } from 'components/primitives';
import { SideBarProps } from './SideBar.props';
import { useStyles } from './SideBar.styles';

const SideBar: FC<SideBarProps> = (props: SideBarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isOpen, setIsOpen] = useState(props.open || false);
  const onClose = (event: any) => {
    setIsOpen(false);
    if (typeof props.onClose === 'function') {
      props.onClose(event);
    }
  };
  useEffect(() => {
    setIsOpen(props.open || false);
  }, [setIsOpen, props.open]);

  return isMobile ? (
    <ModalComponent open={isOpen} onClose={onClose}>
      {props.children}
    </ModalComponent>
  ) : (
    <Drawer
      variant="persistent"
      PaperProps={{ elevation: 10 }}
      classes={{ paper: classes.drawer }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.sidebar} role="presentation">
        {props.children}
      </div>
    </Drawer>
  );
};

export default React.memo(SideBar);
