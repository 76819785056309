import React, { FC } from 'react';
import { Box, Button } from '@mui/material';
import Main from 'components/layouts/Main';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { useStyles } from './EditProfile.styles';
import { EditProfileProps } from './EditProfile.props';
import { HeaderSection, EditProfileDetails, MobileButton } from '../../modules';
import {
  HeaderImage,
  Widget,
  SplitView,
  Button as CustomButton,
  ImageUpload
} from '../../primitives';

const EditProfileView: FC<EditProfileProps> = (props: EditProfileProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {editImages, setEditImages} = props

  return (
    <Main
      footer={
        isMobile ? (
          <MobileButton
            text="Save changes"
            onClick={props.onSave}
            cancelText="Cancel"
            onCancel={props.onCancel}
            disabled={props.disabled}
          />
        ) : (
          <div />
        )
      }
    >
      <Box style={{ marginTop: '1em' }}>
        <HeaderSection onSubheadingClick={props.onSaveChanges} />
        <Box className={classes.splitViewContainer}>
          <SplitView
            leftChild={
              <>
                <div className={classes.imageUploadContainer}>
                    <ImageUpload 
                        onChange={setEditImages} 
                        value={editImages} 
                        maxNumber={1}
                    />
                </div>
              </>
            }
            rightChild={
              <EditProfileDetails
                firstName={props.state.firstName}
                lastName={props.state.lastName}
                email={props.state.email}
                mobile={props.state.mobile}
                postcode={props.state.postcode}
                message={props.message}
                setFirstName={props.setFirstName}
                setLastName={props.setLastName}
                setEmail={props.setEmail}
                setMobile={props.setMobile}
                setPostcode={props.setPostcode}
                setTouchedFirstName={props.setTouchedFirstName}
                setTouchedLastName={props.setTouchedLastName}
                setTouchedEmail={props.setTouchedEmail}
                setTouchedMobile={props.setTouchedMobile}
                setTouchedPostcode={props.setTouchedPostcode}
                errors={props.errors}
              />
            }
          />
        </Box>
        {!isMobile && (
          <Box className={classes.buttonContainer}>
            <Button className={classes.cancelButton} onClick={props.onCancel}>
              Cancel
            </Button>
            <CustomButton
              disabled={props.disabled}
              text="Save changes"
              onClick={props.onSave}
              style={{ width: '25%' }}
            />
          </Box>
        )}
      </Box>
    </Main>
  );
};

export default React.memo(EditProfileView);
