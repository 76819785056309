import React, { FC, useRef, useEffect, useState } from 'react';
import useForm from 'utils/Hooks/useForm';
import { pluck, filter } from 'ramda';
import { phoneRegex, postcodeRegex } from 'consts/validation';
import View from './EditProfile.view';
import { EditProfilePublicProps } from './EditProfile.props';

const EditProfileContainer: FC<EditProfilePublicProps> = (
  props: EditProfilePublicProps,
) => {
  const {user} = props
  const fileInputRef = useRef(null);
  const [reader, setReader] = useState(new FileReader());
  // const [image, setImage] = useState('');

  const {
    state,
    handleOnSubmit,
    handleOnChange,
    disable,
    handleOnBlur,
  } = useForm(
    {
      firstName: { value: user.firstName || '', error: '' },
      lastName: { value: user.lastName || '', error: '' },
      email: { value: user.email || '', error: '' },
      mobile: { value: user.mobile || '', error: '' },
      postcode: { value: user.postcode || '', error: '' },
      image: { value: user.image || '', error: '' },
    },
    {
      firstName: (value?: string) => (!value ? 'First name is required.' : null),
      lastName: (value?: string) => (!value ? 'Last name is required.' : null),
      email: (value?: string) => {
        if (!value) {
          return 'Email Address is required.';
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return 'You must enter a valid email address.';
        }
        return null;
      },
      mobile: (value?: string) => {
        if (!value) return null;
        if (!phoneRegex.test(value))
          return 'A valid Australian mobile number is required.';
        return null;
      },
      postcode: (value?: string) => {
        if (!value) return null;
        if (!postcodeRegex.test(value)) return 'A valid postcode is required.';
        return null;
      },
    },
    (s: any) => {
      props.onSave({
        firstName: s.firstName.value,
        lastName: s.lastName.value,
        email: s.email.value,
        mobile: s.mobile.value,
        postcode: s.postcode.value,
        image: s.image.value,
      });
    },
  );

  const setEditImages = (images) => {
    if (images.length <= 0) {
      handleOnChange('image')('')
    } else {
      handleOnChange('image')(images[0].data_url)
    }
  }

  useEffect(() => {
    const listener = () => {
      // @ts-ignore
      handleOnChange('image')(reader.result);
    };
    reader.addEventListener('load', listener, false);
    return () => {
      reader.removeEventListener('load', listener);
    };
  }, [reader]);
  const onChangePhotoSelect = (event: any) => {
    // @ts-ignore
    if (
      fileInputRef &&
      fileInputRef.current &&
      // @ts-ignore
      fileInputRef.current.files &&
      // @ts-ignore
      fileInputRef.current.files.length &&
      // @ts-ignore
      fileInputRef.current.files[0]
    ) {
      // @ts-ignore
      reader.readAsDataURL(fileInputRef.current.files[0]);
    }
  };

  // @ts-ignore
  const errors: string[] = Object.values(
    pluck<'error', { error?: string; touched?: boolean }>(
      'error',
      filter(s => Boolean(s.touched), state),
    ),
  ).filter(v => Boolean(v));

  const image = state.image.value || props.image

  return (
    <View
      {...props}
      setFirstName={handleOnChange('firstName')}
      setLastName={handleOnChange('lastName')}
      setEmail={handleOnChange('email')}
      setMobile={handleOnChange('mobile')}
      setPostcode={handleOnChange('postcode')}
      setTouchedFirstName={handleOnBlur('firstName')}
      setTouchedLastName={handleOnBlur('lastName')}
      setTouchedEmail={handleOnBlur('email')}
      setTouchedMobile={handleOnBlur('mobile')}
      setTouchedPostcode={handleOnBlur('postcode')}
      state={state}
      disabled={disable}
      onCancel={props.onCancel}
      onSave={handleOnSubmit}
      errors={errors}
      //onChangePhotoSelect={onChangePhotoSelect}
      fileInputRef={fileInputRef}
      image={state.image.value || props.image}
      editImages={image ? [{data_url: image}] : []}
      setEditImages={setEditImages}
    />
  );
};

export default React.memo(EditProfileContainer);
