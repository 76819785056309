import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors, fontFamilies } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    resultsContainer: {
      //backgroundColor: 'rgba(162, 170, 173, 0.1)',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      [theme.breakpoints.down('xl')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
      gridGap: '30px 0px',
    },
    createListingButton: {
      marginTop: '104px',
      marginBottom: '30px'
    },
    button: {
      cursor: 'pointer'
    },
    filterContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
      '& div': {
        flex: 0.2
      }
    }
  }),
);
