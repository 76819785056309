import React, { FC, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Modal as ModalComponent } from 'components/primitives';
import { MobileAppBarMenuProps } from './MobileAppBarMenu.props';

const MobileAppBarMenu: FC<MobileAppBarMenuProps> = (
  props: MobileAppBarMenuProps,
) => {
  const [showModal, setShowModal] = useState(props.open || false);
  const onModalClose = () => {
    setShowModal(false);
    if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };
  useEffect(() => {
    setShowModal(props.open || false);
  }, [setShowModal, props.open]);

  return (
    <ModalComponent open={showModal} onClose={onModalClose}>
      {props.children}
    </ModalComponent>
  );
};

export default React.memo(MobileAppBarMenu);
