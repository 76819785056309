import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(2),
    },
    subHeading: {
      marginBottom: theme.spacing(4),
    },
    button: {
      marginBottom: theme.spacing(1.5),
    },
    errorMessage: {
      marginBottom: theme.spacing(1.5),
    },
    or: {
      textAlign: 'center',
      position: 'relative',
      color: colors.lightGrey,
      height: '20px',
      marginBottom: theme.spacing(1.5),
      '&::before': {
        content: '""',
        background: 'rgba(0,0,0,0.08)',
        height: '1px',
        position: 'absolute',
        left: '0',
        top: '50%',
        width: '45%',
      },
      '&::after': {
        content: '""',
        background: 'rgba(0,0,0,0.08)',
        height: '1px',
        position: 'absolute',
        right: '0',
        top: '50%',
        width: '45%',
      },
    },
    field: {
      marginBottom: theme.spacing(1.5),
    },
    footer: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    link: {
      cursor: 'pointer',
    },
  }),
);
