import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors, fontFamilies } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      gap: '20px',
      paddingTop: '100px',
      paddingBottom: '50px'
    },
    footerButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    button: {
      width: '345px',

      [theme.breakpoints.down('sm')]: {
        flex: 1,
      }
    },
    separator: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      backgroundColor: 'rgba(162, 170, 173)',
      opacity: 0.1
    },
  }),
);
