import React, {useState, useEffect} from 'react'
import {makeStyles, createStyles} from '@mui/styles'
import {moment, weekdays, weekdayAbbrev} from 'utils/moment'
import {FormControlLabel, Switch} from '@mui/material'
import {Typo, Checkbox, Select} from 'components/primitives'
import _ from 'lodash'
import DayPicker, { DateUtils } from 'react-day-picker'

export const useStyles = makeStyles((theme) =>
  createStyles({
    availabilityContainer: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      gap: '30px'
    },
    grayContainer: {
      background: 'rgba(162, 170, 173, 0.1)',
      borderRadius: 5,
      padding: 20,
      marginBottom: 20,
    },
    columnContainer: {
      flex: 1,
    },
    dayContainer: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row'
    },
    dayLabelContainer: {
      width: '70px',
      fontWeight: 'bold'
    },
    timeContainer: {
    },
    heading: {
      marginBottom: theme.spacing(2),
    },
  }),
)

const dayMap = {
  'MO': 'Mon',
  'TU': 'Tues',
  'WE': 'Wed',
  'TH': 'Thu',
  'FR': 'Fri',
  'SA': 'Sat',
  'SU': 'Sun'
}


const Day = (props) => {
    const classes = useStyles(props)
    const {dayName, dayEnabled, startTime, endTime} = props
    const startStr = moment(startTime, 'HH:mm').format('ha')
    const endStr = moment(endTime, 'HH:mm').format('ha')
    return (
        <div className={classes.dayContainer}>
            <Typo variant="body0" className={classes.dayLabelContainer}>
              {dayMap[weekdayAbbrev(dayName)]}
            </Typo>
            
            {dayEnabled &&
              <div className={classes.timeContainer}>
                {startStr} - {endStr}
              </div>
            }
            {!dayEnabled &&
              <div className={classes.timeContainer}>
                Closed
              </div>
            }
        </div>
    )
}

const AvailabilitySummary = (props) => {
    const {value} = props
    const classes = useStyles(props)
    
    const [state, setState] = useState([])
    const [blockedDays, setBlockedDays] = useState([])

    // on external change
    useEffect(() => {
      try {
        // determine the enabled days
        const s = _.cloneDeep(state)
        weekdays.forEach((dayName, idx) => {
          s[dayName] = {dayEnabled: false}
        })

        const {rrules, exdates} = value

        rrules.forEach((rrule, idx) => {    
          const {startTime, endTime, day} = rrule
          const dayName = weekdays.find(dayName => weekdayAbbrev(dayName) == day)
          s[dayName].dayEnabled = true
          s[dayName].startTime = startTime
          s[dayName].endTime = endTime
        })
        setState(s)

        setBlockedDays(exdates.map(dt => moment(dt, 'YYYY-MM-DD').toDate()))
        console.log(value)
      } catch (err) {
        console.warn('AVAILABILITY RRULE ERROR: ', err)
      }
    }, [value])
     

    return (
        <div className={classes.availabilityContainer}>
          <div className={classes.columnContainer}>
            <Typo variant="h4" color="darkGrey" className={classes.heading}>
              Availability
            </Typo>
            <div className={classes.grayContainer}>
              {weekdays.map((dayName, idx) => {
                  return (
                    <Day
                      dayName={dayName} key={idx} 
                      {...state[dayName]}
                    />
                  )
              })}
            </div>
          </div>

          <div className={classes.columnContainer}>
            <Typo variant="h4" color="darkGrey" className={classes.heading}>
                Block days
            </Typo>
            <DayPicker
              selectedDays={blockedDays}
              disabledDays={[
                { before: new Date() }
              ]}
            />
          </div>
        </div>
    )
}

export default AvailabilitySummary