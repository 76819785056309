import { authActions } from 'store/actions';
import { Dispatch } from 'redux';
import { sendVerificationEmailEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';
import {resendVerification} from 'services/cognito'

interface AccountCredentials {
  email: string;
}

interface Signalling {
  finishUpdating?: (data: any) => void;
  updateErrorMessage?: (message: any) => void;
}

const sendVerificationEmail = (
  credentials: AccountCredentials,
  signalling?: Signalling,
) => (dispatch: Dispatch, getState: () => Store) => {

  resendVerification(credentials.email)
    .then(cognitoResponse => {
      if (signalling && signalling.finishUpdating) {
        signalling.finishUpdating(null)
      }
    })
    .catch(err => {
      if (signalling && signalling.updateErrorMessage)
        signalling.updateErrorMessage(err)
    })
  
/*

  const sendVerificationEmailResponse = apiFetch<AccountCredentials, any>(
    sendVerificationEmailEndpoint,
    credentials,
  )(dispatch, getState)
    .then(p => {
      if (signalling && signalling.finishUpdating)
        signalling.finishUpdating(p.data);
      return p.data;
    })
    .catch(err => {
      if (signalling && signalling.updateErrorMessage)
        signalling.updateErrorMessage(err);
    });
  dispatch(authActions.sendVerificationEmail(sendVerificationEmailResponse));
  return sendVerificationEmailResponse;
  */
};

export default sendVerificationEmail;
