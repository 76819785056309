import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {ROUTES} from 'routes';
import ManageListingsView from './ManageListings.view';
import productThunks from 'thunks/product';
import { pathOr, symmetricDifference } from 'ramda';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import routerThunks from 'thunks/router'
import {useInfiniteScroll} from 'components/modules'

const formatProducts = (entity) => {
  // @TODO remove default value once data has been corrected
  // const address: any = entity.metadata.location;
  const host =
    entity &&
    entity &&
    entity.host &&
    entity.host.length
      ? entity.host[0]
      : null;

  const images = pathOr([pathOr(null, ['heroImage'], entity)], ['images'], entity).filter(i => i)
  return {
    id: entity.ID,
    type: entity.type,
    capacity: entity.capacity,
    title: entity.title,
    rating: entity.rating || 0,
    price: pathOr(0, ['price'], entity),
    weekdayPrice: pathOr(
      0,
      ['pricing', 'weekdays'],
      entity,
    ),
    weekendPrice: pathOr(
      0,
      ['pricing', 'weekends'],
      entity,
    ),
    favourite: entity.isFavourite || false,
    address: pathOr(0, ['location', 'address'], entity),
    latitude: pathOr(0, ['location', 'latitude'], entity),
    longitude: pathOr(0, ['location', 'longitude'], entity),
    //image: pathOr('', ['metadata', 'heroImage'], entity),
    //images: pathOr([], ['metadata', 'images'], entity),
    images,
    hostedBy: pathOr('', ['first_name'], host),
    isLocked: entity.isLocked
  };
};

const ManageListingsContainer = (props) => {
  const dispatch = useDispatch()

  const fetchMoreFn = (lastKey, resolve, reject) => {
    dispatch(
      productThunks.getMyProducts({
          startKey: lastKey
        },
        (response) => {
          const products = response.data.products.sort((a, b) => {
            return a.title.localeCompare(b.title)
          })
          resolve(products, response.lastKey)
        },
        (error) => {
          reject(error)
        },
      ),
    )
  }
  const {data: products, setData: setProducts, fetchMore, hasMore, loading} = useInfiniteScroll(fetchMoreFn)
  const data = (Array.isArray(products) ? products : []).map(formatProducts);
  
  const onViewListing = (id) => (event) => {
    dispatch(routerThunks.link(ROUTES.LISTING_DETAILS, { id }));
  }

  const onViewRequest = (id) => () => {
    dispatch(routerThunks.link(ROUTES.REQUESTED_BOOKINGS, { id }));
  }

  const onEditListing = (id) => () => {
    console.log('edit listing!')
    dispatch(routerThunks.link(ROUTES.EDIT_LISTING, { id }));
  }

  const onLockPress = (id) => (event) => {
    console.log('TOGGLE LOCK!', id)
    dispatch(
      productThunks.getById(
        {
          // @ts-ignore
          productId: id,
        },
        (response) => {
          const product = response.data
          product.isLocked = !product.isLocked

          dispatch(
            productThunks.add(
              product,
              (response) => {
                // https://stackoverflow.com/questions/61096061/why-do-react-hooks-reference-old-data-in-event-handlers
                setProducts((prev) => {
                  const productIdx = prev.findIndex(p => p.ID == product.ID)
                  const patchedProducts = [...prev]
                  patchedProducts[productIdx] = product
                  return patchedProducts
                })
              },
              (error) => {
                console.error(error)
              },
            ),
          );
        },
        (error) => {
          console.error(error)
        },
      ),
    );

    event.stopPropagation()
  }

  return (
    <ManageListingsView
      {...props}
      isLoading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      results={data}
      onViewListing={onViewListing}
      onCreateNewListing={() => dispatch(routerThunks.link(ROUTES.CREATE_LISTING))}
      onViewRequest={onViewRequest}
      onLockPress={onLockPress}
      onEditListing={onEditListing}
    />
  );
};

export default React.memo(ManageListingsContainer);
