import { makeStyles, createStyles, Theme } from '@mui/styles';
import { colors, fontFamilies } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    questionsContainer: {
      marginTop: '1em',
      marginBottom: '1em'
    },
    question: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    answer: {
      color: '#A0AAAD',
      marginBottom: theme.spacing(5),
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(8),
    },
    declineButton: {
      border: 'none',
      backgroundColor: 'transparent',
      color: '#ED1F54'
    },
    link: {
      cursor: 'pointer',
    },
    rightChildContainer: {
      marginLeft: theme.spacing(6),
    },
    heading: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(3),
    },
  }),
);
