import { handleActions } from 'redux-actions';
import { asyncData, createAsyncReducers, presetReducers } from 'utils/Redux';
import { EntityState } from 'types/store/EntityState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';
import { ExampleListing } from '../../types/ExampleListing';

const DEFAULT_STATE = asyncData(ASYNC_STATUS.INITIAL);


const reducer = handleActions(
  {
    ...createAsyncReducers('ENTITY/ENTITY'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
