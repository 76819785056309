import React, { FC } from 'react';
import { Box, Container, FormControlLabel, FormGroup } from '@mui/material';
import { TypesFilterMobileProps } from './TypesFilterMobile.props';
import { Typo, Tile, Checkbox } from '../../primitives';

import MobileButton from '../MobileButton';
import { useStyles } from './TypesFilterMobile.styles';

const TypesFilterMobileView: FC<TypesFilterMobileProps> = props => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.container}>
        <div className={classes.headerContainer}>
          <Typo variant="h2">Type</Typo>
          <button className={classes.clearButton} onClick={props.reset}>
            <Typo variant="body2" color="lightGrey">
              Clear
            </Typo>
          </button>
        </div>
        <FormGroup>
          <Box className={classes.controlLabel} borderBottom={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.activeType.includes('corporate')}
                  onClick={() => props.onToggleType('corporate')}
                />
              }
              label={
                <Typo
                  style={{
                    color: 'rgb(5, 195, 222)',
                  }}
                  variant="body0"
                >
                  Corporate
                </Typo>
              }
            />
          </Box>
          <Box className={classes.controlLabel} borderBottom={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.activeType.includes('creative')}
                  onClick={() => props.onToggleType('creative')}
                />
              }
              label={
                <Typo style={{ color: '#dc4d5e' }} variant="body0">
                  Creative
                </Typo>
              }
            />
          </Box>
          <Box className={classes.controlLabel} borderBottom={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.activeType.includes('functions')}
                  onClick={() => props.onToggleType('functions')}
                />
              }
              label={
                <Typo style={{ color: '#3f6abd' }} variant="body0">
                  Functions
                </Typo>
              }
            />
          </Box>
          <Box className={classes.controlLabel} borderBottom={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.activeType.includes('sports')}
                  onClick={() => props.onToggleType('sports')}
                />
              }
              label={
                <Typo style={{ color: '#f9af34' }} variant="body0">
                  Sports
                </Typo>
              }
            />
          </Box>
          <Box className={classes.controlLabel} borderBottom={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.activeType.includes('outdoor')}
                  onClick={() => props.onToggleType('outdoor')}
                />
              }
              label={
                <Typo style={{ color: '#4caca7' }} variant="body0">
                  Outdoor
                </Typo>
              }
            />
          </Box>
          <Box className={classes.controlLabel} borderBottom={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.activeType.includes('other')}
                  onClick={() => props.onToggleType('other')}
                />
              }
              label={
                <Typo style={{ color: '#f39e8c' }} variant="body0">
                  Other
                </Typo>
              }
            />{' '}
          </Box>
        </FormGroup>
      </Box>
      <Box
        style={{
          display: 'flex',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <MobileButton text="Confirm" onClick={props.onSubmit} />
      </Box>
    </Box>
  );
};

export default React.memo(TypesFilterMobileView);
