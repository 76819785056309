import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'ENTITY_DETAILS';
const createContactActions = makeCreateActions(ns);

const productDetailsActions = createContactActions({
  getById: presetActions.makeAsyncAction(`${ns}/GET_ENTITY_DETAILS_BY_ID`),
});

export default productDetailsActions;
