import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import pathOr from 'ramda/es/pathOr';
import productThunks from 'thunks/product';
import userThunk from 'thunks/user';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { asyncData } from 'utils/Redux';
import View from './MySavedListings.view';
import routerThunks from 'thunks/router'
import {ROUTES} from '../..';

const userFallback = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const Container = (props) => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: user } = useSelectorSafe(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }

  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  /*
  const entityState = useSelectorSafe(
    state => state.product,
    asyncData(ASYNC_STATUS.INITIAL),
  );
/*
  const formatProducts = (product: any) => {
    const location: any = product.location;
    return {
      id: product.ID,
      title: product.title,
      address: location.address,
      image: pathOr('', ['metadata', 'heroImage'], product),
      host: product.provider.ID,
    };
  };
*/

  const favourites = pathOr(
    [],
    ['data', 'data', 'favourites'],
    useSelectorSafe(state => state.favourites),
  );

  const favouritesData = favourites.map(f => f.prod_ID);
  const modifiedProducts = products.map((e) => ({
      ...e,
      isFavourite: favouritesData.includes(e.ID),
    }))
    .filter(v => v.isFavourite)
    //.map(formatProducts);

  const removeBooking = (id) => {
    dispatch(userThunk.removeFavourite({ productId: id }));
  };
  const handleViewListing = (id) => {
    dispatch(routerThunks.link(ROUTES.LISTING_DETAILS, { id }));
  };
  const handleContactHostClick = (id, provider) => {
    dispatch(routerThunks.link(ROUTES.MESSAGES, { provider }));
  };
  const handleMyBookingsClick = () => {
    dispatch(routerThunks.link(ROUTES.MY_BOOKINGS));
  };

  useEffect(() => {
    if (user) {
      dispatch(userThunk.getFavourites());
      dispatch(userThunk.getFavouriteProducts(
        (response) => {
          setLoading(false)
          setProducts(response.data.products)
        },
        (error) => {
          setLoading(false)
        }
      ));
    }
  }, [user]);
  return (
    <View
      {...props}
      onViewListingClick={handleViewListing}
      onContactHostClick={handleContactHostClick}
      onMyBookingsClick={handleMyBookingsClick}
      products={modifiedProducts}
      loading={loading}
      removeBooking={removeBooking}
    />
  );
};

export default React.memo(Container);
