import { handleActions } from 'redux-actions';
import { asyncData, createAsyncReducers, presetReducers } from 'utils/Redux';
import { FilterOptionsState } from 'types/store/FilterOptionsState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions, productActions } from '../actions';

const DEFAULT_STATE: FilterOptionsState = asyncData(ASYNC_STATUS.INITIAL);


const reducer = handleActions<FilterOptionsState, any>(
  {
    ...createAsyncReducers('ENTITY/GET_ENTITY_FILTERS'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
