import React, { FC } from 'react';
import { Main } from 'components/layouts';
import { UserIcon, EmailIcon } from 'assets';
import PhoneIcon from '@mui/icons-material/Smartphone';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import { colors } from 'theme/themeConstants';
import { useStyles } from './ContactUs.styles';
import { ContactUsProps } from './ContactUs.props';
import {
  Typo,
  HorizontalLine,
  Select,
  TextArea,
  Button,
  InputField,
} from '../../../components/primitives';
import { MobileButton } from '../../../components/modules';

const ContactUsView: FC<ContactUsProps> = ({
  user,
  submitMessage,
  name,
  setName,
  setTouchedName,
  email,
  setEmail,
  setTouchedEmail,
  contactNumber,
  setContactNumber,
  setTouchedContactNumber,
  type,
  setType,
  setTouchedType,
  message,
  setMessage,
  setTouchedMessage,
  disabled,
  errors,
  msg,
}: ContactUsProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoggedIn = user;
  return (
    <Main
      footer={
        isMobile ? (
          <MobileButton
            disabled={disabled}
            text="Send message"
            onClick={submitMessage}
          />
        ) : (
          <div />
        )
      }
    >
      <Typo className={classes.title} variant="h2">
        Contact Us
      </Typo>
      <Typo variant="body2" className={classes.subTitle} color="lightGrey">
        Fill out the form below with your information
      </Typo>
      {!isLoggedIn && (
        <Box className={classes.senderDetails}>
          <InputField
            id="name"
            icon={() => <UserIcon />}
            placeholder="Name"
            onChange={event => {
              setName(event.target.value);
            }}
            onBlur={setTouchedName}
            value={name.value}
            error={Boolean(name.error) && name.touched}
          />
          <Box className={classes.inputRow}>
            <InputField
              id="email"
              icon={() => <EmailIcon />}
              placeholder="Email"
              onChange={event => {
                setEmail(event.target.value);
              }}
              onBlur={setTouchedEmail}
              value={email.value}
              error={Boolean(email.error) && email.touched}
            />
            <InputField
              id="phone"
              icon={() => <PhoneIcon />}
              placeholder="Contact Number"
              style={{ marginLeft: 16 }}
              onChange={event => {
                setContactNumber(event.target.value);
              }}
              onBlur={setTouchedContactNumber}
              value={contactNumber.value}
              error={Boolean(contactNumber.error) && contactNumber.touched}
            />
          </Box>
        </Box>
      )}
      <HorizontalLine className={classes.separator} />
      <Box
        border={1}
        borderColor={type.error ? colors.red : 'rgb(245, 245, 245)'}
        className={classes.selectContainer}
      >
        <Select
          id="messageType"
          value={type.value}
          onChange={value => setType(value)}
          options={[
            {
              label: 'Enquiry',
              value: 'enquiry',
            },
            {
              label: 'Issue',
              value: 'issue',
            },
          ]}
        />
      </Box>
      <Box className={classes.textAreaContainer}>
        <TextArea
          rows={isMobile ? 11 : 25}
          rowsMax={isMobile ? 11 : 25}
          placeholder="Tell us what you think..."
          value={message.value}
          error={Boolean(message.error)}
          onChange={event => setMessage(event.target.value)}
        />
      </Box>
      {errors.length ? (
        <div className={classes.errorMessage}>
          {errors.map(errMsg => (
            <Typo variant="body1" color="red" key={errMsg}>
              {errMsg}
            </Typo>
          ))}
        </div>
      ) : null}
      {Boolean(msg) && (
        <div className={classes.errorMessage}>
          <Typo variant="body1" color="green">
            {msg}
          </Typo>
        </div>
      )}
      {!isMobile && (
        <Box className={classes.buttonContainer}>
          <Button
            disabled={disabled}
            text="Send message"
            style={{ width: '50%' }}
            onClick={submitMessage}
          />
        </Box>
      )}
    </Main>
  );
};

export default React.memo(ContactUsView);
