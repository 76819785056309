import React, {useState, useEffect} from 'react'
import Link from './Link'
import Typo from './Typo'
import { makeStyles, createStyles, useTheme } from '@mui/styles'
import useDimensions from "react-cool-dimensions"
import useMediaQuery from '@mui/material/useMediaQuery'

const useStyles = makeStyles((theme) =>
  createStyles({
    parent: {
      overflow: 'hidden',
      position: 'relative'
    },
    bottomFade: {
        width: '100%',
        height: '50px',
        bottom: '0',
        background: 'rgb(255,255,255)',
        background: 'linear-gradient(180deg, rgba(2255,255,255,0) 0%, rgba(255,255,255,1) 80%);',
        position: 'absolute' 
    },
    button: {
      cursor: 'pointer'
    },
  })
)

export const CollapseView = (props) => {
  const {children, className} = props
  const minSize = props.minSize || 200
  const classes = useStyles(props)
  const [expanded, setExpanded] = useState(false)
  const [needsExpanding, setNeedsExpanding] = useState(false)
  const parentHeight = (!needsExpanding || expanded) ? 'auto' : minSize

  const theme = useTheme()
  //const sm = theme.breakpoints.values.sm //.down('sm')
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const { observe, unobserve, width, height, entry } = useDimensions({
    //breakpoints: { sm: 0, lg: sm },
    onResize: ({ observe, unobserve, width, height, entry, currentBreakpoint }) => {
      // Triggered whenever the size of the target is changed...
      setNeedsExpanding(height > minSize && isSm)
      unobserve() // To stop observing the current target element
      observe() // To re-start observing the current target element
    },
  })

  return (
    <div className={className}>
      <div className={classes.parent} style={{height: parentHeight}}>
        <div style={{height:'auto'}} ref={observe}>
          {children}
        </div>
        {(!expanded && needsExpanding) &&
          <div className={classes.bottomFade}>
            &nbsp;
          </div>
        }
      </div>

      {needsExpanding &&
        <Typo variant="body1" color="blue" component="a" onClick={() => setExpanded(!expanded)} className={classes.button}>
          {expanded ? 'Show less' : 'Show more'}
        </Typo>
      }
    </div>
  )
}