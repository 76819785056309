import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: 600,
      padding: theme.spacing(4),
      marginBottom: theme.spacing(12),
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    clearButton: {
      backgroundColor: 'transparent',
      border: 'none',
    },
  }),
);
