import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import authThunks from 'thunks/auth';
import { UserState } from 'types/store/UserState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { asyncData } from 'utils/Redux';
import routerThunks from 'thunks/router'
import {ROUTES} from 'routes';
import { LoginFormPublicProps } from './LoginForm.props';
import View from './LoginForm.view';

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const FieldContainer: FC<LoginFormPublicProps> = (
  props: LoginFormPublicProps,
) => {
  /*
  const { status } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );*/

  const dispatch = useDispatch();
  const [busy, setBusy] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState('');

  /*
  useEffect(() => {
    if (status === 'COMPLETE' && props.onClose) {
      props.onClose();
    }
  }, [status]);
  */

  return (
    <View
      {...props}
      busy={busy}
      email={email}
      password={password}
      onEmailChange={event => setEmail(event.target.value)}
      onPasswordChange={event => setPassword(event.target.value)}
      error={
        (status === 'ERROR' && isDirty)
          ? 'Invalid credentials, please try again.'
          : (error?.message || error)
      }
      onLogin={() => {
        setIsDirty(true);
        setBusy(true)
        dispatch(
          authThunks.login({
            email,
            password,
          },
          (success) => {
            if (props.onClose) {
              props.onClose()
            }
          },
          (error) => {
            if (error.code == "UserNotConfirmedException") {
              if (props.openModal) {
                props.openModal('CHECK_EMAIL', {
                  email,
                  password,
                  action: 'verify-email',
                })();
              }
            } else {
              if (error.message) {
                error.message = error.message.replaceAll('Username', 'Email Address')
              }
              setError(error)
            }
            setBusy(false)
          }),
        );
      }}
    />
  );
};

export default React.memo(FieldContainer);
