import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import routerThunks from 'thunks/router'
import uuid from 'uuid/v4';
import {ROUTES, routeAndPortalFromLocation, PORTALS, NOT_FOUND} from '../..';
import View from './Messages.view';
import messagingThunks from '../../../thunks/messaging';
import { UserState } from '../../../types/store/UserState';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles';

const REFRESH_TIMEOUT = 10000

const getThreads = (dispatch: any, portalName: string) => {
  const thunk = (portalName == PORTALS.HOST) ? messagingThunks.getProviderThreads : messagingThunks.getUserThreads
  return new Promise((resolve, reject) => {
    dispatch(
      thunk(
        (response: any) => {
          resolve(response);
        },
        (error: string) => {
          reject(error);
        },
      ),
    );
  });
}

const getThreadMessages = (dispatch: any, data) =>
  new Promise((resolve, reject) => {
    dispatch(
      messagingThunks.getThreadMessages(
        data,
        (response: any) => {
          resolve(response);
        },
        (error: string) => {
          reject(error);
        },
      ),
    );
  });

  /*
const readThread = (dispatch: any, data) =>
  new Promise((resolve, reject) => {
    dispatch(
      messagingThunks.readThreadById(data,
        (response: any) => {
          resolve(response);
        },
        (error: string) => {
          reject(error);
        },
      ),
    );
  });
*/

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const Container = (props: any) => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { user: fromUser, provider: fromProvider } = useSelectorSafe<UserState>(
    state => {
      return state.user.data.data
    },
    userFallback,
  );
  if (!fromUser) {
    goToListings();
  }

  const location = useSelectorSafe(
    state => state.location.type,
    NOT_FOUND,
  );
  const [routeName, portalName, portal] = routeAndPortalFromLocation(location)


  const airplacerThread = {
    excerpt: 'Welcome to Airplacer',
    isAirplacerThread: true,
    ID: 'airplacer-thread',
    provider: {
      name: 'Airplacer',
      image: ''
    },
    user: {
      firstName: 'Airplacer',
      lastName: '',
      image: ''
    }
  }

  const airplacerMessage = {
    [portal == PORTALS.CUSTOMER ? 'prov_ID' : 'user_ID']: 'airplacer',
    data: {
      type: 'intro',
      title: 'Welcome to Airplacer!',
      buttonText: 'Go to listings',
      onButtonClick: () => goToListings(),
    }
  }

  const [threads, setThreads] = useState([airplacerThread]);
  // @ts-ignore
  const toProvider = useSelectorSafe(state => state.location.payload.provider)
  const toBooking = useSelectorSafe(state => state.location.payload.booking)
  const toUser = useSelectorSafe(state => state.location.payload.user)
  const [messages, setMessages] = useState(null);
  const [selectedThread, setSelectedThread] = useState(null);
  const bottomRefWeb = useRef(null);
  const bottomRefMobile = useRef(null);
  const [loading, setLoading] = useState(true);
  const [threadLoading, setThreadLoading] = useState(false);

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileRef = useRef(isMobile)
  isMobileRef.current = isMobile

  const refreshThreadMessages = (thread) => {
    if (thread && thread.ID) {
      getThreadMessages(dispatch, {
          thre_ID: thread.ID,
          sender_ID: (portalName == PORTALS.HOST) ? thread.provider.ID : thread.user.ID,
        }).then(
        (response) => {
          if (response && response.messages) {
            setMessages(response.messages);
          }
          setThreadLoading(false)
        },
        (error) => {
          console.log('_ERROR_', error);
          setThreadLoading(false)
        },
      )
    }
  }

  const onThreadSelect = (thread: any) => {
    setSelectedThread(thread)
    setMessages([])
    if (thread && thread.isAirplacerThread) {
      setMessages([airplacerMessage])
      return
    }

    if (thread && thread.ID) {
      setThreadLoading(true)
      refreshThreadMessages(thread)
    }
  }
  
  const refreshThreads = () => {
    return getThreads(dispatch, portalName)
      .then((response: any) => {
        let threads = response.threads || []
        threads.push(airplacerThread)
 
        let threadToSelect = null

        // are we trying to message a particular user?
        if (portalName === PORTALS.HOST) {
          // try to default to the first thread with unread messages
          threadToSelect = threads.find(t => t.provUnreadMessages > 0)

          if (toUser && toUser.ID) {
            threadToSelect = response.threads.find(
              (currentThread: any) => currentThread.user_ID === toUser.ID,
            )

            if (!threadToSelect) {
              // create new thread!
              const newThread = {
                isStarting: true,
                ID: 'starting-thread',
                provider: fromProvider,
                user: toUser
              }
              threads.push(newThread)
              threadToSelect = newThread
            }
          }
        }

        // we are trying to message a particular provider?
        if (portalName === PORTALS.CUSTOMER) {
          // try to default to the first thread with unread messages
          threadToSelect = threads.find(t => t.userUnreadMessages > 0)

          if (toProvider && toProvider.ID) {
            threadToSelect = response.threads.find(
              (currentThread: any) => currentThread.prov_ID === toProvider.ID,
            )

            if (!threadToSelect) {
              // create new thread!
              const newThread = {
                isStarting: true,
                ID: 'starting-thread',
                provider: toProvider,
                user: fromUser
              }
              threads.push(newThread)
              threadToSelect = newThread
            }
          }
        }

        // still no threads to select? grab the first! (if not mobile view)
        if (!isMobileRef.current) {
          threadToSelect = threadToSelect || threads[0]
        }

        setThreads(threads)

        // only select the thread the first time we refresh/load the threads
        if (!selectedThread) {
          onThreadSelect(threadToSelect)
        }
        setLoading(false)
        return response
      })
      .catch((error: any) => {
        console.log('_ERROR_', error)
        throw error
      })
  }

  const sendDataToCurrentThread = (data) => {
    if (!selectedThread || selectedThread.isAirplacerThread) {
      return
    }

    dispatch(
      messagingThunks.sendMessage(
        {
          sender_ID: (portalName == PORTALS.HOST) ? selectedThread.provider.ID : selectedThread.user.ID,
          user_ID: selectedThread.user.ID,
          prov_ID: selectedThread.provider.ID,
          data
        },
        (messageResponse: any) => {
          getThreadMessages(dispatch, {
              thre_ID: messageResponse.thre_ID,
              sender_ID: (portalName == PORTALS.HOST) ? selectedThread.provider.ID : selectedThread.user.ID,
            })
            .then((threadResponse: any) => {
              // in the case the thread ID changes, which will occur when the first message is sent to a new user/provider
              // we refresh the threads ASAP, then modify the selected thread
              if (selectedThread.ID != messageResponse.thre_ID) {
                refreshThreads().then(response => {
                  let threadToSelect = response.threads.find(
                    (currentThread: any) => currentThread.ID === messageResponse.thre_ID,
                  )
                  setSelectedThread(threadToSelect)
                  setMessages(threadResponse.messages)
                })
              }
              setMessages(threadResponse.messages)
            })
            .catch((error: any) => {
              console.log('_ERROR_', error);
            });
        },
        (error: string) => {
          console.log('_ERROR_', error);
        },
      ),
    );
  };

  const sendMessageToCurrentThread = (message: any) => {
    console.log('send message:', message)
    sendDataToCurrentThread({
      type: 'text',
      value: message,
    })
  }

  const scrollToBottom = () => {
    if (bottomRefWeb && bottomRefWeb.current) {
      // @ts-ignore
      bottomRefWeb.current.scrollIntoView({ behaviour: 'smooth' });
    }
    if (bottomRefMobile && bottomRefMobile.current) {
      // @ts-ignore
      bottomRefMobile.current.scrollIntoView({ behaviour: 'smooth' });
    }
  }

  const onMentionPress = (booking) => {
    sendDataToCurrentThread({
      type: 'booking',
      ...booking
    })
  }

  const onBookingClick = (id) => {
    dispatch(routerThunks.link(ROUTES.VIEW_BOOKING_DETAILS, { id }));
  }

  // setup a timer to call refresh every 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      refreshThreads()
      if (selectedThread) {
        refreshThreadMessages(selectedThread)
      }
    }, REFRESH_TIMEOUT)
    return () => clearTimeout(timer)
  })
  // refresh on load
  useEffect(() => {
    refreshThreads()
  }, [])

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <View
      threads={threads}
      thread={selectedThread}
      messages={messages}
      bottomRefWeb={bottomRefWeb}
      bottomRefMobile={bottomRefMobile}
      onThreadSelect={onThreadSelect}
      sendMessage={sendMessageToCurrentThread}
      loading={loading}
      threadLoading={threadLoading}
      portalName={portalName}
      onMentionPress={onMentionPress}
      onBookingClick={onBookingClick}
    />
  );
};

export default React.memo(Container);
