import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(4),
      marginBottom: theme.spacing(12),
    },
    controlLabel: {
      paddingTop: 16,
      paddingBottom: 16,
      borderBottomColor: 'rgb(245, 245, 245)',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(4),
    },
    clearButton: {
      backgroundColor: 'transparent',
      border: 'none',
    },
  }),
);
