import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      //marginTop: '80px',
      //paddingBottom: '85px'
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: '10px'
    },

    errorContainer: {
      marginBottom: '10px'
    },
    
    mobileStepper: {
      width: '100%'
    },
    stepLabelContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    stepDone: {
      color: colors.blue,
      flex: 1,
      textAlign: 'center'
    },
    stepTodo: {
      color: 'lightGrey',
      flex: 1,
      textAlign: 'center'
    },

    backButton: {
      cursor: 'pointer',
      marginRight: '16px'
    },
    nextButton: {
      width: '345px'
    }
  }),
);
