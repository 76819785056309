import get from './get';
import getById from './getById';
import getFilterOptions from './getFilterOptions';
import add from './add';
import getMyProducts from './getMyProducts';

const productThunks = {
  get,
  getById,
  getFilterOptions,
  add,
  getMyProducts
};

export default productThunks;
