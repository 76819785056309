import React, { FunctionComponent, useState, useEffect } from 'react';
import Hidden from '@mui/material/Hidden';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { AirplacerIcon, CloseIcon } from 'assets';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { useStyles } from './Modal.styles';
import { ModalProps } from './Modal.props';
// import MenuIcon from "@mui/material/SvgIcon/SvgIcon";

const ModalView: FunctionComponent<ModalProps> = props => {
  const [open, setOpen] = useState(props.open);
  const classes = useStyles(props);
  const theme = useTheme();
  // @ts-ignore
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeModal = () => {
    if (typeof props.onClose === 'function') {
      props.onClose(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [setOpen, props.open]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      classes={{ paper: classes.paper }}
    >
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Hidden only={['md', 'lg', 'xl']}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={props.goToHomeRoute}
            >
              <AirplacerIcon />
            </IconButton>
          </Hidden>
          <div className={classes.spacer} />
          {!props.hideCloseButton && <CloseIcon className={classes.closeIconSmall} onClick={closeModal} />}
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.contentContainer}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ModalView);
