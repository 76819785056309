import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    hostedByContainer: {
      background: 'rgba(162, 170, 173, 0.1)',
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'inherit',
      outline: 'inherit',
      font: 'inherit',
      padding: 0,
      border: 'none',
      width: '100%',
    },
  }),
);
