import { Dispatch } from 'redux';
import { getUserThreadsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

interface Data {}

const getUserThreads = (
  onSuccess?: (response: any) => void,
  onError?: (error: string) => void,
) => (dispatch: Dispatch, getState: () => Store) => {
  const getThreadsFetch = apiFetch<Data, any>(getUserThreadsEndpoint, {})(
    dispatch,
    getState,
  )
    .then(response => {
      if (response && onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    })
    .catch(error => {
      if (error && onError) {
        onError(error);
      }
      return error;
    });
  return getThreadsFetch;
};

export default getUserThreads;
