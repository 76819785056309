import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {ROUTES} from 'routes';
import parse from 'date-fns/parse';
import { differenceInDays } from 'date-fns';
import View from './BookingDetails.view';
import { SplitView, TextArea, Select, Typo } from '../../../components/primitives';
import { ButtonGroup } from '../../../components/modules';
import { bookingDetailsActions } from '../../../store/actions';
import routerThunks from 'thunks/router'
import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import { UserState } from '../../../types/store/UserState';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
const nextDay = new Date(tomorrow);
nextDay.setDate(nextDay.getDate() + 1);

// @ts-ignore
const LongText = ({ options, question, value, onChange }) => (
  <div style={{ marginTop: '1em' }}>
    <TextArea
      placeholder={(options && options.placeholder) || 'Please type your response here'}
      value={value}
      label={question}
      onChange={onChange}
    />
  </div>
);

// @ts-ignore
const YesNo = ({ question, onSelect, selectedIndex }) => (
  <div style={{ marginTop: '1em' }}>
    <SplitView
      // @ts-ignore
      leftChild={
        <ButtonGroup
          label={question}
          onSelect={onSelect}
          selectedIndex={selectedIndex}
        />
      }
      // @ts-ignore
      rightChild={null}
      leftFraction={0.5}
    />
  </div>
);

// @ts-ignore
const DropDown = ({ id, question, options, onSelect, value }) => (
  <div style={{ marginTop: '1em' }}>
    <Typo variant="body1">{question}</Typo>
    <div
      style={{
        border: '1px solid rgb(245, 245, 245)',
        width: 'calc(50% - 0.75em)',
      }}
    >
      <Select id={id} value={value} onChange={onSelect} options={options} />
    </div>
  </div>
);

const componentTypes = {
  yesNo: YesNo,
  longText: LongText,
  dropdown: DropDown,
};

// @ts-ignore
const toComponent = (question, answer, replaceIndex, index) => {
  // @ts-ignore
  const Component =
    // @ts-ignore
    componentTypes[question.type] ? componentTypes[question.type] : null;
  const additionalProps = {};
  // @ts-ignore
  if (question.type === 'yesNo') {
    // @ts-ignore
    additionalProps.onSelect = (selected: any) => replaceIndex(selected, index);
    // @ts-ignore
    additionalProps.selectedIndex = (answer || {}).index;
    if (question.options) {
      // @ts-ignore
      additionalProps.options = question.options;
    }
  } else if (question.type === 'longText') {
    // @ts-ignore
    additionalProps.value = (answer || {}).value;
    // @ts-ignore
    additionalProps.onChange = (event: any) =>
      replaceIndex({ value: event.target.value }, index);
    if (question.options) {
      // @ts-ignore
      additionalProps.options = question.options;
    }
  } else if (question.type === 'dropdown') {
    if (question.options) {
      // @ts-ignore
      additionalProps.options = question.options.choices;
      // @ts-ignore
      additionalProps.onSelect = selected =>
        replaceIndex({ value: selected }, index);
      // @ts-ignore
      additionalProps.id = question.options.id;
    }
    // @ts-ignore
    additionalProps.value = (answer || {}).value || '';
  }
  return Component ? (
    <Component question={question.question} {...additionalProps} key={index} />
  ) : null;
};

/*
const bookingQuestionsApi = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: '0',
          type: 'dropdown',
          options: {
            id: 'reason',
            choices: [
              {
                label: 'Lecture',
                value: 'lecture',
              },
              {
                label: 'Meeting',
                value: 'meeting',
              },
            ],
          },
          question: 'Why do you require the room?',
        },
        {
          id: '2',
          type: 'yesNo',
          question: 'Is this a recurring booking?',
        },
        {
          id: '3',
          type: 'longText',
          options: {
            placeholder: `Please type your response here`,
          },
          question: `Is there any additional information required?`,
        },
      ]);
    }, 250);
  });
*/

const getTimePeriod = (
  startDate: string,
  endDate: string,
  startTime: any,
  endTime: any,
) => {
  if (
    startDate &&
    endDate &&
    typeof startTime !== 'undefined' &&
    typeof endTime !== 'undefined'
  ) {
    return (
      (differenceInDays(
        // @ts-ignore
        parse(endDate, 'yyyy-MM-dd', new Date()),
        // @ts-ignore
        parse(startDate, 'yyyy-MM-dd', new Date()),
      ) +
        1) *
      (endTime - startTime)
    );
  }
  return 0;
};

const toBookingDetailsDto = (bookingDetails: any) => {
  /*
  const startDate =
    bookingDetails && bookingDetails.startDate
      ? moment(bookingDetails.startDate, 'DD MMM, YYYY').format(
          'YYYY-MM-DD hh:mm:ss',
        )
      : '';
  const endDate =
    bookingDetails && bookingDetails.endDate
      ? moment(bookingDetails.endDate, 'DD MMM, YYYY').format(
          'YYYY-MM-DD hh:mm:ss',
        )
      : '';
      */
  const startTime =
    bookingDetails && typeof bookingDetails.startTime !== 'undefined'
      ? moment(bookingDetails.startTime, 'hh').format('LT')
      : '';
  const endTime =
    bookingDetails && typeof bookingDetails.endTime !== 'undefined'
      ? moment(bookingDetails.endTime, 'hh').format('LT')
      : '';
/*
  const startDateTime = moment(
    bookingDetails.startDate,
    'DD MMM, YYYY hh:mm',
  ).add(bookingDetails.startTime, 'hours');
  const endDateTime = moment(bookingDetails.endDate, 'DD MMM, YYYY hh:mm').add(
    bookingDetails.endTime,
    'hours',
  );
*/
  // const duration = moment.duration(endDateTime.diff(startDateTime));
  // const hours = duration.asHours();
  const hours =
    bookingDetails &&
    bookingDetails.startDate &&
    bookingDetails.endDate &&
    typeof bookingDetails.startTime !== 'undefined' &&
    typeof bookingDetails.endTime !== 'undefined'
      ? getTimePeriod(
          bookingDetails.startDate,
          bookingDetails.endDate,
          bookingDetails.startTime,
          bookingDetails.endTime,
        )
      : 0;
  const convertToDisplayDate = (date: string) => {
    const newDate = moment(date, 'YYYY-MM-DD').format('DD MMM, YYYY');
    return newDate;
  };
  const startDateDisplay =
    bookingDetails && bookingDetails.startDate
      ? convertToDisplayDate(bookingDetails.startDate)
      : '';
  const endDateDisplay =
    bookingDetails && bookingDetails.endDate
      ? convertToDisplayDate(bookingDetails.endDate)
      : '';
  
  //const product = bookingDetails.product

  const data = {
    bookingDetails: {
      /*
      name: product.title || '_MISSING_NAME_',
      location: product.location,
      price: (bookingDetails || {}).price || 0,
      weekdayPrice: (bookingDetails || {}).weekdayPricePerHour || 0,
      weekendPrice: (bookingDetails || {}).weekendPricePerHour || 0,
      images: product.images,
      */
      fields: [
        {
          title: 'Date',
          value: `${startDateDisplay} - ${endDateDisplay}`,
        },
        { title: 'Time', value: `${startTime} - ${endTime}` },
        { title: 'Duration', value: hours },
      ],
      ...bookingDetails,
      /*
      startDate,
      endDate,
      startDateTime,
      endDateTime,
      provider: product.provider*/
    },
  };
  return data;
};

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

// @ts-ignore
const BookingDetailsContainer = props => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: user } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }
  const bookingState = useSelectorSafe<any>((state: any) => {
    return state.bookingDetails;
  });
  const bookingDetails =
    bookingState && bookingState.booking ? bookingState.booking : null;
  // console.log('_BOOKING_STATE_', bookingState);
  const data = toBookingDetailsDto(bookingDetails);
  //const [questions, setQuestions] = useState([]);

  const questions = bookingDetails.product.questions

  const [answers, setAnswers] = useState([]);

  // @ts-ignore
  const replaceIndex = (answer, index) => {
    const answersCopy = JSON.parse(JSON.stringify(answers));
    // @ts-ignore
    answersCopy[index] = { ...answer, id: questions[index].id };
    setAnswers(answersCopy);
  };

  const questionComponents = useMemo(() => {
    return questions.map((question, index) =>
      toComponent(
        question,
        answers.length > index ? answers[index] : null,
        replaceIndex,
        index,
      ),
    );
  }, [questions, answers]);
/*
  useEffect(() => {
    bookingQuestionsApi()
      .then(response => {
        // @ts-ignore
        setQuestions(response);
      })
      .catch(error => {});
  }, []);
*/
  const onSubmit = () => {
    // Validate the questions
    // On success - route to the next page (send the details)
    // Create the questions object.
    const formattedQuestions = questions.map((question, index) => {
      const answer = answers[index]
      if (!answer) {
        return null
      }
      return {
        ...question,
        answer: answer?.value ?? null
      }
      /*
      // @ts-ignore
      const findAnswer = answer =>
        answer &&
        answer.id &&
        question &&
        // @ts-ignore
        question.id &&
        // @ts-ignore
        answer.id === question.id;
      return {
        // @ts-ignore
        id: question.id,
        // @ts-ignore
        question: question.question,
        // @ts-ignore
        answer: (answers.find(findAnswer) || {}).value || '',
      };*/
    }).filter(q => !!q);
    dispatch(bookingDetailsActions.setBookingQuestions(formattedQuestions));
    dispatch(routerThunks.link(ROUTES.CONFIRM_BOOKING_DETAILS));
  };
  const goToHostDetails = () => {
    // @ts-ignore
    if (
      data &&
      // @ts-ignore
      data.bookingDetails &&
      // @ts-ignore
      data.bookingDetails.host &&
      // @ts-ignore
      data.bookingDetails.host.id
    ) {
      // @ts-ignore
      const hostId = data.bookingDetails.host.id;
      // @ts-ignore
      dispatch(
        // @ts-ignore
        routerThunks.link(ROUTES.HOST_DETAILS, { id: hostId }),
      );
    }
  };
  return (
    <View
      {...props}
      questionComponents={questionComponents}
      bookingDetails={bookingDetails} //data.bookingDetails}
      fields={data.bookingDetails.fields}
      goToHostDetails={goToHostDetails}
      onSubmit={onSubmit}
    />
  );
};

export default React.memo(BookingDetailsContainer);
