import { makeStyles, createStyles } from '@mui/styles';
import { ButtonPublicProps } from './Button.props';
import { colors } from '../../../theme/themeConstants';
import { fallback } from '../../../utils/Data';

export const useStyles = makeStyles(() =>
  createStyles({
    button: ({
      backgroundColor: bg,
      hoverColor,
      color,
      hoverBackgroundColor: hbg,
      disabled,
      round,
      small
    }) => ({
      ...fallback(
        // @ts-ignore
        t => (t[bg] ? { backgroundColor: t[bg] } : undefined),
        { backgroundColor: 'rgb(5, 195, 217)' },
        colors,
      ),
      ...fallback(
        // @ts-ignore
        t => (t[color] ? { color: t[color] } : undefined),
        { color: 'white' },
        colors,
      ),
      borderRadius: round ? '50px' : '5px',
      //boxShadow: `0 1px 1px rgba(0,0,0,0.08)`,
      width: '100%',
      height: small ? '36px' : '50px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        ...fallback(
          // @ts-ignore
          t => (t[hbg] ? { backgroundColor: t[hbg] } : undefined),
          {
            // @ts-ignore
            backgroundColor: colors[bg] || 'rgba(5,195,217,0.51)',
          },
          colors,
        ),
        ...fallback(
          // @ts-ignore
          t => (t[hoverColor] ? { color: t[hoverColor] } : undefined),
          // @ts-ignore
          { color: colors[color] || 'white' },
          colors,
        ),
      },
      ...(disabled
        ? {
            backgroundColor: colors.lightGrey,
            color: colors.white,
            '&:hover': {
              backgroundColor: colors.lightGrey,
              color: colors.white,
            },
          }
        : {}),
    }),

    textButton: ({
      backgroundColor: bg,
      hoverColor,
      color,
      hoverBackgroundColor: hbg,
      disabled,
    }: ButtonPublicProps) => ({}),

    icon: {
      height: '25px',
      width: '25px',
      marginRight: '15px',
    },
    typography: {
      textTransform: 'none',
    },
  }),
);
