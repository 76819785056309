import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './HeadlineSection.styles';
import { HeadlineSectionProps } from './HeadlineSection.props';
import { Typo } from '../../primitives';

const HeadlineSectionView: FC<HeadlineSectionProps> = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <Typo variant="h1" className={classes.headline}>
        {`Find the spaces you've been searching for.`}
      </Typo>
    </Box>
  );
};

export default React.memo(HeadlineSectionView);
