import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(3),
    },
    field: {
      marginBottom: theme.spacing(1.5),
    },
    messageContainer: {
      marginBottom: theme.spacing(1.5),
    },
    accountActionsContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    changePasswordButton: {
      color: 'rgb(187, 192, 194)',
      textTransform: 'none',
    },
    deleteAccountButton: {
      color: 'red',
      textTransform: 'none',
    },
  }),
);
