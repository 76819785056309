import React, { useState, useMemo, useEffect, useRef } from 'react';
import View from './EditListing.view';
import CreateNewListingVenue from 'components/modules/CreateNewListingVenue' 
import CreateNewListingGallery from 'components/modules/CreateNewListingGallery'
import CreateNewListingBooking from 'components/modules/CreateNewListingBooking'
import CreateEditListingContainer from '../CreateListing/CreateEditListing.container'

const steps = [
  {
    label: 'Venue',
    component: (props) => {
      return (
        <div>
          <CreateNewListingVenue {...props}/>
        </div>
      )
    }
  },
  {
    label: 'Gallery',
    component: (props) => {
      return (
        <div>
          <CreateNewListingGallery {...props}/>
        </div>
      )
    }
  },
  {
    label: 'Booking',
    component: (props) => {
      return (
        <div>
          <CreateNewListingBooking {...props}/>
        </div>
      )
    }
  }
]

const EditListingContainer = props => {
  return (
    <CreateEditListingContainer
      {...props}
      view={View}
      steps={steps}
      mode='edit'
    />
  );
};

export default React.memo(EditListingContainer);