import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '100px',
      marginBottom: '100px'
    },
    title: {
        marginTop: '22px',
        marginBottom: '20px'
    },
    description: {
        maxWidth: '500px',
        textAlign: 'center'
    }
  }),
);
