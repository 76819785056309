// API helper methods
import moment from 'moment'
//import momentTz from 'moment-timezone'

export const bookingTimeData = (booking) => {
    /*
    const tz = (booking.product && booking.product.timezone) || 'Australia/Sydney'
    const startOn = booking.startOn ? momentTz(booking.startOn).tz(tz) : null;
    const endOn = booking.endOn ? momentTz(booking.endOn).tz(tz) : null;
*/
    const startOn = booking.startOn ? moment(booking.startOn) : null;
    const endOn = booking.endOn ? moment(booking.endOn) : null;

    const singleDay = startOn.isSame(endOn, 'day')

    return {
        startOn,
        endOn,
        //tz,
        singleDay
    }
}

export const bookingIsPassed = (booking) => {
    if (!booking) {
        return false
    }

    const now = moment()
    const end = moment(booking.endOn)
    const passed = now.isAfter(end)
    return passed
}

export const bookingIsChangeable = (booking) => {
    if (!booking) {
        return false
    }

    if (booking.status == 'pending') {
        return true
    }

    if (bookingIsPassed(booking)) {
        return false
    }

    if (booking.status == 'declined') {
        return false
    }

    return true
}