import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'store/actions';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { asyncData } from 'utils/Redux';
import {ROUTES, PORTALS, NOT_FOUND, routeAndPortalFromLocation} from 'routes';
import messagingThunks from 'thunks/messaging';
import routerThunks from 'thunks/router'
import View, {NewMessage} from './AppBar.view';
import { openPrivacy, openTerms } from 'services/privacyAndTerms';
import { EnvelopeIcon } from 'assets';

const userFallback = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const profileFallback = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load profile' },
]);

const getModalState = (query) => {
  if (!query) {
    return 'CLOSE';
  }

  if (typeof query['reset-password'] !== 'undefined') {
    return 'CREATE_NEW_PASSWORD';
  }

  return 'CLOSE';
};

const AppBarContainer = (props) => {
  const { data: user, status, errors } = useSelectorSafe(
    state => state.user,
    userFallback,
  );
  const query = useSelectorSafe(
    state => state.location.query,
  );
  const profile = useSelectorSafe(
    // @ts-ignore
    state => state.profile,
    profileFallback,
  );

  const location = useSelectorSafe(
    state => state.location.type,
    NOT_FOUND,
  );
  const [routeName, portalName, portal] = routeAndPortalFromLocation(location)

  // @ts-ignore
  const [showModal, setShowModal] = useState(getModalState(query));
  const dispatch = useDispatch();
  const [unreadMessages, setUnreadMessages] = useState(0)

  useEffect(() => {
    if (!user) {
      return
    }

    const thunk = (portalName == PORTALS.HOST) ? messagingThunks.getProviderThreads : messagingThunks.getUserThreads
    dispatch(
      thunk(
        response => {
          if (response && response.threads && response.threads.length) {
            let responseUnread = 0
            // eslint-disable-next-line no-plusplus
            const unreadKey = (portalName == PORTALS.HOST) ? 'provUnreadMessages' : 'userUnreadMessages'
            for (let i = 0; i < response.threads.length; i++) {
              if (response.threads[i][unreadKey] > 0) {
                responseUnread = response.threads[i][unreadKey]
                break
              }
            }
            setUnreadMessages(responseUnread)
          }
        },
        error => {
          console.log('_ERROR_', error);
        },
      ),
    );
  }, [user])

  useEffect(() => {
    setShowModal(getModalState(query));
  }, [setShowModal, getModalState, query]);


  const goToProviderAdmin = () => dispatch(routerThunks.link(ROUTES.PROVIDER_ADMIN))
  const goToSettingsAdmin = () => dispatch(routerThunks.link(ROUTES.SETTINGS_ADMIN))
  const goToProviders = () => dispatch(routerThunks.link(ROUTES.PROVIDERS))
  const goToContactUs=() => dispatch(routerThunks.link(ROUTES.CONTACT_US))
  const goToMyBookings=() => dispatch(routerThunks.link(ROUTES.MY_BOOKINGS))
  const goToMessages=() => {
    return dispatch(routerThunks.link(ROUTES.MESSAGES))
  }
  const goToManageListings=() => dispatch(routerThunks.link(ROUTES.ROOT))
  const goToBookingRequests=() => dispatch(routerThunks.link(ROUTES.REQUESTED_BOOKINGS))
  const goToScheduleBookings=() => dispatch(routerThunks.link(ROUTES.SCHEDULED_BOOKINGS))

  const openSignUpModal=() => setShowModal('SIGNUP')
  const openLoginModal=() => setShowModal('LOGIN')

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const isLoggedIn = user;

  let appBarItems = []
  switch (portalName) {
    case PORTALS.CUSTOMER: {
      appBarItems = isLoggedIn
      ? [
          {
            text: 'Contact Us',
            onClick: goToContactUs,
          },
          {
            text: 'My Bookings',
            onClick: goToMyBookings,
          },
          {
            //text: 'Messages',
            icon: <EnvelopeIcon width={20} height={20}/>,
            onClick: goToMessages,
            children: unreadMessages ? <NewMessage /> : null,
          },
        ]
      : [
          {
            text: 'Contact Us',
            onClick: goToContactUs,
          },
          {
            text: 'Sign up',
            onClick: () => {
              openSignUpModal();
              handleCloseMenu();
              setShowMobileMenu(false);
            },
          },
          {
            text: 'Login',
            onClick: () => {
              openLoginModal();
              handleCloseMenu();
              setShowMobileMenu(false);
            },
          },
        ];
    } break;

    case PORTALS.HOST: {
      appBarItems = isLoggedIn
      ? [
          {
            text: 'Manage',
            onClick: goToManageListings,
          },
          {
            text: 'Request',
            onClick: goToBookingRequests,
          },
          {
            text: 'Schedule',
            onClick: goToScheduleBookings,
          },
          {
            //text: 'Messages',
            icon: <EnvelopeIcon width={20} height={20}/>,
            onClick: goToMessages,
            children: unreadMessages ? <NewMessage /> : null,
          },
        ]
      : []
    } break;

    case PORTALS.ADMIN: {
      appBarItems = isLoggedIn
      ? [
          {
            text: 'Provider Admin',
            onClick: goToProviderAdmin,
          },
          {
            text: 'Settings Admin',
            onClick: goToSettingsAdmin,
          }
        ]
      : []
    } break;
  }

  // Add a circle div for notifications on "messages"
  return (
    <View
      {...props}
      portalName={portalName}
      appBarItems={appBarItems}
      goToHomeRoute={() => dispatch(routerThunks.link(ROUTES.ROOT))}
      goToTerms={openTerms} //() => dispatch(routerThunks.link(ROUTES.TERMS))}
      goToPrivacy={openPrivacy} //() => dispatch(routerThunks.link(ROUTES.PRIVACY_POLICY))}
      goToContactUs={goToContactUs}
      goToMyBookings={goToMyBookings}
      goToProviders={goToProviders}
      goToProviderAdmin={goToProviderAdmin}
      goToSavedListings={() =>
        dispatch(routerThunks.link(ROUTES.MY_SAVED_LISTINGS))
      }
      goToMessages={goToMessages}
      goToInviteFriends={() =>
        dispatch(routerThunks.link(ROUTES.INVITE_A_FRIEND))
      }
      goToProfile={() => dispatch(routerThunks.link(ROUTES.PROFILE))}
      loading={status === ASYNC_STATUS.LOADING}
      user={user}
      errors={errors}
      showModal={showModal}
      openSignUpModal={openSignUpModal}
      openLoginModal={openLoginModal}
      closeModal={() => setShowModal('CLOSE')}
      logOut={() => {
        dispatch(authActions.logout());
        dispatch(routerThunks.link(ROUTES.ROOT));
        setShowModal('CLOSE');
      }}
      setShowMobileMenu={setShowMobileMenu}
      showMobileMenu={showMobileMenu}
      handleCloseMenu={handleCloseMenu}
      anchorEl={anchorEl}
      handleOpenMenu={handleOpenMenu}
      // @ts-ignore
      image={((profile || {}).data || {}).image || ''}
      unreadMessages={unreadMessages}
    />
  );
};

export default React.memo(AppBarContainer);
