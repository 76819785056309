import getById from './getById';
import add from './add';
import validateDiscountCode from './validateDiscountCode';
import getClientSecret from './getClientSecret';
import getMyBookings from './getMyBookings';
import getMyHostBookings from './getMyHostBookings';
import get from './get';
import getProviderBookings from './getProviderBookings';

const bookingThunks = {
  getById,
  add,
  validateDiscountCode,
  getClientSecret,
  getMyBookings,
  getMyHostBookings,
  get,
  getProviderBookings
};

export default bookingThunks;
