import { productActions } from 'store/actions';
import { Dispatch } from 'redux';
import { addProductEndpoint, productSignedUrlEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch'
import {uploadImages} from 'utils' 

const addProduct = (
  data,
  onSuccess,
  onError,
) => async (dispatch, getState) => {

  let product = data
  try {
    // if this is a new product, create it without any images as we want to get an ID for signed urls
    // then we can upload the images and modify the product again to include images
    const isNew = !product.hasOwnProperty('ID')
    if (isNew) {
      const images = product.images
      product.images = []
      const r = await apiFetch(addProductEndpoint, product)(dispatch, getState)
        .catch((error) => {
          if (onError) {
            onError(error)
          }
        })
      product = {
        ...data,
        ID: r.data.ID,
        images
      }
    }

    product.images = await uploadImages(product.images, productSignedUrlEndpoint, {ID: product.ID}, dispatch, getState)

    const addProductFetch = productActions.addProduct(
      apiFetch(addProductEndpoint, product)(dispatch, getState)
        .then((response) => {
          if (onSuccess) onSuccess(response)
        })
        .catch((error) => {
          if (onError) onError(error)
        }),
    )
    dispatch(addProductFetch)

  } catch (error) {
    if (onError) {
      onError(error)
    }
  }
 
}

export default addProduct
