import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import {
  Typo,
  Button,
  FacebookLogin,
  GoogleLogin,
} from 'components/primitives';
import { useStyles } from './SignUpForm.styles';
import { SignUpFormProps } from './SignUpForm.props';
import { LoginModalForms } from '../LoginModal/LoginModal.const';

const SignUpFormView: FC<SignUpFormProps> = props => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const onLoginClick = props.openModal
    ? props.openModal(LoginModalForms.LOGIN)
    : () => {};
  const onCreateAccountClick = props.openModal
    ? props.openModal(LoginModalForms.CREATE_NEW_ACCOUNT)
    : () => {};
  const onCloseMenu = props.openModal
    ? props.openModal(LoginModalForms.CLOSE)
    : () => {};
  const onSignUpCallback = (res: {
    status: 'success' | 'error';
    message: string;
  }) => {
    if (res.status === 'success') {
      onCloseMenu();
      setError('');
    } else {
      setError(res.message);
    }
  };

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        Sign Up
      </Typo>
      <Box className={classes.subHeading}>
        <Typo variant="body2" color="lightGrey">
          Securing your spaces is seconds away
        </Typo>
        {Boolean(error) && (
          <Typo variant="body1" color="red">
            {error}
          </Typo>
        )}
      </Box>
      <GoogleLogin onClickCallback={onSignUpCallback} />
      {/*<FacebookLogin onClickCallback={onSignUpCallback} />*/}
      <Typo variant="body2" className={classes.or}>
        or
      </Typo>
      <Button
        text="Create Account"
        className={classes.button}
        onClick={onCreateAccountClick}
      />
      <Box className={classes.footer}>
        <Typo variant="body1" color="lightGrey">
          Already have an account?{' '}
          <Typo
            variant="body1"
            component="a"
            color="blue"
            onClick={onLoginClick}
            className={classes.link}
          >
            Log in
          </Typo>
        </Typo>
      </Box>
    </>
  );
};

export default React.memo(SignUpFormView);
