//
// Troubleshooting:
// If getting a 400 error on the web browser about cognito, there is a fix here: https://github.com/aws-amplify/amplify-js/issues/1542#issuecomment-710726085
//

import Amplify, { Auth } from 'aws-amplify'

const isLocal = window.location.href.indexOf("localhost") > -1

Amplify.configure({
    Auth: {
        identityPoolId: 'ap-southeast-2:2ab60dcf-b9f2-4bca-b813-8598c4f0405c',
        region: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_sDxOXf28x',
        userPoolWebClientId: '7ll4uu8e3rlhb5v6bci7enderb',
        mandatorySignIn: false,
        cookieStorage: {
            domain: isLocal ? 'localhost' : 'airplacer.com',
            path: '/',
            expires: 30,
            secure: !isLocal
        }
    }
})

const passwordMinLength = 8

const simplifyError = (err) => {
    if (err.code == 'InvalidParameterException'
        || err.code == 'InvalidPasswordException') {

        if (err.message.includes("password") || err.message.includes("Password")) {
            err.message = `Your password must be at least ${passwordMinLength} characters long, contain at least one number, contain at least one symbol and have a mixture of uppercase and lowercase letters.`
        }
    }

    err.message = err.message.replaceAll('Username', 'Email Address')
    err.message = err.message.replaceAll('username', 'email address')
    return err
}

/*

let currentUser = null

export function checkUserSession(force = false) {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise(async (resolve, reject) => {
            console.log('checkUserSession called');

            if (currentUser && !force) {
                dispatch(onUpdateUser(currentUser))
                resolve()
            } else {

                let response = await _updateUser()
                let hasError = response && response.error ? true : false;
                
                //If registration error
                if (response && !hasError) {
                    dispatch(onUpdateUser(currentUser))
                    dispatch({ type: 'API_IDLE' })
                    resolve()
                } else {
                    dispatch(onUpdateUser(null))
                    dispatch({ type: 'API_IDLE' })
                    resolve()
                }
            }
        })
    }
}
*/

export const forgotPasswordWithEmail = (data) => {
    const { email } = data;
    return Auth.forgotPassword(email)
        .catch(err => {
            throw simplifyError(err)
        })
}

export const forgotPasswordSubmitWithEmail = (data) => {
    const {email, password, verificationCode} = data
    return Auth.forgotPasswordSubmit(email, verificationCode, password)
        .catch(err => {
            throw simplifyError(err)
        })
}

export const resendVerification = (email) => {
    return Auth.resendSignUp(email)
        .catch(err => {
            throw simplifyError(err)
        })
}

export const verifyUserWithEmail = (credentials) => {
    const {email, verificationCode} = credentials
    return Auth.confirmSignUp(email, verificationCode)
        .catch(err => {
            throw simplifyError(err)
        })
}



export const registerWithEmail = (credentials) => {
    const {email, firstName, lastName, password} = credentials
    const username = email
    return Auth.signUp({
        username,
        password,
        attributes: {
            email,
            given_name: firstName,
            family_name: lastName
        }
    })
    .catch(async (err) => {
        if (err.code == "UserNotConfirmedException") {
            await resendVerification(username)   
        }

        throw simplifyError(err)
    })
}

export const signInWithEmail = (credentials) => {
    const { email, password } = credentials
    const username = email
    return Auth.signIn(username, password)
        .catch(async (err) => {
            console.log("signIn", err)
            if (err.code == "UserNotConfirmedException") {
                await resendVerification(username)
            }

            if (err.code == "InvalidParameterException") {
                err.message = 'Password cannot be empty.'
                throw(err)
            }

            throw simplifyError(err)
        })
}

export const signInWithGoogle = (credentials) => {
    const user = {
        email: credentials.profileObj.email,
        name: `${credentials.profileObj.givenName} ${credentials.profileObj.familyName}`.trim(),
        picture: credentials.profileObj.imageUrl,
    }
    return Auth.federatedSignIn('google', {token: credentials.tokenId, expires_at: credentials.tokenObj.expires_at}, user)
        .catch(async (err) => {
            console.log("signIn", err)
            throw simplifyError(err)
        })
}

/*


function onSignIn(data) {

    return {
        type: 'SIGN_IN',
        data: data,
        receivedAt: Date.now()
    }
}


// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function signInWithGoogle2(googleResponse) {
    return async function (dispatch) {
        dispatch({ type: 'API_LOADING' })

        const responsePayload = parseJwt(googleResponse.credential);

        console.log("ID: " + responsePayload.sub);
        console.log('Full Name: ' + responsePayload.name);
        console.log('Given Name: ' + responsePayload.given_name);
        console.log('Family Name: ' + responsePayload.family_name);
        console.log("Image URL: " + responsePayload.picture);
        console.log("Email: " + responsePayload.email);

        let user = {
            email: responsePayload.email,
            name: responsePayload.name
        };

        const federatedUser = await Auth.federatedSignIn(
            'google',
            { token: googleResponse.credential, expires_at: responsePayload.exp },
            user
        )
        console.log('federatedUser', federatedUser);
        currentUser = federatedUser
        const federatedInfo = Cache.getItem('federatedInfo');
        console.log("federatedInfo", federatedInfo)

        var response = await _updateUser(user);
        console.log("update get user response ...", response);
        console.log("update response...", response);
        if (response && !response.error) {
            dispatch(onSignIn(currentUser))
        } else {
            //toast.error(response.message ? response.message : "Sorry, Something went wrong.")
            //console.log("signout...");
            currentUser = null
            return dispatch(onSignOut())
        }
    }
}

export function signInWithGoogle(googleUser) {
    return async function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        const { id_token, expires_at } = googleUser.getAuthResponse();
        const profile = googleUser.getBasicProfile();
        let user = {
            email: profile.getEmail(),
            name: profile.getName()
        };

        console.log("Google user...", user);

        /*if (user.email.indexOf("@2xe.com.au") == -1 && user.email.indexOf("@jpmedia.com.au") == -1 && user.email.indexOf("@gmail.com") == -1) {
            toast.error("Invalid email address.")
            return dispatch(onSignIn(null))
        }* /

        const federatedUser = await Auth.federatedSignIn(
            'google',
            { token: id_token, expires_at },
            user
        );
        console.log('federatedUser', federatedUser);
        currentUser = federatedUser
        const federatedInfo = Cache.getItem('federatedInfo');
        console.log("federatedInfo", federatedInfo)

        var response = await _updateUser(user);
        console.log("update get user response ...", response);
        console.log("update response...", response);
        if (response && !response.error) {
            dispatch(onSignIn(currentUser))
        } else {
            //toast.error(response.message ? response.message : "Sorry, Something went wrong.")
            //console.log("signout...");
            currentUser = null
            return dispatch(onSignOut())
        }
    }
}

function onSignOut() {
    return {
        type: 'SIGN_OUT',
        data: null,
        receivedAt: Date.now()
    }
}

export function signOut() {
    return function (dispatch) {
        dispatch({ type: 'API_LOADING' })
        return new Promise((resolve, reject) => {
            Auth.signOut().
                then((user) => {
                    console.log("Signout successful")
                    currentUser = null
                    dispatch(onSignOut())
                    resolve()
                })
                .catch((err) => {
                    console.log("Signout failed", err)
                    currentUser = null
                    dispatch(onSignOut(null))
                    resolve()
                }
                );

        })


    }
}

function onUpdateUser(data) {
    return {
        type: 'ON_UPDATE_USER',
        data: data,
        receivedAt: Date.now()
    }
}


async function _updateUser(xx) {
    return new Promise(async (resolve, reject) => {
        console.log('_updateUser called');
        Auth.currentAuthenticatedUser(xx)
            .then(async (data) => {
                currentUser = data;
                /*
                //TODO write lambda function to fetch user
                let fetchedUser = await _getData("/getUser", "ON_UPDATE_USER", "", true)
                console.log("fetcheduser....", fetchedUser);
                //Check for registration error
                if(fetchedUser && fetchedUser.error){
                    //resolve(false)
                    currentUser = null
                    resolve(fetchedUser)
                }

                currentUser = currentUser ? Object.assign(currentUser, fetchedUser) : null
                * /
                resolve(true)
            })
            .catch((err) => {
                console.log("_updateUser ERROR", err)
                resolve(false)
            });
    })
}

*/