import { makeStyles, createStyles, Theme } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(5),
      display: 'flex',
      flexDirection: 'row'
    },
    leftContainer: {
      flex: 1
    },
    rightContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    heading: {
      marginBottom: theme.spacing(2.5),
    },
    field: {
      marginBottom: theme.spacing(1.5),
      color: theme.palette.primary.light,
    },
    breadcrumbType: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  }),
);
