import React, { FC } from 'react';
import { Checkbox } from '@mui/material';
import { useStyles } from './Checkbox.styles';

const CheckboxView = props => {
  const classes = useStyles();

  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={
        <span className={`${classes.icon} ${classes.checkedIcon}`} />
      }
      icon={<span className={classes.icon} />}
      
      className={classes.checkbox}
      {...props}
    />
  );
};

export default React.memo(CheckboxView);
