import React, { FC } from 'react';
import { Box } from '@mui/material';
import { TypesFilterProps } from './TypesFilter.props';
import { Typo, Tile } from '../../primitives';

import MobileButton from '../MobileButton';
import { useStyles } from './TypesFilter.styles';

/* eslint-disable global-require */
const WorkIcon = require('../../../assets/work-icon.svg') as string;
/* eslint-enable global-require */

const TypesFilterView: FC<TypesFilterProps> = props => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.container}>
        <div className={classes.headerContainer}>
          <Typo variant="h2">Type</Typo>
          <button className={classes.clearButton} onClick={props.reset}>
            <Typo variant="body2" color="lightGrey">
              Clear
            </Typo>
          </button>
        </div>
        <Box className={classes.tileContainer}>
          <Box className={classes.rowContainer}>
            <Tile
              text="Corporate"
              style={{ width: '45%' }}
              iconUrl={WorkIcon}
              backgroundColor="rgb(5, 195, 222)"
              iconAlignedLeft={false}
              isGhostTile={false}
              isActive={props.activeType.includes('corporate')}
              onClick={() => props.onToggleType('corporate')}
            />
            <Tile
              text="Creative"
              style={{ width: '45%' }}
              iconUrl={WorkIcon}
              backgroundColor="#dc4d5e"
              iconAlignedLeft={false}
              isGhostTile={false}
              isActive={props.activeType.includes('creative')}
              onClick={() => props.onToggleType('creative')}
            />
          </Box>
          <Box className={classes.rowContainer}>
            <Tile
              text="Functions"
              style={{ width: '45%' }}
              iconUrl={WorkIcon}
              backgroundColor="#3f6abd"
              iconAlignedLeft={false}
              isGhostTile={false}
              isActive={props.activeType.includes('functions')}
              onClick={() => props.onToggleType('functions')}
            />
            <Tile
              text="Sports"
              style={{ width: '45%' }}
              iconUrl={WorkIcon}
              backgroundColor="#f9af34"
              iconAlignedLeft={false}
              isGhostTile={false}
              isActive={props.activeType.includes('sports')}
              onClick={() => props.onToggleType('sports')}
            />
          </Box>
          <Box className={classes.rowContainer}>
            <Tile
              text="Outdoor"
              style={{ width: '45%' }}
              iconUrl={WorkIcon}
              backgroundColor="#4caca7"
              iconAlignedLeft={false}
              isGhostTile={false}
              isActive={props.activeType.includes('outdoor')}
              onClick={() => props.onToggleType('outdoor')}
            />
            <Tile
              text="Other"
              style={{ width: '45%' }}
              iconUrl={WorkIcon}
              backgroundColor="#f39e8c"
              iconAlignedLeft={false}
              isGhostTile={false}
              isActive={props.activeType.includes('other')}
              onClick={() => props.onToggleType('other')}
            />
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          position: 'fixed',
          bottom: 0,
          width: 400, // An only possible fix, cause the parent element is already in fixed position.
        }}
      >
        <MobileButton text="Confirm" onClick={props.onSubmit} />
      </Box>
    </Box>
  );
};

export default React.memo(TypesFilterView);
