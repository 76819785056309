import { handleActions } from 'redux-actions';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';

const DEFAULT_STATE = asyncData(ASYNC_STATUS.INITIAL);


const reducer = handleActions(
  {
    ...createAsyncReducers('USER/GET_PROFILE'),
    ...createAsyncReducers('USER/UPDATE_PROFILE'),
    //'USER/UPDATE_PROFILE_FULFILLED': (state, action) => action.payload,
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
