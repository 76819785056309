import React from 'react';
import {
  Button,
  TextArea,
  InputField,
  Typo,
  Link,
} from 'components/primitives';
import { Box } from '@mui/material';
import { EmailIcon, UserIcon } from '../../../assets';
import { HeaderSection } from '../../../components/modules';
import Main from '../../../components/layouts/Main';
import { useStyles } from '../../shared/ContactUs/ContactUs.styles';

// This should have a form wrapping it with the submit behaviour and the default submit behaviour disabled.
const InviteAFriendView = ({
  // @ts-ignore
  goToHome,
  // @ts-ignore
  friendName,
  // @ts-ignore
  friendEmail,
  // @ts-ignore
  message,
  // @ts-ignore
  setFriendName,
  // @ts-ignore
  setFriendEmail,
  // @ts-ignore
  setMessage,
  // @ts-ignore
  inviteAFriend,
  // @ts-ignore
  canInviteAFriend,
  // @ts-ignore
  successMessage,
}) => {
  const classes = useStyles();
  return (
    <Main>
      <Box>
        <HeaderSection
          heading="Invite A Friend"
          subHeading="Fill out the form below with your information"
        />
        <Box>
          <div>
            <InputField
              id="user"
              icon={() => <UserIcon />}
              placeholder="Friend's Name"
              style={{ marginBottom: '1em' }}
              onChange={setFriendName}
              value={friendName}
              onBlur={() => {}}
              error={false}
            />
            <InputField
              id="email"
              icon={() => <EmailIcon />}
              placeholder="Email Address"
              style={{ marginBottom: '1em' }}
              onChange={setFriendEmail}
              value={friendEmail}
              onBlur={() => {}}
              error={false}
            />
            <TextArea
              placeholder="Add a message"
              value={message}
              label=""
              onChange={setMessage}
              style={{ marginBottom: '1em' }}
            />
            {successMessage && (
              <div className={classes.errorMessage}>
                <Typo variant="body1" color="green">
                  {successMessage}
                </Typo>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Typo color="lightGrey">
                Return to <Link onClick={goToHome}>Home</Link>
              </Typo>
              <div style={{ width: '50%', marginLeft: '1em' }}>
                <Button
                  text="Invite friend"
                  onClick={inviteAFriend}
                  disabled={!canInviteAFriend}
                />
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Main>
  );
};

export default InviteAFriendView;
