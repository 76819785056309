import getThreadMessages from './getThreadMessages';
import getUserThreads from './getUserThreads';
import getProviderThreads from './getProviderThreads';
import sendMessage from './sendMessage';

const messagingThunks = {
  getThreadMessages,
  getUserThreads,
  getProviderThreads,
  sendMessage,
};

export default messagingThunks;
