import React, { useEffect, useRef, useState } from 'react'
import { ApprovedIcon, DeclinedIcon, PendingIcon } from 'assets'
import Typo from './Typo'

export const StatusLabel = (props) => {
    const {status, style} = props
    
    let IconComponent
    let statusColor
    if (status === 'pending') {
      IconComponent = PendingIcon
      statusColor = '#ffb81c'
    } else if (status === 'declined' || status === 'cancelled' || status === 'deleted') {
      IconComponent = DeclinedIcon
      statusColor = '#ed1f54'
    } else {
      IconComponent = ApprovedIcon
      statusColor = '#00b2a9'
    }

    return (
      <div
        style={{
          //backgroundColor: 'white',
          //borderRadius: '16px',
          //border: '1px solid rgba(162, 170, 173, 0.1)',
          display: 'flex',
          alignItems: 'center',
          //padding: '0.25em',
          //paddingLeft: '0.5em',
          //paddingRight: '0.5em',
          color: statusColor,
          textTransform: 'capitalize',
          ...style,
        }}
      >
        <IconComponent style={{ marginRight: '0.25em' }} />
        <Typo component='span'>{status}</Typo>
      </div>
    )
}

export const StatusBadge = (props) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: '16px',
        //border: '1px solid rgba(162, 170, 173, 0.1)',
        display: 'flex',
        alignItems: 'center',
        padding: '0.25em',
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
        textTransform: 'capitalize',
        ...props.style,
      }}
    >
      <StatusLabel status={props.status}/>
    </div>
  )
}
