import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      marginTop: '50px',
      position: 'relative',
      backgroundColor: '#f4f4f4',
    },

    container: {
      //marginTop: 'auto', // push us to the bottom/end
      flex: 1,
      overflow: 'hidden',

      
      padding: '54px 0',
      color: '#828282',
      
      fontSize: '18px',
      //position: 'absolute',
      zIndex: 999,
    },

    p: {
      paddingBottom: '18px'
    },

    a: {
      color: '#56c1da',
      textDecoration: 'none'
    },

    containerContainer: {
      display: 'flex',
      flexDirection: 'row',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      }
    },

    leftContainer: {
      padding: '0 20px',
      flex: 1,
      flexDirection: 'column',
      display: 'flex',
      
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',

        '& p': {
          textAlign: 'center'
        },
      }
    },

    rightContainer: {
      padding: '0 20px',
      paddingTop: '107px',
      flex: 1,
      display: 'flex',
      flexDirection: 'row',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingBottom: '50px',
        paddingTop: '0px'
      }
    },

    menuCol: {
      padding: '0, 15px 10px 15px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        alignItems: 'center'
      }
    },

    menuA: {
      color: '#828282',
      textDecoration: 'none'
    },

    airplacerIcon: {
      marginBottom: '30px'
    },

    bgImage: {
      position: 'absolute',
      bottom: '0px',
      right: '0px',
      zIndex: 1,

      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  }),
);
