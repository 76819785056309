import { contactActions } from 'store/actions';
import { Dispatch } from 'redux';
import { contactUsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { User } from '../../types/User';
import { Store } from '../../types/store/Store';

interface Data {
  name: string;
  email: string;
  contactNumber: string;
  type: string;
  message: string;
}

const contactUs = (data: Data) => (
  dispatch: Dispatch,
  getState: () => Store,
) => {
  dispatch(
    contactActions.submit(
      apiFetch<Data, User>(contactUsEndpoint, data)(dispatch, getState),
    ),
  );
};

export default contactUs;
