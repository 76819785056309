import React, { useState } from 'react';
import moment from 'moment';
import { useStyles } from './Chatlog.styles';
import { PlaceholderProfileImage } from '../../../../assets';
import { Avatar, TextArea, Typo, Button, Loading } from '../../../../components/primitives';
import { SendIcon } from '../../../../assets';
import Booking from './Booking';
import { classExpression } from '@babel/types';
import {PORTALS} from 'routes'

export const ChatMessage = (props) => {
  const {
    // @ts-ignore
    yours,
    // @ts-ignore
    data,
    onBookingClick = (id: string) => {},
    onMentionPress
  } = props
  const classes = useStyles();
  const type = data && data.type && data.type.toLowerCase()
  if (type === 'text') {
    return (
      <div
        className={yours ? classes.messageBoxYours : classes.messageBoxOther}
        style={{ padding: '1em' }}
      >
        <Typo variant="body2">{data.value}</Typo>
      </div>
    );
  }
  if (type === 'booking') {
    return (
      <Booking
        id={data.ID || '0'}
        onContactHostClick={() => {}}
        status={data.status || 'pending'}
        onBookingClick={() => onBookingClick(data.ID)}
        onMentionPress={onMentionPress}
        data={data}
        myBooking={{
          title: data.venue || '',
          image: data.image || PlaceholderProfileImage,
          startDate: data.startDate,
          endDate: data.endDate,
        }}
      />
    );
  }
  if (type === 'intro') {
    return (
      <div
        className={yours ? classes.messageBoxYours : classes.messageBoxOther}
        style={{ padding: '1em' }}
      >
        <div>
          <Typo style={{ color: '#000', marginBottom: '1em' }} variant="h3">
            {data.title}
          </Typo>
          <Button onClick={data.onButtonClick} text={data.buttonText} />
        </div>
      </div>
    );
  }
  return null;
};

// @ts-ignore
const AutoResizeTextArea = ({ placeholder, value, onChange, submit }) => {
  const handleChange = (e: any) => {
    const limit = 150;
    e.target.style.height = 'inherit';
    e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
    onChange(e);
  };
  const handleKeyPress = (e: any) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      submit(e);
    }
  };
  return (
    <textarea
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      placeholder={placeholder}
      value={value}
      style={{
        resize: 'none',
        padding: '1em',
        margin: '0',
        border: '1px solid rgb(245, 245, 245)',
        backgroundColor: 'white',
        width: '100%',
      }}
      rows={1}
      autoFocus
    />
  );
};

const Chatlog = ({
  // @ts-ignore
  sendMessage,
  // @ts-ignore
  bottomRef,
  // @ts-ignore
  otherUser,
  // @ts-ignore
  users,
  // @ts-ignore
  thread,
  // @ts-ignore
  messages,
  threadLoading,
  portalName,
  onBookingClick
}) => {
  const [messageValue, setMessageValue] = useState('');
  // @ts-ignore
  const groupedMessages = [];
  const isUser = (message: any) => {
    const r = (portalName == PORTALS.CUSTOMER && message.hasOwnProperty('user_ID')) //.userId !== otherUser.id;
    const r2 = (portalName == PORTALS.HOST && message.hasOwnProperty('prov_ID'))
    return r || r2
  }
  // @ts-ignore
  messages.forEach(message => {
    // @ts-ignore
    if (groupedMessages.length === 0) {
      groupedMessages.push([message])
      return
    } 
    
    const lastGroup = groupedMessages[groupedMessages.length - 1]
    const lastMessage = lastGroup[lastGroup.length - 1]
    if (
      lastMessage.user_ID === message.user_ID &&
      lastMessage.prov_ID === message.prov_ID &&
      lastMessage.data.type === message.data.type
    ) {
      lastGroup.push(message);
    } else {
      groupedMessages.push([message]);
    }
  });
  // @ts-ignore
  const onMessageSubmit = event => {
    event.preventDefault();
    sendMessage(messageValue);
    setMessageValue('');
  };

  if (threadLoading) {
    return (
      <Loading isLoading={threadLoading} center/>
    )
  }

  return (
    <div
      style={{
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          overflowY: 'auto',
          padding: '0em',
          flex: 1,
        }}
      >
        {// Needs an additional comment here to stop Typescript from complaining.
        // @ts-ignore
        groupedMessages.map((group, i) => {
            if (!group.length) {
              return null
            }

            const yours = isUser(group[0])
            const imageSrc = group[0].hasOwnProperty('user_ID') ? thread.user.image : thread.provider.image
            return (
              <div
                key={i}
                style={{
                  margin: '1.75em',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {!yours &&
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Avatar size={30} src={imageSrc} />
                  </div>
                }

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  {// Needs an additional comment here to stop Typescript from complaining.
                  // @ts-ignore
                  group.map((message, j) => (
                    <ChatMessage
                      key={j}
                      yours={yours}
                      type={message.data.type}
                      data={message.data}
                      onBookingClick={onBookingClick}
                    />
                  ))}
                  {group && group.length && !isUser(group[group.length - 1]) ? (
                    <Typo variant="body2" style={{ color: 'rgb(150, 160, 163)' }}>
                      {group[group.length - 1].type === 'booking'
                        ? `Booking approved `
                        : `Delivered `}
                      {moment(group[group.length - 1].createdOn).format(
                        'ddd DD MMM, YYYY, hh:mm A',
                      )}
                    </Typo>
                  ) : null}
                </div>
              </div>
            )
        })}
        <div ref={bottomRef} />
      </div>
      {thread.type === 'AIRPLACER' ? null : (
        <form
          style={{
            width: '100%',
            display: 'flex',
            paddingLeft: '1em',
            paddingRight: '1em',
            paddingTop: '1em',
            position: 'relative',
          }}
          onSubmit={onMessageSubmit}
        >
          <AutoResizeTextArea
            value={messageValue}
            placeholder="Type a message..."
            // @ts-ignore
            onChange={(event: any) => setMessageValue(event.target.value)}
            submit={onMessageSubmit}
          />
          <button
            style={{
              background: 'none',
              color: 'inherit',
              border: 'none',
              padding: 0,
              font: 'inherit',
              cursor: 'pointer',
              outline: 'inherit',
              position: 'absolute',
              right: '1.5em',
              bottom: '0.6em',
              width: '1.75em',
              height: '1.75em',
            }}
            onClick={onMessageSubmit}
          >
            <SendIcon
              style={{
                width: '1.75em',
                height: '1.75em',
              }}
            />
          </button>
        </form>
      )}
    </div>
  );
};

export default Chatlog;
