import React from 'react';
import Grid from '@mui/material/Grid';
import { Main } from 'components/layouts';
import Typo from 'components/primitives/Typo';
import { Avatar, Loading } from 'components/primitives';
import { BackButtonIcon } from 'assets';
import Chatlog from './Chatlog';
import ConversationList from './ConversationList';
import HostDetails from './HostDetails';
import UserDetails from './UserDetails'
import { useStyles } from './Messages.styles';
import {PORTALS} from 'routes'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/styles';

const WebView = ({
  // @ts-ignore
  otherUser,
  // @ts-ignore
  sendMessage,
  // @ts-ignore
  onThreadSelect,
  // @ts-ignore
  bottomRef,
  // @ts-ignore
  users,
  // @ts-ignore
  threads,
  // @ts-ignore
  thread,
  // @ts-ignore
  messages,
  portalName,
  threadLoading,
  onMentionPress,
  onBookingClick
}) => {
  const {provider, user} = thread
  const image = (portalName == PORTALS.CUSTOMER) ? (provider && provider.image) : (user && user.image)
  const name = (portalName == PORTALS.CUSTOMER) ? ((provider && provider.name) || '_MISSING_PROVIDER_') : ((user && `${user.firstName} ${user.lastName}`) || '_MISSING_USER_')

  const showProviderDetails = ((portalName == PORTALS.CUSTOMER) && !thread.isAirplacerThread)
  const showUserDetails = ((portalName == PORTALS.HOST) && !thread.isAirplacerThread)

  return (
    <>
      <Grid
        item
        xs={3}
        style={{
          borderRight: '1px solid rgb(245, 245, 245)',
          maxHeight: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ConversationList
          threads={threads}
          thread={thread}
          onThreadSelect={onThreadSelect}
          portalName={portalName}
        />
      </Grid>
      <Grid item container xs={9} style={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          style={{
            borderBottom: '1px solid rgb(245, 245, 245)',
            height: '80px',
            display: 'flex',
            paddingTop: 10,
            paddingLeft: 10,
            alignItems: 'center',
          }}
        >
          <div
            style={{
              marginRight: 10,
            }}
          >
            <Avatar margin={'0'} src={image}/>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typo variant="h4">
              {name}
            </Typo>
          </div>
        </Grid>
        <Grid
          item
          xs={8}
          style={{
            height: 'calc(100% - 80px)',
          }}
        >
          {thread ? (
            <Chatlog
              users={users}
              messages={messages}
              thread={thread}
              bottomRef={bottomRef}
              sendMessage={sendMessage}
              otherUser={otherUser}
              threadLoading={threadLoading}
              portalName={portalName}
              onBookingClick={onBookingClick}
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            borderLeft: '1px solid rgb(245, 245, 245)',
            height: 'calc(100% - 80px)',
          }}
        >
          {showProviderDetails &&
            <HostDetails host={provider} onMentionPress={onMentionPress} />
          }
          {showUserDetails &&
            <UserDetails user={user} onMentionPress={onMentionPress} />
          }
        </Grid>
      </Grid>
    </>
  );
};

const MobileView = ({
  // @ts-ignore
  otherUser,
  // @ts-ignore
  sendMessage,
  // @ts-ignore
  threadSelected,
  // @ts-ignore
  onThreadSelect,
  // @ts-ignore
  bottomRef,
  // @ts-ignore
  users,
  // @ts-ignore
  threads,
  // @ts-ignore
  thread,
  // @ts-ignore
  messages,
  portalName,
  threadLoading,
  onMentionPress,
  onBookingClick
}) => {
  const {provider, user} = thread || {}
  const image = (portalName == PORTALS.CUSTOMER) ? (provider && provider.image) : (user && user.image)
  const name = (portalName == PORTALS.CUSTOMER) ? ((provider && provider.name) || '_MISSING_PROVIDER_') : ((user && `${user.firstName} ${user.lastName}`) || '_MISSING_USER_')

  return thread ? (
    <Grid
      container
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      <Grid
        item
        style={{
          borderBottom: '1px solid rgb(245, 245, 245)',
          height: '80px',
          display: 'flex',
          paddingTop: 10,
          paddingLeft: 10,
          alignItems: 'center',
          width: '100%',
        }}
      >
        <button
          style={{
            padding: '1em',
            marginRight: '1em',
            background: 'none',
            color: 'inherit',
            border: 'none',
            font: 'inherit',
            cursor: 'pointer',
            outline: 'inherit',
          }}
          onClick={() => onThreadSelect(null)}
        >
          <BackButtonIcon />
        </button>
        <Avatar margin={'0'} src={image}/>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '1em',
          }}
        >
          <Typo variant="h4">
            {name}
          </Typo>
        </div>
      </Grid>
      <Grid
        item
        style={{
          height: 'calc(100% - 80px)',
        }}
      >
        <Chatlog
          users={users}
          messages={messages}
          thread={thread}
          bottomRef={bottomRef}
          sendMessage={sendMessage}
          otherUser={otherUser}
          threadLoading={threadLoading}
          portalName={portalName}
          onBookingClick={onBookingClick}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid
      item
      style={{
        maxHeight: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <ConversationList
        threads={threads}
        thread={thread}
        onThreadSelect={onThreadSelect}
        portalName={portalName}
      />
    </Grid>
  );
};

const View = ({
  // @ts-ignore
  otherUser,
  // @ts-ignore
  sendMessage,
  // @ts-ignore
  threadSelected,
  // @ts-ignore
  onThreadSelect,
  // @ts-ignore
  bottomRefWeb,
  // @ts-ignore
  bottomRefMobile,
  // @ts-ignore
  users,
  // @ts-ignore
  threads,
  // @ts-ignore
  thread,
  // @ts-ignore
  messages,
  // @ts-ignore
  loading,
  threadLoading,
  portalName,
  onMentionPress,
  onBookingClick
}) => {
  const classes = useStyles();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Main fullWidth hideWebsiteFooter>
      <Grid container style={{ height: '100%' }}>
        <Loading isLoading={loading} center/>
        {loading ? null : (
          <>
            {!isMobile &&
              <WebView
                otherUser={otherUser}
                sendMessage={sendMessage}
                onThreadSelect={onThreadSelect}
                users={users}
                threads={threads}
                thread={thread}
                messages={messages}
                bottomRef={bottomRefWeb}
                portalName={portalName}
                threadLoading={threadLoading}
                onMentionPress={onMentionPress}
                onBookingClick={onBookingClick}
              />
            }
            {isMobile &&
              <MobileView
                onThreadSelect={onThreadSelect}
                users={users}
                threads={threads}
                thread={thread}
                messages={messages}
                bottomRef={bottomRefMobile}
                otherUser={otherUser}
                sendMessage={sendMessage}
                portalName={portalName}
                threadLoading={threadLoading}
                onBookingClick={onBookingClick}
              />
            }
          </>
        )}
      </Grid>
    </Main>
  );
};

export default React.memo(View);
