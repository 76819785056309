import React, { FC } from 'react';
import { Grid, TextareaAutosize } from '@mui/material';
import { useStyles } from './TextArea.styles';
import Typo from '../Typo';

const TextAreaView = (props) => {
  const { value, placeholder, onChange, rows, rowsMax, style, disabled, onBlur, error, maximum } = props;
  const classes = useStyles(props);

  const onChangeInternal = (event) => {
    let value = event.target.value
    if (maximum) {
      value = value.slice(0, maximum)
    }
    onChange({
      ...event,
      target: {
        ...event.target,
        value
      }
    })
  }
  return (
    <Grid className={classes.container} container style={style}>
      {props.label ? (
        <Grid item xs={12} style={{ width: '100%', marginBottom: '1em' }}>
          <Typo variant="body1">{props.label}</Typo>
        </Grid>
      ) : null}
      <Grid item xs={12} style={{ width: '100%', display: 'flex' }}>
        <TextareaAutosize
          minRows={rows || 12}
          maxRows={rowsMax || rows || 12}
          placeholder={placeholder}
          value={value}
          onChange={onChangeInternal}
          className={classes.textArea}
          style={{ paddingRight: '5em' }}
          disabled={disabled}
          onBlur={onBlur}
          error={error}
        />
      </Grid>
      {maximum && (
        <Typo variant="body1" color="lightGrey" className={classes.maximumLabel}>
          {value?.length || 0} characters (maximum {maximum} characters)
        </Typo>
      )}
    </Grid>
  );
};

export default React.memo(TextAreaView);
