import { Dispatch } from 'redux';
import { getBookingsByUserIdEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

const getMyHostBookings = (
  data,
  onSuccess,
  onError,
) => (dispatch, getState) => {
  return apiFetch(getBookingsByUserIdEndpoint, data)(
    dispatch,
    getState,
  )
    .then((response) => {
      if (onSuccess) onSuccess(response);
    })
    .catch((error) => {
      if (onError) onError(error);
    });
};

export default getMyHostBookings;
