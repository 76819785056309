import React from 'react';
import { Box } from '@mui/material';
import Main from 'components/layouts/Main';
import { HeaderSection } from '../../modules';
import { SplitView } from '../../primitives';
import { useStyles } from './BookingDetails.styles';

// @ts-ignore
const BookingDetailsView = props => {
  const classes = useStyles();
  return (
    <Main>
      <Box style={{ marginTop: '1em' }}>
        <HeaderSection heading={props.heading} subHeading={props.subheading} />
        {props.headerChildren ? props.headerChildren : null}
        <Box className={classes.splitViewContainer}>
          <SplitView
            leftChild={props.leftChildren}
            rightChild={props.rightChildren}
            leftFraction={props.leftFraction || 0.333}
          />
        </Box>
        {props.footerChildren ? props.footerChildren : null}
      </Box>
    </Main>
  );
};

export default React.memo(BookingDetailsView);
