import React, { ReactElement, FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    centerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%'
    }
  }),
);

const Loading = (props) => {
  const isLoading = (props.isLoadingComplete == undefined) ? props.isLoading : !props.isLoadingComplete
  if (!isLoading) {
    if (!props.children) {
      return null
    }
    
    return (props.children)
  }

  const classes = useStyles()
  let className = props.className
  if (props.center) {
    className = classes.centerContainer
  }

  return (
    <div className={className}>
      <CircularProgress 
        //style={{ color: '#fff', marginLeft: 16 }}
        size={24}
      />
    </div>
  );
};


export default Loading;
