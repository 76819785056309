import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { UserState } from 'types/store/UserState';
import { ContactState } from 'types/store/ContactState';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import useForm from 'utils/Hooks/useForm';
import { pluck, filter } from 'ramda';
import { contactActions } from 'store/actions';
import { ContactUsProps } from './ContactUs.props';
import ContactUsView from './ContactUs.view';
import contactThunks from '../../../thunks/contact';
import {toast} from 'react-toastify'

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const initialFormState = {
  name: { value: '', error: '' },
  email: { value: '', error: '' },
  contactNumber: { value: '', error: '' },
  message: { value: '', error: '' },
  type: { value: 'enquiry', error: '' },
};

const ContactUsContainer: FC<ContactUsProps> = (props: ContactUsProps) => {
  const dispatch = useDispatch();
  const { data: user } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );
  const { status: contactStatus } = useSelectorSafe<ContactState>(
    state => state.contact,
    asyncData(ASYNC_STATUS.INITIAL),
  );
  const [message, setMessage] = useState('');
  const {
    state,
    handleOnSubmit,
    disable,
    handleOnChange,
    reset,
    handleOnBlur,
  } = useForm(
    initialFormState,
    {
      ...(!user
        ? {
            name: (value: string) => {
              if (!value) {
                return 'User is required.';
              }

              return null;
            },
            email: (value: string) => {
              if (!value) {
                return 'Email address is required.';
              }
              if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                return 'You must enter a valid email address.';
              }

              return null;
            },
            contactNumber: (value: string) => {
              if (!value) {
                return 'Contact is required.';
              }

              return null;
            },
          }
        : {}),
      type: (value: string) => {
        if (!value) {
          return 'Type is required.';
        }

        return null;
      },
      message: (value: string) => {
        if (!value) {
          return 'Message is required.';
        }

        return null;
      },
    },
    (s: any) => {
      dispatch(
        contactThunks.contactUs({
          name: s.name.value,
          email: s.email.value,
          contactNumber: s.contactNumber.value,
          type: s.type.value,
          message: s.message.value,
        }),
      );
    },
  );

  useEffect(() => {
    if (contactStatus === 'COMPLETE') {
      setMessage('')
      /*
        `${state.type.value[0].toUpperCase() +
          state.type.value.slice(1)} sent.`,
      );*/
      toast.success(`${state.type.value[0].toUpperCase() +
        state.type.value.slice(1)} sent.`)
      reset();
    }
  }, [contactStatus]);

  // will unmount
  useEffect(() => {
    return () => {
      dispatch(contactActions.clear());
    };
  }, []);

  // @ts-ignore
  const errors: string[] = Object.values(
    pluck<'error', { error?: string; touched?: boolean }>(
      'error',
      filter(s => Boolean(s.touched), state),
    ),
  ).filter(v => Boolean(v));

  const fieldsEmpty = user
    ? state.type.value === '' || state.message.value === ''
    : state.name.value === '' ||
      state.email.value === '' ||
      state.contactNumber.value === '' ||
      state.type.value === '' ||
      state.message.value === '';

  return (
    <ContactUsView
      {...props}
      user={user}
      submitMessage={handleOnSubmit}
      name={state.name}
      email={state.email}
      contactNumber={state.contactNumber}
      type={state.type}
      message={state.message}
      setName={handleOnChange('name')}
      setTouchedName={handleOnBlur('name')}
      setEmail={handleOnChange('email')}
      setTouchedEmail={handleOnBlur('email')}
      setContactNumber={handleOnChange('contactNumber')}
      setTouchedContactNumber={handleOnBlur('contactNumber')}
      setType={handleOnChange('type')}
      setTouchedType={handleOnBlur('type')}
      setMessage={handleOnChange('message')}
      setTouchedMessage={handleOnBlur('message')}
      disabled={disable || fieldsEmpty}
      msg={message}
      errors={errors}
    />
  );
};

export default React.memo(ContactUsContainer);
