import { userActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getFavouriteProductsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

interface Data {}

const getFavouriteProducts = (onSuccess, onError) => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    userActions.getFavouriteProducts(
      apiFetch<Data, any>(getFavouriteProductsEndpoint, {})(dispatch, getState)
        .then((response: any) => {
          if (onSuccess) onSuccess(response);
          return response;
        })
        .catch((error: any) => {
          if (onError) onError(error);
          return error;
        }),
    ),
  );
};

export default getFavouriteProducts;
