import React, { useState, useEffect } from 'react';
import {ROUTES} from 'routes';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// import momentTz from 'moment-timezone';
import parse from 'date-fns/parse';
import { differenceInDays } from 'date-fns';
import View from './ConfirmBookingDetails.view';
import bookingThunks from '../../../thunks/booking';
import { bookingDetailsActions } from '../../../store/actions';
import routerThunks from 'thunks/router'
import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import { UserState } from '../../../types/store/UserState';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import {roles} from 'services/roles'
import {toast} from 'react-toastify'

const discountRequest = (dispatch: any, discountCode: any) =>
  new Promise((resolve, reject) => {
    dispatch(
      bookingThunks.validateDiscountCode(
        { discountCode },
        (data: any) => {
          if (data.type === 'PERCENT') {
            resolve({
              id: data.id,
              type: data.type,
              value: data.value,
              formatted: `-${data.value}%`,
            });
          } else if (data.type === 'FLAT_AMOUNT') {
            resolve({
              id: data.id,
              type: data.type,
              value: data.value,
              formatted: `-$${data.value}`,
            });
          }
        },
        (error: any) => {
          // This is where I need to connect up the error message
          console.log('testing');
          console.log('_ERROR_', error);
        },
      ),
    );
  });

const getTimePeriod = (
  startDate: string,
  endDate: string,
  startTime: any,
  endTime: any,
) => {
  if (
    startDate &&
    endDate &&
    typeof startTime !== 'undefined' &&
    typeof endTime !== 'undefined'
  ) {
    return (
      (differenceInDays(
        // @ts-ignore
        parse(endDate, 'yyyy-MM-dd', new Date()),
        // @ts-ignore
        parse(startDate, 'yyyy-MM-dd', new Date()),
      ) +
        1) *
      (endTime - startTime)
    );
  }
  return 0;
};

const toBookingDetailsDto = (bookingDetails: any) => {
  const startTime =
    bookingDetails && typeof bookingDetails.startTime !== 'undefined'
      ? moment(bookingDetails.startTime, 'hh').format('LT')
      : '';
  const endTime =
    bookingDetails && typeof bookingDetails.endTime !== 'undefined'
      ? moment(bookingDetails.endTime, 'hh').format('LT')
      : '';

  const startUtc = moment(bookingDetails.startDate, 'YYYY-MM-DD')
    .add(bookingDetails.startTime, 'hours')
    .utc();

  let bookingEndDateTime;
  if (bookingDetails.startDate === bookingDetails.endDate) {
    bookingEndDateTime = moment(bookingDetails.endDate, 'YYYY-MM-DD')
      .add(bookingDetails.endTime, 'hours')
      .utc()
      .set({ date: startUtc.date() })
      .format('YYYY-MM-DD HH:mm:ss');
  } else {
    bookingEndDateTime = moment(bookingDetails.endDate, 'YYYY-MM-DD')
      .add(bookingDetails.endTime, 'hours')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
  }

  const hours =
    bookingDetails &&
    bookingDetails.startDate &&
    bookingDetails.endDate &&
    typeof bookingDetails.startTime !== 'undefined' &&
    typeof bookingDetails.endTime !== 'undefined'
      ? getTimePeriod(
          bookingDetails.startDate,
          bookingDetails.endDate,
          bookingDetails.startTime,
          bookingDetails.endTime,
        )
      : 0;

  const convertToDisplayDate = (date: string) => {
    const newDate = moment(date, 'YYYY-MM-DD').format('DD MMM, YYYY');
    return newDate;
  };
  const startDateDisplay =
    bookingDetails && bookingDetails.startDate
      ? convertToDisplayDate(bookingDetails.startDate)
      : '';
  const endDateDisplay =
    bookingDetails && bookingDetails.endDate
      ? convertToDisplayDate(bookingDetails.endDate)
      : '';
  const data = {
    fields: [
      {
        title: 'Date',
        value: `${startDateDisplay} - ${endDateDisplay}`,
      },
      { title: 'Time', value: `${startTime} - ${endTime}` },
      { title: 'Duration', value: `${hours} hours` },
    ],
  };
  return data;
};

// @ts-ignore
const OptionalRender = ({ bookingDetails, dispatch, ...props }) => {
  const product = bookingDetails.product || {}
  const pricing = product.pricing || {}

  const totalBeforeDiscount = pricing.weekdays * bookingDetails.weekdayBookingHours + pricing.weekends * bookingDetails.weekendBookingHours

  const [submitBusy, setSubmitBusy] = useState(false)
  const bookingDetailsDto = toBookingDetailsDto(bookingDetails);
  const [discountField, setDiscountField] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [discount, setDiscount] = useState(null);
  const [total, setTotal] = useState(totalBeforeDiscount);
  const [errors, setErrors] = useState({});

  const applyDiscountCode = () => {
    discountRequest(dispatch, discountField)
      .then(res => {
        // @ts-ignore
        setDiscount(res);
        setDiscountField('');
      })
      .catch(error => {});
  };

  // @ts-ignore
  const onSubmitMockSuccess = async (stripe, elements, cardSetup, manualBooking = false) => {
    setErrors({});
    setSubmitBusy(true);
    const startOn = moment(`${bookingDetails.startDate}:${bookingDetails.startTime}`, 'YYYY-MM-DD:H').format().split('+')[0]
    const endOn = moment(`${bookingDetails.endDate}:${bookingDetails.endTime}`, 'YYYY-MM-DD:H').format().split('+')[0]
    const transactions = (cardSetup && cardSetup.setupIntent && [cardSetup?.setupIntent]) || []
    //console.log('transactions:')
    //console.log(transactions)
    //console.log('-----')
    dispatch(
      bookingThunks.add(
        {
          // @ts-ignore
          prod_ID: bookingDetails.product.ID,
          // @ts-ignore
          prov_ID: bookingDetails.product.prov_ID,

          // @ts-ignore
          startOn,
          // @ts-ignore
          endOn,

          manualBooking,

          price: bookingDetails.price,

          // @ts-ignore
          questions: bookingDetails.questions,

          // @ts-ignore
          //discountId: (discount || {}).id || '',
          transactions
        },
        (success: any) => {
          console.log('success');
          setSubmitBusy(false);
          dispatch(bookingDetailsActions.setBookingStatus('COMPLETE'));
          dispatch(
            routerThunks.link(ROUTES.BOOKING_REQUESTED, {
              bookingDetails,
            }),
          )
        },
        (error: any) => {
          setSubmitBusy(false)
          toast.error(error.message || error.error)
          /*
          dispatch(bookingDetailsActions.setBookingStatus('COMPLETE'));
          dispatch(
            routerThunks.link(ROUTES.BOOKING_REQUESTED, {
              bookingDetails,
            }),
          )*/
        },
      ),
    );
  }

  // @ts-ignore
  const onSubmit = async (stripe, elements) => {
    // Need to do validation to check that nameOnCard is not empty.
    setErrors({})
    if (nameOnCard === '') {
      // Handle error. (Set the error to a message)
      setErrors({
        nameOnCard: 'Invalid Name',
      })
      return
    }

    if (!stripe || !elements) {
      setErrors({
        message: 'Stripe/elements error',
      })
      return
    }

    setSubmitBusy(true)

    const p = new Promise((resolve, reject) => {
      dispatch(
        bookingThunks.getClientSecret({},
          (data: any) => {
            if (!data.client_secret) {
              setErrors({
                message: 'Payment environment on server not setup properly',
              })
              setSubmitBusy(false)
              reject()
              return
            }
            resolve(data)
          },
          (error: any) => {
            console.log('_ERROR_', error);
            setErrors({
              booking: true,
            });
            setSubmitBusy(false)
            reject()
          },
        ),
      );
    })

    const data = await p
    const clientSecret = data.client_secret

    const card = elements.getElement('card')
    const cardSetup = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card,
        billing_details: { name: nameOnCard },
      },
    })

    console.log('paymentMethod: ', cardSetup?.setupIntent?.payment_method)

    if (cardSetup && !cardSetup.error) {
      onSubmitMockSuccess(stripe, elements, cardSetup)
    } else {
      setSubmitBusy(false)
      setErrors({
        message: cardSetup.error.message
      })
    }
  }

  const onManualBookingPress = () => {
    onSubmitMockSuccess(null, null, null, true)
  }

  useEffect(() => {
    if (discount) {
      // @ts-ignore
      if (discount.type === 'PERCENT') {
        // @ts-ignore
        setTotal(
          // @ts-ignore
          Math.round((totalBeforeDiscount - totalBeforeDiscount * (discount.value / 100)) * 100) / 100,
        );
        // @ts-ignore
      } else if (discount.type === 'FLAT_AMOUNT') {
        // @ts-ignore
        setTotal(
          // @ts-ignore
          Math.round(
            (totalBeforeDiscount -
              // @ts-ignore
              discount.value) *
              100,
          ) / 100,
        );
      }
    } else {
      // @ts-ignore
      setTotal(Math.round((totalBeforeDiscount) * 100) / 100);
    }
  }, [discount]);

  if (!process.env.REACT_APP_STRIPE_PK) {
    setErrors({
      message: 'Payment gateway missing required environment variables'
    })
  }

  return (
    <StripeProvider
      // @ts-ignore
      apiKey={process.env.REACT_APP_STRIPE_PK || null}
    >
      <Elements>
        {
          // @ts-ignore
          <View
            {...props}
            onManualBookingPress={props.hasManualBookingRole ? onManualBookingPress : null}
            discount={discount}
            setDiscount={setDiscount}
            discountField={discountField}
            setDiscountField={setDiscountField}
            applyDiscountCode={applyDiscountCode}
            nameOnCard={nameOnCard}
            setNameOnCard={setNameOnCard}
            total={total}
            // @ts-ignore
            bookingHourlyRate={bookingDetails.price}
            weekdayBookingHourlyRate={pricing.weekdays}
            weekendBookingHourlyRate={pricing.weekends}
            fields={bookingDetailsDto.fields}
             // @ts-ignore
            entityImages={product.images}
            // @ts-ignore
            entityTitle={product.title}
            // @ts-ignore
            entityAddress={
              product.location && product.location.address
                ? product.location.address
                : ''
            }
            // @ts-ignore
            productId={product.ID}
            onSubmit={onSubmit}
            onSubmitMockSuccess={onSubmitMockSuccess}
            provider={product.provider}
            submitBusy={submitBusy}
            loading={false}
            errors={errors}
          />
        }
      </Elements>
    </StripeProvider>
  );
};

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

// @ts-ignore
const ConfirmBookingDetailsContainer = props => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: user } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }

  const hasManualBookingRole = user.data.user.roles.includes(roles.manual_booking)

  const bookingState = useSelectorSafe<any>(
    (state: any) => state.bookingDetails,
  );
  const bookingDetails =
    bookingState && bookingState.booking ? bookingState.booking : null;
  if (
    !(bookingDetails && bookingDetails.questions) ||
    bookingDetails.status === 'COMPLETE'
  ) {
    dispatch(routerThunks.link(ROUTES.HOME));
    return null;
  }
  const goToHostDetails = () => {
    // @ts-ignore
    if (
      // @ts-ignore
      bookingDetails &&
      // @ts-ignore
      bookingDetails.product &&
      bookingDetails.product.provider &&
      // @ts-ignore
      bookingDetails.product.provider.ID
    ) {
      // @ts-ignore
      const hostId = bookingDetails.product.provider.ID;
      // @ts-ignore
      dispatch(
        // @ts-ignore
        routerThunks.link(ROUTES.HOST_DETAILS, { id: hostId }),
      );
    }
  };
  return (
    <OptionalRender
      bookingDetails={bookingDetails}
      dispatch={dispatch}
      goToHostDetails={goToHostDetails}
      hasManualBookingRole={hasManualBookingRole}
      {...props}
    />
  );
};

export default ConfirmBookingDetailsContainer;
