import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import View from './InviteAFriend.view';
import routerThunks from 'thunks/router'
import userThunks from '../../../thunks/user';
import {ROUTES} from '../..';
import { UserState } from '../../../types/store/UserState';
import { asyncData } from '../../../utils/Redux';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const InviteAFriendContainer = () => {
  const dispatch = useDispatch();
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };
  const { data: user } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );
  if (!user) {
    goToListings();
  }
  const [friendName, setFriendName] = useState('');
  const [friendEmail, setFriendEmail] = useState('');
  const [message, setMessage] = useState('');
  const [canInviteAFriend, setCanInviteAFriend] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const clearFields = () => {
    setFriendName('');
    setFriendEmail('');
    setMessage('');
    setCanInviteAFriend(false);
  };
  const goToHome = () => dispatch(routerThunks.link(ROUTES.ROOT));
  const inviteAFriend = () => {
    dispatch(
      userThunks.inviteAFriend(
        {
          friendName,
          friendEmail,
          message,
        },
        (response: any) => {
          clearFields();
          setSuccessMessage('Friend invitation sent.');
        },
        (error: string) => {
          console.log('_SHOW_ERROR_MESSAGE_', error);
          setSuccessMessage('');
        },
      ),
    );
  };
  useEffect(() => {
    // Should convert this validation to proper validation
    setCanInviteAFriend(
      !(friendName === '' || friendEmail === '' || message === ''),
    );
  }, [friendName, friendEmail, message]);
  return (
    // @ts-ignore
    <View
      friendName={friendName}
      friendEmail={friendEmail}
      message={message}
      setFriendName={(event: any) => setFriendName(event.target.value)}
      setFriendEmail={(event: any) => setFriendEmail(event.target.value)}
      setMessage={(event: any) => setMessage(event.target.value)}
      canInviteAFriend={canInviteAFriend}
      goToHome={goToHome}
      inviteAFriend={inviteAFriend}
      successMessage={successMessage}
    />
  );
};

export default InviteAFriendContainer;
