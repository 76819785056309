import { handleActions } from 'redux-actions';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';
import { CreateAccount } from 'types/CreateAccount';
import { CreateAccountState } from 'types/store/CreateAccountState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';

const DEFAULT_STATE: CreateAccountState = asyncData(ASYNC_STATUS.INITIAL);


const reducer = handleActions<CreateAccountState, any>(
  {
    ...createAsyncReducers<CreateAccount>('AUTH/SIGNUP'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
