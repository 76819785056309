import React, { FC, useState } from 'react';
import { TextField as MTextField, Grid, InputAdornment, IconButton } from '@mui/material';
import { useStyles } from './InputField.styles';
import {TextField} from '../TextField'
import {Visibility, VisibilityOff} from '@mui/icons-material'

export const onKeyPressPositiveDecimal = (data) => {
  let charCode = (data.which) ? data.which : data.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    data.preventDefault();
  } else {
    return true;
  }
}

export const onKeyPressPositiveInteger = (data) => {
  let charCode = (data.which) ? data.which : data.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
    data.preventDefault();
  } else {
    return true;
  }
}

export const onPasteDisable = (e) => {
  e.preventDefault()
}

const InputFieldView = (props) => {
  const {
    id,
    icon: Icon,
    placeholder,
    style,
    onChange,
    value,
    className,
    type = 'text',
    onBlur,
    multiline,
    errorMessage = '',
    disabled,
    onKeyPress,
    onPaste,
    inputProps,
    required
  } = props;

  const classes = useStyles(props);

  const isPassword = (type == 'password')
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  return (
    <Grid
      container
      alignItems="flex-end"
      className={`${classes.container} ${className}`}
      style={style}
    >
      {Icon ? (
        <Grid item className={classes.iconContainer}>
          <Icon className={classes.icon} />
        </Grid>
      ) : null}
      <Grid item xs>
        <TextField
          id={id}
          error={errorMessage.length > 0 || props.error}
          label={placeholder}
          className={classes.inputField}
          InputProps={{
            //disableUnderline: true,
            className: classes.input,
            endAdornment: isPassword && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          helperText={errorMessage}
          FormHelperTextProps={{
            classes: {
              root: classes.error,
            },
            error: true,
          }}
          onChange={onChange}
          value={value}
          type={showPassword ? 'text' : type}
          onBlur={onBlur}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
          inputProps={inputProps}
          required={required}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(InputFieldView);
