import React, { FC } from 'react';
import {Button} from 'components/primitives'
import { useDispatch } from 'react-redux';
import routerThunks from 'thunks/router'
import {ROUTES, PORTALS} from 'routes'

const DevRoot = (props) => {
    const dispatch = useDispatch()

    return (
        <div>
            Airplacer Dev Root
            <Button
                text="Customer"
                style={{marginBottom: '5px'}}
                onClick={() => {
                    dispatch(routerThunks.link(ROUTES.ROOT, null, PORTALS.CUSTOMER))
                }}
            />
            <Button
                text="Host"
                style={{marginBottom: '5px'}}
                onClick={() => {
                    dispatch(routerThunks.link(ROUTES.ROOT, null, PORTALS.HOST))
                }}
            />
            <Button
                text="Admin"
                style={{marginBottom: '5px'}}
                onClick={() => {
                    dispatch(routerThunks.link(ROUTES.ROOT, null, PORTALS.ADMIN))
                }}
            />
        </div>
    )
}

export default DevRoot