import { API } from '../../consts';
import _ from 'lodash'

const { URL, VERSION } = API;

export const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const endpointGroups = {
  AUTH: 'auth',
  CONTACT: 'contact',
  ENTITY: 'product',
  PRODUCT: 'product',
  USER: 'user',
  BOOKING: 'booking',
  HELP_DESKS: 'helpdesks',
  MESSAGING: 'messaging',
  REVIEW: 'review',
  HOST: 'provider',
  PROVIDER: 'provider',
  AVAILABILITIES: 'availabilities',
  SETTINGS: 'settings'
};

export const auth = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
};


const withInfiniteScroll = (url) => {
  const urlTransformer = (data) => {
    if (data.startKey) {
      return `${url}?start-key=${data.startKey}`
    }
    return url
  }
  return urlTransformer
}

const getQuery = (obj) => {
  let query = _.map(obj, (value, key) => {
    if (value === null || value === undefined) {
      return ''
    }
    return `${key}=${encodeURIComponent(value)}`
  }).join('&')
  if (query) {
    query = `?${query}`
  }
  return query
}


export const facebookAccessEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/facebook-access`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const googleAccessEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/google-access`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const signupEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/signup`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const loginEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/login`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const forgotPasswordEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/forgot-password`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const resetPasswordEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/reset-password`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const contactUsEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTACT}/contact-us`,
  method: methods.POST,
  auth: auth.OPTIONAL,
};

export const getProviderEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const updateProviderEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getUsersEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: (obj) => {
    let query = getQuery(obj)
    return `${URL}/${VERSION}/${endpointGroups.USER}${query}`
  },
}

export const getProfileEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/profile`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const updateProfileEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/profile`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const userSignedUrlEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/signed-url`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const addFavouriteEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/favourite`,
  method: methods.POST,
  auth: auth.REQUIRED,
  urlTransformer: ({ productId }) =>
    `${URL}/${VERSION}/${endpointGroups.USER}/favourite/${productId}`,
};

export const removeFavouriteEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/favourite`,
  method: methods.DELETE,
  auth: auth.REQUIRED,
  urlTransformer: ({ productId }) =>
    `${URL}/${VERSION}/${endpointGroups.USER}/favourite/${productId}`,
};

export const getFavouritesEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/favourites`,
  method: methods.GET,
  auth: auth.REQUIRED,
}

export const getFavouriteProductsEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/favourite-products`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const sendVerificationEmailEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/send-verification-email`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const updatePasswordEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/update-password`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const addProductEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PRODUCT}`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const productSignedUrlEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PRODUCT}/signed-url`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getEntityByIdEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: ({ productId }) =>
    `${URL}/${VERSION}/${endpointGroups.ENTITY}/${productId}`,
};

export const getEntityFilters = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}/filters`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getBookingsEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}s`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: ({ bookingId }) =>
    `${URL}/${VERSION}/${endpointGroups.BOOKING}s`,
};

export const getBookingByIdEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: ({ bookingId }) =>
    `${URL}/${VERSION}/${endpointGroups.BOOKING}/${bookingId}`,
};

export const providerSignedUrlEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}/signed-url`,
  method: methods.POST,
  auth: auth.REQUIRED,
}

export const postProviderEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}`,
  method: methods.POST,
  auth: auth.REQUIRED,
}

export const postProviderUpdateUserEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}/update-user`,
  method: methods.POST,
  auth: auth.REQUIRED,
}

export const getProviderUsersEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}/:providerId/users`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: (provider) => {
    return `${URL}/${VERSION}/${endpointGroups.PROVIDER}/${provider.ID}/users`
  }
}

export const getProvidersEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}`,
  method: methods.GET,
  auth: auth.REQUIRED,
}

export const getProvidersForUserEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}/user-providers`,
  method: methods.GET,
  auth: auth.REQUIRED,
}

export const setActiveProviderForUserEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}/set-active`,
  method: methods.POST,
  auth: auth.REQUIRED
}

export const getActiveProviderForUserEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PROVIDER}/active`,
  method: methods.GET,
  auth: auth.REQUIRED
}

export const getProviderBookingsEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}/provider-bookings`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: (obj) => {
    let query = getQuery(obj)
    return `${URL}/${VERSION}/${endpointGroups.BOOKING}/provider-bookings${query}`
  },
};

export const getBookingByProductIdEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}s/product`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: ({ productId }) =>
    `${URL}/${VERSION}/${endpointGroups.BOOKING}s/product/${productId}`,
};

export const addBookingEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const validateDiscountCodeEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}/validate-discount-code`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getClientSecretEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}/client-secret`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const getBookingsByUserIdEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}/user-bookings`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: (obj) => {
    //let url = withInfiniteScroll(`${URL}/${VERSION}/${endpointGroups.BOOKING}/user-bookings`)(obj)
    if (obj.startKey) {
      obj['start-key'] = obj.startKey
      delete obj.startKey
    }
    let query = getQuery(obj)
    return `${URL}/${VERSION}/${endpointGroups.BOOKING}/user-bookings${query}`
  },
/*
  urlTransformer: 
  /*({ hostId }) =>
    hostId
      ? `${URL}/${VERSION}/${
          endpointGroups.BOOKING
        }/user-bookings?prov_ID=${hostId}`
      : `${URL}/${VERSION}/${endpointGroups.BOOKING}/user-bookings`,
      */
};

export const getAllHelpDesksEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.HELP_DESKS}`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const inviteAFriendEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/invite-a-friend`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getThreadByIdEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread`,
  method: methods.POST,
  auth: auth.REQUIRED,
  urlTransformer: ({ thre_ID }) => {
    return `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread/${thre_ID}`
  },
};

export const getProviderThreadsEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread/provider-threads`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const getUserThreadsEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread/user-threads`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const sendMessageEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const createReviewEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.REVIEW}`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getReviewsEndpoint = {
  url: `${URL}/${VERSION}${endpointGroups.REVIEW}`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: ({ productId, ...otherParams }) => {
    let query = getQuery(otherParams)
    return `${URL}/${VERSION}/${endpointGroups.REVIEW}/product/${productId}${query}`
  },
};

export const getHostEndpoint = {
  url: `${URL}/${VERSION}${endpointGroups.HOST}`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: ({ hostId }) =>
    `${URL}/${VERSION}/${endpointGroups.HOST}/${hostId}`,
};
/*
export const readThreadEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread`,
  method: methods.POST,
  auth: auth.REQUIRED,
  urlTransformer: ({ thre_ID }) => {
    return `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread/${thre_ID}`
  },
};
*/

export const getAvailabilitiesByEntityIdEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AVAILABILITIES}`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: ({ productId, startDate, endDate }) =>
    `${URL}/${VERSION}/${
      endpointGroups.AVAILABILITIES
    }/${productId}?startDate=${startDate}&endDate=${endDate}`,
};

export const getProviderProductsEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.PRODUCT}/provider-products`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: withInfiniteScroll(`${URL}/${VERSION}/${endpointGroups.PRODUCT}/provider-products`)
};

export const getAllEntitiesEndpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}s`,
  method: methods.GET,
  auth: auth.OPTIONAL,
  urlTransformer: ({
    startKey,
    capacity,
    priceRange,
    dateRange,
    attributes,
    types,
    subtypes,
    location,
  }) => {
    let url = `${URL}/${VERSION}/${endpointGroups.ENTITY}s`;
    if (
      !(
        startKey ||
        capacity ||
        priceRange ||
        dateRange ||
        attributes ||
        types ||
        subtypes ||
        location
      )
    ) {
      return url
    }
    url += '?'
    let params = []
    if (startKey) {
      params.push(`start-key=${startKey}`)
    }
    if (capacity && capacity.max) {
      params.push(
        `capacity=${capacity.min || 0},${capacity.max}`,
      );
    }
    if (priceRange && priceRange.max) {
      params.push(
        `price=${priceRange.min || 0},${priceRange.max}`,
      );
    }
    if (dateRange && dateRange.from && dateRange.to) {
      params.push(
        `date=${dateRange.from},${dateRange.to}`,
      );
    }
    if (attributes && attributes.length) {
      params.push(
        `attributes=${attributes.join(',')}`
      )
    }
    if (types && types.length) {
      params.push(
        `types=${types.join(',')}`
      )
    }
    if (subtypes && subtypes.length) {
      params.push(
        `subtypes=${subtypes.join(',')}`
      )
    }
    if (location) {
      let locationStr = `location=${location.lat},${location.long}`
      if (location.radius) {
        locationStr += `,${location.radius}`
      }
      params.push(locationStr)
    }

    params = params.filter(p => !!p)
    return `${url}${params.join('&')}`;
  },
};
