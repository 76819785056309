import { makeStyles, createStyles } from '@mui/styles';
import { colors, fontFamilies } from 'theme/themeConstants';
import { SearchProps } from './Search.props';

export const useStyles = makeStyles((theme) =>
  createStyles({
    searchBar: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    locationSearchContainer: {
      width: '345px',
      marginRight: '20px',
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
      },
    },
    filterBarContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      //justifyContent: 'space-between',
      //flex: 1,
      alignItems: 'center',

      '& button': {
        width: '160px'
      },

      //alignSelf: 'stretch',
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px',
      },
    },
    filterLabel: {
      height: '30px',
      padding: '0 15px',
      borderRadius: '15px',
      border: 0,
      color: colors.white,
      backgroundColor: colors.blue,
      marginRight: '14px',
      fontFamily: fontFamilies.gilroy,
      fontSize: '15px',
      marginBottom: '5px',
    },
    filterToggle: {
      height: '30px',
      padding: '0 15px',
      borderRadius: '15px',
      borderWidth: 1,
      borderColor: colors.lightGrey,
      color: colors.lightGrey,
      backgroundColor: colors.white,
      fontFamily: fontFamilies.gilroy,
      fontSize: '15px',
    },
    contentContainer: {
      display: 'flex',
      overflow: 'hidden',
      flex: 1,

      // isMobile:
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
    },
    featureContainer: {
      paddingTop: '50px',
      overflowY: 'auto',
      flex: 1,
    },
    filterContainer: {
      overflowY: 'auto',
      flex: 1,
    },
    featureHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '30px',
      [theme.breakpoints.down('xs')]: {
        '& h1': {
          fontSize: '25px',
        },
        '& h5': {
          fontSize: '15px',
        },
      },
    },
    featureItemsContainer: {
      marginBottom: '80px',
      position: 'relative',
      '&:after': {
        content: '""',
        background: colors.lightGrey,
        opacity: 0.1,
        position: 'absolute',
        top: '45%',
        zIndex: -1,
        left: 0,
        right: 0,
        bottom: '-30px',
      },
    },
    additionalFilterContainer: {},
    resultsItemsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      [theme.breakpoints.down('xl')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
      gridGap: '30px 0px',
    },
    resultsItemContainerVisibleMap: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      [theme.breakpoints.down('xl')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr',
      },
      gridGap: '30px 0px',
    },
    mapContainer: {
      display: 'flex',

      // isMobile:
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
    },
    showMapButtonContainer: {
      width: '40px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      boxShadow: '1px 0 1px rgba(0,0,0,0.02)',

      // isMobile:
      [theme.breakpoints.down('sm')]: {
        minHeight: '40px',
        height: '40px',
        width: '100%'
      },
    },
    toggleMapButton: {
      width: '40px',
      border: 0,
      background: 'transparent',
      height: '100%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '999',

      // isMobile:
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40px'
      }
    },
    buttonIconContainer: {
      width: '18px',
      height: '100px',

      // isMobile:
      [theme.breakpoints.down('sm')]: {
        transform: 'rotate(90deg)',
      },
    },
    list: {
      width: 400,
    },
    map: ({ isMapVisible }: SearchProps) => ({
      transition: 'width 150ms ease-in-out, height 150ms ease-in-out, min-height 150ms ease-in-out',
      maxWidth: '50vw',
      width: isMapVisible ? '550px' : '0',
      height: '100%',
    }),
    mapMobile: ({ isMapVisible }: SearchProps) => ({
      transition: 'width 150ms ease-in-out, height 150ms ease-in-out, min-height 150ms ease-in-out',

      maxWidth: '100%',
      height: isMapVisible ? '40vh' : '0',
      minHeight: isMapVisible ? '40vh' : '0',
      backgroundColor: isMapVisible ? 'red' : 'green',
      width: '100%'
    }),
    mapInner: {
      width: '550px',
      height: '100%',

      // isMobile:
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '40vh'
      }
    }
  }),
);
