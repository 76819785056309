import React from 'react';
import Box from '@mui/material/Box';
import { Main } from 'components/layouts';
import { HeaderSection } from '../../../components/modules';
import { Link, Typo } from '../../../components/primitives';
import LoginModal from '../../../components/modules/LoginModal';
import Avatar from '../../../components/primitives/Avatar';

// @ts-ignore
const View = ({
  loading,
  host,
  hostName,
  memberSince,
  onContactHost,
  showModal,
  setShowModal,
}: {
  loading: boolean;
  hostName: string;
  memberSince: string;
  onContactHost: () => void;
  showModal: any;
  setShowModal: any;
}) => {
  return (
    <Box style={{ height: '100vh' }}>
      <Main fullWidth>
        <div
          style={{
            maxHeight: '100%',
            marginTop: 16,
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '2em',
            paddingRight: '2em',
          }}
        >
          {loading ? null : (
            <div style={{ width: '100%', maxWidth: '1200px' }}>
              <div style={{
                display: 'flex',
              }}>
                <Avatar square={true} size={180} src={(host && host.image) || ''}/>

                <div style={{
                  marginLeft: '20px'
                  }}
                >
                  <HeaderSection heading={hostName} subHeading={memberSince} />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      paddingBottom: '2em',
                      flexDirection: 'column',
                    }}
                  >
                    <div style={{ marginTop: '1em' }}>
                      <Link onClick={onContactHost}>
                        <Typo color="blue">Contact Host</Typo>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <LoginModal open={showModal} onClose={() => setShowModal('CLOSE')} />
      </Main>
    </Box>
  );
};

export default React.memo(View);
