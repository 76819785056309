import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import routerThunks from 'thunks/router'
import bookingThunks from 'thunks/booking';
import {ROUTES} from 'routes';
import View from './UserDetails.view';
import {useInfiniteScroll} from 'components/modules'
import moment from 'moment';

const Container = (props: any) => {
  const dispatch = useDispatch();
  const goToUser = (id: string) => {
    console.log('View user with id:', id)
    //dispatch(routerThunks.link(ROUTES.HOST_DETAILS, { id }));
  };
  const goToBooking = (id: string) => {
    dispatch(routerThunks.link(ROUTES.VIEW_BOOKING_DETAILS, { id }));
  };

  const fetchMoreFn = (lastKey, resolve, reject) => {
    const params = {
      user_ID: props.user.ID
    }
    if (lastKey) {
      params['start-key'] = lastKey
    }

    dispatch(
      bookingThunks.getProviderBookings(params,
        (response) => {
          const bookings = response.data.bookings
          let filteredBookings = bookings.sort((a, b) => {
            return moment(a.startOn) - moment(b.startOn)
          })
          resolve(filteredBookings, response.lastKey)
        },
        (error) => {
          reject(error)
        }
      )
    )
  }
  const {data: bookings, setData: setBookings, fetchMore, hasMore, loading} = useInfiniteScroll(fetchMoreFn, [props.user])

  return (
    <View
      {...props}
      goToUser={goToUser}
      bookings={bookings}
      hasMore={hasMore}
      loading={loading}
      fetchMore={fetchMore}
      goToBooking={goToBooking}
      onMentionPress={props.onMentionPress}
    />
  );
};

export default React.memo(Container);
