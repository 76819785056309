import React, { FC } from 'react';
import { Box } from '@mui/material';
import { EmailIcon, LockIcon, UserIcon } from 'assets';
import { useStyles } from './CreateAccountForm.styles';
import { CreateAccountFormProps } from './CreateAccountForm.props';
import { Typo, Button, InputField, Link, Checkbox } from '../../primitives';
import { LoginModalForms } from '../LoginModal/LoginModal.const';
import {FormControlLabel} from '@mui/material'

const ResetPasswordFormView: FC<CreateAccountFormProps> = props => {
  const classes = useStyles();
  const onLoginClick = props.openModal
    ? props.openModal(LoginModalForms.LOGIN)
    : () => {};

  const {errorMessage, errorField, busy} = props

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        Create Account
      </Typo>
      <Typo variant="body2" className={classes.subHeading} color="lightGrey">
        Enter your details to get started
      </Typo>
      
      <InputField
        icon={UserIcon}
        id="firstName"
        placeholder="First Name"
        className={classes.field}
        value={props.firstName.value}
        error={errorField == 'firstName'}
        //error={Boolean(props.firstName.error) && props.firstName.touched}
        onChange={event => props.setFirstName(event.target.value)}
        onBlur={props.setTouchedFirstName}
      />
      <InputField
        icon={UserIcon}
        id="lastName"
        placeholder="Last Name"
        className={classes.field}
        value={props.lastName.value}
        error={errorField == 'lastName'}
        //error={Boolean(props.lastName.error) && props.lastName.touched}
        onChange={event => props.setLastName(event.target.value)}
        onBlur={props.setTouchedLastName}
      />
      <InputField
        icon={EmailIcon}
        id="email"
        placeholder="Email Address"
        className={classes.field}
        value={props.emailAddress.value}
        error={errorField == 'emailAddress'}
        //error={Boolean(props.emailAddress.error) && props.emailAddress.touched}
        onChange={event => props.setEmailAddress(event.target.value)}
        onBlur={props.setTouchedEmailAddress}
      />
      <InputField
        icon={LockIcon}
        type="password"
        id="password"
        placeholder="Password"
        className={classes.field}
        value={props.password.value}
        error={errorField == 'password'}
        //error={Boolean(props.password.error) && props.password.touched}
        onChange={event => props.setPassword(event.target.value)}
        onBlur={props.setTouchedPassword}
      />
      <InputField
        icon={LockIcon}
        type="password"
        id="confirmPassword"
        placeholder="Confirm Password"
        className={classes.field}
        value={props.confirmPassword.value}
        error={errorField == 'confirmPassword'}
        /*
        error={
          Boolean(props.confirmPassword.error) && props.confirmPassword.touched
        }*/
        onChange={event => props.setConfirmPassword(event.target.value)}
        onBlur={props.setTouchedConfirmPassword}
      />
      <Box className={classes.terms}>
        <Typo variant="body1" color="lightGrey">
          By continuing you agree to our&nbsp;
          <Link onClick={props.toTerms}>
            <Typo variant="body1" color="red" component="span">
              T&Cs&nbsp;
            </Typo>
          </Link>
          and&nbsp;
          <Link onClick={props.toPrivacy}>
            <Typo variant="body1" color="red" component="span">
              Privacy Policy
            </Typo>
          </Link>
        </Typo>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.agreeTerms.value}
            onClick={() => {
              props.setTouchedAgreeTerms(true) 
              props.setAgreeTerms(!props.agreeTerms.value)
            }}
            error={errorField == 'agreeTerms'}
          />
        }
        label="I agree"
        className={classes.agreeTerms}
      />
      {/*props.errors.length ? (
        <div className={classes.errorMessage}>
          {props.errors.map(msg => (
            <Typo variant="body1" color="red" key={msg}>
              {msg}
            </Typo>
          ))}
        </div>
          ) : null*/}

      {Boolean(errorMessage) && (
        <Typo variant="body1" color="red">
          {errorMessage}
        </Typo>
      )}

      <Button
        text="Create Account"
        className={classes.button}
        onClick={props.onCreateAccount}
        disabled={busy}
        loading={busy}
      />
      <Box className={classes.footer}>
        <Typo variant="body1" color="lightGrey">
          Already have an account?&nbsp;
          <Typo
            variant="body1"
            color="blue"
            component="a"
            onClick={onLoginClick}
            className={classes.link}
          >
            Log in
          </Typo>
        </Typo>
      </Box>
      
    </>
  );
};

export default React.memo(ResetPasswordFormView);
