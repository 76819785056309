import { userActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getProfileEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

interface Data {}

const getProfile = () => (
  dispatch: Dispatch,
  getState: () => Store,
) => {
  return dispatch(
    userActions.getProfile(
      apiFetch<Data, any>(getProfileEndpoint, {})(dispatch, getState)
        .then(p => {
          return p.data
        }),
    ),
  );
};

export default getProfile;
