import React, {useRef} from 'react';

import { Button as MButton, Container, Box } from '@mui/material';
import { Button, LocationDetailsCard, SplitView, Stepper, Select } from 'components/primitives';
import { BookingDetails as BookingDetailsLayout } from 'components/layouts';
import Typo from 'components/primitives/Typo';
import { useStyles } from './CreateListing.styles';
import Main from 'components/layouts/Main';
import Slider from 'react-slick';
import { AppBar } from 'components/modules';

const sliderSettings = {
  touchMove: false,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  useTransform: true,
  autoplay: false,
  arrows: false,
  infinite: false,
  dots: false,
  pauseOnHover: true,
  centerMode: false,
};

// @ts-ignore
const CreateListingView = (props) => {
  const {
    // @ts-ignore
    questionComponents,
    // @ts-ignore
    bookingDetails,
    // @ts-ignore
    onSubmit,
    // @ts-ignore
    goToHostDetails,
    onViewListing,

    steps,
    activeStep,
    onNextStep,
    onBackStep,
    nextDisabled,
    errorMessage,
    errorField
  } = props;
  const classes = useStyles(props);
  const Component = steps[activeStep].component
  const nextBusy = false

  return (
    <Box className={classes.mainContainer}>
      <AppBar />

      <div className={classes.footerContainer}>
        <Container
          style={{
            height: 'calc(100% - 64px - 12px)',
          }}
        >
          <Stepper steps={steps} activeStep={activeStep} onNext={onNextStep} onBack={onBackStep} nextDisabled={nextDisabled} errorMessage={errorMessage}/>
        </Container>
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.featureContainer} id='scrollableDiv'>
          <Container
            style={{
              height: 'calc(100% - 64px - 12px)',
            }}
          >
            <Component {...props}/>
            <div className={classes.bottomPad}/>
          </Container>
        </div>
      </div>

      <div className={classes.footerContainer}>
        <Container
          style={{
            height: 'calc(100% - 64px - 12px)',
          }}
        >
          <div className={classes.errorContainer}>
            {Boolean(errorMessage) && (
              <Typo variant="body1" color="red">
                {errorMessage}
              </Typo>
            )}
          </div>
          <div className={classes.buttonContainer}> 
            {activeStep != 0 &&
              <Button
                text="Back"
                onClick={onBackStep}
                className={classes.nextButton}
                disabled={nextDisabled || nextBusy}
                style={{marginRight: '20px' }}
                color="blue"
                backgroundColor="white"
                variant='outlined'
              />
            }
            <Button
              text="Next"
              onClick={onNextStep}
              className={classes.nextButton}
              disabled={nextDisabled || nextBusy}
              loading={nextBusy}
            />
          </div>
        </Container>
      </div>
      
    </Box>
  );
};

export default React.memo(CreateListingView);
