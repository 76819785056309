import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import routerThunks from 'thunks/router'
import {ROUTES} from 'routes';
import ScheduledBookingsView from './ScheduledBookings.view';
import moment from 'moment'
import bookingThunks from 'thunks/booking';
import {useInfiniteScroll, useChangeBookingStatus} from 'components/modules'
import { bookingIsPassed } from 'services/api';

const ScheduledBookingsContainer = (props) => {
  const dispatch = useDispatch()

  const fetchMoreFn = (lastKey, resolve, reject) => {
    const params = {
      status: 'confirmed'
    }
    if (lastKey) {
      params['start-key'] = lastKey
    }

    dispatch(
      bookingThunks.getProviderBookings(params,
        (response) => {
          const bookings = response.data.bookings
          let filteredBookings = bookings.filter(booking => {
            return !bookingIsPassed(booking)
          })
          filteredBookings = filteredBookings.sort((a, b) => {
            if (b.product.title == a.product.title) {
              return moment(a.startOn) - moment(b.startOn)
            }
            return a.product.title.localeCompare(b.product.title)
          })
/*
          // because we filter client side, we need to get more data if we filter out manually :-/
          // we should move this filtering to the backend
          if (filteredBookings.length <= 0 && response.lastKey) {
            fetchMoreFn(response.lastKey, resolve, reject)
            return 
          }
*/
          resolve(filteredBookings, response.lastKey)
        },
        (error) => {
          reject(error)
        }
      )
    )
  }
  const {data: bookings, setData: setBookings, fetchMore, hasMore, loading} = useInfiniteScroll(fetchMoreFn)

  const onViewBooking = (id) => () =>
    dispatch(routerThunks.link(ROUTES.VIEW_BOOKING_DETAILS, { id }));

  const onContactGuest = (booking) => () => {
    dispatch(routerThunks.link(ROUTES.MESSAGES, { user: booking.user }));
  }

  const changeBookingStatus = useChangeBookingStatus((newBooking) => {
    setBookings((prev) => {
      const patchedBookings = prev.filter((b) => b.ID != newBooking.ID)
      return patchedBookings
    })
  })

  const onCancelBooking = (booking) => () => {
    changeBookingStatus.show(booking, 'cancelled')
  }
 
  return (
    <ScheduledBookingsView
      {...props}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      results={bookings}
      onViewBooking={onViewBooking}
      onCreateNewListing={() => dispatch(routerThunks.link(ROUTES.CREATE_LISTING))}
      onViewRequest={onViewBooking}
      onContactGuest={onContactGuest}
      goToBookingHistory={() => dispatch(routerThunks.link(ROUTES.BOOKING_HISTORY))}
      onCancelBooking={onCancelBooking}
      changeBookingStatus={changeBookingStatus}
    />
  );
};

export default React.memo(ScheduledBookingsContainer);
