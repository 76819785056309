import { makeStyles, createStyles } from '@mui/styles';
import {flex_row} from 'utils'

const imageAndPlaceholderContainer = {
  backgroundColor: 'rgb(245, 245, 245)',
  borderRadius: '10px',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'relative'
}

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      ...flex_row,
      gap: '10px',
      height: '285px',
    },
    placeholderContainer: {
      ...imageAndPlaceholderContainer,
      cursor: 'pointer',
    },
    imageContainer: {
      ...imageAndPlaceholderContainer,
      cursor: 'move',
    },
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '10px',
      objectFit: 'cover'
    },
    remove: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      cursor: 'pointer',
    }
  }),
);
