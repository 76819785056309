import React from 'react';
import { Button, LocationDetailsCard, Link } from 'components/primitives';
import { BookingDetails as BookingDetailsLayout } from 'components/layouts';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typo from '../../../components/primitives/Typo';
import { useStyles } from './BookingRequested.styles';

const BookingRequestedView = ({
  goHome,
  bookingDetails,
  goToMyBookings,
  goToHostDetails,
}: {
  goHome: () => void;
  bookingDetails: any;
  goToMyBookings: () => void;
  goToHostDetails: () => void;
}) => {
  const classes = useStyles();
  if (!bookingDetails) {
    // Should add some code so that it redirects correctly.
    return <div />;
  }
  const product = bookingDetails.product
  const pricing = product.pricing || {}

  return (
    <BookingDetailsLayout
      heading="Booking Requested"
      leftChildren={
        <LocationDetailsCard
          images={product.images}
          title={product.name}
          weekdayPrice={pricing.weekdays}
          weekendPrice={pricing.weekends}
          address={
            product.location && product.location.address
              ? product.location.address
              : ''
          }
          price={bookingDetails.price}
          fields={[
            { title: 'Date', value: bookingDetails.fields[0].value },
            { title: 'Time', value: bookingDetails.fields[1].value },
            {
              title: 'Duration',
              value: `${bookingDetails.fields[2].value} hours`,
            },
          ]}
          provider={product.provider}
          goToHostDetails={goToHostDetails}
        />
      }
      rightChildren={
        <Grid container>
          <Grid item xs={12}>
            <Typo variant="h4" color="darkGrey">
              Request submitted!
            </Typo>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <Typo variant="body1" color="lightGrey">
              {`Your booking request has been lodged, you may track it's progress in `}
              <Link onClick={goToMyBookings}>
                <span className={classes.field}>My Bookings.</span>
              </Link>
            </Typo>
          </Grid>
          <Box style={{ width: '100%', paddingTop: 16 }}>
            <Button onClick={goHome} text="Return Home" />
          </Box>
        </Grid>
      }
    />
  );
};

export default BookingRequestedView;
