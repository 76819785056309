import React, { FC } from 'react';
import ListingDetailsView from './ListingDetails.view';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { useDispatch } from 'react-redux';
import routerThunks from 'thunks/router'
import {ROUTES} from 'routes';

const ListingDetailsContainer = (
  ownProps,
) => {
  const combinedProps = {
    ...ownProps,
  };

  const dispatch = useDispatch();
  const payload = useSelectorSafe(state => state.location.payload);

  const onEditListing = (id) => () => {
    console.log('edit listing!')
    dispatch(routerThunks.link(ROUTES.EDIT_LISTING, { id }));
  };

  return <ListingDetailsView {...combinedProps}
    onEditListing={onEditListing(payload.id)}
  />;
};

export default ListingDetailsContainer;
