import React from 'react';
import HostedBy from 'components/modules/HostedBy';
import { ChatMessage } from '../Chatlog/Chatlog.view';
import {bookingTimeData} from 'services/api'
import {InfiniteScroll } from 'components/modules';
import {
  Typo,
  HorizontalLine,
  Loading
} from 'components/primitives';

const HostDetails = (props) => {
  const {
    host,
    goToHost,
    bookings,
    goToBooking,
    loading,
    hasMore,
    fetchMore,
    onMentionPress
  } = props
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', maxHeight: '100%' }}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '1.5em',
            paddingLeft: '1.5em',
            paddingRight: '1.5em',
          }}
        >
          <HostedBy
            host={host}
            onClick={() => {
              if (host && host.ID) {
                goToHost(host.ID);
              }
            }}
          />
          <HorizontalLine style={{ marginTop: '2em', marginBottom: '0.5em' }} />
        </div>
        <div
        /*
          style={{
            paddingLeft: '1.5em',
            paddingRight: '1.5em',
            paddingBottom: '1.5em',
          }}*/
        >
          <Loading isLoading={loading}>
            <InfiniteScroll
              style={{
                width: '100%',
                display: 'grid',
                //gridTemplateColumns: 'repeat(auto-fill, 345px)',
                gridGap: '2em',
                justifyContent: 'center',
                paddingBottom: '2em',
              }}
              hasMore={hasMore}
              fetchMore={fetchMore}
              /*
              emptyMessage={
                <Typo>
                    There are no bookings at this time
                </Typo>
              }*/
            >
              {//
              // @ts-ignore
              bookings.map(
                (booking: any, i: number) => {
                  const { ID, status, product } = booking
                  const {startOn, endOn} = bookingTimeData(booking)
                  const startDateFormat = startOn
                    ? startOn.format('DD MMM YYYY, hh:mm A')
                    : '';
                  const endDateFormat = endOn
                    ? endOn.format('DD MMM YYYY, hh:mm A')
                    : '';
                  return (
                    <div style={{ paddingTop: '1.5em' }} key={i}>
                      <ChatMessage
                        yours={false}
                        onBookingClick={goToBooking}
                        onMentionPress={onMentionPress}
                        data={{
                          type: "booking",
                          ID,
                          image: product.images[0],
                          venue: product.title,
                          startDate: startDateFormat,
                          endDate: endDateFormat,
                          status,
                        }}
                      />
                    </div>
                  );
                },
              )}
            </InfiniteScroll>
          </Loading>
        </div>
      </div>
    </div>
  );
};

export default HostDetails;
