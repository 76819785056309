import { colors } from './themeConstants';

const {
  primaryWhite,
  darkTeal,
  white,
  darkGrey,
  blue,
  lightGrey,
  orange,
} = colors;

const palette = {
  /*
  background: {
    default: 'red', //white,
  },*/
  primary: {
    main: blue,
    contrastText: darkGrey,
  },
  secondary: {
    main: darkTeal,
    contrastText: primaryWhite,
  },
  /*
  text: {
    primary: darkGrey,
    secondary: lightGrey,
    orange,
  },*/
};

export default palette;
