import React, { FC } from 'react';
import { Box } from '@mui/material';
import Main from 'components/layouts/Main';
import { useStyles } from './Profile.styles';
import { ProfileProps } from './Profile.props';
import { HeaderSection, ProfileDetails } from '../../modules';
import { HeaderImage, Widget, SplitView, Link } from '../../primitives';

const ProfileView: FC<ProfileProps> = (props: ProfileProps) => {
  const {
    onEditOrAddInformation,
    user,
    message,
  } = props
  const {
    image,
    firstName,
    lastName,
    email,
    mobile,
    postcode,
  } = user || {}
  const classes = useStyles();
  return (
    <Main>
      <Box style={{ marginTop: '1em' }}>
        <HeaderSection
          onSubheadingClick={onEditOrAddInformation}
          heading="Profile"
          subHeading="Edit or add information"
        />
        <Box className={classes.splitViewContainer}>
          <SplitView
            leftChild={
              <HeaderImage image={image} />
            }
            rightChild={
              <ProfileDetails
                firstName={firstName}
                lastName={lastName}
                email={email}
                mobile={mobile}
                postcode={postcode}
                message={message}
              />
            }
          />
        </Box>
      </Box>
    </Main>
  );
};

export default React.memo(ProfileView);
