import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import routerThunks from 'thunks/router'
import {ROUTES} from 'routes';
import BookingHistoryView from './BookingHistory.view';
import moment from 'moment'
import bookingThunks from 'thunks/booking';
import {useInfiniteScroll} from 'components/modules'
import DayPicker, { DateUtils } from 'react-day-picker'
import { bookingIsPassed } from 'services/api';

const BookingHistoryContainer = (props) => {
  const dispatch = useDispatch()

  const statusFilters = {
    all: (booking) => booking !== undefined,
    pending: (booking) => booking.status === 'pending',
    confirmed: (booking) => booking.status === 'confirmed',
    declined: (booking) => booking.status === 'declined' || booking.status === 'cancelled' || booking.status === 'deleted',
  }
  const [statusFilterName, setStatusFilterName] = useState('all')
  const statusFilter = statusFilters[statusFilterName]

  //const [dateFilterRefresh, setDateFilterRefresh] = useState(0)
  //const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [dateRange, setDateRange] = useState({from: undefined, to: undefined})
  /*
  const onDayClick = (day) => {
    // reset range selection on third click
    if (dateRange.to) {
      setDateRange({ from: day, to: undefined })
      return
    }

    const range = DateUtils.addDayToRange(day, dateRange)
    setDateRange(range)
    if (range.to) {
      setDatePickerOpen(false)
      setDateFilterRefresh(dateFilterRefresh + 1)
    }
  }*/

  const fetchMoreFn = (lastKey, resolve, reject) => {
    const params = {}
    if (lastKey) {
      params['start-key'] = lastKey
    }

    dispatch(
      bookingThunks.getProviderBookings(params,
        (response) => {
          const bookings = response.data.bookings
          let filteredBookings = bookings.filter(booking => {
            // pending bookings do not show up in history
            if (booking.status === 'pending') {
              return false
            }
            if (booking.status == 'cancelled' || booking.status == 'declined') {
              return true
            }

            // only past bookings show up in history
            return bookingIsPassed(booking)
          })

          filteredBookings = filteredBookings.filter(statusFilter)

          // filter based on selected date range
          filteredBookings = filteredBookings.filter(booking => {
            if (!dateRange.from || !dateRange.to) {
              return true
            }

            const from = moment(dateRange.from)
            const to = moment(dateRange.to)
            return moment(booking.startOn).isSameOrAfter(from, 'day') && moment(booking.endOn).isSameOrBefore(to, 'day')
          })

          resolve(filteredBookings, response.lastKey)
        },
        (error) => {
          reject(error)
        }
      )
    )
  }
  const {data: bookings, setData: setBookings, fetchMore, hasMore, loading} = useInfiniteScroll(fetchMoreFn, [statusFilterName, dateRange])

  const onViewBooking = (id) => () =>
    dispatch(routerThunks.link(ROUTES.VIEW_BOOKING_DETAILS, { id }));

  const onContactGuest = (booking) => () => {
    dispatch(routerThunks.link(ROUTES.MESSAGES, { user: booking.user }));
  }
 
  const onBack = () => {
    dispatch(routerThunks.link(ROUTES.SCHEDULED_BOOKINGS));
  }
  
  return (
    <BookingHistoryView
      {...props}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      results={bookings}
      onViewBooking={onViewBooking}
      onCreateNewListing={() => dispatch(routerThunks.link(ROUTES.CREATE_LISTING))}
      onViewRequest={onViewBooking}
      onContactGuest={onContactGuest}
      goToBookingHistory={() => dispatch(routerThunks.link(ROUTES.BOOKING_HISTORY))}
      backLabel='Schedule'
      onBack={onBack}
      statusFilterName={statusFilterName}
      setStatusFilterName={setStatusFilterName}
      //onDayClick={onDayClick}
      dateRange={dateRange}
      setDateRange={setDateRange}
      //datePickerOpen={datePickerOpen}
      //setDatePickerOpen={setDatePickerOpen}
    />
  );
};

export default React.memo(BookingHistoryContainer);
