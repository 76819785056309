import React, { FC } from 'react';
import View from './Main.view';
import { MainPublicProps } from './Main.props';

const MainContainer: FC<MainPublicProps> = props => {
  const data = {};

  return <View {...props} {...data} />;
};

export default React.memo(MainContainer);
