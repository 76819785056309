import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    detailsContainer: {
      background: 'rgba(162, 170, 173, 0.1)',
      borderRadius: 5,
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1em',
      padding: '1em',
      flexDirection: 'column',
    },
    totalContainer: {
      background: 'white',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1em',
      marginTop: '1em',
      padding: '1em',
      webkitBoxShadow: '0px 0px 20px 0px rgba(0,0,0,0.05)',
      mozBoxShadow: '0px 0px 20px 0px rgba(0,0,0,0.05)',
      boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.05)',
    },
    errorMessage: {
      color: 'red',
      marginTop: 15,
      textAlign: 'center',
    },
    link: {
      cursor: 'pointer',
    },
  }),
);
