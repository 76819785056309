import { Dispatch } from 'redux';
import { inviteAFriendEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

interface Data {
  friendEmail?: string;
  friendName?: string;
  message?: string;
}

const inviteAFriend = (
  data: Data,
  onSuccess?: (response: any) => void,
  onError?: (error: string) => void,
) => (dispatch: Dispatch, getState: () => Store) => {
  const inviteAFriendFetch = apiFetch<Data, any>(inviteAFriendEndpoint, data)(
    dispatch,
    getState,
  )
    .then(response => {
      if (response && onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    })
    .catch(error => {
      if (error && onError) {
        onError(error);
      }
      return error;
    });
  return inviteAFriendFetch;
};

export default inviteAFriend;
