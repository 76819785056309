import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { ButtonGroupProps } from './ButtonGroup.props';
import { Typo, GhostButton } from '../../primitives';

// @ts-ignore
const ButtonGroupView: FC<ButtonGroupProps> = ({
  label = '',
  onSelect = () => {},
  selectedIndex = -1,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typo variant="body1">{label}</Typo>
      </Grid>
      <Grid item xs={6}>
        <GhostButton
          style={{}}
          selected={selectedIndex === 0}
          text="Yes"
          onClick={() => onSelect({ index: 0, value: 'yes' })}
        />
      </Grid>
      <Grid item xs={6}>
        <GhostButton
          style={{}}
          selected={selectedIndex === 1}
          text="No"
          onClick={() => onSelect({ index: 1, value: 'no' })}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(ButtonGroupView);
