import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      paddingLeft: '7.5px',
      paddingRight: '7.5px',
      pointerEvents: 'auto',
    },
    imageContainer: {
      height: 0,
      overflow: 'hidden',
      paddingTop: '75.24752475%',
      background: colors.lightGrey,
      position: 'relative',
      borderRadius: '10px',
      marginBottom: '10px',
    },
    image: ({ image }: { image: string }) => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url('${image}')`,
      backgroundSize: 'cover',
    }),
    title: {
      marginBottom: '10px',
      pointerEvents: 'auto',
    },
    address: {
      marginBottom: '10px',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    bottomContainer: {
      display: 'flex',
    },
    separator: {
      margin: '100px',
      // padding: '100px',
    },

    contentContainer: {
      display: 'flex',
      overflow: 'hidden',
      flex: 1,
      height: '100%'
    },
    featureContainer: {
      paddingTop: '50px',
      overflowY: 'auto',
      flex: 1,
    },

    mapContainer: {
      display: 'flex',
    },
    showMapButtonContainer: {
      width: '40px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      boxShadow: '1px 0 1px rgba(0,0,0,0.02)',
    },
    toggleMapButton: {
      width: '40px',
      border: 0,
      background: 'transparent',
      height: '100%',
      cursor: 'pointer',
    },
    map: ({ isMapVisible }) => ({
      transition: 'width 150ms ease-in-out',
      maxWidth: '50vw',
      width: isMapVisible ? '550px' : '0',
      height: '100%',
    }),
    mapInner: {
      width: '550px',
      height: '100%',
    }
  }),
);
