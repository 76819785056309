import React, { FC, useState, useEffect } from 'react';
import { PlaceIcon } from 'assets';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useStyles } from './LocationSearch.styles';
import { LocationSearchProps } from './LocationSearch.props';
import InputField from '../InputField';
import Typo from '../Typo';

const LocationSearchView: FC<LocationSearchProps> = (
  props: LocationSearchProps,
) => {
  const classes = useStyles(props);
  const [address, setAddress] = useState('');
  const handleAddressChange = (value: string) => {
    if (address !== value) {
      setAddress(value);
      if (value === '') {
        props.onChange();
      }
    }
  };
  const handleSelect = async (addr: string) => {
    setAddress(addr)
    geocodeByAddress(addr)
      .then(results => getLatLng(results[0]))
      .then(latLng =>
        props.onChange({
          label: addr,
          coords: latLng,
        }),
      )
      .catch(error => {
        // eslint-disable-next-line
        console.error('Error', error);
      });
  };

  useEffect(() => {
    if (address !== props.value) {
      handleSelect(props.value)
    }
  }, [props.value])

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleAddressChange}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: { country: 'au' },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className={classes.container}>
          <InputField
            //id={props.id}
            type="text"
            icon={PlaceIcon}
            className={classes.inputField}
            {...getInputProps({
              placeholder: 'Search by location',
            })}
            inputProps={{
              autocomplete: 'stop-doing-it-chrome!'
            }}
          />
          <div
            className={`autocomplete-dropdown-container ${
              classes.suggestionsContainer
            }`}
          >
            {loading && (
              <div className={classes.inactive}>
                <Typo variant="body2">Loading...</Typo>
              </div>
            )}
            {suggestions.map(suggestion => {
              const style = suggestion.active
                ? classes.active
                : classes.inactive;
              return (
                <div
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion)}
                  className={style}
                >
                  <Typo variant="body2">{suggestion.description}</Typo>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default React.memo(LocationSearchView);
