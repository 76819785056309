import React from 'react';
import { ApprovedIcon, DeclinedIcon, PendingIcon } from 'assets';
import { Link, Typo, HorizontalLine, StatusBadge } from 'components/primitives';

// @ts-ignore
const Booking = ({
  // @ts-ignore
  id,
  // @ts-ignore
  onBookingClick,
  // @ts-ignore
  status,
  // @ts-ignore
  myBooking,
  onMentionPress,
  data: booking
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 4px 2px rgba( 0, 0, 0, 0.1 )',
        overflow: 'hidden',
        borderRadius: '16px',
        maxWidth: '380px',
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          src={myBooking.image}
          style={{
            width: '100%',
            height: '227px',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={() => onBookingClick(id)}
        />
        <StatusBadge
          status={status}
          style={{
            position: 'absolute',
            bottom: '0',
            right: '1em',
            transform: 'translateY(15%)'
          }}
        />
      </div>
      <div
        style={{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Link
          onClick={() => onBookingClick(id)}
          style={{ textDecoration: 'none' }}
        >
          <Typo variant="h3" style={{ marginBottom: '0.5em' }}>
            {myBooking.title}
          </Typo>
        </Link>
        <Typo color="lightGrey">
          {myBooking.startDate} - {myBooking.endDate}
        </Typo>
      </div>
      <div
        style={{
          height: '0.5em',
          width: '100%',
          paddingTop: '0.5em',
        }}
      >
        <HorizontalLine />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '1em',
        }}
      >
        <Typo color="blue">
          <Link onClick={() => onBookingClick(id)}>Booking details</Link>
        </Typo>
        {onMentionPress &&
          <Typo color="blue">
            <Link onClick={() => onMentionPress(booking)}>Mention</Link>
          </Typo>
        }
      </div>
    </div>
  );
};

export default Booking;
