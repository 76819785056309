/*
export const attributes = [
    "wifi",
    "chairs",
    "tables",
    "computer",
    "wheelchair",
    "whiteboard"
  ]
  
export const typeOptions = [
    {
      label: 'Corporate',
      value: 'corporate',
      subtypeOptions: [
        {
          label: 'Boardroom',
          value: 'boardroom'
        },
        {
          label: 'Office',
          value: 'office'
        },
        {
          label: 'Meeting Room',
          value: 'meeting-room'
        },
        {
          label: 'Computer Lab',
          value: 'computer-lab'
        }
      ]
    },
    {
      label: 'Creative',
      value: 'creative',
      subtypeOptions: [
        {
          label: 'Work Hub',
          value: 'work-hub'
        },
        {
          label: 'Recording Studio',
          value: 'recording-studio'
        },
        {
          label: 'Dance Studio',
          value: 'dance-studio'
        },
        {
          label: 'Art Studio',
          value: 'art-studio'
        }
      ]
    },
    {
      label: 'Functions',
      value: 'functions',
      subtypeOptions: [
        {
          label: 'Indoor',
          value: 'indoor'
        },
        {
          label: 'Outdoor',
          value: 'outdoor'
        },
        {
          label: 'Outdoor With Cover',
          value: 'outdoor-with-cover'
        }
      ]
    },
    {
      label: 'Sports',
      value: 'sports',
      subtypeOptions: [
        {
          label: 'Indoor Courts',
          value: 'indoor-courts'
        },
        {
          label: 'Outdoor Courts',
          value: 'outdoor-courts'
        },
        {
          label: 'Soccer Field',
          value: 'soccer-field'
        },
        {
          label: 'Multi-sport Fields',
          value: 'multisport-fields'
        },
        {
          label: 'Indoor Sports Studio/Gym',
          value: 'indoor-sports-studio-or-gym'
        },
      ]
    },
    {
      label: 'Outdoor',
      value: 'outdoor',
      subtypeOptions: [
        {
          label: 'Functions Outdoors',
          value: 'functions-outdoors'
        },
        {
          label: 'Functions Outdoors With Cover',
          value: 'functions-outdoors-with-cover'
        },
        {
          label: 'Gardens',
          value: 'gardens'
        },
        {
          label: 'Outdoor Swimming Pool',
          value: 'outdoor-swimming-pool'
        },
        {
          label: 'Indoor Swimming Pool',
          value: 'indoor-swimming-pool'
        },
      ]
    },
    {
      label: 'Other',
      value: 'other',
      subtypeOptions: [
        {
          label: 'Kitchen',
          value: 'kitchen'
        },
        {
          label: 'Garage',
          value: 'garage'
        },
        {
          label: 'Warehouse',
          value: 'warehouse'
        },
        {
          label: 'Swimming Pool (Indoor)',
          value: 'swimming-pool-indoor'
        },
        {
          label: 'Swimming Pool (Outdoor)',
          value: 'swimming-pool-outdoor'
        },
      ]
    }
]
*/

// to help us get the labels for a products type and subtype
export const typeOptionsForProduct = (product, typeOptions) => {
    const typeOption = typeOptions.find(to => to.value == product.type)
    const subtypeOptions = typeOption?.subtypeOptions || []
    const subtypeOption = subtypeOptions.find(so => so.value == product.subtype)
    return {typeOption, subtypeOption}
}