import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './ProfileDetails.styles';
import { ProfileDetailsProps } from './ProfileDetails.props';
import { Typo, Field, HorizontalLine, Widget } from '../../primitives';

const ProfileDetailsView: FC<ProfileDetailsProps> = ({
  firstName,
  lastName,
  email,
  mobile,
  postcode,
  message,
}: ProfileDetailsProps) => {
  const classes = useStyles();
  return (
    <Widget
      heading="Your Details"
      bodyChild={
        <>
          {Boolean(message.message) && (
            <Box className={classes.messageContainer}>
              <Typo
                variant="body1"
                color={message.type === 'success' ? 'green' : 'red'}
              >
                {message.message}
              </Typo>
            </Box>
          )}
          <Field label="Name" value={`${firstName} ${lastName}`} className={classes.field} />
          <Field label="Email" value={email || ''} className={classes.field} />
          <Field label="Mobile" value={mobile || '-'} className={classes.field} />
          <Field
            label="Postcode"
            value={postcode || '-'}
            className={classes.field}
          />
          <HorizontalLine />
        </>
      }
    />
  );
};

export default React.memo(ProfileDetailsView);
