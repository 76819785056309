import React, { FC } from 'react';
import { Profile, EditProfile } from 'components/layouts/';
import { ProfileProps } from './Profile.props';

const ProfileView: FC<ProfileProps> = (props: ProfileProps) => {
  const {
    inEditMode,
    setEditMode,
    user,
    onSave,
    message,
  } = props
  return inEditMode ? (
    <EditProfile
      onSaveChanges={() => setEditMode(false)}
      user={user}
      onCancel={() => setEditMode(false)}
      onSave={onSave}
      message={message}
    />
  ) : (
    <Profile
      onEditOrAddInformation={() => setEditMode(true)}
      user={user}
      message={message}
    />
  );
};

export default React.memo(ProfileView);
