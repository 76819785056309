import React, { FC } from 'react';
import { UserIcon, EmailIcon } from 'assets';
import PhoneIcon from '@mui/icons-material/Smartphone';
import PostcodeIcon from '@mui/icons-material/PinDropOutlined';
import { Button, Box } from '@mui/material';
import { useStyles } from './EditProfileDetails.styles';
import { EditProfileDetailsProps } from './EditProfileDetails.props';
import { Widget, InputField, Typo } from '../../primitives';
import { ModalConsumer } from '../../../contexts/ModalContext';
import { LoginModalForms } from '../LoginModal/LoginModal.const';

const EditProfileDetailsView: FC<EditProfileDetailsProps> = (
  props: EditProfileDetailsProps,
) => {
  const marginBottom = '1em';
  const classes = useStyles();
  return (
    <Widget
      heading="Your Details"
      bodyChild={
        <>
          {Boolean(props.message.message) ||
            (Boolean(props.errors.length) && (
              <Box className={classes.messageContainer}>
                {Boolean(props.message.message) && (
                  <Typo
                    variant="body1"
                    color={props.message.type === 'success' ? 'green' : 'red'}
                  >
                    {props.message.message}
                  </Typo>
                )}
                {props.errors.map((msg: string) => (
                  <Typo variant="body1" color="red" key={msg}>
                    {msg}
                  </Typo>
                ))}
              </Box>
            ))}
          <InputField
            id="firstName"
            icon={() => <UserIcon />}
            placeholder="First Name"
            style={{
              marginBottom,
            }}
            // @ts-ignore
            onChange={event => props.setFirstName(event.target.value)}
            value={props.firstName.value}
            onBlur={props.setTouchedFirstName}
            error={Boolean(props.firstName.error) && props.firstName.touched}
          />
          <InputField
            id="lastName"
            icon={() => <UserIcon />}
            placeholder="Last Name"
            style={{
              marginBottom,
            }}
            // @ts-ignore
            onChange={event => props.setLastName(event.target.value)}
            value={props.lastName.value}
            onBlur={props.setTouchedLastName}
            error={Boolean(props.lastName.error) && props.lastName.touched}
          />
          <InputField
            id="email"
            icon={() => <EmailIcon />}
            placeholder="Email"
            style={{
              marginBottom,
            }}
            onChange={event => {
              props.setEmail(event.target.value);
            }}
            value={props.email.value}
            onBlur={props.setTouchedEmail}
            error={Boolean(props.email.error) && props.email.touched}
            disabled
          />
          <InputField
            id="mobile"
            icon={() => <PhoneIcon />}
            placeholder="Mobile"
            style={{
              marginBottom,
            }}
            onChange={event => props.setMobile(event.target.value)}
            value={props.mobile.value}
            onBlur={props.setTouchedMobile}
            error={Boolean(props.mobile.error) && props.mobile.touched}
          />
          <InputField
            id="postcode"
            icon={() => <PostcodeIcon />}
            placeholder="Postcode"
            style={{
              marginBottom,
            }}
            onChange={event => props.setPostcode(event.target.value)}
            value={props.postcode.value}
            onBlur={props.setTouchedPostcode}
            error={Boolean(props.postcode.error) && props.postcode.touched}
          />
          <Box className={classes.accountActionsContainer}>
            <ModalConsumer>
              {({ open, onClose, setModal }) => (
                <Button
                  className={classes.changePasswordButton}
                  onClick={() => setModal(LoginModalForms.RESET)}
                >
                  Change password
                </Button>
              )}
            </ModalConsumer>
          </Box>
        </>
      }
    />
  );
};

export default React.memo(EditProfileDetailsView);
