import React, { FC, useEffect, useState } from 'react';
import {ROUTES, NOT_FOUND, routeAndPortalFromLocation, PORTALS} from 'routes';
import pathOr from 'ramda/es/pathOr';
import { asyncData } from 'utils/Redux';
import { useDispatch } from 'react-redux';
import userThunk from 'thunks/user';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import Dinero from 'dinero.js';
import { ListingDetailsPublicProps } from './ListingDetails.props';
import ListingDetailsView from './ListingDetails.view';
import { ASYNC_STATUS } from '../../../types/store/AsyncStatus';
import productThunks from '../../../thunks/product';
import { bookingDetailsActions } from '../../../store/actions';
import routerThunks from 'thunks/router'
import { EntityState } from '../../../types/store/EntityState';
import reviewThunks from 'thunks/review'
import {toast} from 'react-toastify'

// @ts-ignore
const toEntityDetails = (entity) => {
  if (!entity || !entity.data) {
    return null;
  }
  const e = entity.data;
  //const facilityFilter = (facility: any) => metadata.attributes[facility];
  // @ts-ignore
  return {
    ID: e.ID,
    attributes: e.attributes, //Object.keys(metadata.attributes).filter(facilityFilter),
    capacity: e.capacity,
    title: e.title,
    description: e.description,
    rating: e.rating,
    type: e.type,
    subtype: e.subtype,
    weekdayPrice: e.pricing.weekdays,
    weekendPrice: e.pricing.weekends,
    weekdayPriceData: Dinero({
      amount: Math.round(e.pricing.weekdays * 100),
      currency: 'AUD',
    }),
    weekendPriceData: Dinero({
      amount: Math.round(e.pricing.weekends * 100),
      currency: 'AUD',
    }),
    location: e.location,
    images: e.images,
    videos: e.videos,
    provider: e.provider,
    questions: e.questions,
    schedule: e.schedule
  };
};

const ListingDetailsContainer: FC<ListingDetailsPublicProps> = (
  ownProps: ListingDetailsPublicProps,
) => {
  const dispatch = useDispatch();
  const isLoggedIn = Boolean(useSelectorSafe(state => state.token) || false);
  const user = pathOr(
    { id: '' },
    ['data', 'data', 'user'],
    useSelectorSafe(state => {
      return state.user
    }),
  );
  const payload = useSelectorSafe(state => state.location.payload);


  const location = useSelectorSafe(
    state => state.location.type,
    NOT_FOUND,
  );
  const [routeName, portalName, portal] = routeAndPortalFromLocation(location)

  const createListingState = useSelectorSafe(
    state => state.createListing
  );

  const entitiesState = useSelectorSafe<EntityState>(
    state => state.product,
    asyncData(ASYNC_STATUS.INITIAL),
  );
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(userThunk.getFavourites());
    }
  }, []);
  const favourites: { prod_ID: string }[] = pathOr(
    [],
    ['data', 'data', 'favourites'],
    useSelectorSafe(state => state.favourites),
  );
  const favouritesData = favourites.map(f => f.prod_ID);
  const [entityState, setEntityState] = useState(null);
  const onViewListing = (id: number | string) => () => {
    window.scrollTo(0, 0);
    dispatch(routerThunks.link(ROUTES.LISTING_DETAILS, { id }));
  };
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('')

  const toggleFavourite = (productId: string, isFavourite: boolean) => {
    if (isFavourite) {
      dispatch(userThunk.removeFavourite({ productId }));
    } else {
      dispatch(userThunk.addFavourite({ productId }));
    }
  };

  const relEntities = pathOr(
    [],
    ['data', 'data', 'products'],
    entitiesState,
  ).filter(
    // @ts-ignore
    entity => entity.ID !== pathOr('', ['data', 'ID'], entityState),
  );

  const relatedEntities = relEntities.map((relEntity: any) => ({
    ID: relEntity.ID,
    type: relEntity.type,
    capacity: relEntity.capacity,
    title: relEntity.title,
    address: pathOr(0, ['location', 'address'], relEntity),
    rating: relEntity.rating || 0,
    weekdayPrice: pathOr(
      0,
      ['pricing', 'weekdays'],
      relEntity,
    ),
    weekendPrice: pathOr(
      0,
      ['pricing', 'weekends'],
      relEntity,
    ),
    favourite: favouritesData.includes(relEntity.ID),
    images: relEntity.images,
    onClick: onViewListing(relEntity.ID),
  }));

  const alertUser = (e) => {
    const confirmationMessage = 'Changes that you made may not be saved.';
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }

  useEffect(() => {
    // @ts-ignore
    if (
      payload &&
      // @ts-ignore
      payload.id &&
      // @ts-ignore
      !(
        entityState &&
        // @ts-ignore
        entityState.data &&
        // @ts-ignore
        entityState.data.ID &&
        // @ts-ignore
        entityState.data.ID === payload.id
      )
    ) {
      if (payload.id == 'local') {
        if (createListingState.data == null) {
          // ops! user refreshed!
          dispatch(routerThunks.link(ROUTES.ROOT))
          return
        }

        setLoading(false)
        setEntityState(createListingState)

        window.addEventListener('beforeunload', alertUser)
        return () => {
          window.removeEventListener('beforeunload', alertUser)
        }
      }

      setLoading(true);
      dispatch(
        productThunks.getById(
          {
            // @ts-ignore
            productId: payload.id,
          },
          (response: any) => {
            setLoading(false);
            setEntityState(response);
          },
          (error: any) => {
            setLoading(false);
          },
        ),
      );
    }
  }, [payload]);

  useEffect(() => {
    if (payload.id == 'local') {
      return
    }

    dispatch(productThunks.get({},
      (response) => {
        console.log('got products for related places I guess')
      },
      (error) => {
        console.error('_ERROR_', error)
      }));
  }, []);

  // confirmed reviews
  const [reviews, setReviews] = useState([])
  useEffect(() => {
    if (payload.id == 'local') {
      return
    }

    dispatch(reviewThunks.getReviews({productId: payload.id, status: 'confirmed'},
      (response) => {
        setReviews(response.reviews)
      },
      (error) => {
        console.error('_ERROR_', error)
      }))
  }, [payload])

  // pending reviews
  const [pendingReviews, setPendingReviews] = useState([])
  useEffect(() => {
    if (payload.id == 'local') {
      return
    }

    if (portalName == PORTALS.CUSTOMER) {
      return
    }

    dispatch(reviewThunks.getReviews({productId: payload.id, status: 'pending'},
      (response) => {
        setPendingReviews(response.reviews)
      },
      (error) => {
        console.error('_ERROR_', error)
      }))
  }, [payload])

  const submitPendingReview = (review) => {
    dispatch(
      reviewThunks.submitReview(
        review,
        success => {
          const filteredPendingReviews = pendingReviews.filter(r => r.ID != review.ID)
          setPendingReviews(filteredPendingReviews)
        },
        error => {
          console.log('_ERR_', error)
        },
      ),
    )
  }

  const onDeclinePendingReview = (review) => {
    review.status = 'declined'
    submitPendingReview(review)
  }

  const onApprovePendingReview = (review) => {
    review.status = 'confirmed'
    submitPendingReview(review)
  }

  // @ts-ignore
  const goToBookingDetails = (
    // @ts-ignore
    product,
    // @ts-ignore
    startDate,
    // @ts-ignore
    endDate,
    // @ts-ignore
    price,
    // @ts-ignore
    startTime,
    // @ts-ignore
    endTime,
    // @ts-ignore
    weekdayBookingHours,
    // @ts-ignore
    weekendBookingHours,
  ) => {
    dispatch(
      // @ts-ignore
      bookingDetailsActions.setBooking({
        product,
        startDate,
        endDate,
        startTime,
        endTime,
        price,
        weekdayBookingHours,
        weekendBookingHours,
      }),
    );
    dispatch(routerThunks.link(ROUTES.BOOKING_DETAILS));
  };
  const goToListings = () => {
    dispatch(routerThunks.link(ROUTES.ROOT));
  };

  const entityDetails = entityState ? toEntityDetails(entityState) : null;

  const goToHostDetails = () => {
    // @ts-ignore
    if (entityDetails && entityDetails.provider && entityDetails.provider.ID) {
      // @ts-ignore
      const hostId = entityDetails.provider.ID;
      // @ts-ignore
      dispatch(
        // @ts-ignore
        routerThunks.link(ROUTES.HOST_DETAILS, { id: hostId }),
      );
    }
  };
  const goToContactHost = () => {
    if (entityDetails && entityDetails.provider) {
      dispatch(
        routerThunks.link(ROUTES.MESSAGES, {
          // @ts-ignore
          provider: entityDetails.provider,
        }),
      );
    }
  };


  const onEditListing = () => {
    console.log('edit listing!')
    dispatch(routerThunks.link(ROUTES.EDIT_LISTING, { id: payload.id }));
  };

  const isPublished = (entityDetails && entityDetails.ID) || false
  const needsSaving = payload.id == 'local'//entityDetails.needsSaving

  const onPublishListing = () => {
    console.log('create the new listing!')
    setSaving(true);
    dispatch(
      productThunks.add(
        entityState.data,
        (response) => {
          setSaving(false);
          toast.success(isPublished ? 'Listing saved' : 'Listing published')
          dispatch(routerThunks.link(ROUTES.LISTING_DETAILS, { id: response.data.ID }));
        },
        (error) => {
          setError(error.message || error)
          setSaving(false);
        },
      ),
    );

  };


  return (
    // @ts-ignore
    <ListingDetailsView
      portalName={portalName}
      related={relatedEntities}
      entityState={entityDetails}
      reviews={reviews}
      pendingReviews={pendingReviews}
      entityStateOriginal={
        // @ts-ignore
        entityState && entityState.data ? entityState.data : undefined
      }
      user={user}
      goToBookingDetails={goToBookingDetails}
      goToListings={goToListings}
      loading={loading}
      toggleFavourite={toggleFavourite}
      goToHostDetails={goToHostDetails}
      goToContactHost={goToContactHost}
      onEditListing={onEditListing}
      onPublishListing={!isPublished && onPublishListing}
      onSaveListing={isPublished && needsSaving && onPublishListing}
      saving={saving}
      disabled={saving}
      error={error}
      onDeclinePendingReview={onDeclinePendingReview}
      onApprovePendingReview={onApprovePendingReview}
    />
  );
};

export default ListingDetailsContainer;
