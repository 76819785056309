import React, { FC } from 'react';
import {ROUTES} from 'routes';
import { useDispatch } from 'react-redux';
import routerThunks from 'thunks/router'
import View from './Search.view';
import { SearchPublicProps } from './Search.props';

const SearchContainer: FC<SearchPublicProps> = props => {
  const dispatch = useDispatch();
  const data = {
    goToContactRoute: () => dispatch(routerThunks.link(ROUTES.CONTACT_US)),
  };

  return <View {...props} {...data} />;
};

export default React.memo(SearchContainer);
