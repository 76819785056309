import React from 'react';
import { Box } from '@mui/material';
import { Button, Select, Typo, Calendar, Link } from 'components/primitives';
import moment from 'moment';
import HostedBy from 'components/modules/HostedBy';
import { useStyles } from './BookingSelect.styles';

const disablePast = true // set to false only for testing bookings made in the past

const convertToDisplayDate = (date: string) => {
  const newDate = moment(date, 'YYYY-MM-DD').format('DD MMM, YYYY');
  return newDate;
};

const BookingSelectView = (props: any) => {
  const {
    initialDate,
    openCalendar,
    setOpenCalendar,
    startDate,
    endDate,
    calendarHook,
    totalPriceData,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    onBookNow,
    onHostDetails,
    onContactHost,
    host,
    startTimeOptions,
    endTimeOptions,
    startTimeDisplay,
    setStartTimeDisplay,
    scheduleIndex,
    setScheduleIndex,
    priceData,
    weekdayPriceData,
    weekendPriceData,
    durationData,
    weekdayDurationData,
    weekendDurationData,
    timePeriod,
    weekdayHours,
    weekendHours,
    canBook,
    onStartTimeSelect,
    onEndTimeSelect,
    disabledModifier,
  } = props

  const classes = useStyles();
  // return null;
  return (
    <div className={classes.rightContent}>
      <Typo variant="h4" className={classes.inputLabel}>
        Select Date
      </Typo>
      <Select
        id="date"
        openOverride={openCalendar}
        setIsOpenOverride={setOpenCalendar}
        options={[
          {
            label:
              startDate && endDate
                ? `${convertToDisplayDate(
                    startDate.date,
                  )} - ${convertToDisplayDate(endDate.date)}`
                : 'Date',
            value:
              startDate && endDate
                ? `${convertToDisplayDate(
                    startDate.date,
                  )} - ${convertToDisplayDate(endDate.date)}`
                : 'Date',
          },
        ]}
        customContent={true}
        value={
          startDate && endDate
            ? `${convertToDisplayDate(
                startDate.date,
              )} - ${convertToDisplayDate(endDate.date)}`
            : 'Date'
        }
        onChange={() => {}}
      >
        <Calendar
          disablePast={disablePast}
          hookState={calendarHook}
          disabledModifier={disabledModifier}
          initialSelectedDate={initialDate}
          notAvailableDates={props.notAvailableDates}
          toMonth={props.toMonth}
        />
      </Select>
      <Typo variant="h4" className={classes.inputLabel}>
        Select Time
      </Typo>
      <Typo variant="body1" className={classes.note}>
        Times where the space is unavailable will not appear.
      </Typo>
      <div className={classes.selectTime}>
        <Select
          id="start-time"
          value={
            startTime && startTime.value ? startTime.value : 'placeholder'
          }
          onChange={onStartTimeSelect}
          options={[
            {
              label: 'Start Time',
              value: 'placeholder',
            },
            ...(startTimeOptions || []),
          ]}
        />
        <Select
          id="end-time"
          value={endTime && endTime.value ? endTime.value : 'placeholder'}
          // onChange={v => setEndTime(v)}
          onChange={onEndTimeSelect}
          options={[
            {
              label: 'End Time',
              value: 'placeholder',
            },
            ...(endTimeOptions || []),
          ]}
        />
      </div>
      <div className={classes.breakDown}>
        <div
          className={classes.breakDownItem}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <div className={classes.breakDownItemLabel}>
            <Typo variant="body1" color="lightGrey">
              Weekday price
            </Typo>
            <Typo variant="body1" color="lightGrey">
              {`AUD $${weekdayPriceData.toFormat('0,0.00') || 0}/hour`}
            </Typo>
            <Typo variant="body1" color="lightGrey">
              {`x ${weekdayHours} ${
                weekdayHours > 1 || weekdayHours < 1 ? 'hours' : 'hour'
              }`}
            </Typo>
          </div>
          <div className={classes.breakDownItemValue}>
            <Typo variant="h5">{`$${weekdayDurationData.toFormat(
              '0,0.00',
            )}`}</Typo>
          </div>
        </div>
        <div
          className={classes.breakDownItem}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <div className={classes.breakDownItemLabel}>
            <Typo variant="body1" color="lightGrey">
              Weekend price
            </Typo>
            <Typo variant="body1" color="lightGrey">
              {`AUD $${weekendPriceData.toFormat('0,0.00') || 0}/hour`}
            </Typo>
            <Typo variant="body1" color="lightGrey">
              {`x ${weekendHours} ${
                weekendHours > 1 || weekendHours < 1 ? 'hours' : 'hour'
              }`}
            </Typo>
          </div>
          <div className={classes.breakDownItemValue}>
            <Typo variant="h5">{`$${weekendDurationData.toFormat(
              '0,0.00',
            )}`}</Typo>
          </div>
        </div>
        <div className={classes.breakDownItem}>
          <div className={classes.breakDownItemLabel}>
            <Typo variant="body1" color="lightGrey">
              Service fee
            </Typo>
          </div>
          <div className={classes.breakDownItemValue}>
            <Typo variant="h5">*</Typo>
          </div>
        </div>
        <div className={classes.breakDownItem}>
          <div className={classes.breakDownItemLabel}>
            <Typo variant="h5" color="lightGrey">
              Total
            </Typo>
          </div>
          <div className={classes.breakDownItemValue}>
            <Typo variant="h5">{`$${totalPriceData.toFormat('0,0.00')}`}</Typo>
          </div>
        </div>
      </div>
      <Button text="Book Now" disabled={!canBook} onClick={onBookNow} />
      <div style={{ marginTop: '1em', marginBottom: '1em' }}>
        <HostedBy host={host} onClick={onHostDetails} />
      </div>
      <Typo variant="body1" color="blue">
        <Link onClick={onContactHost}>
          <Typo color="blue">Contact host</Typo>
        </Link>
      </Typo>
    </div>
  );
};

export default BookingSelectView;
