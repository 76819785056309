import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import hostThunks from 'thunks/host';
import routerThunks from 'thunks/router'
import {ROUTES} from '../..';
import View from './HostDetails.view';

const Container = (props: any) => {
  const dispatch = useDispatch();
  const isLoggedIn = Boolean(useSelectorSafe(state => state.token) || false);
  const [showModal, setShowModal] = useState('CLOSE');
  const [loading, setLoading] = useState(true);
  const hostId = useSelectorSafe(state =>
    state &&
    // @ts-ignore
    state.location &&
    // @ts-ignore
    state.location.payload &&
    // @ts-ignore
    state.location.payload.id
      ? //
        // @ts-ignore
        state.location.payload.id
      : '',
  );
  const [host, setHost] = useState(null);
  useEffect(() => {
    if (!hostId) return;
    dispatch(
      hostThunks.getHostById(
        hostId,
        (response: any) => {
          setHost(response);
          setLoading(false);
        },
        (error: any) => {
          console.log('_ERROR_', error);
        },
      ),
    );
  }, [hostId]);
  const onContactHost = () => {
    // @ts-ignore
    if (isLoggedIn) {
      // @ts-ignore
      if (host && host.ID) {
        dispatch(routerThunks.link(ROUTES.MESSAGES, { provider: host }));
      }
    } else {
      setShowModal('LOGIN');
    }
  };
  // @ts-ignore
  const hostName = host ? host.name : '';
  // // @ts-ignore
  // host && host.metadata && host.metadata.name ? host.metadata.name : '';
  const memberSince =
    // @ts-ignore
    host && host.createdOn
      ? //
        // @ts-ignore
        `Member since ${moment(host.createdOn, 'ISO-8601').format('YYYY')}`
      : '';
  return (
    <View
      {...props}
      host={host}
      loading={loading}
      hostName={hostName}
      memberSince={memberSince}
      onContactHost={onContactHost}
      showModal={showModal}
      setShowModal={setShowModal}
    />
  );
};

export default React.memo(Container);
