import React, { FC } from 'react';
import {
  ListingDetailsPublicProps,
  ListingDetailsProps,
} from './ListingDetails.props';
import ListingDetailsView from './ListingDetails.view';

const ListingDetailsContainer: FC<ListingDetailsPublicProps> = (
  ownProps: ListingDetailsPublicProps,
) => {
  const combinedProps: ListingDetailsProps = {
    ...ownProps,
  };

  return <ListingDetailsView {...combinedProps} />;
};

export default ListingDetailsContainer;
