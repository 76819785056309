import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    rightContent: {
      width: 370,
      height: '100%',
      marginLeft: 28,
      boxShadow: '0 5px 30px 0 rgba(0,0,0,0.05)',
      borderRadius: 5,
      padding: 20,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: 20,
        marginLeft: 0,
      },
    },
    inputLabel: {
      marginBottom: 10,
    },
    note: {
      marginBottom: 10,
    },
    selectDate: {
      marginBottom: 20,
    },
    selectTime: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
      gridGap: 4,
      marginBottom: 20,
    },
    breakDown: {
      marginBottom: 20,
    },
    breakDownItem: {
      display: 'flex',
      marginBottom: 10,
    },
    breakDownItemLabel: {
      flex: 1,
    },
    breakDownItemValue: {},
  }),
);
