import React, { FC } from 'react';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { TypoPublicProps } from './Typo.props';
import { colors } from '../../../theme/themeConstants';
import typography, {
  appTypoVariantMapping,
} from '../../../theme/typography';

import { fallback } from '../../../utils/Data';

interface Styles {
  root: {};
}

const useStyles = makeStyles(
  (): Styles => ({
    // @ts-ignore
    root: ({ variant, color, component }: TypoPublicProps) => ({
      ...fallback(
        // @ts-ignore
        t => t[variant],
        {},
        typography,
      ),
      ...fallback(
        // @ts-ignore
        t => (t[color] ? { color: t[color] } : {}),
        {},
        colors,
      ),
      '&:hover': {
        cursor: component === 'a' ? 'cursor' : 'inherit',
      },
    }),
  }),
);

const TypoContainer: FC<TypoPublicProps> = (ownProps: TypoPublicProps) => {
  const { color, variant, ...typoProps } = ownProps;
  const variantProp = fallback(
    // @ts-ignore
    p => appTypoVariantMapping[p.variant],
    variant,
    ownProps,
  ) as TypographyProps['variant'];
  const colorProp = fallback(
    // @ts-ignore
    p => colors[p.color],
    color,
    ownProps,
  ) as TypographyProps['color'];

  // todo: deep merge these two results in case `root` is used in both (for e.g.)
  const classes = {
    ...useStyles(ownProps),
    ...ownProps.classes,
  };

  const combinedProps: TypographyProps = {
    ...typoProps,
    classes,
    variant: variantProp === variant ? variant : undefined,
    color: colorProp === color ? color : undefined,
  };

  return <MuiTypography {...combinedProps}>{ownProps.children}</MuiTypography>;
};

export default TypoContainer;
