import { handleActions } from 'redux-actions';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';

const DEFAULT_STATE = asyncData(ASYNC_STATUS.INITIAL);

const reducer = handleActions(
  {
    ...createAsyncReducers('PROVIDER/GET_PROVIDER'),
    ...createAsyncReducers('PROVIDER/UPDATE_PROVIDER'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
