import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { pathOr, symmetricDifference } from 'ramda';
import { filterActions, userActions } from 'store/actions';
import routerThunks from 'thunks/router'
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import {ROUTES} from 'routes';

import productThunks from 'thunks/product';
import userThunk from 'thunks/user';
import { FilterState } from 'types/store/FilterState';
import { EntityState } from 'types/store/EntityState';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { Store } from 'types/store/Store';
import { useCalendar } from 'components/primitives';
import {useInfiniteScroll} from 'components/modules'
import SearchView from './Search.view';
import { SearchPublicProps } from './Search.props';
import moment from 'moment'
import { computeDistanceBetween } from 'spherical-geometry-js'

const formatEntities = (entity: any) => {
  // @TODO remove default value once data has been corrected
  // const address: any = entity.metadata.location;
  const host: string =
    entity &&
    entity.host &&
    entity.host.length
      ? entity.host[0]
      : null;

  const images = pathOr([pathOr(null, ['heroImage'], entity)], ['images'], entity).filter(i => i)
  return {
    id: entity.ID,
    type: entity.type,
    capacity: entity.capacity,
    title: entity.title,
    rating: entity.rating || 0,
    weekdayPrice: pathOr(
      0,
      ['pricing', 'weekdays'],
      entity,
    ),
    weekendPrice: pathOr(
      0,
      ['pricing', 'weekends'],
      entity,
    ),
    favourite: entity.isFavourite || false,
    address: pathOr(0, ['location', 'address'], entity),
    latitude: pathOr(0, ['location', 'latitude'], entity),
    longitude: pathOr(0, ['location', 'longitude'], entity),
    images,
    hostedBy: pathOr('', ['first_name'], host),
  };
};

const SearchContainer: FC<SearchPublicProps> = (props: SearchPublicProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = Boolean(useSelectorSafe(state => state.token) || false);
  const locationState = useSelectorSafe(state => state.location)
  const filterState = useSelector<Store, FilterState>(state => state.filter);
  // @ts-ignore
  const filterOptions = useSelectorSafe(
    state => state.filterOptions,
    asyncData(ASYNC_STATUS.INITIAL),
  );

  const [isMapVisible, setMapVisible] = useState(false);
  const toggleMap = () => {
    setMapVisible(!isMapVisible);
  };

  const favourites: { prod_ID: string }[] = pathOr(
    [],
    ['data', 'data', 'favourites'],
    useSelectorSafe(state => state.favourites),
  );
  // const filterOptions = useSelectorSafe(state => state.filter)
  const onViewListing = (id: number | string) => () =>
    dispatch(routerThunks.link(ROUTES.LISTING_DETAILS, { id }));
  const [userCoords, setUserCoords] = useState();
  // const [autoLoad, setAutoLoad] = useState(false);
  const [filterTypes, setFilterTypes] = useState<string[]>([]);
  const [filterSubTypes, setFilterSubTypes] = useState<string[]>([]);
  const [filterFacilities, setFilterFacilities] = useState<string[]>([]);
  const [filterPrice, setFilterPrice] = useState<number[]>([]);
  const [filterCapacity, setFilterCapacity] = useState<number[]>([]);
  const [filterFromDate, setFilterFromDate] = useState<Date | null>(null);
  const [filterToDate, setFilterToDate] = useState<Date | null>(null);
  const [filterLocation, setFilterLocation] = useState(locationState?.query?.location || '');

  const resetDateFilter = () => {
    filterActions.setFilter({
      dateRange: undefined,
    });
    setFilterToDate(null);
    setFilterFromDate(null);
  };
  const calendarHook = useCalendar(
    ({ from, to }) => {
      if (from) {
        setFilterFromDate(from);
        setFilterToDate(to || null);
      }
      if (!(to || from)) {
        resetDateFilter();
      }
    },
    (date) => true,
  );

  const toDate = moment().add(1, 'year').subtract(1, 'day').toDate()
  const notAvailableDates = [{ after: toDate }]

  const resetTypeFilter = () => {
    setFilterTypes([]);
    dispatch(
      filterActions.setFilter({
        types: [],
      }),
    );
  };
  const resetAdditionalFilter = () => {
    setFilterSubTypes([]);
    setFilterFacilities([]);
    setFilterPrice([]);
    setFilterCapacity([]);
    dispatch(
      filterActions.setFilter({
        subTypes: [],
        price: [],
        capacity: [],
        attributes: [],
      }),
    );
  };
  const onSubmitDate = () => {
    dispatch(
      filterActions.setFilter({
        dateRange: {
          from: filterFromDate,
          to: filterToDate || filterFromDate,
        },
      }),
    );
  };
  const clearFilters = () => {
    dispatch(filterActions.clearFilter());
    resetAdditionalFilter();
    resetTypeFilter();
    resetDateFilter();
  };

  const onSubmitTypes = () => {
    dispatch(
      filterActions.setFilter({
        types: filterTypes,
      }),
    );
  };

  const onSubmitSubTypes = () => {
    dispatch(
      filterActions.setFilter({
        subTypes: filterSubTypes,
      }),
    );
  };

  const onSubmitAdditionalFilters = () => {
    dispatch(
      filterActions.setFilter({
        ...(filterPrice
          ? {
              priceRange: {
                min: filterPrice[0],
                max: filterPrice[1],
              },
            }
          : {}),
        ...(filterCapacity
          ? {
              capacity: {
                min: filterCapacity[0],
                max: filterCapacity[1],
              },
            }
          : {}),
        ...(filterSubTypes
          ? {
              subtypes: filterSubTypes,
            }
          : {}),
        ...(filterFacilities
          ? {
              attributes: filterFacilities,
            }
          : {}),
      }),
    );
  };

  const onToggleType = (type: string) => {
    setFilterTypes(symmetricDifference(filterTypes || [], [type]));
  };

  const onToggleSubType = (subtype: string) => {
    setFilterSubTypes(symmetricDifference(filterSubTypes || [], [subtype]));
  };

  const onToggleFacility = (facility: string) => {
    setFilterFacilities(
      symmetricDifference(filterFacilities || [], [facility]),
    );
  };

  // get user location to be use as default location
  useEffect(() => {
    clearFilters();

    // copy query values into the appropriate states
    if (locationState && locationState?.query) {
      const filterTypes = locationState?.query.types?.split(',') || []
      //const filterPrice = locationState?.query.price?.split(',') || []
      const filterCapacity = locationState?.query.capacity?.split(',') || []
      const filterDate = locationState?.query.date?.split(',').map(d => moment(d).toDate()) || null

      setFilterTypes(filterTypes)
      //setFilterPrice(filterPrice)
      setFilterCapacity(filterCapacity)

      if (filterDate) {
        setFilterFromDate(filterDate[0]);
        setFilterToDate(filterDate[1]);
      }
      if (locationState?.query.location) {
        setFilterLocation(locationState?.query.location)
      }

      dispatch(
        filterActions.setFilter({
          //location: locationState?.query.location,
          ...(filterDate
            ? {
                dateRange: {
                  from: filterDate[0],
                  to: filterDate[1],
                },
              }
              : {}),
          ...(filterCapacity
            ? {
                capacity: {
                  min: filterCapacity[0],
                  max: filterCapacity[1],
                },
              }
            : {}),
          ...(filterTypes
            ? {
                types: filterTypes,
              }
            : {}),
        }),
      );

      /*
      setTimeout(() => {
        onSubmitTypes()
        onSubmitAdditionalFilters()
        onSubmitDate()
      }, 0)*/
    }

    // locationState
    dispatch(productThunks.getFilterOptions());
    if (isLoggedIn) {
      dispatch(userThunk.getFavourites());
    }
    // if ('geolocation' in navigator) {
    //   navigator.geolocation.getCurrentPosition(
    //     position => {
    //       setUserCoords({
    //         lat: position.coords.latitude,
    //         lng: position.coords.longitude,
    //       });
    //       setAutoLoad(true);
    //     },
    //     error => {
    //       setAutoLoad(true);
    //     },
    //   );
    // } else {
    //   setAutoLoad(true);
    // }
    // setTimeout(() => {
    //   setAutoLoad(true);
    // }, 500);
  }, []);

  // save user location to redux store
  useEffect(() => {
    if (!filterState.location && userCoords) {
      dispatch(
        filterActions.setFilter({
          location: {
            label: '',
            coords: {
              lat: userCoords.lat,
              lng: userCoords.lng,
            },
          },
        }),
      );
    }
  }, [userCoords]);

  const mapRef = useRef(null);
  const [center, setCenter] = useState({ lat: -27, lng: 133 });
  const [zoom, setZoom] = useState(4.1);
  const [map, _setMap] = useState(null);
  const [selectedId, setSelectedId] = useState('');
  const [isIgnoreNextMapChange, setIgnoreNextMapChange] = useState(true)

  const setMap = (map) => {
    _setMap(map)
  }

  const setCenterMap = (position: any, markerId: string) => {
    if (map) {
      // @ts-ignore
      setIgnoreNextMapChange(true)
      map.panTo(new window.google.maps.LatLng(position.lat, position.lng));
      setSelectedId(markerId);
    }
  };

  const onMapChange = (props) => {
    const {center, bounds} = props
    const {location} = filterState
    if (isIgnoreNextMapChange) {
      setIgnoreNextMapChange(false)
      return
    }
    if (!isMapVisible) {
      return
    }
    if (center.lat == 0 && center.lng == 0) {
      return
    }
    const radius = computeDistanceBetween(bounds.nw, bounds.se) * 0.5
    if (center.lat == location?.coords.lat && center.lng == location?.coords.lng && radius == location?.radius) {
      return
    }

    // if we exceed some sort of zoom level, remove the location search
    const maxRadius = 100000 // 100km
    if (radius > maxRadius) {
      // oh we already have no location search, so abort to avoid a new search
      if (!location) {
        return
      }

      dispatch(
        filterActions.setFilter({
          location: null,
        }),
      );
      return
    }

    dispatch(
      filterActions.setFilter({
        location: {
          coords: center,
          radius
        },
      }),
    );
  }

  const fetchMoreFn = (lastKey, resolve, reject) => {
    const startKey = lastKey
    const { location, dateRange, ...filters } = filterState
    dispatch(
      productThunks.get({
        startKey,
        ...filters,
        ...(dateRange && (dateRange.from || dateRange.to)
          ? {
              dateRange: {
                from: dateRange.from
                  ? format(dateRange.from, 'yyyy-MM-dd')
                  : undefined,
                to: dateRange.to
                  ? format(dateRange.to, 'yyyy-MM-dd')
                  : undefined,
              },
            }
          : {}),
        ...(location &&
        location.coords &&
        location.coords.lng &&
        location.coords.lat
          ? {
              location: {
                long: location.coords.lng,
                lat: location.coords.lat,
                radius: location.radius || 10000
              },
            }
          : {}),
      },
      (response) => {
        resolve(response.data.products, response.lastKey)
      },
      (error) => {
        reject(error)
      }),
    )
  }
  const {data: products, setData: setProducts, fetchMore, hasMore, loading} = useInfiniteScroll(fetchMoreFn, [filterState /* , autoLoad */])

  const toggleFavourite = (productId: string, isFavourite: boolean) => {
    if (isFavourite) {
      dispatch(userThunk.removeFavourite({ productId }));
    } else {
      dispatch(userThunk.addFavourite({ productId }));
    }
  };
  const favouritesData = favourites.map(f => f.prod_ID);
  const productsWithFav = products.map(
    (e: { ID: string }) => ({
      ...e,
      isFavourite: favouritesData.includes(e.ID),
    }),
  );

  const data = productsWithFav.map(formatEntities);

  // @ts-ignore
  const featured = data;
  const results = data;
  const formattedFilterOptions =
    // @ts-ignore
    ((filterOptions || {}).data || {}).data || undefined;


  const deselectMarker = () => {
    setSelectedId('');
  };

  const locationCardOnMouseOver = (feature) => {
      setCenterMap(
        { lat: feature.latitude, lng: feature.longitude },
        feature.id,
      )
  }
  
  const locationCardOnMouseOut = (feature) => {
      deselectMarker();
  }

  const onChangeLocation = (v?: FilterState['location']) => {
    /*
    if (filterLocation != v?.label) {
      setFilterLocation(v?.label || '')
    }*/
    if (v) {
      setCenterMap(v.coords, '')
      if (map) {
        map.setZoom(13)
      }
      //setZoom(5)

      dispatch(
        filterActions.setFilter({
          location: v,
        }),
      );
    } else {
      dispatch(
        filterActions.setFilter({
          location: null,
        }),
      );
    }
  };

    
  return (
    <SearchView
      {...props}
      fetchMore={fetchMore}
      hasMore={hasMore}
      loading={loading}
      isMapVisible={isMapVisible}
      onViewListing={onViewListing}
      toggleMap={toggleMap}
      featured={featured}
      results={results}
      filterState={filterState}
      onChangeLocation={onChangeLocation}
      // @ts-ignore
      calendarHook={calendarHook}
      toMonth={toDate}
      notAvailableDates={notAvailableDates}
      onSubmitDate={onSubmitDate}
      onSubmitTypes={onSubmitTypes}
      onSubmitSubTypes={onSubmitSubTypes}
      onToggleSubType={onToggleSubType}
      onToggleType={onToggleType}
      onToggleFacility={onToggleFacility}
      filterLocation={filterLocation}
      filterTypes={filterTypes}
      filterSubTypes={filterSubTypes}
      filterFacilities={filterFacilities}
      filterCapacity={filterCapacity}
      filterPrice={filterPrice}
      setFilterPrice={setFilterPrice}
      setFilterCapacity={setFilterCapacity}
      onSubmitAdditionalFilters={onSubmitAdditionalFilters}
      resetAdditionalFilter={resetAdditionalFilter}
      resetTypeFilter={resetTypeFilter}
      filterOptions={
        formattedFilterOptions
          ? {
              ...formattedFilterOptions,
            }
          : null
      }
      toggleFavourite={toggleFavourite}
      onMapChange={onMapChange}

      locationCardOnMouseOver={locationCardOnMouseOver}
      locationCardOnMouseOut={locationCardOnMouseOut}
      mapRef={mapRef}
      setMap={setMap}
      selectedId={selectedId}
      center={center}
      zoom={zoom}
    />
  );
};

export default React.memo(SearchContainer);
