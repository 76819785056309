import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Main from 'components/layouts/Main'
import {HeaderSection, ConfirmModal} from 'components/modules'
import {Typo, SplitView, HeaderImage, ImageUpload, Field, HorizontalLine, Loading, Button, ButtonContainer, InputField, Select, Link} from 'components/primitives'
import {makeStyles, createStyles} from '@mui/styles'
import useSelectorSafe from 'store/selectors/useSelectorSafe'
import { asyncData } from 'utils/Redux'
import { ASYNC_STATUS } from 'types/store/AsyncStatus'
import {getUsersForProvider, postProviderUpdateUser, getProviders, setActiveProvider, postProvider} from 'thunks/provider'
import {getUsers} from 'thunks/user'
import { EmailIcon } from 'assets'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {validEmail} from 'utils'

import {useStyles, ProviderUserEntry, AddUserModal} from '../../host/Providers/Providers'
// TODO: share more code with Providers

const ProviderAdmin = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles(props)

    const { data: user, status, errors } = useSelectorSafe(
        state => state.user,
        asyncData(ASYNC_STATUS.INITIAL),
    )

    const [editMode, setEditMode] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const [refreshProviders, setRefreshProviders] = useState(0)
    const [refreshUsers, setRefreshUsers] = useState(0)
    const [providerUsers, setProviderUsers] = useState([])
    const [error, setError] = useState()
    const [loadingProviders, setLoadingProviders] = useState(true)
    const [loadingUsers, setLoadingUsers] = useState(true)

    const [providers, setProviders] = useState([])
    const [activeProvID, setActiveProvID] = useState(null)

    useEffect(() => {
        dispatch(getUsersForProvider({ID: activeProvID}))
            .then(response => {
                setProviderUsers(response.data.providerusers)
                setLoadingUsers(false)
            })
            .catch(err => {
                setError(err.message || err)
                setLoadingUsers(false)
            })
    }, [refreshUsers, activeProvID])


    const onSelectProvider = (prov_ID) => {
        setEditMode(false)
        setLoadingUsers(true)
        setLoadingProviders(true)
        setProviderUsers([])
        setActiveProvID(prov_ID)
        setLoadingProviders(false)
    }

    useEffect(() => {
        dispatch(getProviders())
            .then(response => {
                setProviders(response.data.providers)
                setLoadingProviders(false)
                if (response.data.providers.length) {
                    onSelectProvider(response.data.providers[0].ID)
                }
            })
            .catch(err => {
                setError(err.message || err)
                setLoadingProviders(false)
            })
    }, [refreshProviders])


    const onRemoveProviderUser = async (providerUser) => {
        const isSelf = (user.data.user.ID == providerUser.user_ID)
        return dispatch(postProviderUpdateUser({
            operation: 'remove',
            prov_ID: providerUser.prov_ID,
            user_ID: providerUser.user_ID
          }))
          .then(puuResponse => {
            if (isSelf) {
                setActiveProvID(puuResponse.data?.prov_ID)
                setRefreshProviders(refreshProviders + 1)
            } else {
                setRefreshUsers(refreshUsers + 1)
            }
          })
          .catch(err => {
            setError(err.message || err)
        })
    }

    const foundProvider = providers.find(p => p.ID == activeProvID)
    const {image, name} =  foundProvider || {}
    const providerOptions = providers.map(p => {
        return {label: p.name, value: p.ID}
    })

    const [editBusy, setEditBusy] = useState(false)
    const [editName, setEditName] = useState()
    const [editImages, setEditImages] = useState([])

    const onEditPress = () => {
        setError()
        setEditBusy(false)
        setEditName(name)
        setEditImages(image ? [{data_url: image}] : [])
        setEditMode(true)
    }

    const onSavePress = () => {
        setError()
        setEditBusy(true)
        dispatch(postProvider({
            ...foundProvider,
            name: editName,
            image: editImages.length ? editImages[0].data_url : null
          }))
          .then(response => {
            setActiveProvID(response.data.ID)
            setRefreshProviders(refreshProviders + 1)
            setEditMode(false)
          })
          .catch(error => {
              setError(error.message || error)
          })
    }

    return (
        <Main>
            <HeaderSection
                heading="Provider Admin"
                subHeading="Manage and administer providers"
            />

            {Boolean(error) &&
                <Typo variant="body1" color="red">
                  {error}
                </Typo>
            }

            {
                <>
                    <div className={classes.activeProviderContainer}>
                        <Typo variant="h5" color="darkGrey" className={classes.activeProviderHeading}>
                            Provider
                        </Typo>

                        <Select
                            id="provider"
                            value={activeProvID || 'placeholder'}
                            onChange={(option) => onSelectProvider(option)}
                            options={providerOptions}
                        />
                    </div>
                    <HorizontalLine/>
                </>
            }

            <Loading isLoading={loadingProviders}>
                <div className={classes.splitViewContainer}>
                    <SplitView
                        leftChild={
                            <>
                                {editMode ?
                                    <div className={classes.imageUploadContainer}>
                                        <ImageUpload 
                                            onChange={setEditImages} 
                                            value={editImages} 
                                            maxNumber={1}
                                        />
                                    </div>
                                :
                                    <HeaderImage image={image} />
                                }
                            </>
                        }
                        rightChild={
                            <div className={classes.rightChild}>
                                <div className={classes.usersHeaderContainer}>
                                    <Typo variant="h3" className={classes.field}>
                                        Details
                                    </Typo>

                                    {!editMode &&
                                        <Typo variant="body1" className={classes.field}>
                                            <Link onClick={onEditPress}>
                                                Edit
                                            </Link>
                                        </Typo>
                                    }
                                </div>

                                {editMode ?
                                    <InputField
                                        id="name"
                                        placeholder="Name"
                                        onChange={event => setEditName(event.target.value)}
                                        value={editName}
                                        //onBlur={props.setTouchedLastName}
                                        //error={Boolean(props.lastName.error) && props.lastName.touched}
                                    />
                                :
                                    <Field label="Name" value={name} className={classes.field} />
                                }

                                <div style={{flex: 1}}/>

                                {editMode &&
                                    <ButtonContainer className={classes.buttonContainer}>
                                        <Button className={classes.cancelButton} onClick={() => setEditMode(false)}
                                            text="Cancel"
                                            variant='text'
                                            color='lightGrey'
                                            disabled={editBusy}
                                        />
                                        <Button
                                            disabled={editBusy}
                                            loading={editBusy}
                                            text="Save changes"
                                            onClick={onSavePress}
                                        />
                                    </ButtonContainer>
                                }
                            </div>
                        }
                    />
                </div>
                <HorizontalLine />
                <div className={classes.membersContainer}>
                    <div className={classes.usersHeaderContainer}>
                        <Typo variant="h3" className={classes.field}>
                            Users
                        </Typo>

                        <Typo variant="body1" className={classes.field}>
                            <Link onClick={() => setModalOpen(true)}>
                                Add User
                            </Link>
                        </Typo>
                    </div>
                    <div>
                        <Loading isLoading={loadingUsers} />
                        {providerUsers.map(providerUser => 
                            <ProviderUserEntry 
                                providerUser={providerUser} 
                                onRemove={onRemoveProviderUser} 
                                canRemove={providerUsers.length > 1}
                                isSelf={user.data.user.ID == providerUser.user_ID}
                            />
                        )}
                    </div>
                </div>
            </Loading>

            <AddUserModal open={modalOpen} onClose={() => setModalOpen(false)} onSuccess={() => {setModalOpen(false); setRefreshUsers(refreshUsers + 1)}} provider={foundProvider}/>
        </Main>
    )
}

export default ProviderAdmin