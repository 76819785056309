import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import { Main } from 'components/layouts';
import { Container } from '@mui/material';
import {
  Typo,
  StarRating,
  HorizontalLine,
  Avatar,
  LocationCard,
  Link,
  Button,
  Loading,
  ButtonContainer,
  Gallery,
  CollapseView,
} from 'components/primitives';
import LoginModal from 'components/modules/LoginModal';
import {AvailabilitySummary} from 'components/modules'
import Slider from 'react-slick';
import { useStyles } from './ListingDetails.styles';
import { ListingDetailsProps } from './ListingDetails.props';
import Map from '../Map';
import BookingSelect from './BookingSelect';
import {PORTALS} from 'routes'
import { Button as MButton } from '@mui/material'
import {typeOptionsForProduct} from 'services/product'
import {useGetSettingsList, getSettingValue} from 'services/react-query'

const sliderSettings = {
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 4,
  useTransform: true,
  autoplay: true,
  arrows: false,
  infinite: false,
  dots: false,
  pauseOnHover: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 599,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '30px',
      },
    },
  ],
};

const ListingDetailsView: FC<ListingDetailsProps> = (
  props,
): ReactElement<'div'> => {
  const classes = useStyles(props);
  // @ts-ignore
  const { entityState, entityStateOriginal, portalName, reviews, pendingReviews } = props;
  const images = (entityState && entityState.images) || [];
  const videos = (entityState && entityState.videos) || [];
  const lightboxData = images.map(i => ({url: i, type: 'image'})).concat(videos.map(v => ({url: v, type: 'video'})))

  const [showModal, setShowModal] = useState('CLOSE');
  const [showSliderModal, setShowSliderModal] = useState(false);
  const [imageIndex, setImageIndex] = useState(15);
  const mapRef = useRef(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(15);
  const [showMap, setShowMap] = useState(false);
  const [markers, setMarkers] = useState([]);


  const settingsListQuery = useGetSettingsList()
  const typeOptions = getSettingValue(settingsListQuery, 'product-types', [])

  useEffect(() => {
    if (entityState && entityState.location) {
      const { location } = entityState;
      // @ts-ignore
      setCenter({
        lat: Number(location.latitude || 0),
        lng: Number(location.longitude || 0),
      });
      // @ts-ignore
      setMarkers([
        {
          // @ts-ignore
          latitude: Number(location.latitude || 0),
          // @ts-ignore
          longitude: Number(location.longitude || 0),
          id: entityState ? entityState.ID : '-1',
        },
      ]);
      setShowMap(true);
    }
  }, [entityState]);

  if (!entityState) {
    return (
      <Main fullWidth={true} footer={<div />}>
        <Loading isLoading={props.loading} center/>
      </Main>
    );
  }

  const {typeOption, subtypeOption} = typeOptionsForProduct(entityState, typeOptions)

  const outlineButtonProps = {
      color: "blue",
      backgroundColor: "white",
      variant: 'outlined'
  }

  const paragraphs = entityState.description.split('\n').filter(p => !!p)
  return (
    <Main fullWidth={true} footer={<div />}>
      <div className={classes.imagesContainer}>
        <div className={classes.imageCarouselPlaceHolder}>
          {images.length &&
            <img
              src={images[0]}
              style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
              onClick={() => {
                setImageIndex(0)
                setShowSliderModal(true)
              }}
            />
          }
        </div>
        {images.length > 1 &&
          <div className={classes.sideImagesContainer}>
            {images.map((src: string, idx: number) => {
              if (idx == 0 || idx >= 3) {
                return null
              }

              return (
                <div className={classes.sideImagePlaceHolder} key={idx}>
                  <img
                    src={src}
                    style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => {
                      setImageIndex(idx)
                      setShowSliderModal(true)
                    }}
                  />
                </div>
              )
            })}
          </div>
        }
      </div>
      <Container className={classes.container}>
        <div className={classes.leftContent}>
          <div className={classes.headerInfo}>
            <Typo variant="h6" color="red">
              {typeOption?.label} / {subtypeOption?.label}
            </Typo>
            <Typo variant="h6" color="blue">
              Capacity: {entityState.capacity.min}-{entityState.capacity.max}
            </Typo>
          </div>
          <Typo variant="h2" className={classes.listingTitle}>
            {entityState.title}
          </Typo>
          {/*
          <Typo
            variant="body2"
            color="lightGrey"
            className={classes.listingAddress}
          >
            {entityState.location.address}
          </Typo>
      */}
          <div className={classes.ratingContainer}>
            <StarRating size="20px" rating={entityState.rating || 0} />
            <Typo variant="h4" className={classes.price}>
              {`$${entityState.weekdayPriceData.toFormat('0,0.00') ||
                0} - $${entityState.weekendPriceData.toFormat('0,0.00') ||
                0}/hour`}
            </Typo>
          </div>
          <HorizontalLine />
          {!!paragraphs.length && <Typo variant="h4" style={{marginTop: '20px'}}>Description</Typo>}
          <CollapseView className={classes.listingDescription}>
              {paragraphs.map((paragraph, idx) => {
                return (
                  <Typo
                    variant="body1"
                    component="p"
                    key={idx}
                    className={classes.listingParagraph}
                  >
                    {paragraph}
                  </Typo>
                )
              })}
          </CollapseView>
          <div className={classes.attributes}>
            <Typo variant="h4">Facilities</Typo>
            {entityState.attributes.length ? (
              <ul style={{ textTransform: 'capitalize' }}>
                {entityState.attributes.map((facility: any, key: any) => (
                  <li key={key}>
                    <Typo variant="body1">{facility}</Typo>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
          <div className={classes.location}>
            <div className={classes.subHeaderContainer}>
              <Typo variant="h4">Location</Typo>
            </div>
            <Typo
              variant="body2"
              className={classes.listingAddress}
            >
              {entityState.location.address}
            </Typo>
            <div className={classes.mapContainer}>
              {showMap ? (
                <Map
                  center={center}
                  defaultZoom={zoom}
                  mapRef={mapRef}
                  markers={markers}
                />
              ) : null}
            </div>
          </div>
          <div className={classes.reviews}>
            <div className={classes.subHeaderContainer}>
              <Typo variant="h4">Reviews</Typo>
              {reviews && reviews.length >= 3 ? (
                <Typo variant="body1" color="blue">
                  View more
                </Typo>
              ) : null}
            </div>
            <div className={classes.reviewContainer}>
              {reviews && reviews.length ? (
                // @ts-ignore
                reviews.map((review, i) => (
                  <div className={classes.review} key={i}>
                    <Typo
                      variant="body1"
                      component="p"
                      className={classes.reviewText}
                    >
                      {review.commentBooking}
                    </Typo>
                    <div className={classes.reviewFooter}>
                      <div className={classes.reviewAuthor}>
                        {/* Need to also add the URL */}
                        <Avatar
                          square={true}
                          size={30}
                          src={review.user && review.user.profilePicture}
                        />
                        <Typo
                          variant="body1"
                          className={classes.reviewAuthorName}
                        >
                          {review.user && review.user.firstName} {review.user && review.user.lastName}
                        </Typo>
                      </div>
                      <StarRating rating={review.rating} />
                    </div>
                  </div>
                ))
              ) : (
                <div className={classes.review}>
                  <Typo
                    variant="body1"
                    component="p"
                    className={classes.reviewText}
                  >
                    There are currently no reviews for this venue.
                  </Typo>
                </div>
              )}
            </div>
          </div>

          {portalName == PORTALS.HOST &&
            <>
              <HorizontalLine />
              <div className={classes.pendingReviews}>
                <div className={classes.subHeaderContainer}>
                  <Typo variant="h4">Pending Reviews</Typo>
                  {pendingReviews && pendingReviews.length >= 3 ? (
                    <Typo variant="body1" color="blue">
                      View more
                    </Typo>
                  ) : null}
                </div>
                <div className={classes.reviewContainer}>
                  {pendingReviews && pendingReviews.length ? (
                    // @ts-ignore
                    pendingReviews.map((review, i) => (
                      <div className={classes.review} key={i}>
                        <Typo
                          variant="body1"
                          component="p"
                          className={classes.reviewText}
                        >
                          {review.commentBooking}
                        </Typo>
                        <div className={classes.reviewFooter}>
                          <div className={classes.reviewAuthor}>
                            {/* Need to also add the URL */}
                            <Avatar
                              square={true}
                              size={30}
                              src={review.user && review.user.profilePicture}
                            />
                            <Typo
                              variant="body1"
                              className={classes.reviewAuthorName}
                            >
                              {review.user && review.user.firstName} {review.user && review.user.lastName}
                            </Typo>
                          </div>
                          <StarRating rating={review.rating} />
                        </div>
                        <ButtonContainer>
                            <Button
                                text="Decline"
                                loadingText="Decline"
                                className={classes.button}
                                //disabled={busy}
                                onClick={() => props.onDeclinePendingReview(review)}
                                variant="text"
                                color="lightGrey"
                            />
                            <Button
                                text="Approve"
                                loadingText="Approve"
                                className={classes.button}
                                //loading={busy}
                                //disabled={busy}
                                onClick={() => props.onApprovePendingReview(review)}
                            />
                        </ButtonContainer>
                      </div>
                    ))
                  ) : (
                    <div className={classes.review}>
                      <Typo
                        variant="body1"
                        component="p"
                        className={classes.reviewText}
                      >
                        There are currently no pending reviews for this venue.
                      </Typo>
                    </div>
                  )}
                </div>
              </div>
            </>
          }
        </div>

        {portalName == PORTALS.HOST &&
          <div style={{marginLeft: '50px', flex: 1}}>
            <div className={classes.pendingReviews}>
              <div className={classes.subHeaderContainer}>
                <Typo variant="h4">User Questions</Typo>
              </div>
              {entityState.questions && entityState.questions.map((qr, index) => {
                  return (
                    <div className={classes.questionsContainer}>
                      <Typo className={classes.question}>
                        {index+1}. {qr.question}
                      </Typo>
                    </div>
                  )
              })}
            </div>

            <AvailabilitySummary value={entityState.schedule}/>
          </div>
        }
        
        {portalName == PORTALS.CUSTOMER &&
          // @ts-ignore
          <BookingSelect
            entityState={entityStateOriginal}
            user={props.user}
            setShowModal={setShowModal}
            goToContactHost={props.goToContactHost}
            goToHostDetails={props.goToHostDetails}
            goToBookingDetails={props.goToBookingDetails}
            capacity={entityState.capacity}
            title={entityState.title}
            description={entityState.description}
            images={entityState.images}
            location={entityState.location}
            provider={entityState.provider}
          />
        }
      </Container>
      {portalName == PORTALS.CUSTOMER &&
        <>
          <Container>
            <HorizontalLine />
          </Container>

          <div className={classes.relatedPlaces}>
            <Container>
              <div className={classes.subHeaderContainer}>
                <Typo variant="h1">Related Places</Typo>
                <Typo variant="body1" color="blue">
                  <Link onClick={() => props.goToListings()}>View more</Link>
                </Typo>
              </div>
            </Container>
            <Container className={classes.related}>
              <Slider {...sliderSettings}>
                {props.related.map(related => {
                  return (
                    <LocationCard
                      key={related.ID}
                      type={related.type}
                      capacity={related.capacity}
                      title={related.title}
                      address={related.address}
                      rating={related.rating}
                      price={related.price}
                      weekdayPrice={related.weekdayPrice}
                      weekendPrice={related.weekendPrice}
                      favourite={related.favourite}
                      images={related.images}
                      onClick={related.onClick}
                      onFavouriteClick={() =>
                        props.toggleFavourite(related.ID, related.favourite)
                      }
                    />
                  )
                })}
              </Slider>
            </Container>
          </div>
        </>
      }
      <LoginModal open={showModal} onClose={() => setShowModal('CLOSE')} />
      <Gallery
        open={showSliderModal}
        data={lightboxData}
        onClose={() => setShowSliderModal(false)}
        slideIndex={imageIndex}
        setSlideIndex={setImageIndex}
      />
      {props.error ? (
        <Typo variant="body1" color="red" className={classes.errorMessage}>
          {props.error}
        </Typo>
      ) : null}
      {portalName == PORTALS.HOST &&
        <Container className={classes.footerContainer}>
          <Button
            text="Edit Listing"
            className={classes.button}
            onClick={props.onEditListing}
            disabled={props.disabled}
            {...((props.onPublishListing || props.onSaveListing) && outlineButtonProps)}
          />
          {props.onPublishListing &&
            <Button
              loading={props.disabled}
              loadingText={'Publishing Listing'}
              text={"Publish Listing"}
              className={classes.button}
              onClick={props.onPublishListing}
            />
          }
          {props.onSaveListing &&
            <Button
              loading={props.disabled}
              loadingText={'Saving Listing'}
              text={"Save Listing"}
              className={classes.button}
              onClick={props.onSaveListing}
            />
          }
        </Container>
      }
    </Main>
  );
};

export default ListingDetailsView;
