import React from 'react';
import Avatar from '../../primitives/Avatar';
import Typo from '../../primitives/Typo';
import { useStyles } from './RequestedBy.styles';

// @ts-ignore
const RequestedByView = ({ user, onClick }) => {
  const classes = useStyles();
  return (
    <button className={classes.hostedByContainer} onClick={onClick}>
      <Avatar size={30} src={(user && user.image) || ''}/>
      <Typo variant="body1" color="lightGrey">
        Requested By{' '}
        <Typo variant="h5" color="darkGrey" component="span">
          {' '}
          {user ? `${user.firstName} ${user.lastName}` : ''}
        </Typo>
      </Typo>
    </button>
  );
};

export default React.memo(RequestedByView);
