import React, { useState } from 'react';
import { Divider } from '@mui/material';
import { useStyles } from './ImageUpload.styles';
import ImageUploading from 'react-images-uploading';
import { CameraIcon, DeclinedIcon } from 'assets';

const ImageUploadView = (
  props,
) => {
  const classes = useStyles()
  const maxNumber = props.maxNumber || 5
  const [dragId, setDragId] = useState(null)

  const onChange = async (imageList, addUpdateIndex) => {
    props.onChange(imageList, addUpdateIndex)
  }

  return (
    <ImageUploading
      multiple
      maxNumber={maxNumber} 
      dataURLKey="data_url"
      {...props}
      onChange={onChange}
    >
      {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => {
          const moveItem = (from, to) => {
            let images = [...imageList]
            let f = images.splice(from, 1)[0]
            images.splice(to, 0, f)
            props.onChange(images)
            setDragId(to)
          }

          const placeholderCount = maxNumber - imageList.length
          const placeholderList = [...Array(placeholderCount).keys()];
          return (
            <div className={classes.container}>
              {imageList.map((image, index) => {
                return (
                  <div id={'image_' + index} key={'image_' + index} className={classes.imageContainer}
                    draggable
                    onDragOver={(e) => {
                      let target = e.nativeEvent.target
                      if (target.nodeName != 'DIV') {
                        target = target.parentElement
                      }

                      const idSplit = target.id.split('_')
                      if (idSplit <= 0) {
                        return
                      }
                      const targetId = Number(idSplit[1])
                      if (dragId != targetId) {
                        moveItem(dragId, targetId)
                      }

                      e.preventDefault()
                    }}
                    onDragStart={(e) => {
                      console.log('start drag')
                      setDragId(index)
                    }}
                    onDrop={(e) => {
                      console.log('drop')
                    }}
                  >
                    <img src={image.data_url} className={classes.img} />
                    <div onClick={() => onImageRemove(index)} className={classes.remove}>
                      <DeclinedIcon/>
                    </div>
                  </div>
                )
              })}
              {placeholderList.map((i, index) => {
                return (
                  <div id={'placeholder_' + index} key={'placeholder_' + index} className={classes.placeholderContainer} {...dragProps} onClick={onImageUpload}>
                    <CameraIcon />
                  </div>
                )
              })}
            </div>
          )
        }}
    </ImageUploading>
  );
};

export default React.memo(ImageUploadView);
