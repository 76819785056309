import React, { FC } from 'react';
import { useStyles } from './BookingCard.styles';
import Typo from '../Typo';
import {StatusLabel} from '../StatusLabel'
import { Button } from '@mui/material';
import Avatar from '../Avatar';
import Slider from 'react-slick';
import moment from 'moment'
import {bookingTimeData} from 'services/api'

const reallyOnClick = (onClick) => (event) => {
  onClick(event)
  event.stopPropagation()
}

function SliderArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={style}
      onClick={reallyOnClick(onClick)}
    />
  );
}

const sliderSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SliderArrow />,
  prevArrow: <SliderArrow />
};

const BookingCardView = props => {
  const classes = useStyles(props)
  const {booking, onContactGuest, onApproveBooking, onCancelBooking} = props
  const {user, product} = booking
  const {startOn, endOn, singleDay} = bookingTimeData(booking)

  return (
    <div
      className={classes.container}
    >
      <div className={classes.innerContainer}>
        <div className={classes.clickContainer}
          onClick={props.onClick}
          onMouseOver={props.onMouseOver ? props.onMouseOver : () => {}}
          onMouseOut={props.onMouseOut ? props.onMouseOut : () => {}}
        >
          {/*product.images &&
            <div className={classes.imageContainer}>
              <div className={classes.imageInnerContainer}>
                <Slider {...sliderSettings} className={classes.imageSlider}>
                  {product.images && product.images.map(image => {
                    return (
                      <img src={image} className={classes.sliderImage}/>
                    )
                  })}
                </Slider>
              </div>
            </div>
          */}

          {user &&
            <div className={classes.bookedByContainer}>
              <Avatar size={30} src={user.image}/>
              <Typo variant="body1" color="lightGrey">
                Booked by{' '}
                <Typo variant="h5" color="darkGrey" component="span">
                  {user.firstName}{' '}{user.lastName}
                </Typo>
              </Typo>
            </div>
          }

          <div className={classes.bodyContainer}>
            <div
              style={{
                //flex: 1.75,
                display: 'flex',
                //overflow: 'hidden',
                //textOverflow: 'ellipsis',
                //minHeight: '52px',
                //maxHeight: '52px',
                marginBottom: '10px',
                //alignItems: 'center',
                maxWidth: '233px' // FMNOTE: not ideal!
              }}
            >
              <Typo variant="h3" color="darkGrey" className={classes.title}>
                {product?.title ?? '_MISSING_PRODUCT_'}
              </Typo>
            </div>

            {singleDay &&
              <div
                style={{
                  flex: 2,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <Typo variant="body1" color="lightGrey" component="span" /*className={classes.date}*/>
                  {startOn.format('D MMM YYYY')}
                </Typo>
                <Typo variant="body1" color="lightGrey" component="span" /*className={classes.time}*/>
                  {startOn.format('h:mma')} - {endOn.format('h:mma')} 
                </Typo>
              </div>
            }
            {!singleDay &&
              <Typo variant="body1" color="lightGrey" className={classes.date}>
                {startOn.format('D MMM YYYY, h:mma')} - {endOn.format('D MMM YYYY, h:mma')}
              </Typo>
            }
            {props.showStatus &&
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flex: 2,
                }}
              >
                <Typo variant="body1" color="lightGrey" component="span">
                  Status
                </Typo>
                <StatusLabel
                  status={booking.status}
                />
              </div>
            }
          </div>
        </div>
        <div className={classes.footerContainer}>
          {onContactGuest &&
            <Button
              className={classes.footerButton}
              onClick={props.onContactGuest}
            >
              <Typo variant="body2" color="blue">
                Contact Guest
              </Typo>
            </Button>  
          }

          {onApproveBooking && 
            <Button
            className={classes.footerButton}
            onClick={() => {
              onApproveBooking()
            }}
          >
            <Typo variant="body2" color="blue">
              Approve Booking
            </Typo>
          </Button> 
          }
          {onCancelBooking && 
            <Button
              className={classes.footerButton}
              onClick={() => {
                onCancelBooking()
              }}
            >
              <Typo variant="body2" color="red">
                Cancel Booking
              </Typo>
            </Button>  
          }
          {/*
            <Button
              className={classes.footerButton}
              onClick={() => {
                props.onViewRequest()
              }}
            >
              <Typo variant="body2" color="blue" className={classes.date}>
                View Details
              </Typo>
            </Button>   
            */}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BookingCardView);
