import { bookingActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getProviderBookingsEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';

const getProviderBookings = (
  data,
  onSuccess,
  onError,
) => (dispatch, getState) => {
  const getProviderBookingsFetch = apiFetch(getProviderBookingsEndpoint, data)(
    dispatch,
    getState,
  )
    .then(response => {
      if (onSuccess) onSuccess(response);
      return response;
    })
    .catch(error => {
      if (onError) onError(error);
      return error;
    });
  dispatch(bookingActions.getProviderBookings(getProviderBookingsFetch));
};

export default getProviderBookings;
