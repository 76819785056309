import React, { FC } from 'react';
import { Box } from '@mui/material';
import { CameraIcon } from 'assets';
import { useStyles } from './HeaderImage.styles';

const HeaderImageView = (props) => {
  const classes = useStyles(props);
  return (
    <Box className={classes.container}>
      {props.image ? <img src={props.image} className={classes.image}/> : <CameraIcon className={classes.icon} />}
    </Box>
  );
};

export default React.memo(HeaderImageView);
