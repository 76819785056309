import { Box } from '@mui/material';
import React, { FC } from 'react';
import { Calendar, Typo } from '../../primitives';
import MobileButton from '../MobileButton';
import { CalendarFilterMobileProps } from './CalendarFilterMobile.props';
import { useStyles } from './CalendarFilterMobile.styles';

const CalendarFilterMobileView: FC<CalendarFilterMobileProps> = ({
  state,
  onSubmitDate,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.container}>
        <div className={classes.headerContainer}>
          <Typo style={{ marginLeft: 24 }} variant="h4">
            Select Date
          </Typo>
          <button className={classes.clearButton} onClick={state.handleReset}>
            <Typo variant="body2" color="lightGrey">
              Clear
            </Typo>
          </button>
        </div>
        <Calendar disablePast={true} hookState={state} />
      </Box>
      <Box
        style={{
          display: 'flex',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <MobileButton text="Select Dates" onClick={onSubmitDate} />
      </Box>
    </Box>
  );
};

export default React.memo(CalendarFilterMobileView);
