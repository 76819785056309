import React from 'react';
import { Avatar, Typo, HorizontalLine } from 'components/primitives';
import { useStyles } from './ConversationList.styles';
import {PORTALS} from 'routes'

// Image on the left hand side, text on the right
const ConversationListElement = (props) => {
  const {
    onThreadSelect,
    selectedId,
    thread,
    portalName
  } = props
  const {
    user,
    provider,
    excerpt
  } = thread
  const classes = useStyles();
  const image = (portalName == PORTALS.CUSTOMER) ? (provider && provider.image) : (user && user.image)
  const name = (portalName == PORTALS.CUSTOMER) ? ((provider && provider.name) || '_MISSING_PROVIDER_') : ((user && `${user.firstName} ${user.lastName}`) || '_MISSING_USER_')
  const unreadMessages = (portalName == PORTALS.CUSTOMER) ? thread.userUnreadMessages : thread.provUnreadMessages
  return (
    <button
      className={selectedId ? classes.selectedListElement : classes.listElement}
      onClick={() => {
        onThreadSelect(thread)
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '0.5em',
          alignItems: 'center',
        }}
      >
        <div>
          <Avatar
            src={image}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typo
            variant="h4"
            style={{ textAlign: 'left', fontWeight: 'normal', color: '#000' }}
          >
            {name}
          </Typo>
          <Typo
            variant="body2"
            style={{
              textAlign: 'left',
              color: 'rgb(150, 160, 163)',
              marginTop: 8,
            }}
          >
            {excerpt || (unreadMessages > 0 ? `${unreadMessages} new message(s)` : '')}
          </Typo>
        </div>
      </div>
      <div style={{ marginLeft: '1em' }}>
        <HorizontalLine />
      </div>
    </button>
  );
};

const ConversationList = ({
  threads,
  thread,
  onThreadSelect,
  portalName
}: {
  threads: any;
  thread: any;
  onThreadSelect: any;
}) => {
  const desc = (portalName == PORTALS.CUSTOMER) ? 'Communicate with hosts' : 'Communicate with customers'
  return (
    <>
      <div style={{ padding: '1em', paddingBottom: '3em', width: '100%' }}>
        <Typo variant="h1">Messages</Typo>
        <Typo variant="body2">{desc}</Typo>
      </div>
      <div style={{ marginLeft: '1em', width: '100%' }}>
        <HorizontalLine />
      </div>
      <div style={{ overflowY: 'auto', overflowX: 'hidden', width: '100%' }}>
        {threads.map(
          (
            thr,
            i: number,
          ) => {
            return (
              <ConversationListElement
                key={i}
                onThreadSelect={onThreadSelect}
                // @ts-ignore
                selectedId={thr?.ID === thread?.ID}
                thread={thr}
                portalName={portalName}
              />
            )
          },
        )}
      </div>
    </>
  );
};

export default ConversationList;
