import {productSignedUrlEndpoint} from 'services/fetch/apiEndpoints'
import * as imageConversion from 'image-conversion'
import apiFetch from 'services/fetch/apiFetch'
import {promiseAllMap} from './utils'
import _ from 'lodash'

const isLocal = window.location.href.indexOf("localhost") > -1

export const isUrl = (image) => {
    if (!_.isString(image)) {
        return false
    }

    if (image.startsWith('https://') || image.startsWith('http://')) {
        return true
    }
    return false
}

export const imagesToUpload = (images) => {
    const uploads = images.filter((image) => !isUrl(image)).filter(image => !!image)
    return uploads
}

// Given an array of urls and data_url images,
// upload those images which are data_url's to s3 and return a new array of the new urls
export const uploadImages = async (images, signedUrlEndpoint, signedUrlEndpointPayload = {}, dispatch, getState) => {
    try {
        const uploads = imagesToUpload(images)
        if (uploads.length <= 0) {
            return images
        }

        // get signed s3 urls for uploading
        const uploadUrls = await apiFetch(signedUrlEndpoint, {count: uploads.length, ...signedUrlEndpointPayload})(dispatch, getState)
        
        // upload the images
        // https://github.com/aws-samples/amazon-s3-presigned-urls-aws-sam/blob/master/frontend/index.html
        const rUpload = await promiseAllMap(uploads, async (image, idx) => {
            const f = await imageConversion.dataURLtoFile(image, 'image/jpeg')
            const im = await imageConversion.dataURLtoImage(image)
            let {width, height} = im
            //const aspect = width / height

            let scale = 1

            const maxWidth = 2048
            const maxHeight = 1080

            if (width > maxWidth) {
                scale = maxWidth / width
            }
            if (height > maxHeight) {
                scale = maxHeight / height
            }

            const uploadUrl = uploadUrls.data[idx]
            const imagesIdx = images.findIndex((img) => image === img)
/*    
            const splits = image.split(',')
            const imageType = splits[0].replace('data:', '').replace(';base64', '')
            let binary = atob(splits[1])
            let array = []
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i))
            }

            const imgFile = new Blob([new Uint8Array(array)], {type: imageType})
*/
            // convert images to jpg
            const blobData = await imageConversion.compress(f, {
                quality: 0.8,
                type: 'image/jpeg',
                scale
            })

            //console.log('S3 Uploading to: ', uploadUrl)
    
            const headers = new Headers();
            headers.append("Content-Type", "image/jpeg");
            let result = await fetch(uploadUrl, {
                method: 'PUT',
                headers,
                body: blobData,
            }).then(response => response.text())
            //console.log('S3 upload Result: ', result)

            // local dev ignore an AccessDenied error which occurs when running a local back end
            if (isLocal && result.includes('AccessDenied')) {
                result = ''
            }

            if (result != '') {
                throw result
            }
    
            // Final URL for the user doesn't need the query string params
            const imageUrl = uploadUrl.split('?')[0]
            images[imagesIdx] = imageUrl
        })
    } catch (e) { 
        throw e
    }

    return images
}