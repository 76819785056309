import React, { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { CreateAccount } from 'types/CreateAccount';
import routerThunks from 'thunks/router'
import {ROUTES} from 'routes';
import useForm from 'utils/Hooks/useForm';
import { pluck, filter } from 'ramda';
import View from './CreateAccountForm.view';
import { CreateAccountFormPublicProps } from './CreateAccountForm.props';
import authThunks from '../../../thunks/auth';
import {registerWithEmail} from 'services/cognito'
import { openPrivacy, openTerms } from 'services/privacyAndTerms';

const CreateAccountForm: FC<CreateAccountFormPublicProps> = (
  props: CreateAccountFormPublicProps,
) => {
  const dispatch = useDispatch();
  const [responseError, setResponseError] = useState('');
  const createAccount = useSelectorSafe<CreateAccount>(
    // @ts-ignore
    store => store.createAccount,
    asyncData(ASYNC_STATUS.INITIAL),
  );
/*
  const serverErrors = useMemo(
    () => ({
      emailAddress: { error: (createAccount.errors.errors || {}).email },
    }),
    [createAccount.errors],
  );
*/
 
  const {
    state,
    handleOnChange,
    handleOnSubmit,
    handleOnBlur,
    disable,
  } = useForm(
    {
      agreeTerms: { value: false, error: '' },
      firstName: { value: '', error: '' },
      lastName: { value: '', error: '' },
      emailAddress: { value: '', error: '' },
      password: { value: '', error: '' },
      confirmPassword: { value: '', error: '' },
    },
    {
      /*
      agreeTerms: (value) => {
        return (!value ? 'You must agree to terms and conditions.' : null)
      },
      firstName: (value?: string) => (!value ? 'First Name is required.' : null),
      lastName: (value?: string) => (!value ? 'Last Name is required.' : null),
      emailAddress: (value?: string) => {
        if (!value) {
          return 'Email Address is required.';
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return 'You must enter a valid email address.';
        }
        return null;
      },
      password: (value?: string, state) => {
        if (!value) {
          return 'Password is requried.';
        }

        if (state.confirmPassword.touched) {
          if (state.confirmPassword.value !== value) {
            return 'Confirm Password and Password must match.';
          }
        }

        return null
      },
      // @ts-ignore
      confirmPassword: (value?: string, s) => {
        if (!value) {
          return 'Confirm Password is requried.';
        }
        if (s.password.value !== value) {
          return 'Confirm Password and Password must match.';
        }

        return null;
      },
      */
    },
    // @ts-ignore
    (s) => {
      /*
      if (s.password.value != s.confirmPassword.value) {
        console.error('Confirm Password and Password do not match')
        return
      }

      registerWithEmail({
        firstName: s.firstName.value,
        lastName: s.lastName.value,
        email: s.emailAddress.value,
        password: s.password.value,
      })
        .then(async (cognitoResponse) => {
          setResponseError('');
          if (props.openModal) {
            props.openModal('CHECK_EMAIL', {
              email: s.emailAddress.value,
              action: 'verify-email',
            })();
          }
        })
        .catch(error => {
          console.log('all bad!')
          if (error && error.message) {
            const errorMsg = error.message.replaceAll('Username', 'Email Address')
            setResponseError(errorMsg);
          } else {
            setResponseError('');
          }
        })
/*
      dispatch(
        authThunks.createAccount(
          {
            firstName: s.firstName.value,
            lastName: s.lastName.value,
            email: s.emailAddress.value,
            password: s.password.value,
          },
          {
            finishUpdating: () => {
              setResponseError('');
              if (props.openModal) {
                props.openModal('CHECK_EMAIL', {
                  email: s.emailAddress.value,
                  action: 'verify-email',
                })();
              }
            },
            updateErrorMessage: error => {
              if (error && error.message) {
                setResponseError(error.message);
              } else {
                setResponseError('');
              }
            },
          },
        ),
      );*/
    },
    //serverErrors,
  );

  const [errorMessage, setErrorMessage] = useState()
  const [errorField, setErrorField] = useState()
  const [busy, setBusy] = useState(false)

  const setFieldError = (field, message) => {
    setErrorMessage(message)
    setErrorField(field)
  }

  const onCreateAccount = () => {
    setFieldError(null, null)

    if (!state.firstName.value) {
      setFieldError('firstName', 'First Name is required')
      return
    }

    if (!state.lastName.value) {
      setFieldError('lastName', 'Last Name is required')
      return
    }

    if (!state.emailAddress.value) {
      setFieldError('emailAddress', 'Email Address is required')
      return
    }

    if (!state.password.value) {
      setFieldError('password', 'Password is required')
      return
    }

    if (!state.confirmPassword.value) {
      setFieldError('confirmPassword', 'Confirm Password is required')
      return
    }

    if (state.password.value !== state.confirmPassword.value) {
      setFieldError('confirmPassword', 'Confirm Password and Password do not match')
      return
    }

    if (!state.agreeTerms.value) {
      setFieldError('agreeTerms', 'Agree to the T&Cs and Privacy Policy to proceed')
      return
    }

    console.log('yes do it!')
    setBusy(true)

    registerWithEmail({
      firstName: state.firstName.value,
      lastName: state.lastName.value,
      email: state.emailAddress.value,
      password: state.password.value,
    })
      .then(async (cognitoResponse) => {
        if (props.openModal) {
          props.openModal('CHECK_EMAIL', {
            email: state.emailAddress.value,
            password: state.password.value,
            action: 'verify-email',
          })();
        }
      })
      .catch(error => {
        console.log('all bad!')
        if (error && error.message) {
          const errorMsg = error.message.replaceAll('Username', 'Email Address')
          setErrorMessage(errorMsg)
        } else {
          setErrorMessage(null)
        }
        setBusy(false)
      })
  }

  // @ts-ignore
  const errors: string[] = Object.values(
    pluck<'error', { error?: string; touched?: boolean }>(
      'error',
      filter(s => Boolean(s.touched), state),
    ),
  ).filter(v => Boolean(v));

  if (responseError) {
    errors.push(responseError);
  }

  return (
    <View
      {...props}
      busy={busy}
      errorMessage={errorMessage}
      errorField={errorField}
      toTerms={openTerms} //() => dispatch(routerThunks.link(ROUTES.TERMS))}
      toPrivacy={openPrivacy} //() => dispatch(routerThunks.link(ROUTES.PRIVACY_POLICY))}
      onCreateAccount={onCreateAccount} //handleOnSubmit}
      errors={errors}
      agreeTerms={state.agreeTerms}
      setAgreeTerms={handleOnChange('agreeTerms')}
      setTouchedAgreeTerms={handleOnBlur('agreeTerms')}
      firstName={state.firstName}
      lastName={state.lastName}
      emailAddress={state.emailAddress}
      password={state.password}
      confirmPassword={state.confirmPassword}
      setPassword={handleOnChange('password')}
      setFirstName={handleOnChange('firstName')}
      setLastName={handleOnChange('lastName')}
      setEmailAddress={handleOnChange('emailAddress')}
      setConfirmPassword={handleOnChange('confirmPassword')}
      setTouchedPassword={handleOnBlur('password')}
      setTouchedFirstName={handleOnBlur('firstName')}
      setTouchedLastName={handleOnBlur('lastName')}
      setTouchedEmailAddress={handleOnBlur('emailAddress')}
      setTouchedConfirmPassword={handleOnBlur('confirmPassword')}
      disabled={false} //disable}
    />
  );
};

export default React.memo(CreateAccountForm);
