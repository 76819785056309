import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typo from '../Typo';
import Link from '../Link';

const BreadcrumbsView = ({
  elements,
  hideLast,
}: {
  elements: { title: string; onClick: () => void }[];
  hideLast: boolean;
}) => (
  <Breadcrumbs area-label="breadcrumb" style={{ marginBottom: '0.5em' }}>
    {elements.map((element, i) =>
      i === elements.length - 1 && !hideLast ? (
        <Typo key={i}>{element.title}</Typo>
      ) : (
        <Typo color="blue" key={i}>
          <Link onClick={element.onClick}>{element.title}</Link>
        </Typo>
      ),
    )}
  </Breadcrumbs>
);

export default BreadcrumbsView;
