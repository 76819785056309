import React, {useEffect, useState} from 'react'
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker'
import {makeStyles, createStyles} from '@mui/styles'
import Select from './Select'
import Typo from './Typo'

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
        //padding: theme.spacing(1)
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '10px'
    },
    clearButton: {
        backgroundColor: 'transparent',
        border: 'none',
        paddingRight: '15px'
    },
  }),
)

const convertToDisplayDate = (date) => {
    const newDate = moment(date, 'YYYY-MM-DD').format('D MMM')
    return newDate
}

export const DateRangeSelect = (props) => {
    const classes = useStyles(props)
    const {value, onChange} = props

    const [datePickerOpen, setDatePickerOpen] = useState(false)
    const [dateRange, setDateRange] = useState({from: undefined, to: undefined})

    useEffect(() => {
        setDateRange(value)
    }, [value])

    const onDayClick = (day) => {
        // reset range selection on third click
        if (dateRange.to) {
            setDateRange({ from: day, to: undefined })
            return
        }

        const range = DateUtils.addDayToRange(day, dateRange)
        setDateRange(range)
        if (range.to) {
            setDatePickerOpen(false)
            onChange(range)
        }
    }

    const onClear = () => {
        const range = { from: undefined, to: undefined }
        setDateRange(range)
        setDatePickerOpen(false)
        onChange(range)
    }

    const dateLabel = dateRange.from && dateRange.to ? `${convertToDisplayDate(dateRange.from)} - ${convertToDisplayDate(dateRange.to)}` : 'Date'

    return (
        <Select
            id="date"
            openOverride={datePickerOpen}
            setIsOpenOverride={setDatePickerOpen}
            options={[
                {
                    label: dateLabel,
                    value: dateLabel,
                },
            ]}
            customContent={true}
            value={dateLabel}
            onChange={() => {}}
            className={classes.field}
        >
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    <Typo style={{ marginLeft: 24 }} variant="h4">
                        Select Date
                    </Typo>
                    <button className={classes.clearButton} onClick={onClear}>
                        <Typo variant="body2" color="lightGrey">
                            Clear
                        </Typo>
                    </button>
                </div>

                <DayPicker
                    className="Selectable"
                    selectedDays={[dateRange.from, dateRange]}
                    onDayClick={onDayClick}
                    modifiers={{start: dateRange.from, end: dateRange.to}}
                />
            </div>
        </Select>
    )
}