import React from 'react';
import { Box, useTheme } from '@mui/material';
import { HeaderSection } from '../../../components/modules';
import { Link, HorizontalLine, Loading } from '../../../components/primitives';
import Main from '../../../components/layouts/Main';
import { useStyles } from './MySavedListings.styles';
import Typo from '../../../components/primitives/Typo';
import { HeartIcon } from '../../../assets';

const Product = (props) => {
  const {
    product,
    onContactHostClick,
    onViewListingClick,
    removeBooking,
  } = props
  const id = product.ID
  const image = product.images[0]

  // @ts-ignore
  const classes = useStyles({ image, favourite: true });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 4px 2px rgba( 0, 0, 0, 0.1 )',
        overflow: 'hidden',
        borderRadius: '16px',
      }}
    >
      <div style={{ position: 'relative' }}>
        <img
          src={image}
          style={{
            width: '100%',
            height: '227px',
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={() => onViewListingClick(id)}
        />
        <HeartIcon
          className={classes.heart}
          onClick={() => removeBooking(id)}
        />
      </div>
      <div
        style={{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Link
          onClick={() => onViewListingClick(id)}
          style={{ textDecoration: 'none' }}
        >
          <Typo
            variant="h3"
            style={{ marginBottom: '0.5em', textDecoration: 'none' }}
          >
            {product.title}
          </Typo>
        </Link>
        <Typo color="lightGrey">{product.location.address}</Typo>
      </div>
      <div
        style={{
          height: '0.5em',
          width: '100%',
          paddingTop: '0.5em',
        }}
      >
        <HorizontalLine />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '1em',
        }}
      >
        <Typo color="blue">
          <Link onClick={() => onContactHostClick(id, product.provider)}>
            Contact host
          </Link>
        </Typo>
        <Typo color="blue">
          <Link onClick={() => onViewListingClick(id)}>View listing</Link>
        </Typo>
      </div>
    </div>
  );
};

const View = ({
  products,
  onViewListingClick,
  onContactHostClick,
  onMyBookingsClick,
  removeBooking,
  loading
}) => {
  const theme = useTheme();
  return (
    <Main>
      <HeaderSection
        heading="My Saved Listings"
        subHeading="View your saved listings"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          maxWidth: '1200px',
          marginBottom: '6em',
        }}
      >
        <Typo color="lightGrey">
          <Link onClick={onMyBookingsClick}>My Bookings</Link>
        </Typo>
      </div>
      <Loading isLoading={loading}/>
      <div
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 345px)',
          gridGap: '2em',
          justifyContent: 'center',
          paddingBottom: '2em',
        }}
      >
        {products.map((product, i) => (
          <Product
            key={i}
            product={product}
            onViewListingClick={onViewListingClick}
            onContactHostClick={onContactHostClick}
            removeBooking={removeBooking}
          />
        ))}
      </div>
    </Main>
  );
};

export default React.memo(View);
