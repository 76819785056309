import React, { FC, ReactElement, useState, useEffect, useRef } from 'react';
import { useStyles } from './CreateNewListingGallery.styles';
import {Typo, SplitView, InputField, Select, ImageUpload} from 'components/primitives';

const CreateNewListingGalleryView = (
  props,
) => {
  const classes = useStyles(props)
  const {errorField} = props

  return (
    <div className={classes.container}>
      {props.headerChildren ? props.headerChildren : null}
      <div className={classes.splitViewContainer}>
        <ImageUpload 
          onChange={props.setImages} 
          value={props.images.value}
          error={errorField == 'images'}
        />
        <Typo variant="body2" color="lightGrey" className={classes.description}>
          Click and drag the images to re-order them. The first image will be the primary image. We suggest using landscape format photos to best showcase your space. We require photos that are at least 1024px x 683px.
        </Typo>
        <InputField
          id="video"
          placeholder="Video URL"
          className={classes.videoField}
          value={props.video.value || ''}
          //error={Boolean(props.video.error) && props.video.touched}
          error={errorField == 'video'}
          onChange={event => props.setVideo(event.target.value)}
          onBlur={props.setTouchedVideo}
        />
        <Typo variant="body2" color="lightGrey" className={classes.description}>
          An optional video will be added to the end of the fullscreen gallery.
        </Typo>
      </div>
    </div>
  );
};

export default CreateNewListingGalleryView;
