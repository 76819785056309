import { makeStyles, createStyles } from '@mui/styles';
import { colors } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1.5),
      width: '100px'
    },
  }),
);
