import React, {Component} from 'react';
import Main from 'components/layouts/Main';
import { useStyles } from './ScheduledBookings.styles';
import { Box, Container } from '@mui/material';
import {HeaderSection, InfiniteScroll, ChangeBookingStatus} from 'components/modules';
import { Button, SplitView, Loading, BookingCard, Typo, Select, ListPlaceholder } from 'components/primitives';
import moment from 'moment'
import Link from 'components/primitives/Link';

const convertToDisplayDate = (date) => {
    const newDate = moment(date, 'YYYY-MM-DD').format('DD MMM, YYYY');
    return newDate;
};

const ScheduledBookings = (props) => {
    const classes = useStyles(props);
    const {
        startDate,
        endDate,
        changeBookingStatus
    } = props

    return (
        <Main>
            <div style={{ marginTop: '1em' }}>
                <HeaderSection
                    heading="Schedule"
                    subHeading="View all your upcoming bookings"
                    rightChildren={
                        <Typo variant="body1" color="blue">
                            <Link onClick={() => {
                                props.goToBookingHistory()
                            }}>
                                Booking History
                            </Link>
                        </Typo>
                    }
                />
                {/*
                <div className={classes.filterContainer}>
                    <Select
                        id="date"
                        /*
                        openOverride={props.openCalendar}
                        setIsOpenOverride={props.setOpenCalendar}
                        * /
                        options={[
                        {
                            label:
                            startDate && endDate
                                ? `${convertToDisplayDate(
                                    startDate.date,
                                )} - ${convertToDisplayDate(endDate.date)}`
                                : 'Date',
                            value:
                            startDate && endDate
                                ? `${convertToDisplayDate(
                                    props.startDate.date,
                                )} - ${convertToDisplayDate(endDate.date)}`
                                : 'Date',
                        },
                        ]}
                        customContent={true}
                        value={
                        startDate && endDate
                            ? `${convertToDisplayDate(
                                startDate.date,
                            )} - ${convertToDisplayDate(endDate.date)}`
                            : 'Date'
                        }
                        onChange={() => {}}
                        className={classes.field}
                    >
                        <Calendar
                            disablePast={true}
                            hookState={props.calendarHook}
                            disabledModifier={props.disabledModifier}
                            initialSelectedDate={props.initialDate}
                        />
                    </Select>
                    <Select
                        id="capacity"
                        value={
                            /*props.capacity.value ? props.capacity.value :* / 'placeholder'
                        }
                        //onChange={value => props.setCapacity(value)}
                        options={[
                            {
                            label: 'All Listings',
                            value: 'placeholder',
                            }
                            //...(startTimeOptions || []),
                        ]}
                    />
                </div>
                */}
                <Loading isLoading={props.loading}>
                    <InfiniteScroll
                        className={classes.resultsContainer}
                        hasMore={props.hasMore}
                        fetchMore={props.fetchMore}
                        emptyMessage={
                            <ListPlaceholder
                                description='There are no scheduled bookings at this time'
                            />
                        }
                    >
                        {props.results.map(result => (
                            <BookingCard
                                booking={result}
                                key={result.ID}
                                onClick={props.onViewBooking(result.ID)}
                                onFavouriteClick={() =>
                                    props.toggleFavourite(result.ID, result.favourite)
                                }
                                onContactGuest={props.onContactGuest(result)}
                                onViewRequest={props.onViewRequest(result.ID)}
                                onCancelBooking={props.onCancelBooking(result)}
                            />
                        ))}
                    </InfiniteScroll>
                </Loading>
                <ChangeBookingStatus {...changeBookingStatus}/>
            </div>
        </Main>
    )
}

export default React.memo(ScheduledBookings);
