import React, {Component} from 'react';
import Link from 'redux-first-router-link'
import Main from 'components/layouts/Main';
import { useStyles } from './BookingHistory.styles';
import { Box, Container } from '@mui/material';
import {HeaderSection, InfiniteScroll} from 'components/modules';
import { Button, SplitView, Loading, BookingCard, Typo, Select, DateRangeSelect, ListPlaceholder } from 'components/primitives';
import moment from 'moment'
import DayPicker, { DateUtils } from 'react-day-picker'
import {ArrowBack} from '@mui/icons-material'

const convertToDisplayDate = (date) => {
    const newDate = moment(date, 'YYYY-MM-DD').format('D MMM')
    return newDate
};

const BookingHistory = (props) => {
    const classes = useStyles(props);
    const {
        startDate,
        endDate,
        dateRange
    } = props
    const dateLabel = dateRange.from && dateRange.to ? `${convertToDisplayDate(dateRange.from)} - ${convertToDisplayDate(dateRange.to)}` : 'Date'

    return (
        <Main>
            <div style={{ marginTop: '1em' }}>
                <HeaderSection
                    breadcrumbs={[
                        { label: props.backLabel, icon: <ArrowBack fontSize='16px'/>, onClick: () => props.onBack() }
                    ]}
                    heading="Booking History"
                    subHeading="View all your completed and cancelled bookings"
                />
                <div className={classes.filterContainer}>
                    <DateRangeSelect value={props.dateRange} onChange={props.setDateRange} />
                    {/*
                    <Select
                        id="date"
                        openOverride={props.datePickerOpen}
                        setIsOpenOverride={props.setDatePickerOpen}
                        options={[
                            {
                                label: dateLabel,
                                value: dateLabel,
                            },
                        ]}
                        customContent={true}
                        value={dateLabel}
                        onChange={() => {}}
                        className={classes.field}
                    >
                        <div className={classes.headerContainer}>
                            <Typo style={{ marginLeft: 24 }} variant="h4">
                                Select Date
                            </Typo>
                            <button className={classes.clearButton} onClick={props.handleReset}>
                                <Typo variant="body2" color="lightGrey">
                                    Clear
                                </Typo>
                            </button>
                        </div>

                        <DayPicker
                            className="Selectable"
                            selectedDays={[dateRange.from, dateRange]}
                            onDayClick={props.onDayClick}
                            modifiers={{start: dateRange.from, end: dateRange.to}}
                        />
                    </Select>
                    */}
                    <Select
                      id="statusFilter"
                      value={props.statusFilterName}
                      onChange={props.setStatusFilterName}
                      options={[
                        {
                          label: 'All',
                          value: 'all',
                        },
                        {
                          label: 'Confirmed',
                          value: 'confirmed',
                        },
                        {
                          label: 'Pending',
                          value: 'pending',
                        },
                        {
                          label: 'Declined',
                          value: 'declined',
                        },
                      ]}
                    />
                </div>
                <Loading isLoading={props.loading}>
                    <InfiniteScroll
                        className={classes.resultsContainer}
                        hasMore={props.hasMore}
                        fetchMore={props.fetchMore}
                        emptyMessage={
                            <ListPlaceholder
                                description='There are no completed or cancelled bookings at this time'
                            />
                        }
                    >
                        {props.results.map(result => (
                            <BookingCard
                                booking={result}
                                key={result.ID}
                                onClick={props.onViewBooking(result.ID)}
                                onFavouriteClick={() =>
                                    props.toggleFavourite(result.ID, result.favourite)
                                }
                                onContactGuest={props.onContactGuest(result)}
                                onViewRequest={props.onViewRequest(result.ID)}
                                showStatus={true}
                            />
                        ))}
                    </InfiniteScroll>
                </Loading>
            </div>
        </Main>
    )
}

export default React.memo(BookingHistory);
