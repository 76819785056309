import React, {Component} from 'react';
import Main from 'components/layouts/Main';
import { useStyles } from './ManageListings.styles';
import { Box, Container } from '@mui/material';
import HeaderSection from 'components/modules/HeaderSection';
import { ListPlaceholder, Loading, AdminLocationCard, Typo } from 'components/primitives';
import {InfiniteScroll} from 'components/modules'
import Link from 'components/primitives/Link';
import { HouseIcon } from 'assets';

const ManageListings = (props) => {
    const classes = useStyles(props);
    return (
        <Main>
            <div style={{ marginTop: '1em' }}>
                <HeaderSection
                    heading="Manage"
                    subHeading="This is the home where all your active and inactive listings will be located. Here you can create, edit and delete your spaces"
                    rightChildren={
                        <Typo variant="body1" color="blue">
                            <Link onClick={() => {
                                props.onCreateNewListing()
                            }}>
                                Create New Listing
                            </Link>
                        </Typo>
                    }
                />
                <Loading isLoading={props.isLoading}>
                    <InfiniteScroll
                        className={classes.resultsContainer}
                        hasMore={props.hasMore}
                        fetchMore={props.fetchMore}
                        emptyMessage={
                            <ListPlaceholder
                                icon={<HouseIcon width={80} height={80}/>}
                                title='YOUR LISTINGS'
                                description='This is the home where all your listings (active and inactive) will be and where you can create, edit and delete spaces'
                            />
                        }
                    >
                        {props.results.map(result => (
                            <AdminLocationCard
                                key={result.id}
                                type={result.type}
                                capacity={result.capacity}
                                title={result.title}
                                address={result.address}
                                rating={result.rating}
                                price={result.price}
                                weekdayPrice={result.weekdayPrice}
                                weekendPrice={result.weekendPrice}
                                favourite={result.favourite}
                                images={result.images}
                                onClick={props.onViewListing(result.id)}
                                onFavouriteClick={() =>
                                    props.toggleFavourite(result.id, result.favourite)
                                }
                                onViewRequest={props.onViewRequest(result.id)}
                                onLockPress={props.onLockPress(result.id)}
                                onEditListing={props.onEditListing(result.id)}
                                isLocked={result.isLocked}
                                /*
                                onMouseOver={() =>
                                    setCenterMap(
                                        {
                                        lat: result.latitude,
                                        lng: result.longitude,
                                        },
                                        result.id,
                                    )
                                }
                                onMouseOut={() => {
                                    deselectMarker();
                                }}*/
                            />
                        ))}
                    </InfiniteScroll>
                </Loading>
            </div>
        </Main>
    )
}

export default React.memo(ManageListings);
