import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(3),
    },
    field: {
      marginBottom: theme.spacing(1.5),
    },
    messageContainer: {
      marginBottom: theme.spacing(1.5),
    },
  }),
);
