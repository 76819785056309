import React, { Suspense, useState } from 'react';
//import Loading from '../Loading';
import Loading from '../../primitives/Loading'

const LazyComponent = (srcRelativeImportPath: string, props: {} = {}) => {
  
  const Component = React.lazy(() => import(`../../../${srcRelativeImportPath}`))
/*
  const [Comp, setComp] = useState(null)
  const imp = import(`../../../${srcRelativeImportPath}`).then((r) => {
    console.log('imported!')
    setComp(r.default)
  })
  .catch((e) => {
    console.log('failed')
  })

  if (Comp) {
    return <Comp {...props} />
  }

  return (
    <div style={{ width: '100%', height: '100vh', marginTop: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Loading isLoading/>
    </div>
  )
*/
  return (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default LazyComponent;
