import { makeStyles, createStyles } from '@mui/styles';
import { colors, fontFamilies } from 'theme/themeConstants';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      paddingLeft: '7.5px',
      paddingRight: '7.5px',
      pointerEvents: 'auto',
    },
    imageContainer: {
      height: 0,
      overflow: 'hidden',
      paddingTop: '75.24752475%',
      background: colors.lightGrey,
      position: 'relative',
      borderRadius: '10px',
      marginBottom: '10px',
    },
    image: ({ image }) => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url('${image}')`,
      backgroundSize: 'cover',
    }),
    title: {
      marginBottom: '10px',
      pointerEvents: 'auto',
    },
    address: {
      marginBottom: '10px',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    bottomContainer: {
      display: 'flex',
    },
    separator: {
      margin: '100px',
    },
    filterBarContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      alignItems: 'center',
      alignSelf: 'stretch',
      [theme.breakpoints.down('sm')]: {
        marginTop: '15px',
      },
    },
    filterLabelContainer: {
      padding: 'auto 20px',
    },
    filterLabel: {
      height: '30px',
      minWidth: '150px',
      padding: '0 15px',
      borderRadius: '15px',
      border: 0,
      color: colors.white,
      backgroundColor: colors.blue,
      marginRight: '14px',
      fontFamily: fontFamilies.gilroy,
      fontSize: '15px',
      marginBottom: '5px',
    },
  }),
);
