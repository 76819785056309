import React, { FC, useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { MenuProps } from './Menu.props';

// @ts-ignore
const StyledMenu = props => (
  <Menu
    elevation={0}
    //getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
);

// @ts-ignore
const StyledMenuItem = props => <MenuItem {...props} />;

const MenuView: FC<MenuProps> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = {
    elements: [
      {
        text: 'Profile',
      },
      {
        text: 'My Spaces',
      },
      {
        text: 'Manage',
      },
      {
        text: 'Schedule',
      },
      {
        text: 'List a space',
      },
      {
        text: 'Booking Requests',
      },
      {
        text: 'Messages',
      },
      {
        text: 'Invite friends',
      },
      {
        text: 'Contact us',
      },
      {
        text: 'Log Out',
      },
      {
        text: 'T&Cs and Privacy Policy',
      },
    ],
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
      >
        Open Menu
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {data.elements.map((element, i) => (
          <StyledMenuItem key={i}>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary={element.text} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default React.memo(MenuView);
