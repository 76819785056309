import { userActions } from 'store/actions';
import { Dispatch } from 'redux';
import { updateProfileEndpoint, userSignedUrlEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import {uploadImages} from 'utils' 

const updateProfile = (data, onSuccess, onError) => async (
  dispatch,
  getState,
) => {
  try {
    data.image = (await uploadImages([data.image], userSignedUrlEndpoint, {}, dispatch, getState))[0]

    const updateProfileFetch = apiFetch(updateProfileEndpoint, data)(
      dispatch,
      getState,
    )
      .then(response => {
        response.data.image += `?nocache=${Date.now()}` // force change of image path to trigger a refetch from the browser
        if (response && onSuccess) {
          onSuccess(response.data);
        }
        return response.data;
      })
      .catch(error => {
        if (error && onError) {
          onError(error);
        }
        return error;
      });
    dispatch(userActions.updateProfile(updateProfileFetch))
  } catch (error) {
    if (onError) {
      onError(error)
    }
  }
};

export default updateProfile;
