import getProfile from './getProfile';
import updateProfile from './updateProfile';
import inviteAFriend from './inviteAFriend';
import addFavourite from './addFavourite';
import getFavourites from './getFavourites';
import getFavouriteProducts from './getFavouriteProducts'
import removeFavourite from './removeFavourite';

import apiFetch from 'services/fetch/apiFetch'
import { getUsersEndpoint } from 'services/fetch/apiEndpoints'

export const getUsers = (query) => async (dispatch, getState) => {
  return apiFetch(getUsersEndpoint, query)(dispatch, getState)
}

const userThunks = {
  getProfile,
  updateProfile,
  inviteAFriend,
  addFavourite,
  getFavourites,
  getFavouriteProducts,
  removeFavourite,
};

export default userThunks;
