import React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import { useStyles } from './Stepper.styles';
import { Button, Typo } from 'components/primitives';

// https://mui.com/components/steppers/
// @ts-ignore
const StepperView = props => {
  const {
    activeStep,
    onNext,
    onBack,
    steps,
    nextDisabled,
    nextBusy,
    errorMessage
  } = props
  const classes = useStyles();
  const stepCount = steps.length;
  return (
    <div className={classes.container}>
      <MobileStepper
        variant="progress"
        steps={stepCount + 1}
        position="static"
        activeStep={activeStep + 1}
        className={classes.mobileStepper}
        classes={{
          progress: classes.mobileStepper
        }}
      />
      <div className={classes.stepLabelContainer}>
        {steps.map((step, index) => {
            const {label} = step
            return (
              <Typo
                variant="body1"
                className={activeStep >= index ? classes.stepDone : classes.stepTodo }
              >
                {label}
              </Typo>
            )
        })}
      </div>
    </div>
  );
};

export default React.memo(StepperView);
