import React, {useState, useEffect, Fragment} from 'react'
import {useDispatch} from 'react-redux'
import {Loading, HorizontalLine, InputField, Button, Typo} from 'components/primitives'
import {makeStyles, createStyles} from '@mui/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import bookingThunks from 'thunks/booking'

export const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },
    buttonContainer: {

    },
    button: {
        width: '150px'
    },
    heading: {
        textTransform: 'none'
    },
    link: {
        cursor: 'pointer',
    },
  }),
)

const ChangeBookingStatus = (props) => {
    const {booking, status, statusReason, setStatusReason, open, onConfirm, onClose, busy} = props
    const classes = useStyles(props)
    let title = ''
    switch (status) {
        case 'declined':
            title = `Decline booking?`
            break

        case 'cancelled':
            title = `Cancel booking?`
            break
    }

    return (
        <Dialog 
            fullWidth
            maxWidth="sm"
            open={open}
        >
            <DialogTitle>
                <Typo variant="h2" className={classes.heading}>
                    {title}
                </Typo>
            </DialogTitle>
            <DialogContent className={classes.contentContainer}>
                <InputField
                    id="reason"
                    placeholder="Reason"
                    value={statusReason}
                    className={classes.field}
                    onChange={(event) => setStatusReason(event.target.value)}
                />

            </DialogContent>

            <DialogActions>
                <Typo
                    variant="body1"
                    component="a"
                    color="#ED1F54"
                    style={{ marginRight: '1em' }}
                    onClick={!busy && onClose}
                    className={classes.link}
                    disabled={busy}
                >
                    Cancel
                </Typo>

                <Button
                    text="Confirm"
                    loadingText='Confirming'
                    className={classes.button}
                    onClick={onConfirm}
                    type="submit"
                    loading={busy}
                    disabled={busy}
                />
            </DialogActions>
        </Dialog>
    )
}

export default ChangeBookingStatus

export const useChangeBookingStatus = (onSuccess, onError) => {
    const [open, setOpen] = useState(false)
    const [busy, setBusy] = useState(false)
    const [status, setStatus] = useState(false)
    const [statusReason, setStatusReason] = useState('')
    const [booking, setBooking] = useState(false)
    const dispatch = useDispatch()

    const saveBooking = (booking, status, statusReason) => {
        if (busy) {
            return
        }

        const newBooking = {...booking, status, statusReason}
        setBusy(true)
        dispatch(bookingThunks.add(newBooking,
            (success) => {
                console.log('success')
                onSuccess && onSuccess(newBooking)
                setBusy(false)
                setOpen(false)
            },
            (error) => {
                onError && onError(error)
                setBusy(false)
                setOpen(false)
            }
        ))
    }

    const onClose = () => {
        if (busy) {
            return
        }

        setOpen(false)
        setBusy(false)
    }

    const onConfirm = () => {
        if (busy) {
            return
        }
        
        saveBooking(booking, status, statusReason)
    }

    const show = (booking, status) => {
        if (busy) {
            return
        }

        setStatus(status)
        setBooking(booking)

        switch (status) {
            case 'confirmed':
                saveBooking(booking, status, statusReason)
                return
        }

        setOpen(true)
    }

    return {
        status,
        statusReason,
        setStatusReason,
        busy,
        show,
        open,
        onClose,
        onConfirm
    }
}

