
import { createTheme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import components from './components';

export { ThemeProvider } from '@mui/material/styles';

const t = createTheme({
  palette,
  typography,
  components,
});

export const theme = t;

