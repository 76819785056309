import { makeCreateActions, presetActions } from 'utils/Redux';
import { User } from 'types/User';

const ns = 'AUTH';
const createAuthActions = makeCreateActions(ns);

interface SocialCredentials {
  type: string;
  // eslint-disable-next-line camelcase
  access_token: string;
  email: string;
  name: string;
  
  picture: any;
  userID: string;
}

const authActions = createAuthActions({
  login: presetActions.makeAsyncAction<User>(`${ns}/LOGIN`),
  updateUser: presetActions.makeAsyncAction<User>(`${ns}/UPDATE_USER`),
  socialAccess: presetActions.makeAsyncAction<User>(`${ns}/SOCIAL_ACCESS`),
  logout: presetActions.noPayload,
  signup: presetActions.makeAsyncAction<User>(`${ns}/SIGNUP`),
  forgotPassword: presetActions.makeAsyncAction(`${ns}/FORGOT_PASSWORD`),
  resetPassword: presetActions.makeAsyncAction(`${ns}/RESET_PASSWORD`),
  updatePassword: presetActions.makeAsyncAction(`${ns}/UPDATE_PASSWORD`),
  sendVerificationEmail: presetActions.makeAsyncAction(
    `${ns}/SEND_VERIFICATION_EMAIL`,
  ),
});

export default authActions;
