import React, { FC } from 'react';
import { Box } from '@mui/material';
import { TileGroupProps } from './TileGroup.props';
import { Tile } from '../../primitives';

const TileGroupView: FC<TileGroupProps> = ({ elements }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {elements.map((element, i: number) => (
        <Box
          style={{
            marginLeft: '0.5em',
            marginRight: '0.5em',
          }}
          key={i}
        >
          <Tile {...element} />
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(TileGroupView);
