import React from 'react';
import { Box, useTheme } from '@mui/material';
import format from 'date-fns/format';
import { CalendarFilter, HeaderSection, InfiniteScroll } from '../../../components/modules';
import {
  Link,
  Typo,
  Avatar,
  HorizontalLine,
  Breadcrumbs,
  Loading,
  StatusLabel,
  ListPlaceholder
} from '../../../components/primitives';
import Main from '../../../components/layouts/Main';
import { useStyles } from './MyBookingHistory.styles';
import { SideBar } from '../../../components/layouts';
import {bookingTimeData} from 'services/api'

const Booking = (props) => {
  const {
    // @ts-ignore
    myBooking,
    // @ts-ignore
    onViewDetailsClick,
    // @ts-ignore
    onWriteReviewClick,
    // @ts-ignore
    goToHostDetails,
  } = props
  const id = myBooking.ID
  const {startOn, endOn} = bookingTimeData(myBooking)
  const product = myBooking.product
  const classes = useStyles({ image: product && product.images && product.images[0] });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 4px 2px rgba( 0, 0, 0, 0.1 )',
        overflow: 'hidden',
        borderRadius: '16px',
      }}
    >
      <button
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '1em',
          cursor: 'pointer',
          color: 'inherit',
          outline: 'inherit',
          font: 'inherit',
          border: 'none',
          width: '100%',
          backgroundColor: 'inherit',
        }}
        onClick={() => goToHostDetails(myBooking.provider)}
      >
        <Avatar size={32} margin="0 0.5em 0 0" src={myBooking.provider.image}/>
        <Typo color="lightGrey">Booked with&nbsp;</Typo>
        <Typo>{myBooking.provider.name}</Typo>
      </button>
      <HorizontalLine />
      <div
        style={{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Link
          onClick={() => onViewDetailsClick(id)}
          style={{ textDecoration: 'none' }}
        >
          <Typo variant="h3">{product.title}</Typo>
        </Link>
        <div style={{ paddingTop: '1em' }}>
          <Typo color="lightGrey">
            {startOn.format('DD MMM YYYY, hh:mm A')} - {endOn.format('DD MMM YYYY, hh:mm A')}
          </Typo>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 2,
            width: '100%'
          }}
        >
          <Typo variant="body1" color="lightGrey" component="span">
            Status
          </Typo>
          <StatusLabel
            status={myBooking.status}
          />
        </div>
      </div>
      <div style={{ height: '1em' }}>
        <HorizontalLine />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingLeft: '1em',
          paddingRight: '1em',
          paddingBottom: '1em',
        }}
      >
        <Typo color="lightGrey">
          <Link onClick={() => onViewDetailsClick(id)}>View Details</Link>
        </Typo>
        {!myBooking.review && myBooking.status == 'confirmed' &&
          <Typo color="blue">
            <Link onClick={() => onWriteReviewClick(id)}>Write Review</Link>
          </Typo>
        }
        {myBooking.review && myBooking.status == 'confirmed' &&
          <Typo>Review Submitted</Typo>
        }
      </div>
    </div>
  );
};

const View = ({
  hasMore,
  fetchMore,
  loading,
  myBookings,
  onViewDetailsClick,
  onWriteReviewClick,
  goToHostDetails,
  breadcrumbs,
  calendarHook,
  dateFilterOpen,
  setDateFilterOpen,
  dateRange,
  onSubmitDate,
}) => {
  const theme = useTheme();
  const classes = useStyles({ image: '' });
  return (
    <Box style={{ height: '100vh' }}>
      <Main fullWidth>
        <div
          style={{
            maxHeight: '100%',
            marginTop: 16,
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: '2em',
            paddingRight: '2em',
          }}
        >
          <div style={{ width: '100%', maxWidth: '1200px' }}>
            <Breadcrumbs elements={breadcrumbs} hideLast />
            <HeaderSection
              heading="My Bookings History"
              subHeading="View your completed bookings"
              subHeadingComponents={
                <div
                  className={classes.filterBarContainer}
                  style={{ marginLeft: '1em' }}
                >
                  <div className={classes.filterLabelContainer}>
                    <button
                      className={classes.filterLabel}
                      onClick={() => setDateFilterOpen(!dateFilterOpen)}
                    >
                      {!dateRange || !dateRange.from
                        ? 'Select Date'
                        : format(dateRange.from, 'MMM d')}
                      {!dateRange || !dateRange.to
                        ? ''
                        : ` - ${format(dateRange.to, 'MMM d')}`}
                    </button>
                  </div>
                </div>
              }
            />

            <Loading isLoading={loading}>
              <InfiniteScroll
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, 345px)',
                  gridGap: '2em',
                  justifyContent: 'center',
                  paddingBottom: '2em',
                }}
                hasMore={hasMore}
                fetchMore={fetchMore}
                emptyMessage={
                  <ListPlaceholder
                      description='There are no completed bookings at this time'
                  />
                }
              >
                {myBookings.map((myBooking, i) => (
                  <Booking
                    key={i}
                    id={myBooking.id}
                    myBooking={myBooking}
                    onViewDetailsClick={onViewDetailsClick}
                    onWriteReviewClick={onWriteReviewClick}
                    goToHostDetails={goToHostDetails}
                  />
                ))}
              </InfiniteScroll>
            </Loading>
          </div>
        </div>
      </Main>
      <SideBar open={dateFilterOpen} onClose={() => setDateFilterOpen(false)}>
        <CalendarFilter
          state={calendarHook}
          onSubmitDate={(...p) => {
            onSubmitDate(...p);
          }}
          disablePast={false}
        />
      </SideBar>
    </Box>
  );
};

export default React.memo(View);
