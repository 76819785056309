import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: 32,
    },
    separator: {
      marginTop: 32,
      marginBottom: 64,
    },
    subTitle: {
      marginTop: 24,
    },
    description: {
      marginTop: 16,
    },
  }),
);
