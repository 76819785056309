import React from 'react'
import { Container, Hidden } from '@mui/material';
import { useStyles } from './WebsiteFooter.styles';
import { AirplacerIcon } from 'assets';

export const WebsiteFooter = (props) => {
    const classes = useStyles()
    return (
        <footer className={classes.footer}>
            <div className={classes.bgImage}>
                <img loading="lazy" width="237" height="295" src="http://airplacer.com/wp-content/uploads/2022/01/ap-logo-large-wt.png" alt="" title="ap-logo-large-wt" srcset="https://airplacer.com/wp-content/uploads/2022/01/ap-logo-large-wt.png 475w, https://airplacer.com/wp-content/uploads/2022/01/ap-logo-large-wt-242x300.png 242w" sizes="(max-width: 475px) 100vw, 475px" class="wp-image-210"/>
            </div>

            <div className={classes.container}>
                <Container className={classes.containerContainer}>
                    <div className={classes.leftContainer}>
                        <AirplacerIcon width={221} height={53} className={classes.airplacerIcon}/>

                        <p className={classes.p}>
                            Airplacer Pty Ltd<br/>
                            D'Arcy Barron - CEO<br/>
                            <a href="mailto:support@airplacer.com" className={classes.a}>support@airplacer.com</a>
                        </p>

                        <p className={classes.p}>
                            5/151 Foveaux St<br/>
                            Surry Hills, NSW 2010
                        </p>

                        <p>Designed by <a href="https://kiinagency.com.au/" className={classes.a}>Kiin Agency</a></p>

                        <p className={classes.p}>
                            <a href="https://instagram.com/airplacer" target="_blank" rel="noopener">
                                <img loading="lazy" src="http://airplacer.com/wp-content/uploads/2022/01/insta-ico.png" width="29" height="29" alt="Instagram" class="wp-image-226 alignnone size-full" style={{marginRight: '14px'}}/>
                            </a>
                            &nbsp;
                            <a href="https://www.linkedin.com/company/airplacer/" target="_blank" rel="noopener">
                                <img loading="lazy" src="http://airplacer.com/wp-content/uploads/2022/01/linkedIn-ico.png" width="27" height="27" alt="Instagram" class="wp-image-225 alignnone size-full"/>
                            </a>
                        </p>

                    </div>

                    <div className={classes.rightContainer}>

                        <div className={classes.menuCol}>
                            <a href="https://airplacer.com/terms-and-conditions/" className={classes.menuA}>Terms and Conditions</a>
                            <a href="https://airplacer.com/privacy-policy/" className={classes.menuA}>Privacy Policy</a>
                            <a href="https://airplacer.com/licenceagreement/" className={classes.menuA}>Licence Agreement</a>
                            <a href="https://airplacer.com/insurances/" className={classes.menuA}>Insurances</a>
                        </div>

                        <div className={classes.menuCol}>
                            <a href="https://airplacer.com/about-us/" className={classes.menuA}>About Us</a>
                            <a href="https://airplacer.com/blog/" className={classes.menuA}>Blog</a>
                            <a href="https://airplacer.com/contact-us/" className={classes.menuA}>Contact Us</a>
                            <a href="https://airplacer.com/faqs/" className={classes.menuA}>FAQs</a>
                        </div>
                    </div>
                </Container>
            </div>
        </footer>

    )
}
